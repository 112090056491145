import { arrow, mdiArrowLeft, mdiDelete } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, Form, Layout, Select } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import { useNavigate, useParams } from 'react-router-dom'
import { FormRender, RenderSchema } from '../helpers/FormRenderer'
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { Option } from 'antd/lib/mentions'
import { useRecoilState, useRecoilValue } from 'recoil'
import { biltyInvestorByIdSelector, brokerByIdSelector, getAllLanes, getLanesbyIds } from '../recoil/selectors'
import { useFetch } from '../hooks/graphql'
import { useAddEditVendor } from '../hooks/modalFinishHooks'
import { Spin } from 'antd'
import { useEffect, useState } from 'react'
import { notification } from 'antd'

const antIcon = (
	<LoadingOutlined
		style={{
			fontSize: 40,
		}}
		spin
	/>
)

const AddEditBiltyInvestorPage = () => {
	const { segment, action, brokerId } = useParams()
	const investorData = useRecoilValue(biltyInvestorByIdSelector({ _id: brokerId }))
	const [carrierForm] = Form.useForm()
	const formWatch = Form.useWatch(undefined, carrierForm)
	const navigate = useNavigate()
	const [createNewVendor, createBrokerLoading] = useAddEditVendor()
	const [brokerLoading, setLoading] = useState(false)
	const [api, contextHolder] = notification.useNotification()
	const loading = useFetch({
		type: 'BiltyInvestors',
		atomKey: 'biltyInvestor',
	})

	const openNotification = (text) => {
		api.open({
			message: 'Validation Error',
			description: text,
			duration: 0,
		})
	}

	const onFinish = async ({ values }) => {
		console.log({ values })

		setLoading(true)
		const resp = await createNewVendor({ values: values, props: { edit: action === 'edit', type: 'bilty-investors', _id: brokerId } })
		if (resp.type === 'error') {
			openNotification('Phone Number Already Exists in the Backend - Kindly contact Product Team')
			setLoading(false)
			return
		}
		setLoading(false)
		navigate(-1)
	}

	useEffect(() => {
		if (action === 'edit') {
			const initialValues = {
				...investorData,
				phoneNumber: investorData?.phoneNumber?.slice(3),
			}
			carrierForm.setFieldsValue(initialValues)
		}
	}, [investorData])

	return (
		<>
			<Layout style={{ minHeight: '100vh' }}>
				<Header className='flex flex-row px-0 bg-white shadow' style={{ position: 'fixed', width: '100%', zIndex: 1 }}>
					<div className='flex flex-row h-[72px] w-full items-center justify-between px-6'>
						<div className='flex flex-row'>
							<div>
								<Icon className='w-[78.03px] h-8 bg-stone-50 rounded-[80px]' path={mdiArrowLeft} onClick={() => navigate(-1)} size={1} color={'gray'}></Icon>
							</div>
							<div class="text-zinc-800 text-base font-semibold font-['Inter'] px-4">Back</div>
						</div>
						<div class="text-center text-zinc-800 text-base font-bold font-['Inter']">Adding new Bilty Investor</div>
						<div>
							{brokerLoading ? (
								<Spin className='text-center' indicator={antIcon} />
							) : (
								<button onClick={() => carrierForm.submit()}>
									<div className="w-[168.74px] text-center text-white bg-sky-600 rounded text-sm font-bold font-['Inter'] px-1 py-3">Confirm and Add</div>
								</button>
							)}
						</div>
					</div>
				</Header>
				<Content className=' bg-gray-100' style={{ marginTop: '72px', overflow: 'auto' }}>
					{contextHolder}
					<Form layout='vertical' form={carrierForm} onFinish={(values) => onFinish({ values: values })}>
						<div className='flex flex-col pb-11'>
							<div className='flex flex-col items-center '>
								<div className='flex flex-col items-start'>
									<div class="h-[17px] text-neutral-400 text-sm font-medium font-['Inter'] mt-[24px] mb-[16px]">Basic Investor Information</div>
									<div className=' flex flex-col w-[729px] h-fit'>
										<div className='bg-white rounded-md overflow-clip p-11'>
											{RenderSchema([
												[
													{ label: 'Name', name: ['name'], required: true, type: 'input' },
													{
														label: 'Contact Number',
														name: ['phoneNumber'],
														required: true,
														type: 'phoneNumber',
														disabled: action === 'edit',
													},
												],

												{
													label: 'CNIC Number',
													name: ['cnic'],
													// initialValue: '17301-4300421-9',
													type: 'input',
													inputType: 'number',
												},
											])}
										</div>
									</div>
									<div class=" text-neutral-400 text-sm font-medium font-['Inter'] mt-[24px] mb-[16px]">Bank Information</div>
									<div className=' flex flex-col w-[729px] h-fit'>
										<div className='bg-white rounded-md py-[39px]'>
											<div>
												<Form.List
													name='accounts'
													validateTrigger={['onChange', 'onBlur']}
													initialValue={[{ bankName: 'Al Baraka Bank (Pakistan) Limited' }]}
													rules={[
														{
															validator: async (_, values) => {
																if (!values || values.length < 1) {
																	openNotification('Add relevant Bank Details')
																	throw new Error("At least one 'Bank Info' section is required.")
																}
															},
														},
													]}
												>
													{(fields, { add, remove }) => (
														<div>
															{fields.map(({ key, name, fieldKey, ...restField }, index) => (
																<div key={key}>
																	<div className='px-11 pb-4'>
																		{RenderSchema([
																			[
																				{
																					type: 'input',
																					hidden: true,
																					name: [name, '_id'],
																				},
																				{
																					label: 'Select Bank',
																					value: 'bank',
																					required: true,
																					name: [name, 'bankName'],
																					showSearch: true,
																					type: 'select',
																					options: [
																						'Al Baraka Bank (Pakistan) Limited',
																						'Allied Bank Limited (ABL)',
																						'Askari Bank',
																						'Bank Alfalah Limited (BAFL)',
																						'Bank Al-Habib Limited (BAHL)',
																						'BankIslami Pakistan Limited',
																						'Bank of Punjab (BOP)',
																						'Bank of Khyber',
																						'Deutsche Bank A.G',
																						'Dubai Islamic Bank Pakistan Limited (DIB Pakistan)',
																						'Faysal Bank Limited (FBL)',
																						'First Women Bank Limited',
																						'Habib Bank Limited (HBL)',
																						'Habib Metropolitan Bank Limited',
																						'Industrial and Commercial Bank of China',
																						'Industrial Development Bank of Pakistan',
																						'JS Bank Limited',
																						'MCB Bank Limited',
																						'MCB Islamic Bank Limited',
																						'Meezan Bank Limited',
																						'National Bank of Pakistan (NBP)',
																						'Summit Bank Pakistan',
																						'Standard Chartered Bank (Pakistan) Limited (SC Pakistan)',
																						'Sindh Bank',
																						'The Bank of Tokyo-Mitsubishi UFJ (MUFG Bank Pakistan)',
																						'United Bank Limited (UBL)',
																						'Zarai Taraqiati Bank Limited',
																					],
																				},
																				{
																					label: 'Account Number',
																					value: 'accountNumber',
																					name: [name, 'accountNumber'],

																					required: true,
																					type: 'input',
																					// inputType: 'number',
																				},
																			],
																			{
																				label: 'Account Title',
																				value: 'accountTitle',
																				name: [name, 'accountTitle'],
																				required: true,
																				type: 'input',
																				justifyCenter: false,
																			},
																		])}
																	</div>
																	{fields.length > 1 && (
																		<div className='flex flex-row justify-end mr-[44px]'>
																			<Button
																				type='text'
																				danger
																				icon={<DeleteOutlined />} // Red delete icon
																				onClick={() => {
																					remove(name)
																				}}
																			/>
																		</div>
																	)}

																	{FormRender({
																		type: 'divider',
																	})}
																	{index === fields.length - 1 && (
																		<Button
																			className='flex flex-row items-center ml-[44px] justify-center w-[635px] h-[50px] bg-white rounded-lg border-2 border-dashed border-sky-600'
																			type='dashed'
																			onClick={() => {
																				add()
																			}}
																		>
																			Add Another Account
																		</Button>
																	)}
																</div>
															))}
															{fields.length === 0 && (
																<Button
																	className='flex flex-row items-center ml-[44px] justify-center w-[635px] h-[50px] bg-white rounded-lg border-2 border-dashed border-sky-600'
																	type='dashed'
																	onClick={() => {
																		add()
																	}}
																>
																	Add Account +
																</Button>
															)}
														</div>
													)}
												</Form.List>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Form>
				</Content>
			</Layout>
		</>
	)
}

export default AddEditBiltyInvestorPage
