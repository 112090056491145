import { useFetch } from './graphql'

const useFiltrationData = () => {
	const supplierLoading = useFetch({ type: 'Suppliers', atomKey: 'supplier' })
	const brokerLoading = useFetch({ type: 'Brokers', atomKey: 'broker' })
	const laneLoading = useFetch({ type: 'Lanes', atomKey: 'lane' })
	const corporateLoading = useFetch({ type: 'Corporates', atomKey: 'corporate' })
	const terminalLoading = useFetch({ type: 'Terminals', atomKey: 'terminal' })

	return [supplierLoading, brokerLoading, laneLoading, corporateLoading, terminalLoading].some((loading) => loading)
}

export default useFiltrationData
