import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { NavEnums } from '../../helpers/Enums'

const NavBreadCrumbs = ({ pathName }) => {
	const formattedPath = pathName && pathName?.slice(1)?.split('/')
	let currLoc
	let prevLoc
	const allPaths = formattedPath.reduce((obj, loc, index) => {
		currLoc = index === 0 ? NavEnums[loc] : currLoc?.children?.[loc]
		if (currLoc?.title) {
			obj[loc] = { title: currLoc?.title, link: prevLoc ? `${prevLoc}/${currLoc?.link || loc}` : '/' + (currLoc?.link || loc) }
			prevLoc = currLoc?.link
		}

		return obj
	}, {})
	const validPaths = useMemo(() => formattedPath?.filter((loc) => allPaths[loc]), [formattedPath, allPaths])
	return validPaths?.map((loc, index) =>
		index + 1 < validPaths?.length ? (
			<Link to={allPaths[loc].link} key={`breadcrumb-${allPaths[loc].link}-${index}`}>
				<div className={`font-bold text-[16px] ${index + 1 === validPaths?.length ? ' text-black-400' : 'text-black-200'}`}>
					{`${index > 0 ? '-' : ''}  ${allPaths[loc].title}`}&nbsp;&nbsp;
				</div>
			</Link>
		) : (
			<div
				key={`breadcrumb-${allPaths[loc].link}-${index}`}
				className={`font-bold text-[16px] ${index + 1 === validPaths?.length ? ' text-black-400' : 'text-black-200'}`}
			>
				{`${index > 0 ? '-' : ''}  ${allPaths[loc].title}`}&nbsp;&nbsp;
			</div>
		)
	)
}

export default NavBreadCrumbs
