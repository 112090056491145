import { selectorFamily } from 'recoil'
import {
	AssignmentSearchEnums,
	bPayAdmins,
	VehicleTypeEnums,
	FilterTypeEnums,
	GraphQLEnums,
	PageSearchEnums,
	PaymentStatusEnums,
	PostAssignmentSearchEnums,
	VendorManagementSearchEnums,
} from '../helpers/Enums'
import * as AllAtoms from './atoms'

import moment from 'moment'
import { Capitalize, checkBulkAssignable, filterData, prepareProperties, checkArray, removeTypeName } from '../helpers/Functions'

/* ============================================================================================== */
/* ======================================== SELECTORS =========================================== */
/* ============================================================================================== */

export const authSelector = selectorFamily({
	key: 'authSelector',
	get:
		(props) =>
		({ get }) => {
			const users = get(AllAtoms.userAtom)
			const user = users?.[0]
			const authorized =
				user?.UserType?.filter((type) => ['SuperAdmin', 'Admin', 'ImportExportOps', 'CallSupportAdmin', 'FinanceBiltyManagement'].includes(type?.userType))
					?.length >= 1
			return users ? { user: user?.UserType ? user : 'no user', authorized } : undefined
		},
})

/* ====================================== JOB ASSIGNMENT ========================================= */
export const checkBulkSelector = selectorFamily({
	key: 'checkBulkSelector',
	get:
		(props) =>
		({ get }) => {
			const { selectedRows } = props
			const filterState = get(AllAtoms.filtersAtom)
			return checkBulkAssignable({ jobs: selectedRows, filters: filterState })
		},
})
export const numRowsSelector = selectorFamily({
	key: 'numRowsSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, type, combined = false } = props

			const jobConfig = get(AllAtoms.jobCountAtom)
			const numOrders = combined
				? {
						assignment:
							segment === 'import'
								? jobConfig?.importAssignment
									? Object.values(removeTypeName(jobConfig?.importAssignment))?.reduce((sum, count) => sum + count, 0)
									: 0
								: segment === 'longHaul'
								? jobConfig?.longHaulAssignment
									? Object.values(removeTypeName(jobConfig?.longHaulAssignment))?.reduce((sum, count) => sum + count, 0)
									: 0
								: jobConfig?.exportAssignment?.normal,
						'post-assignment':
							segment === 'import'
								? jobConfig?.importPostAssignment
									? Object.values(removeTypeName(jobConfig?.importPostAssignment))?.reduce((sum, count) => sum + count, 0)
									: 0
								: segment === 'longHaul'
								? jobConfig?.longHaulPostAssignment?.normal
								: jobConfig?.exportPostAssignment?.normal,
				  }
				: type === 'assignment'
				? segment === 'Import'
					? jobConfig?.importAssignment
					: segment === 'Export'
					? {}
					: jobConfig?.longHaulAssignment
				: segment === 'Import'
				? jobConfig?.importPostAssignment
				: segment === 'Export'
				? {}
				: {}
			return numOrders
		},
})

export const numFilteredJobsSelector = selectorFamily({
	key: 'numFilteredJobsSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, type } = props

			const totalJobs = get(
				type === 'assignment'
					? jobsSelector({ segment, tab })
					: type === 'brokers'
					? brokerManagementSelector()
					: type === 'suppliers'
					? supplierManagementSelector()
					: type === 'bilty-investors'
					? biltyInvestorManagementSelector()
					: postAssignmentJobsSelector({ segment, tab })
			)
			const filteredJobs = filterData({
				data: totalJobs,
				filterState: get(AllAtoms.filtersAtom),
				searchEnums: PageSearchEnums[type]?.[segment !== 'vendorManagement' ? Capitalize(segment) : 'default'],
			})
			return { total: totalJobs?.length, filtered: filteredJobs?.length }
		},
})
export const filteredJobsSelector = selectorFamily({
	key: 'filteredJobsSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, pageNo, pageSize } = props
			const filteredJobs = filterData({
				data: get(jobsSelector({ segment, tab })),
				filterState: get(AllAtoms.filtersAtom),
				searchEnums: AssignmentSearchEnums[Capitalize(segment)],
			})

			return [
				Object.assign([], filteredJobs)
					.sort((job1, job2) => job2.jobNumber - job1.jobNumber)
					?.slice(pageNo * pageSize - pageSize, pageNo * pageSize),
				Object.assign([], filteredJobs).sort((job1, job2) => job2.jobNumber - job1.jobNumber)?.length,
			]
		},
})

export const jobByIDSelector = selectorFamily({
	key: 'jobByIDSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, ids, tab } = props
			return get(jobsSelector({ segment, tab })).filter((job) => ids?.includes(job?._id))
		},
})

export const jobsSelector = selectorFamily({
	key: 'jobsSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab } = props
			let jobs = []
			if (segment === 'import') {
				jobs = get(AllAtoms.importOrdersAtom)
			} else if (segment === 'export') {
				jobs = get(AllAtoms.exportOrdersAtom)
			} else if (segment === 'longHaul') {
				jobs = get(AllAtoms.longHaulOrdersAtom)
			}
			return tab === 'lot'
				? getLongHaulLotOrders({ orders: jobs })
				: getJobs({
						jobs: jobs,
						segment,
						tab,
				  })
		},
})

/* ====================================== POST-ASSIGNMENT ======================================== */
export const amountTotalsSelector = selectorFamily({
	key: 'amountTotalsSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, id } = props
			let total = 0,
				totalRemaining = 0,
				totalPaid = 0
			const job = get(postAssignmentSidebarSelector({ segment, tab, id }))
			job?.charges?.forEach((charge) => {
				total += charge?.amount
				if (charge?.status === 'paid') {
					totalPaid += charge?.amount
				}
			})
			job?.revenues?.forEach((revenue) => {
				total -= revenue?.amount
				if (revenue?.status === 'paid') {
					totalPaid -= revenue?.amount
				}
			})
			totalRemaining = total - totalPaid
			return {
				total,
				totalRemaining,
				totalPaid,
			}
		},
})
export const jobRevenuesByStatusSelector = selectorFamily({
	key: 'jobRevenuesByStatusSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, id } = props
			const job = get(postAssignmentSidebarSelector({ segment, tab, id }))
			return job?.revenues?.reduce(
				(revenues, revenue) => ({
					...revenues,
					[`${revenue.status}`]: [...(revenues?.[revenue.status] || []), revenue],
				}),
				{}
			)
		},
})
export const jobChargesByStatusSelector = selectorFamily({
	key: 'jobChargesByStatusSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, id } = props
			const job = get(postAssignmentSidebarSelector({ segment, tab, id }))
			return job?.charges?.reduce(
				(charges, charge) => ({
					...charges,
					[`${charge.status}`]: [...(charges?.[charge.status] || []), charge],
				}),
				{}
			)
		},
})
export const chargeTypesForFormSelector = selectorFamily({
	key: 'chargeTypesForFormSelector',
	get:
		() =>
		({ get }) => {
			const chargeTypes = get(AllAtoms.chargeTypesAtom)

			return chargeTypes?.map((chargeType) => {
				const { customFields, ...rest } = chargeType
				const formCustomFields = customFields?.map((field) => {
					let modifiedField = field
					if (field.type === 'select' || field.type === 'radioGroup') {
						modifiedField = {
							...modifiedField,
							displayProperty: 'displayProperty',
							valueProperty: 'valueProperty',
						}
					}
					return modifiedField
				})
				return {
					...rest,
					customFields: formCustomFields,
				}
			})
		},
})

export const jobChargeSelector = selectorFamily({
	key: 'jobChargeSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, id, chargeId } = props
			const job = get(postAssignmentSidebarSelector({ segment, tab, id }))
			return job?.charges?.find((charge) => charge?._id === chargeId)
		},
})
export const jobRevenueSelector = selectorFamily({
	key: 'jobRevenueSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, id, revenueId } = props
			const job = get(postAssignmentSidebarSelector({ segment, tab, id }))
			return job?.revenues?.find((revenue) => revenue?._id === revenueId)
		},
})
export const postAssignmentJobByIdsSelector = selectorFamily({
	key: 'postAssignmentJobByIdsSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, ids } = props
			let orders = GraphQLEnums.Orders.atoms?.[segment] && get(GraphQLEnums.Orders.atoms?.[segment])
			return groupByJobSidebar({ orders, segment, tab })
				.filter((job) => ids?.includes(job?._id))
				?.map((job) => ({
					...job,
					broker: {
						...job?.broker,
						accounts: job?.broker?.accounts?.filter((account) => !account?.deactivated),
					},
				}))
		},
})
export const postAssignmentSidebarSelector = selectorFamily({
	key: 'postAssignmentSidebarSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, id } = props
			let orders = GraphQLEnums.Orders.atoms?.[segment] && get(GraphQLEnums.Orders.atoms?.[segment])
			const job = groupByJobSidebar({ orders, segment, tab }).find((job) => id === job?._id)
			return {
				...job,
				broker: {
					...job?.broker,
					accounts: job?.broker?.accounts?.filter((account) => !account?.deactivated),
				},
			}
		},
})
export const postAssignmentJobByIDSelector = selectorFamily({
	key: 'postAssignmentJobByIDSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, id } = props
			return get(postAssignmentJobsSelector({ segment, tab })).find((job) => id === job?._id)
		},
})
export const filteredPostAssignmentJobsSelector = selectorFamily({
	key: 'filteredPostAssignmentJobsSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, pageNo, pageSize } = props
			const filteredJobs = filterData({
				data: get(postAssignmentJobsSelector({ segment, tab })),
				filterState: get(AllAtoms.filtersAtom),
				searchEnums: PostAssignmentSearchEnums[Capitalize(segment)],
			})
			const filteredOrders = Object.assign([], filteredJobs).sort((job1, job2) => job2.jobNumber - job1.jobNumber)
			return [
				Object.assign([], filteredJobs)
					.sort((job1, job2) => job2.jobNumber - job1.jobNumber)
					?.slice(pageNo * pageSize - pageSize, pageNo * pageSize),
				filteredOrders?.length,
			]
		},
})
export const postAssignmentJobsSelector = selectorFamily({
	key: 'postAssignmentJobsSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab } = props
			let orders = GraphQLEnums.Orders.atoms?.[segment] && get(GraphQLEnums.Orders.atoms?.[segment])
			return groupByJob({ orders, segment, tab }).filter((job) =>
				['shifting', 'destuffing', 'local'].includes(tab) ? job?.orderCategory === tab : job.subMovementType === Capitalize(segment)
			)
		},
})
/* ====================================== MANAGEMENT VIEW  ======================================= */
export const filteredManagementViewSelector = selectorFamily({
	key: 'filteredManagementViewSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment } = props

			const filterState = get(AllAtoms.filtersAtom)
			let groups = get(managementViewSelector({ segment }))
			if (!FilterTypeEnums.select.checkSelected(filterState?.client?.values)) {
				groups = Object.values(
					groups?.reduce((changedGroups, group) => {
						const prev = changedGroups[`${group?.weight}${group?.lane?._id}`]
						changedGroups[`${group?.weight}${group?.lane?._id}`] = {
							...group,
							client: undefined,
							cost: (prev?.cost || 0) + group?.cost,
							margin: (prev?.margin || 0) + group?.margin,
							costVariance: [...(prev?.costVariance || []), ...(group?.costVariance || [])],
							totalVehicles: (prev?.totalVehicles || 0) + group?.totalVehicles,
							assignedVehicles: (prev?.assignedVehicles || 0) + group?.assignedVehicles,
						}
						return changedGroups
					}, {})
				)
			}
			const filteredGroups = filterData({
				data: groups,
				filterState: filterState,
			})
			return filteredGroups?.sort((a, b) => {
				if (a.lane?._id !== b.lane?._id) {
					return `${b.lane?.from?.[0]}${b.lane?.to?.[0]}` - `${a.lane?.from?.[0]}${a.lane?.to?.[0]}`
				} else if (a.vehicleType !== b.vehicleType) {
					return VehicleTypeEnums[a.vehicleType]?.sortIndex - VehicleTypeEnums[b.vehicleType]?.sortIndex
				} else {
					return a.weight?.split('-')?.[0] - b.weight?.split('-')?.[0]
				}
			})
		},
})

export const managementViewSelector = selectorFamily({
	key: 'managementViewSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment } = props
			const data = get(AllAtoms[`${segment === 'ImportShifting' ? 'import' : segment}ManagementAtom`])
			const finalData = Object.values(
				data &&
					Object.entries(data)
						.reduce((obj, record, index) => {
							const [businessCode, businessCodeSplit] = record
							businessCodeSplit &&
								Object.entries(businessCodeSplit)?.forEach((subLane) => {
									const [subLaneId, subLaneSplit] = subLane
									subLaneSplit &&
										Object.entries(subLaneSplit)?.forEach((vehicle) => {
											const [vehicleType, vehicleSplit] = vehicle
											vehicleSplit &&
												Object.entries(vehicleSplit)?.forEach((weight) => {
													const [weightRange, info] = weight
													const finalRecord = prepareManagamentRow({ businessCode, subLaneId, vehicleType, weightRange, ...info, get, segment })
													obj.push(finalRecord)
												})
										})
								})

							return obj
						}, [])
						?.reduce((changedGroups, group) => {
							const prev = changedGroups[`${group?.weight}${group?.lane?._id}${group?.businessCode}`]
							changedGroups[`${group?.weight}${group?.lane?._id}${group?.businessCode}`] = {
								...group,
								cost: (prev?.cost || 0) + group?.cost,
								quotedCost: (prev?.quotedCost || 0) + group?.quotedCost,
								margin: (prev?.margin || 0) + group?.margin,
								costVariance: [...(prev?.costVariance || []), ...(group?.costVariance || [])],
								totalVehicles: (prev?.totalVehicles || 0) + group?.totalVehicles,
								assignedVehicles: (prev?.assignedVehicles || 0) + group?.assignedVehicles,
							}
							return changedGroups
						}, {})
			)
			return finalData
		},
})
/* ================================= BROKER MANAGEMENT VIEW  ======================================= */
export const brokerManagementByIDSelector = selectorFamily({
	key: 'brokerManagementByIDSelector',
	get:
		(props) =>
		({ get }) => {
			const { brokerId } = props
			return get(filteredBrokerManagementSelector()).find((broker) => brokerId === broker?._id)
		},
})

export const biltyInvestorManagementByIDSelector = selectorFamily({
	key: 'biltyInvestorManagementByIDSelector',
	get:
		(props) =>
		({ get }) => {
			const { investorId } = props
			return get(filteredBiltyInvestorManagementSelector()).find((investor) => investorId === investor?._id)
		},
})

export const brokerByIdSelector = selectorFamily({
	key: 'brokerById',
	get:
		(props) =>
		({ get }) => {
			const brokers = get(AllAtoms.brokersAtom)
			return brokers.find((broker) => broker._id === props._id)
		},
})

export const biltyInvestorByIdSelector = selectorFamily({
	key: 'biltyInvestorByIdSelector',
	get:
		(props) =>
		({ get }) => {
			const investors = get(AllAtoms.biltyInvestorsAtom)
			return investors.find((investor) => investor._id === props._id)
		},
})

export const filteredBrokerManagementSelector = selectorFamily({
	key: 'filteredBrokerManagementSelector',
	get:
		(props) =>
		({ get }) => {
			const filterState = get(AllAtoms.filtersAtom)
			const brokers = get(brokerManagementSelector())
			const filteredBrokers = filterData({
				data: brokers,
				filterState: filterState,
				searchEnums: VendorManagementSearchEnums,
			})

			return filteredBrokers
		},
})

export const filteredBiltyInvestorManagementSelector = selectorFamily({
	key: 'filteredBiltyInvestorManagementSelector',
	get:
		(props) =>
		({ get }) => {
			const filterState = get(AllAtoms.filtersAtom)
			const biltyInvestors = get(biltyInvestorManagementSelector())
			const filteredBiltyInvestors = filterData({
				data: biltyInvestors,
				filterState: filterState,
				searchEnums: VendorManagementSearchEnums,
			})

			return filteredBiltyInvestors
		},
})

export const brokerManagementSelector = selectorFamily({
	key: 'brokerManagementSelector',
	get:
		(props) =>
		({ get }) => {
			const brokers = get(AllAtoms.brokersAtom)
			return brokers.map((broker) => {
				const [filterProperties, searchProperties] = prepareProperties(broker, 'brokers', 'vendorManagement')
				return {
					...broker,
					type: broker?.vendorType,
					filterProperties,
					searchProperties,
				}
			})
		},
})

export const biltyInvestorManagementSelector = selectorFamily({
	key: 'biltyInvestorManagementSelector',
	get:
		(props) =>
		({ get }) => {
			const biltyInvestors = get(AllAtoms.biltyInvestorsAtom)
			return biltyInvestors.map((investor) => {
				const [filterProperties, searchProperties] = prepareProperties(investor, 'bilty-investors', 'vendorManagement')
				return {
					...investor,
					filterProperties,
					searchProperties,
				}
			})
		},
})
/* ================================= BROKER MANAGEMENT VIEW  ======================================= */
export const supplierManagementByIDSelector = selectorFamily({
	key: 'supplierManagementByIDSelector',
	get:
		(props) =>
		({ get }) => {
			const { brokerId } = props
			return get(filteredSupplierManagementSelector()).find((supplier) => brokerId === supplier?._id)
		},
})

export const filteredSupplierManagementSelector = selectorFamily({
	key: 'filteredSupplierManagementSelector',
	get:
		(props) =>
		({ get }) => {
			const filterState = get(AllAtoms.filtersAtom)
			const suppliers = get(supplierManagementSelector())
			const filteredSuppliers = filterData({
				data: suppliers,
				filterState: filterState,
				searchEnums: VendorManagementSearchEnums,
			})

			return filteredSuppliers
		},
})

export const supplierManagementSelector = selectorFamily({
	key: 'supplierManagementSelector',
	get:
		(props) =>
		({ get }) => {
			const suppliers = get(AllAtoms.suppliersAtom)
			return suppliers.map((supplier) => {
				const [filterProperties, searchProperties] = prepareProperties(supplier, 'suppliers', 'vendorManagement')
				return {
					...supplier,
					searchProperties,
				}
			})
		},
})
/* ===================================== ASSIGNMENT MODALS ======================================= */

export const assignmentDataSelector = selectorFamily({
	key: 'assignmentDataSelector',
	get:
		(props) =>
		({ get }) => {
			const currentUserEmail = JSON.parse(localStorage.getItem('user'))?.[0]?.email
			const suppliers = get(GraphQLEnums.Suppliers.atoms?.supplier)?.reduce((supplierList, supplier) => {
				if (!(!bPayAdmins.includes(currentUserEmail) && supplier._id === 'Bpay'))
					supplierList?.push({
						_id: supplier?._id,
						name: supplier?.User?.firstName ? `${supplier?.User?.firstName || ''} ${supplier?.User?.lastName || ''}` : undefined,
						phoneNumber: supplier?.User?.phoneNumber,
					})
				return supplierList
			}, [])
			const brokers = get(GraphQLEnums.Brokers.atoms?.broker)?.map((broker) => ({
				_id: broker?._id,
				name: broker?.User?.firstName ? `${broker?.User?.firstName || ''} ${broker?.User?.lastName || ''}` : undefined,
				phoneNumber: broker?.User?.phoneNumber,
				namePhoneNumber: `${broker?.CompanyName} (${broker?.User?.phoneNumber})`,
			}))

			// add super lane only and add sublanes based on selected jobs, (pass selected ids and use Job by ID selector and map sublanes then),
			const lanes = groupBySuperLane(get(GraphQLEnums.Lanes.atoms?.lane))

			return { suppliers, brokers: [...brokers, { _id: 'other', namePhoneNumber: 'New Broker' }], lanes }
		},
})

export const postAssignmentDataSelector = selectorFamily({
	key: 'assignmentDataSelector',
	get:
		(props) =>
		({ get }) => {
			const suppliers = get(GraphQLEnums.Suppliers.atoms?.supplier)?.map((supplier) => ({
				_id: supplier?._id,
				name: supplier?.User?.firstName ? `${supplier?.User?.firstName || ''} ${supplier?.User?.lastName || ''}` : undefined,
				phoneNumber: supplier?.User?.phoneNumber,
			}))
			const brokers = get(GraphQLEnums.Brokers.atoms?.broker)?.map((broker) => ({
				_id: broker?._id,
				name: broker?.User?.firstName ? `${broker?.User?.firstName || ''} ${broker?.User?.lastName || ''}` : undefined,
				phoneNumber: broker?.User?.phoneNumber,
				namePhoneNumber: `${broker?.CompanyName} (${broker?.User?.phoneNumber})`,
			}))

			return { suppliers, brokers: [...brokers, { _id: 'other', namePhoneNumber: 'New Broker' }] }
		},
})

/* ==================================  FILTER  DATA SELECTORS ===================================== */
export const getAllLanes = selectorFamily({
	key: 'superLanes',
	get:
		(props) =>
		({ get }) => {
			const allLanes = groupBySuperLane(get(GraphQLEnums.Lanes.atoms?.lane))
			const lanes = Object.keys(allLanes)?.reduce((prev, current) => [...prev, { [current]: allLanes[current] }], [])
			return lanes?.map((lane) => {
				return {
					value: Object.keys(lane)?.[0],
					label: `${lane?.[Object.keys(lane)?.[0]]?.superLane?.from}-${lane?.[Object.keys(lane)?.[0]]?.superLane?.to}`,
				}
			})
		},
})

export const getLanesbyIds = selectorFamily({
	key: 'superLanes',
	get:
		(props) =>
		({ get }) => {
			const allLanes = groupBySuperLane(get(GraphQLEnums.Lanes.atoms?.lane))
			const lanes = Object.keys(allLanes)?.reduce((prev, current) => [...prev, { [current]: allLanes[current] }], [])

			return lanes
				?.filter((ln) => props._ids?.includes(Object.keys(ln)?.[0]))
				?.map((lane) => {
					return {
						value: Object.keys(lane)?.[0],
						label: `${lane?.[Object.keys(lane)?.[0]]?.superLane?.from}-${lane?.[Object.keys(lane)?.[0]]?.superLane?.to}`,
					}
				})
		},
})

export const assignmentFiltersDataSelector = selectorFamily({
	key: 'assignmentFiltersDataSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, pageNo, pageSize } = props
			const jobs = get(filteredJobsSelector({ segment: segment === 'ImportShifting' ? 'import' : segment, tab, pageNo, pageSize }))?.[0]
			const { supplier = {}, broker = {}, lane = {}, client = {}, vehicleType = {}, emptyReturn = {}, subLane = {} } = {}
			const allLanes = groupBySuperLane(get(GraphQLEnums.Lanes.atoms?.lane))
			jobs?.forEach((job) => {
				const {
					supplier: jobSupplier,
					broker: jobBroker,
					lane: jobLane,
					superLane: jobSuperLane,
					clientName: jobClientName,
					emptyReturn: jobEmptyReturn,
					vehicleType: jobVehicleType,
				} = job
				if (jobSupplier) {
					supplier[jobSupplier?._id] = jobSupplier
				}
				if (jobBroker) {
					broker[jobBroker?._id] = jobBroker
				}
				if (jobClientName) {
					client[jobClientName] = { _id: jobClientName, clientName: jobClientName }
				}
				if (jobVehicleType) {
					vehicleType[jobVehicleType] =
						(jobVehicleType && VehicleTypeEnums[jobVehicleType === 'flatbed' ? 'Flatbed' : jobVehicleType]) ||
						VehicleTypeEnums[jobVehicleType === 'flatbed' ? 'Flatbed' : `Trolly-${jobVehicleType}`]
				}
				if (jobEmptyReturn) {
					emptyReturn[jobEmptyReturn?._id] = {
						_id: jobEmptyReturn?._id,
						name: jobEmptyReturn?.formattedAddress,
					}
				}
				if (jobLane) {
					if (jobSuperLane) {
						lane[jobSuperLane?._id] = allLanes[jobSuperLane?._id]
						subLane[jobLane?._id] = allLanes[jobSuperLane?._id]?.subLanes?.find((subLaneItem) => jobLane?._id === subLaneItem?._id)
					}
				}
			})
			delete lane.undefined
			delete subLane.undefined
			delete emptyReturn.undefined
			delete supplier.undefined
			delete broker.undefined
			delete vehicleType.undefined
			delete client.undefined
			const readyToMove = { readyToMove: { _id: 'readyToMove', text: 'Ready To Move' }, pending: { _id: 'pending', text: 'Pending' } }
			return { supplier, broker, lane, client, vehicleType, emptyReturn, readyToMove, subLane }
		},
})
export const postAssignmentFiltersDataSelector = selectorFamily({
	key: 'postAssignmentFiltersDataSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab, pageNo, pageSize } = props
			const jobs = get(filteredPostAssignmentJobsSelector({ segment, tab, pageNo, pageSize }))?.[0]
			const { supplier = {}, broker = {}, lane = {}, client = {}, vehicleType = {}, emptyReturn = {}, paymentStatus = {} } = {}
			const allLanes = groupBySuperLane(get(GraphQLEnums.Lanes.atoms?.lane))
			jobs?.forEach((job, index) => {
				const {
					supplier: jobSupplier,
					broker: jobBroker,
					Lane: jobLane,
					orderCategory,
					SuperLane: jobSuperLane,
					businessName: jobClientName,
					terminal: jobEmptyReturn,
					vehicleInfo: jobVehicleInfo,
					containerInfo: jobContainerInfo,
					vendorPayments,
				} = job
				if (jobSupplier) {
					supplier[jobSupplier?._id] = jobSupplier
				}
				if (jobBroker) {
					broker[jobBroker?._id] = jobBroker
				}
				if (jobClientName) {
					client[jobClientName] = { _id: jobClientName, clientName: jobClientName }
				}
				if (jobVehicleInfo || jobContainerInfo) {
					let jobVehicleType = jobVehicleInfo?.vehicleType || jobContainerInfo?.containerDimensions
					jobVehicleType =
						orderCategory === 'destuffing' ? 'Flatbed' : jobVehicleType === 'xl40ftHC' || jobVehicleType === 'xl40ftOT' ? 'xl40ft' : jobVehicleType
					vehicleType[jobVehicleType] = jobVehicleType && VehicleTypeEnums[jobVehicleType]
				}
				if (jobEmptyReturn?.[0]?.Location) {
					emptyReturn[jobEmptyReturn?.[0]?.Location?._id] = {
						_id: jobEmptyReturn?.[0]?.Location?._id,
						name: jobEmptyReturn?.[0]?.Location?.formattedAddress,
					}
				}
				if (vendorPayments?.paymentStatus) {
					paymentStatus[vendorPayments?.paymentStatus] = PaymentStatusEnums[vendorPayments?.paymentStatus]
				}
				if (jobLane) {
					if (jobSuperLane) {
						lane[jobSuperLane?._id] = allLanes[jobSuperLane?._id]
					}
				}
			})
			paymentStatus['pendingFreightCostApproval'] = PaymentStatusEnums['pendingFreightCostApproval']
			delete lane.undefined
			delete emptyReturn.undefined
			delete supplier.undefined
			delete broker.undefined
			delete vehicleType.undefined
			delete client.undefined
			const readyToMove = { readyToMove: { _id: 'readyToMove', text: 'Ready To Move' }, pending: { _id: 'pending', text: 'Pending' } }
			return { supplier, broker, lane, client, vehicleType, emptyReturn, readyToMove, paymentStatus }
		},
})
export const managementViewFiltersDataSelector = selectorFamily({
	key: 'managementViewFiltersDataSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, tab } = props
			const records = get(managementViewSelector({ segment, tab }))
			const allLanes = groupBySuperLane(get(GraphQLEnums.Lanes.atoms?.lane))
			const { client = {}, lane = {}, vehicleType = {}, subLane = {} } = {}
			records.forEach((record) => {
				const { vehicleType: groupVehicleType, clientName, businessCode, subLane: groupLane, lane: groupSuperLane } = record
				if (clientName) {
					client[clientName] = { _id: clientName, clientName: clientName }
				}
				if (groupVehicleType) {
					vehicleType[groupVehicleType] = VehicleTypeEnums[groupVehicleType === 'flatbed' ? 'Flatbed' : groupVehicleType]
				}
				if (groupSuperLane?._id) {
					lane[groupSuperLane?._id] = allLanes[groupSuperLane?._id]
				}
			})
			delete vehicleType.xl40ftHC
			delete vehicleType.xl40ftOT
			delete vehicleType.xl2020ft
			delete vehicleType['Trolly-xl40ftHC']
			delete vehicleType['Trolly-xl40ftOT']
			delete vehicleType['Trolly-xl20ft']
			return { client, lane, vehicleType }
		},
})
export const brokerManagementFilterDataSelector = selectorFamily({
	key: 'brokerManagementFilterDataSelector',
	get:
		(props) =>
		({ get }) => {
			const vendorType = {
				owner: {
					_id: 'owner',
					name: 'Owner',
				},
				broker: {
					_id: 'broker',
					name: 'Broker',
				},
				hybrid1: {
					_id: 'hybrid1',
					name: 'Hybrid 1',
				},
				hybrid2: {
					_id: 'hybrid2',
					name: 'Hybrid 2',
				},
			}

			return { vendorType }
		},
})
export const supplierManagementFilterDataSelector = selectorFamily({
	key: 'supplierManagementFilterDataSelector',
	get:
		(props) =>
		({ get }) => {
			const vendorType = {
				owner: {
					_id: 'owner',
					name: 'Owner',
				},
				broker: {
					_id: 'broker',
					name: 'Broker',
				},
				hybrid1: {
					_id: 'hybrid1',
					name: 'Hybrid 1',
				},
				hybrid2: {
					_id: 'hybrid2',
					name: 'Hybrid 2',
				},
			}

			return { vendorType }
		},
})
export const biltyInvestorManagementFilterDataSelector = selectorFamily({
	key: 'biltyInvestorManagementFilterDataSelector',
	get:
		(props) =>
		({ get }) => {
			const vendorType = {
				owner: {
					_id: 'owner',
					name: 'Owner',
				},
				broker: {
					_id: 'broker',
					name: 'Broker',
				},
				hybrid1: {
					_id: 'hybrid1',
					name: 'Hybrid 1',
				},
				hybrid2: {
					_id: 'hybrid2',
					name: 'Hybrid 2',
				},
			}

			return { vendorType }
		},
})

/* ============================================================================================== */
/* ============================== HELPER FUNCTIONS (FOR SELECTORS) ============================== */
/* ============================================================================================== */

const getJobs = ({ jobs, segment, tab }) => {
	const finalJobs = jobs
		?.map((job, index) => {
			// ****************** JOB **********************
			const _id = job?._id
			const jobNumber = job?.jobNumber
			const status = job?.status
			const vendorPayments = job?.vendorPayments?.costBreakdown
			const quotations = job?.Quotations
			const activeQuotation = job?.Quotations?.[0]
			const factorRequest = activeQuotation?.factorRequest
			const factorStatus = activeQuotation?.factorStatus
			const factoredAmount = activeQuotation?.factoredAmount
			// const factorBase = activeQuotation?.factorBase
			const assignedBy = {
				name: formatName(activeQuotation?.DriverAssignedBy?.firstName, activeQuotation?.DriverAssignedBy?.lastName),
				assignedAt: activeQuotation?.driverAssignedAt,
			}
			const vehicleInfo = job?.OrderDetails && JSON.parse(job?.OrderDetails)?.[0]?.vehicleInfos
			const vehicleDriver = activeQuotation?.driver && JSON.parse(activeQuotation?.driver)
			const [filterProperties, searchProperties] = prepareProperties(
				{ ...job, OrderDetails: job?.OrderDetails && JSON.parse(job?.OrderDetails) },
				'assignment',
				[('shifting', 'destuffing')].includes(tab) ? 'ImportShifting' : Capitalize(segment)
			)
			const lane = {
				_id: job?.Lane?._id,
				from: job?.Lane?.from?.name,
				to: job?.Lane?.to?.name,
			}
			const superLane = {
				_id: job?.SuperLane?._id,
				from: job?.SuperLane?.from?.name,
				to: job?.SuperLane?.to?.name,
			}
			const supplier = {
				_id: activeQuotation?.Supplier?._id,
				name: formatName(activeQuotation?.Supplier?.User?.firstName, activeQuotation?.Supplier?.User?.lastName),
				phoneNumber: activeQuotation?.Supplier?.User?.phoneNumber,
			}
			const paymentTerms = activeQuotation?.paymentTerms
			const broker = {
				_id: activeQuotation?.Broker?._id,
				name: formatName(activeQuotation?.Broker?.User?.firstName, activeQuotation?.Broker?.User?.lastName),
				phoneNumber: activeQuotation?.Broker?.User?.phoneNumber,
				CompanyName: activeQuotation?.Broker?.CompanyName,
			}
			const driver = { _id: job?.Driver?._id, name: job?.Driver?.name, phoneNumber: vehicleDriver?.phoneNumber }
			const vehicle = { registrationNumber: vehicleDriver?.vehicleRegistrationNumber, isBlocked: vehicleDriver?.isBlocked }
			const cost = activeQuotation?.cost

			// ****************** ORDER ********************
			let order = {}
			if (segment === 'import') {
				order = getImportOrder(job?.OrderDetails && JSON.parse(job?.OrderDetails))
			} else if (segment === 'export') {
				order = getExportOrder(job?.OrderDetails && JSON.parse(job?.OrderDetails))
			} else if (segment === 'longHaul') {
				order = getLongHaulOrder(job?.OrderDetails && JSON.parse(job?.OrderDetails))
			}
			return {
				_id,
				jobNumber,
				status,
				assignedBy,
				quotations,
				vendorPayments,
				paymentTerms,
				cost,
				lane,
				superLane,
				supplier,
				broker,
				driver,
				vehicle,
				factorRequest,
				factorStatus,
				factoredAmount,
				// factorBase,
				filterProperties,
				searchProperties,
				vehicleInfo,
				...order,
			}
		})
		?.filter(
			(job) =>
				!['Job Ongoing', 'Job Completed', 'Job Cancelled'].includes(job?.status) &&
				(tab === 'shifting' || tab === 'destuffing' || tab === 'local' ? job?.orderCategory === tab : true)
		)
	return finalJobs
}

const getImportOrder = (orderDetails) => {
	const order = orderDetails?.[0]
	const childOrderId = order?.childOrderId
	const parentOrderId = order?.parentOrderId
	const documentNumber = order?.documentNumber
	const clientName = order?.clientName
	const readyToMove = order?.doInfo?.readyToMove
	const demurrageFreeDays = order?.doInfo?.demurrageFreeDays && moment(order?.doInfo?.demurrageFreeDays, 'x').diff(moment().startOf('day'), 'days')

	const nocExpiry = order?.doInfo?.nocExpiry
	const freeDays = order?.emptyReturnDate && moment(order?.emptyReturnDate).diff(moment().startOf('day'), 'days')
	const freeDaysDate = order?.emptyReturnDate
	const emptyReturn = order?.orderLocations
		?.filter((location) => location?.locationType === 'Empty Container Dropoff')
		?.map((location) => ({
			_id: location?.Location?._id,
			formattedAddress: location?.Location?.formattedAddress,
			additionalDetails: location?.Location?.additionalDetails,
			region: location?.Location?.Region?.name,
			parentRegion: location?.Location?.Region?.ParentRegion?.name,
		}))?.[0]
	const containerInfo = orderDetails?.map((order) => ({
		containerWeight: order?.weight,
		containerNumber: order?.containerNumber,
		containerDimensions: order?.containerDimensions,
	}))
	const isSDOrder = order?.movementType === 'ImportShifting' || order?.movementType === 'ImportDoubleTwentyShifting'

	const orderCategory =
		isSDOrder || order?.subMovementType === 'ImportShortHaul'
			? order?.subMovementType === 'ImportShortHaul'
				? 'local'
				: order?.numberOfFlatbeds === order?.numberOfSubOrders
				? 'shifting'
				: 'destuffing'
			: order?.movementType

	const movementType = order?.movementType
	const subMovementType = order?.subMovementType

	const doStatus = checkArray(order?.doStatus) ? order?.doStatus?.sort((a, b) => b.statusNumber - a.statusNumber)?.[0]?.status : order?.doStatus?.status
	const orderLocations = order?.orderLocations?.sort((a, b) => a.steps - b.steps)
	const vehicleType =
		orderDetails?.length > 1 && order?.vehicleType === 'xl20ft'
			? 'xl2020ft'
			: order?.vehicleType?.includes('xl40ft')
			? 'xl40ft'
			: order?.vehicleType || 'N/A'
	const document = order?.document
	return {
		childOrderId,
		parentOrderId,
		orderCategory,
		freeDaysDate,
		documentNumber,
		readyToMove,
		movementType,
		subMovementType,
		vehicleType,
		document,
		demurrageFreeDays,
		nocExpiry,
		clientName,
		doStatus,
		freeDays,
		emptyReturn,
		containerInfo,
		orderLocations,
	}
}

const getExportOrder = (orderDetails) => {
	const order = orderDetails?.[0]
	const documentNumber = order?.documentNumber
	const clientName = order?.clientName
	const containerInfo = orderDetails?.map((order) => ({
		containerWeight: order?.weight,
		containerNumber: order?.containerNumber,
		containerDimensions: order?.containerDimensions,
	}))
	const loadingDate = order?.loadingDate
	const shippingLine = order?.shippingLine
	const orderLocations = order?.orderLocations?.sort((a, b) => a.steps - b.steps)
	const vehicleType =
		orderDetails?.length > 1 && order?.vehicleType === 'xl20ft'
			? 'xl2020ft'
			: order?.vehicleType?.includes('xl40ft')
			? 'xl40ft'
			: order?.vehicleType || 'N/A'

	const document = order?.document
	return {
		documentNumber,
		document,
		vehicleType,
		loadingDate,
		shippingLine,
		clientName,
		containerInfo,
		orderLocations,
	}
}

const getLongHaulOrder = (orderDetails) => {
	const order = checkArray(orderDetails) ? orderDetails?.[0] : orderDetails
	const loadingDate = order?.loadingDate

	const clientName = order?.clientName
	const vehicleType =
		orderDetails?.length > 1 && order?.vehicleType === 'xl20ft' ? 'xl2020ft' : order?.vehicleType?.includes('xl40ft') ? 'xl40ft' : order?.vehicleType

	const vehicleSubType = order?.vehicleSubType
	const subOrderNumber = order?.subOrderNumber
	const shipmentInfo = checkArray(orderDetails)
		? orderDetails?.reduce(
				(info, order) => ({
					freightWeight: (parseFloat(order?.childShipmentInfo?.freightWeight || '0') + parseFloat(info?.freightWeight || '0')).toFixed(1),
					freightType: order?.childShipmentInfo?.freightType,
				}),
				{}
		  )
		: {
				freightWeight: order?.weight || order?.childShipmentInfo?.freightWeight,
				freightType: order?.childShipmentInfo?.freightType,
		  }

	const orderLocations = order?.orderLocations?.sort((a, b) => a.steps - b.steps)
	return {
		clientName,
		loadingDate,
		vehicleType,
		vehicleSubType,
		shipmentInfo,
		subOrderNumber,
		orderLocations,
	}
}

const getLongHaulLotOrders = ({ orders }) => {
	const finalOrders = orders?.map((order) => {
		const _id = order?._id
		const parentOrder = order?.ParentOrder?.ParentLongHaul
		const parentOrderId = order?.ParentOrder?.ParentLongHaul?._id
		const lotOrder = parentOrder?.preferences?.lotOrder
		const orderNumber = parentOrder?.orderNumber
		const initialSubOrder = parentOrder?.SubLongHauls?.find((subOrder) => subOrder?.subOrderNumber === orderNumber.toString() + '-A')
		const locations = initialSubOrder?.OrderLocations
		const loading = locations
			?.filter((location) => location?.locationType === 'loading')
			?.sort((a, b) => a.steps - b.steps)
			?.map((location) => ({ _id: location?.Location?._id, name: location?.Location?.formattedAddress }))
		const dropoff = locations
			?.filter((location) => location?.locationType === 'dropoff')
			?.sort((a, b) => a.steps - b.steps)
			?.map((location) => ({ _id: location?.Location?._id, name: location?.Location?.formattedAddress }))
		const clientName = order?.Corporate?.businessName
		const totalWeight = parentOrder?.shipmentInfo?.freightWeight
		const shipmentInfo = parentOrder?.shipmentInfo
		const movements = order?.ParentOrder?.Movements
		const totalAssignedWeight = movements?.reduce(
			(assignedWeight, movement) => (parseFloat(assignedWeight) + parseFloat(movement?.ChildOrder?.SubLongHaul?.shipmentInfo?.freightWeight)).toString(),
			'0'
		)
		const remainingWeight = parseFloat(totalWeight) < parseFloat(totalAssignedWeight) ? 0 : parseFloat(totalWeight) - parseFloat(totalAssignedWeight)

		const jobs = movements?.map((movement) => {
			const job = movement?.Job
			const subOrder = movement?.ChildOrder?.SubLongHaul
			return {
				_id: job?._id,
				jobNumber: job?.jobNumber,
				cost: job?.cost,
				childId: subOrder?._id,
				supplier: {
					_id: job?.Supplier?._id,
					name: formatName(job?.Supplier?.User?.firstName, job?.Supplier?.User?.lastName),
					phoneNumber: job?.Supplier?.User?.phoneNumber,
				},
				broker: {
					_id: job?.Broker?._id,
					name: formatName(job?.Broker?.User?.firstName, job?.Broker?.User?.lastName),
					phoneNumber: job?.Broker?.User?.phoneNumber,
				},
				driver: { _id: job?.Driver?._id, name: job?.Driver?.name, phoneNumber: job?.Driver?.phoneNumber },
				vehicle: job?.Vehicle,
				shipmentInfo: subOrder?.shipmentInfo,
				vehicleType: subOrder?.vehicleType,
				committedDays: job?.committedDays,
				committedWeight: job?.committedWeight,
			}
		})

		return {
			_id,
			parentOrderId,
			lotOrder,
			orderNumber,
			clientName,
			initialSubOrder,
			jobs,
			loading,
			dropoff,
			shipmentInfo,
			totalWeight,
			totalAssignedWeight,
			remainingWeight,
		}
	})
	return finalOrders?.filter((order) => order?.lotOrder)
}

const groupByJob = (props) => {
	const { orders, segment, tab } = props
	let groupedJobs = {}

	orders?.forEach((order, index) => {
		const parentOrder = order?.ParentOrder?.[`Parent${Capitalize(segment)}`]
		// const childOrders = parentOrder?.[GraphQLEnus.Orders.childOrders?.[segment]]
		const movements = order?.ParentOrder?.Movements
		movements?.forEach((movement) => {
			const Job = movement?.Job
			if (Job) {
				const subOrder = movement?.ChildOrder?.[`Sub${Capitalize(segment)}`]
				const quotation = Job?.Quotations?.[0]

				const numberFlatbeds = parentOrder?.preferences?.numberFlatbeds
				const numberSubOrders = parentOrder?.SubImports?.length
				const isSDOrder = movement?.movementType === 'ImportShifting' || movement?.movementType === 'ImportDoubleTwentyShifting'
				const orderCategory =
					isSDOrder || movement?.subMovementType === 'ImportShortHaul'
						? movement?.subMovementType === 'ImportShortHaul'
							? 'local'
							: numberFlatbeds === numberSubOrders
							? 'shifting'
							: 'destuffing'
						: parentOrder?.preferences?.movementType
				groupedJobs = {
					...groupedJobs,
					[Job?._id]: {
						_id: Job?._id,
						movementType: movement.movementType,
						subMovementType: movement.subMovementType,
						numberFlatbeds,
						numberSubOrders,
						orderCategory,
						quotation,
						cost: Job?.cost,
						factorRequest: Job?.factorRequest,
						factorStatus: Job?.factorStatus,
						factoredAmount: Job?.factoredAmount,
						committedDays: Job?.committedDays,
						committedWeight: Job?.committedWeight,
						// factorBase: Job?.factorBase,
						assignedBy: {
							name: formatName(Job?.AssignedBy?.firstName, Job?.AssignedBy?.lastName),
							assignedAt: Job?.driverAssignedAt,
						},
						Lane: {
							_id: Job?.Lane?._id,
							from: Job?.Lane?.from?.name,
							to: Job?.Lane?.to?.name,
						},
						SuperLane: {
							_id: Job?.SuperLane?._id,
							from: Job?.SuperLane?.from?.name,
							to: Job?.SuperLane?.to?.name,
						},
						charges: Job?.charges,
						revenues: Job?.revenues,
						jobNumber: Job?.jobNumber,
						paymentTerms: Job?.paymentTerms,
						supplier: {
							_id: Job?.Supplier?._id,
							name: formatName(Job?.Supplier?.User?.firstName, Job?.Supplier?.User?.lastName),
							phoneNumber: Job?.Supplier?.User?.phoneNumber,
						},
						broker: {
							_id: Job?.Broker?._id,
							name: formatName(Job?.Broker?.User?.firstName, Job?.Broker?.User?.lastName),
							phoneNumber: Job?.Broker?.User?.phoneNumber,
							CompanyName: Job?.Broker?.CompanyName,
						},
						loadingDT: subOrder?.loadingDT,
						subOrderNumber: [...(groupedJobs?.[Job?._id]?.subOrderNumber || []), subOrder?.subOrderNumber],
						...(['Import', 'Export'].includes(order?.type) && {
							containerInfo: {
								containerNumber: [...(groupedJobs?.[Job?._id]?.containerInfo?.containerNumber || []), subOrder?.containerNumber],
								containerDimensions: orderCategory === 'destuffing' ? 'Flatbed' : subOrder?.dimension,
								freightType: subOrder?.freightType,
								containerWeight: (groupedJobs[Job?._id]?.containerInfo?.containerWeight || 0) + parseFloat(subOrder?.freightWeight ? subOrder?.freightWeight : '0'),
							},
							clearingAgents: parentOrder?.clearingAgents,
							documentNumber: parentOrder?.documentNumber,
							document: parentOrder?.document,
							terminal: subOrder?.OrderLocations?.filter(
								(location) => location?.locationType === (order?.type === 'Import' ? 'Empty Container Dropoff' : 'Empty Container Pickup')
							),
						}),
						weightInfo: {
							containerWeight: (
								(parseFloat(groupedJobs[Job?._id]?.containerInfo?.containerWeight) || 0) + parseFloat(subOrder?.freightWeight ? subOrder?.freightWeight : '0')
							)?.toString(),
							freightWeight: (
								parseFloat(groupedJobs[Job?._id]?.shipmentInfo?.freightWeight || '0') + parseFloat(subOrder?.shipmentInfo?.freightWeight || '0')
							).toString(),
						},
						vehicleTypeInfo: {
							containerDimensions: orderCategory === 'destuffing' ? 'Flatbed' : subOrder?.dimension,
							vehicleType: orderCategory === 'destuffing' ? 'Flatbed' : subOrder?.vehicleType,
							subOrderNumber: [...(groupedJobs?.[Job?._id]?.subOrderNumber || []), subOrder?.subOrderNumber],
						},
						...(order?.type === 'LongHaul' && {
							vehicleInfo: {
								vehicleType: subOrder?.vehicleType,
								vehicleSubType: subOrder?.vehicleSubType,
							},
							shipmentInfo: {
								freightType: subOrder?.shipmentInfo?.freightType,
								freightWeight: (
									parseInt(groupedJobs[Job?._id]?.shipmentInfo?.freightWeight || '0') + parseInt(subOrder?.shipmentInfo?.freightWeight || '0')
								).toString(),
							},
							freightWeights: [...(groupedJobs[Job?._id]?.freightWeights || []), subOrder?.shipmentInfo?.freightWeight],
						}),
						...(order?.type === 'LongHaul' && {
							isLot: parentOrder?.preferences?.lotOrder,
						}),
						loading: subOrder?.OrderLocations?.filter((location) => location?.locationType === 'loading'),
						dropOff: subOrder?.OrderLocations?.filter((location) => location?.locationType === 'dropoff'),
						driverVehicleRegistration: Job?.Vehicle?.registrationNumber,
						driverPhone: Job?.Driver?.phoneNumber,
						bilty: Job?.bilty?.[0],
						documentNumberArray: Job?.bilty?.map((bilty) => bilty?.documentNumber),
						biltyNumber: Job?.bilty?.[0]?.biltyNumber,
						orderStatus: subOrder?.status,
						businessName: order?.Corporate?.businessName,
						businessCode: order?.businessCode,
						finalizedRate: subOrder?.finalizedRate,
						shippingLine: parentOrder?.shippingLine,
						vendorPayments: Job?.vendorPayments,
						extraWeightChargeReason: Job?.extraWeightChargeReason,
						type: order?.type,
						freeDays: subOrder?.freeDays && moment(subOrder?.freeDays).diff(moment().startOf('day'), 'days'),
						freeDaysDate: subOrder?.freeDays,
						nocExpiry: parentOrder?.doInfo?.nocExpiry,
						driverMatchedAt: Job?.driverMatchedAt,
					},
				}
			}
		})
	})
	return Object.values(groupedJobs).map((job, index) => {
		const properties = prepareProperties(job, 'post-assignment', Capitalize(segment))
		return {
			...job,
			filterProperties: properties[0],
			searchProperties: properties[1],
		}
	})
}

const groupByJobSidebar = (props) => {
	const { orders, segment, tab } = props
	let groupedJobs = {}

	orders?.forEach((order, index) => {
		const parentOrder = order?.ParentOrder?.[`Parent${Capitalize(segment)}`]
		// const childOrders = parentOrder?.[GraphQLEnus.Orders.childOrders?.[segment]]
		const movements = order?.ParentOrder?.Movements
		movements?.forEach((movement) => {
			const Job = movement?.Job
			if (Job) {
				const subOrder = movement?.ChildOrder?.[`Sub${Capitalize(segment)}`]
				const quotation = Job?.Quotations?.[0]
				const numberFlatbeds = parentOrder?.preferences?.numberFlatbeds
				const numberSubOrders = parentOrder?.SubImports?.length
				const isSDOrder = movement?.movementType === 'ImportShifting' || movement?.movementType === 'ImportDoubleTwentyShifting'
				const orderCategory =
					isSDOrder || movement?.subMovementType === 'ImportShortHaul'
						? movement?.subMovementType === 'ImportShortHaul'
							? 'local'
							: numberFlatbeds === numberSubOrders
							? 'shifting'
							: 'destuffing'
						: parentOrder?.preferences?.movementType
				groupedJobs = {
					...groupedJobs,
					[Job?._id]: {
						_id: Job?._id,
						type: order?.type,
						cost: Job?.cost,
						orderCategory,
						movementType: movement.movementType,
						subMovementType: movement.subMovementType,
						numberFlatbeds,
						numberSubOrders,
						committedDays: Job?.committedDays,
						committedWeight: Job?.committedWeight,
						charges: Job?.charges,
						revenues: Job?.revenues,
						jobNumber: Job?.jobNumber,
						paymentTerms: Job?.paymentTerms,
						status: Job?.status,
						shipmentInfo: {
							...(groupedJobs?.[Job?._id]?.shipmentInfo || {}),
							[parentOrder?.documentNumber]: {
								documentNumber: `BL # ${parentOrder?.documentNumber}`,
								containers: [
									...(groupedJobs?.[Job?._id]?.shipmentInfo?.[parentOrder?.documentNumber]?.containers || []),
									{
										containerNumber: subOrder?.containerNumber,
										containerDimension: orderCategory === 'destuffing' ? 'Flatbed' : subOrder?.dimension,
										containerWeight: parseFloat(subOrder?.freightWeight ? subOrder?.freightWeight : '0'),
									},
								],
							},
						},
						...(['Import', 'Export'].includes(order?.type) && {
							terminal: subOrder?.OrderLocations?.filter(
								(location) => location?.locationType === (order?.type === 'Import' ? 'Empty Container Dropoff' : 'Empty Container Pickup')
							),
						}),
						supplier: {
							_id: Job?.Supplier?._id,
							name: formatName(Job?.Supplier?.User?.firstName, Job?.Supplier?.User?.lastName),
							phoneNumber: Job?.Supplier?.User?.phoneNumber,
						},
						broker: {
							_id: Job?.Broker?._id,
							name: formatName(Job?.Broker?.User?.firstName, Job?.Broker?.User?.lastName),
							phoneNumber: Job?.Broker?.User?.phoneNumber,
							CompanyName: Job?.Broker?.CompanyName,
							accounts: Job?.Broker?.accounts,
						},
						driverVehicleRegistration: Job?.Vehicle?.registrationNumber,
						driverPhone: Job?.Driver?.phoneNumber,
						driverName: Job?.Driver?.name,
						jobVehicleType: Job?.vehicleType,
						orderStatus: subOrder?.status,
						businessName: order?.Corporate?.businessName,
						businessCode: order?.businessCode,
						extraWeightChargeReason: Job?.extraWeightChargeReason,
						vendorPayments: Job?.vendorPayments,
						paymentTerms: Job?.paymentTerms,
					},
				}
			}
		})
	})
	return Object.values(groupedJobs).map((job, index) => {
		const properties = prepareProperties(job, 'post-assignment', Capitalize(segment))
		return {
			...job,
			filterProperties: properties[0],
			searchProperties: properties[1],
		}
	})
}

const prepareManagamentRow = (props) => {
	const {
		businessCode,
		count,
		margin,
		matchedJobs,
		nonMatchedJobs,
		subLaneId,
		jobCosts,
		quotedCosts,
		supplyBurn,
		totalCost,
		vehicleType,
		weightRange,
		get,
		segment,
		doInfoRtm,
		jobCreatedAt,
	} = props
	const lanes = get(AllAtoms.lanesAtom)
	const corporates = get(AllAtoms.corporatesAtom)
	const clientName = corporates.find((corporate) => corporate.businessCode === businessCode)?.businessName
	const lane = lanes?.find((lane) => lane._id === subLaneId)
	const superLane = lanes?.find((someLane) => lane?.from.ParentRegion?._id === someLane.from?._id && lane?.to?.ParentRegion?._id === someLane?.to?._id)
	const quotedCost = quotedCosts.reduce((cost, current) => cost + parseInt(current), 0)
	const costVariance = jobCosts && jobCosts?.length > 3 ? jobCosts?.slice(-3)?.reduce((variance, jobCost) => jobCost + variance, 0) / 3 : jobCosts[0]
	return {
		vehicleType: vehicleType,
		weight: weightRange,
		clientName,
		client: clientName,
		businessCode,
		lane: {
			_id: superLane?._id,
			from: superLane?.from?.name,
			to: superLane?.to?.name,
		},
		subLane: {
			_id: lane?._id,
			from: lane?.from?.name,
			to: lane?.to?.name,
		},
		cost: totalCost,
		quotedCost: quotedCost,
		margin: margin,
		supplyBurn:
			!isNaN(((quotedCost - totalCost) / quotedCost) * 100) && isFinite(((quotedCost - totalCost) / quotedCost) * 100)
				? (((quotedCost - totalCost) / quotedCost) * 100).toFixed(2)
				: 0,
		costVariance: [costVariance || 0],
		totalVehicles: matchedJobs + nonMatchedJobs,
		assignedVehicles: matchedJobs,
		// createdAt: jobCreatedAt,
		rtmDate: doInfoRtm,
		filterProperties: prepareProperties(
			{ SuperLane: superLane, vehicleType, clientName, lane, createdAt: jobCreatedAt, rtmDate: doInfoRtm },
			'management',
			Capitalize(segment)
		)?.[0],
	}
}
const groupBySuperLane = (lanes) =>
	lanes
		?.filter((lane) => lane?.to?.ParentRegion && lane?.from?.ParentRegion)
		.reduce((superLanes, lane) => {
			const superLane = lanes?.filter(
				(someLane) => lane.from.ParentRegion?._id === someLane.from?._id && lane?.to?.ParentRegion?._id === someLane?.to?._id
			)?.[0]
			superLanes[superLane?._id] = {
				_id: superLane?._id,
				superLane: formatLane(superLane),
				subLanes: [...(superLanes[superLane?._id]?.subLanes || []), formatLane(lane)],
			}
			return superLanes
		}, {})

const formatLane = (lane) => ({
	_id: lane?._id,
	from: lane?.from?.name,
	to: lane?.to?.name,
	laneRates: lane?.LaneRate?.reduce((laneRates, laneRate) => {
		laneRates[laneRate.WeightRange] = laneRates[laneRate.WeightRange]
			? moment(laneRates[laneRate.WeightRange].createdAt).diff(moment(laneRate.createdAt), 'milliseconds') > 0
				? { rate: laneRates[laneRate.WeightRange].Rate, createdAt: laneRates[laneRate.WeightRange].createdAt }
				: { rate: laneRate.Rate, createdAt: laneRate.createdAt }
			: { rate: laneRate.Rate, createdAt: laneRate.createdAt }
		return laneRates
	}, {}),
})

export const formatName = (firstName, lastName) => (firstName || lastName ? `${firstName || ''} ${lastName || ''}` : '')
export const formatPhoneNumber = (phoneNumber) =>
	phoneNumber && `${phoneNumber?.replace('+92', '0')?.slice(0, 4)}-${phoneNumber?.replace('+92', '0')?.slice(4)}`
