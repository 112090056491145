import { auth, firebase, getAuth } from '../firebase/firebaseConfig'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userAtom } from '../recoil/atoms'

import { useLazyFetch } from './graphql'
import { useEffect } from 'react'
import { authSelector } from '../recoil/selectors'
import { GraphQLEnums } from '../helpers/Enums'
import { useNavigate, useLocation } from 'react-router-dom'

export const useAuth = (setLoading = () => {}, setAuthorized, setAlert) => {
	const setUserAtom = useSetRecoilState(userAtom)
	const [authenticateUser, loading] = useLazyFetch({ type: 'User', atomKey: 'user', storageKey: 'user' })
	const userAuth = useRecoilValue(authSelector())
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const currLocation = pathname.split('/')?.[1]
	// auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

	useEffect(() => {
		if (!loading && userAuth) {
			if (userAuth?.user === 'no user') {
				setAlert({
					type: 'error',
					message: 'No user record for this email address.',
				})
				setLoading(false)
				localStorage.setItem('user', JSON.stringify(null))
			} else if (userAuth?.authorized === false) {
				setLoading(false)
				setAuthorized(false)
				setUserAtom(null)
				localStorage.setItem('user', JSON.stringify(null))
			} else if (userAuth?.authorized === true && currLocation === 'login') {
				setLoading(false)
				navigate('../import', { replace: true })
			}
		}
	}, [userAuth, loading]) // eslint-disable-line

	const signInWithEmailAndPassword = async (email, password) => {
		const userCredential = await auth.signInWithEmailAndPassword(email, password)
		if (userCredential) {
			const token = await auth.currentUser?.getIdToken()
			localStorage.setItem('token', JSON.stringify({ token }))
			const processedVariables = GraphQLEnums.User.fetch.default.getVariables({ _id: userCredential?.user?.uid })
			authenticateUser({ variables: processedVariables })
		}
	}

	const resetPassword = async (email) => {
		await auth.sendPasswordResetEmail(email)
		setLoading(false)
		return 'success'
	}

	const logout = () => {
		if (userAuth) {
			auth.signOut()
			sessionStorage.setItem('user', JSON.stringify(null))
			localStorage.setItem('user', JSON.stringify(null))
			sessionStorage.setItem('token', JSON.stringify(null))
			localStorage.setItem('token', JSON.stringify(null))
			window.location.href = '/'
		}
	}

	const authorization = async (forgotPassword, email, password) => {
		setLoading(true)
		try {
			if (!forgotPassword) {
				await signInWithEmailAndPassword(email, password)
			} else {
				const result = await resetPassword(email)
				if (result === 'success') {
					setAlert({
						type: 'success',
						message: 'Reset link has been sent to your email.',
					})
				}
			}
		} catch (error) {
			setLoading(false)
			if (error.code === 'auth/invalid-email' || error.code === 'auth/wrong-password') {
				setAlert({
					type: 'error',
					message: 'Email address or password is incorrect.',
				})
			} else if (error.code === 'auth/user-not-found') {
				setAlert({
					type: 'error',
					message: 'No user record for this email address.',
				})
			} else {
				setAlert({
					type: 'error',
					message: 'Please check your internet connection.',
				})
			}
		}
	}

	return { authorization, logout }
}
