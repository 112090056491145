import { gql } from '@apollo/client'

import {
	CORPORATE_FIELDS,
	JOB_FIELDS,
	ORDER_FIELDS,
	USER_FIELDS,
	SUPPLIER_FIELDS,
	LANE_FIELDS,
	BROKER_FIELDS,
	LOCATION_FIELDS,
	BILTY_INVESTOR_FIELDS,
} from './fragments'

export const GET_USER = gql`
	${USER_FIELDS}
	query Users($where: UserWhere) {
		users(where: $where) {
			...UserFields
			UserType {
				userType
			}
		}
	}
`

export const GET_ALL_CORPORATES = gql`
	${CORPORATE_FIELDS}
	query Corporates {
		corporates {
			...CorporateFields
		}
	}
`

export const GET_ALL_ORDERS = gql`
	${ORDER_FIELDS}
	query Orders($where: OrderWhere, $options: OrderOptions, $movementsWhere2: MovementWhere) {
		orders(where: $where, options: $options) {
			...OrderFields
		}
	}
`

export const ADVANCED_SEARCH = gql`
	${ORDER_FIELDS}
	query AdvancedSearch($segment: String!, $key: String!, $value: String!) {
		advancedSearch(segment: $segment, key: $key, value: $value) {
			Orders {
				...OrderFields
			}
		}
	}
`

export const GET_ALL_JOBS = gql`
	${JOB_FIELDS}
	query Jobs($where: JobWhere, $options: JobOptions) {
		jobs(where: $where, options: $options) {
			...JobFields
		}
	}
`

export const GET_ALL_SUPPLIERS = gql`
	${SUPPLIER_FIELDS}
	query Suppliers {
		suppliers {
			...SupplierFields
		}
	}
`
export const GET_ALL_BILTY_INVESTORS = gql`
	${BILTY_INVESTOR_FIELDS}
	query BiltyInvestors {
		biltyInvestors {
			...BiltyInvestorFields
		}
	}
`
export const GET_ALL_LANE_RATES = gql`
	${LANE_FIELDS}
	query Lanes {
		lanes {
			...LaneFields
		}
	}
`

export const GET_ALL_BROKERS = gql`
	${BROKER_FIELDS}
	query Brokers {
		brokers(where: { isBlocked: false }) {
			...BrokerFields
		}
	}
`

export const GET_ALL_EMPTY_TERMINALS = gql`
	${LOCATION_FIELDS}
	query Locations {
		locations(where: { type: "Terminal" }) {
			...LocationFields
		}
	}
`

export const GET_ALL_JOB_COUNTS = gql`
	query GetJobConfigs {
		getJobConfigs {
			exportAssignment {
				normal
			}
			exportPostAssignment {
				normal
			}
			importAssignment {
				destuffing
				shifting
				local
				direct
			}
			importPostAssignment {
				destuffing
				direct
				local
				shifting
			}
			longHaulPostAssignment {
				normal
			}
			longHaulAssignment {
				nonLot
				lot
			}
		}
	}
`

export const GET_MANAGEMENT_VIEW_DATA = gql`
	query ManagementViewApi($managementViewInput: ManagementViewInput) {
		managementViewApi(managementViewInput: $managementViewInput)
	}
`

export const GET_VEHICLE = gql`
	query Vehicles($where: VehicleWhere) {
		vehicles(where: $where) {
			_id
			isBlocked
			registrationNumber
		}
	}
`

export const GET_JOB_CHARGE_TYPES = gql`
	query ChargeTypes {
		chargeTypes(where: { chargeBasis_INCLUDES: JOB, portal_INCLUDES: SUPPLY_PORTAL }) {
			_id
			name
			chargeBasis
			commentRequired
			invoiceProofRequired
			payeeTypes
			thirdPartyTypes
			customFields {
				_id
				propertyKey
				type
				label
				message
				placeholder
				required
				tooltip
				hidden
				... on SelectCustomField {
					options {
						_id
						displayProperty
						valueProperty
					}
					showSearch
				}
				... on DateTimeCustomField {
					minDate
					maxDate
					showTime
				}
				... on InputCustomField {
					minValue
					maxValue
					inputType
				}
				... on RadioGroupCustomField {
					options {
						_id
						displayProperty
						valueProperty
					}
					button
				}
				... on UploadCustomField {
					accept
					maxCount
					multiple
					uploadType
				}
			}
		}
	}
`
export const GET_JOB_REVENUE_TYPES = gql`
	query RevenueTypes {
		revenueTypes(where: { revenueBasis_INCLUDES: JOB, portal_INCLUDES: SUPPLY_PORTAL }) {
			_id
			name
			revenueBasis
			commentRequired
			invoiceProofRequired
			payeeTypes
			thirdPartyTypes
			customFields {
				_id
				propertyKey
				type
				label
				message
				placeholder
				required
				tooltip
				hidden
				... on SelectCustomField {
					options {
						_id
						displayProperty
						valueProperty
					}
					showSearch
				}
				... on DateTimeCustomField {
					minDate
					maxDate
					showTime
				}
				... on InputCustomField {
					minValue
					maxValue
					inputType
				}
				... on RadioGroupCustomField {
					options {
						_id
						displayProperty
						valueProperty
					}
					button
				}
				... on UploadCustomField {
					accept
					maxCount
					multiple
					uploadType
				}
			}
		}
	}
`

export const GET_THIRD_PARTIES = gql`
	query ThirdParties {
		thirdParties {
			_id
			name
			phoneNumber
			type
			accounts {
				_id
				accountNumber
				accountTitle
				bankName
				type
			}
		}
	}
`
