import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { GraphQLEnums } from '../helpers/Enums'
import { updateAtom } from '../helpers/Functions'

import { atom, useSetRecoilState } from 'recoil'
import { userAtom } from '../recoil/atoms'

export const useFetch = ({ type, atomKey, queryKey, variables = {}, subscriptionVariables = {}, pageInfo, subscribe, fetchPolicy }) => {
	const setAtom = useSetRecoilState(atomKey ? GraphQLEnums[`${type}`]?.atoms?.[`${atomKey}`] : userAtom)
	const finalVariables = useMemo(() => {
		return Object.keys(variables).length > 0 && GraphQLEnums[`${type}`].fetch?.[queryKey || 'default']?.getVariables(variables)
	}, [variables]) // eslint-disable-line

	const { options } = finalVariables
	let paginationVariables
	if (pageInfo) {
		paginationVariables = {
			...finalVariables,
			options: {
				...options,
				limit: pageInfo?.pageSize,
				offset: pageInfo?.pageSize * (pageInfo?.pageNo - 1),
			},
		}
	}
	const { data, loading, error, fetchMore, subscribeToMore } = useQuery(GraphQLEnums[`${type}`].fetch?.[queryKey || 'default']?.name, {
		variables: pageInfo ? paginationVariables : variables && { ...finalVariables, options },
		// notifyOnNetworkStatusChange: true,
		fetchPolicy: fetchPolicy || 'cache-and-network',
		nextFetchPolicy: fetchPolicy || 'cache-first',
	})

	useEffect(() => {
		if (pageInfo) {
			fetchMore(variables)
		}
	}, [pageInfo]) // eslint-disable-line

	useEffect(() => {
		if (GraphQLEnums[`${type}`].subscription?.[queryKey || 'default']?.name) {
			const processedSubscriptionVariables =
				Object.keys(subscriptionVariables).length > 0 && GraphQLEnums[`${type}`].subscription?.[queryKey || 'default']?.getVariables(subscriptionVariables)

			return subscribeToMore({
				document: GraphQLEnums[`${type}`]?.subscription?.[queryKey || 'default']?.name,
				variables: processedSubscriptionVariables,
				updateQuery: (prev, { subscriptionData }) => updateAtom({ prev, queryKey, subscriptionData, type, setAtom }),
			})
		}
	}, [subscriptionVariables, subscribe]) // eslint-disable-line

	useEffect(() => {
		if (error) {
			throw error
		}

		data && setAtom(GraphQLEnums[`${type}`]?.fetch?.[queryKey || 'default']?.key?.reduce((prev, curr) => prev?.[`${curr}`], data))
	}, [data, error]) // eslint-disable-line

	return loading
}

export const useLazyFetch = ({ type, queryKey, atomKey, storageKey }) => {
	const setAtom = useSetRecoilState(atomKey ? GraphQLEnums[`${type}`]?.atoms?.[`${atomKey}`] : userAtom)
	const [fetchData, { data, loading, error }] = useLazyQuery(GraphQLEnums[`${type}`].fetch?.[queryKey || 'default']?.name)

	useEffect(() => {
		if (error) {
			throw error
		} else if (data && storageKey && atomKey) {
			let processedData = GraphQLEnums[`${type}`]?.fetch?.[queryKey || 'default']?.key?.reduce((prev, curr) => prev?.[`${curr}`], data)
			storageKey && localStorage.setItem(storageKey, JSON.stringify(processedData))
			storageKey && sessionStorage.setItem(storageKey, JSON.stringify(processedData))
			atomKey && setAtom(processedData)
		}
	}, [data, error]) // eslint-disable-line

	return [fetchData, loading, data]
}

export const useMutate = ({ type, action }) => {
	const [setData, { data, loading, error }] = useMutation(GraphQLEnums[type]?.[action].name, {
		// notifyOnNetworkStatusChange: true,
	})
	const [processedData, setProcessedData] = useState(data)
	useEffect(() => {
		if (data) {
			setProcessedData(GraphQLEnums[type]?.[action]?.key?.reduce((prev, curr) => prev?.[`${curr}`], data || []))
		}
	}, [data, error])

	return [setData, loading, processedData]
}
