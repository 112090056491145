import React from 'react'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import './index.css'
import App from './App'

import { ApolloProvider } from '@apollo/client'
import { client } from './graphql/apollConfig'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
	<React.StrictMode>
		<RecoilRoot>
			<ApolloProvider client={client}>
				<App />
			</ApolloProvider>
		</RecoilRoot>
	</React.StrictMode>
)
