import { Button, Checkbox, Form, Progress, Space, Spin, Steps, Switch, Tooltip, notification } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { CostsEnums } from '../../helpers/Enums'
import { FormRender, RenderSchema } from '../../helpers/FormRenderer'
import moment from 'moment'

import * as dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import DynamicTable from '../utility/DynamicTable'
import { render } from '@testing-library/react'
import { mdiCheck, mdiFileDocument, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import { toReadableAmount, toReadableString } from '../../helpers/Functions'
import { AccountNumberSelector, AddEditCostPage, SidebarCard } from '../drawers/JobSidebar'
import { useFetch, useMutate } from '../../hooks/graphql'
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { biltyInvestorsAtom, thirdPartiesAtom } from '../../recoil/atoms'
import { chargeTypesForFormSelector, formatPhoneNumber, jobChargeSelector, postAssignmentSidebarSelector } from '../../recoil/selectors'
import { useRecoilValue } from 'recoil'
import { CustomSwitchWrapper } from '../utility/Utility'
dayjs.extend(weekday)
dayjs.extend(localeData)

export const EditAssignmentModal = ({ data, formWatch: slipped }) => {
	const { job = {}, formData = {} } = data || {}
	const { suppliers, brokers } = formData || {}
	return (
		<div className='my-4 overflow-scroll'>
			<div className='mx-5 w-[45%]'>
				{RenderSchema([
					[
						{
							type: 'select',
							label: 'Supplier',
							name: ['supplier'],
							valueProperty: '_id',
							displayProperty: 'name',
							elementClassName: '!bg-white',
							options: suppliers,
							disabled: job?.supplier?._id && !slipped,
							required: slipped,
							showSearch: true,
							initialValue: job?.supplier?._id,
						},
					],
				])}
			</div>
			<div className='px-6 py-3 flex flex-row w-full align-middle border-t-2 border-b-2 justify-between'>
				<div className='font-[500] text-black-300 text-[16px] my-auto'>Driver Details</div>
				{/* <div className='flex flex-row align-middle items-center'>
					<Form.Item name={['slipped']} noStyle={true}>
						<Switch className='my-auto' disabled={job?.isLot} />
					</Form.Item>
					<div className='pl-2 font-[600] text-black-400 text-[16px] my-auto'>Slip Driver</div>
				</div> */}
			</div>
			<div className='mx-5 mt-3'>
				{RenderSchema([
					[
						{
							type: 'phoneNumber',
							label: 'Driver Phone Number',
							name: ['phoneNumber'],
							initialValue: job?.driverPhone,
						},
						{
							type: 'vehicleRegistration',
							label: 'Vehicle Number',
							name: ['vehicleRegistration'],
							disabled: !slipped,
							initialValue: job?.driverVehicleRegistration,
						},
						{
							type: 'dateTime',
							label: 'Committed Days',
							name: ['committedDays'],
							initialValue: dayjs(parseInt(job?.committedDays)),
						},
					],
					[
						{
							type: 'input',
							inputType: 'number',
							itemClassName: 'w-[35%]',
							addonAfter: 'Tons',
							label: 'Committed Weight',
							name: ['committedWeight'],
							initialValue: job?.committedWeight,
						},
					],
				])}
			</div>
			<div className='mx-5 mt-2 w-[45%]'>
				{RenderSchema([
					{
						type: 'select',
						label: 'Broker',
						name: ['broker'],
						valueProperty: '_id',
						displayProperty: 'namePhoneNumber',
						defaultOption: 'New Broker',
						disabled: !!job?.broker?._id,
						elementClassName: '!bg-white',
						options: brokers,
						showSearch: true,
						required: false,
						className: 'min-w-[40%]',
						initialValue: job?.broker?._id,
					},
				])}
			</div>
			<div className='mx-5'>
				{RenderSchema([
					{
						type: 'dependency',
						name: ['newBroker'],
						independent: 'broker',
						condition: (value) => {
							return value && value?.toLowerCase() === 'other' ? true : false
						},
						successSchema: [
							[
								{
									type: 'input',
									label: 'New Broker Name',
									name: ['brokerName'],
									required: true,
								},
								{
									type: 'phoneNumber',
									label: 'New Broker Mobile Number',
									name: ['brokerPhoneNumber'], //disable if it is already assigned
									required: true,
								},
							],
						],
						failureSchema: [],
					},
				])}
			</div>
		</div>
	)
}

export const EditPaymentsModal = ({ data, form }) => {
	const { job, superUser, supplyAdmin } = data
	const paymentStatus = useMemo(() => job?.vendorPayments?.paymentStatus || 'pendingFreightCostApproval', [data])
	const initialCosts = useMemo(
		() => ({
			...CostsEnums?.reduce((initialCosts, cost) => {
				return { ...initialCosts, [cost.key]: job?.vendorPayments?.costBreakdown?.[cost.key] }
			}, {}),
			cost: job?.cost,
		}),
		[data]
	)

	// useEffect(() => {

	// }, [initialCosts, paymentStatus])
	delete initialCosts.otherCharges

	return (
		<div className='px-5 py-4 overflow-scroll h-[530px]'>
			{RenderSchema([
				[
					{
						type: 'number',
						label: 'Freight Cost',
						name: ['cost'],
						initialValue: job?.cost,
						disabled: ['pendingApproval', 'pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
					{
						type: 'number',
						label: CostsEnums[0].label,
						name: [CostsEnums[0].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[0].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
				],
				[
					{
						type: 'number',
						label: CostsEnums[1].label,
						name: [CostsEnums[1].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[1].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
					{
						type: 'number',
						label: CostsEnums[2].label,
						name: [CostsEnums[2].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[2].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
				],
				[
					{
						type: 'number',
						label: CostsEnums[3].label,
						name: [CostsEnums[3].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[3].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
					{
						type: 'number',
						label: CostsEnums[4].label,
						name: [CostsEnums[4].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[4].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
				],
				[
					{
						type: 'number',
						label: CostsEnums[5].label,
						name: [CostsEnums[5].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[5].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
					{
						type: 'number',
						label: CostsEnums[6].label,
						name: [CostsEnums[6].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[6].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
				],
				[
					{
						type: 'number',
						label: CostsEnums[7].label,
						name: [CostsEnums[7].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[7].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
					{
						type: 'number',
						label: CostsEnums[8].label,
						name: [CostsEnums[8].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[8].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
				],
				[
					{
						type: 'number',
						label: CostsEnums[9].label,
						name: [CostsEnums[9].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[9].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
					{
						type: 'number',
						label: CostsEnums[10].label,
						name: [CostsEnums[10].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[10].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
				],
				[
					{
						type: 'number',
						label: CostsEnums[11].label,
						name: [CostsEnums[11].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[11].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
					{
						type: 'number',
						label: CostsEnums[12].label,
						name: [CostsEnums[12].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[12].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
				],
				[
					{
						type: 'number',
						label: CostsEnums[13].label,
						name: [CostsEnums[13].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[13].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
					{
						type: 'number',
						label: CostsEnums[14].label,
						name: [CostsEnums[14].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[14].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
				],
				[
					{
						type: 'number',
						label: CostsEnums[15].label,
						name: [CostsEnums[15].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[15].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
					{
						type: 'number',
						label: CostsEnums[16].label,
						name: [CostsEnums[16].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[16].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
				],
				[
					{
						type: 'number',
						label: CostsEnums[17].label,
						name: [CostsEnums[17].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[17].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
					{
						type: 'number',
						label: CostsEnums[18].label,
						name: [CostsEnums[18].key],
						initialValue: job?.vendorPayments?.costBreakdown?.[CostsEnums[18].key],
						disabled: ['pendingPayment', 'paid', 'Paid'].includes(paymentStatus),
						inputType: 'text',
						className: 'min-w-[40%]',
						itemClassName: 'mb-5',
					},
				],
			])}
		</div>
	)
}

export const BulkAuthenticateCharges = ({ data, form }) => {
	const { jobs } = data || {}
	const [selectedJob, setSelectedJob] = useState(null)
	// const [selectedChargeIds, setSelectedChargeIds] = useState([])
	const selectedChargeIds = Form.useWatch('selectedChargeIds', form)
	useEffect(() => {
		if (selectedJob?.charges?.every((charge) => selectedChargeIds?.includes(charge?._id))) {
			form.setFieldValue([selectedJob?._id, 'selectAll'], true)
		} else {
			form.setFieldValue([selectedJob?._id, 'selectAll'], false)
		}
	}, [selectedChargeIds, selectedJob])

	return (
		<div className='flex flex-col '>
			<div className='bg-white w-full items-center px-4 py-3 border-b border-black-75'>
				<p className='text-sm font-medium text-black-300'>
					{selectedChargeIds?.length ? `${selectedChargeIds?.length} charges are selected for authentication` : 'Select all charges to authenticate'}
				</p>
			</div>
			<Form.Item hidden={true} name={['selectedChargeIds']} initialValue={[]} key={'selectedChargeIds'} />
			<div className='flex flex-row items-center bg-white'>
				<div className='w-1/3 !h-[400px] bg-white flex flex-col items-start'>
					<DynamicTable
						loading={false}
						hideHeader={true}
						data={jobs}
						rowClassName={(record) => `${selectedJob?._id === record?._id ? 'bg-background' : 'bg-white'} !h-16`}
						onRow={(row) => ({ onClick: (e) => setSelectedJob(row) })}
						columns={() => [
							{
								key: 'jobNumber',
								title: 'Job Number',
								render: (row, index) => (
									<div className='flex flex-row items-center'>
										<p className='px-2 text-sm font-medium text-black-400 '>Job {row?.jobNumber}</p>
										<div className='border-primary-800 border bg-background px-2 py-1 rounded-lg w-fit '>
											<p className='text-xs text-primary-800 font-semibold'>{`${row?.charges?.filter((charge) => selectedChargeIds?.includes(charge?._id))?.length} / ${
												row?.charges?.filter((charge) => charge?.status === 'PENDING_AUTHENTICATION')?.length
											} selected`}</p>
										</div>
									</div>
								),
							},
						]}
					/>
				</div>
				<div className='w-2/3 !h-[400px] bg-black-50 border-l border-black-75 flex flex-col items-start'>
					{selectedJob?._id ? (
						<div className='flex flex-col w-full'>
							<div className='bg-white w-full items-center px-4  !h-12 border-b border-black-75 flex flex-row'>
								<div className=' !w-[60%] flex flex-row items-center   border-r border-black-75 overflow-clip'>
									<div className='flex flex-row items-center justify-start   py-[10px]'>
										<p className='text-sm font-medium text-black-200 '>Broker</p>
									</div>
									<div className={`flex flex-row items-center justify-start py-[10px] bg-white pl-2 max-w-[75%]`}>
										<Tooltip title={selectedJob?.broker?.CompanyName}>
											<p className='text-sm font-semibold text-black-400 truncate'>{selectedJob?.broker?.CompanyName}</p>
										</Tooltip>
									</div>
								</div>
								<div className=' !w-[40%] flex flex-row items-center'>
									<div className='flex flex-row items-center justify-start   py-[10px] '>
										<p className='text-sm font-medium text-black-200 pl-4'>Vehicle</p>
									</div>
									<div className={`flex flex-row items-center justify-start   py-[10px] bg-white pl-4`}>
										<Tooltip title={selectedJob?.driverVehicleRegistration}>
											<p className='text-sm font-semibold text-black-400  truncate'>{selectedJob?.driverVehicleRegistration?.toUpperCase()}</p>
										</Tooltip>
									</div>
								</div>
							</div>
							{selectedJob?.charges?.filter((charge) => charge?.status === 'PENDING_AUTHENTICATION')?.length > 0 && (
								<div className='bg-white w-full items-center px-4  !h-12 border-b border-black-75 flex flex-row'>
									<Form.Item className='!mb-0' valuePropName='checked' name={[selectedJob?.id, 'selectAll']} key={`${selectedJob?.id}-selectAll`}>
										<Checkbox
											onChange={() => {
												let newChargeIds
												if (
													selectedJob?.charges?.filter((charge) => charge?.status === 'PENDING_AUTHENTICATION')?.every((charge) => selectedChargeIds?.includes(charge?._id))
												) {
													const jobChargeIds = selectedJob?.charges?.filter((charge) => charge?.status === 'PENDING_AUTHENTICATION')?.map((charge) => charge?._id)
													newChargeIds = selectedChargeIds?.filter((chargeId) => !jobChargeIds?.includes(chargeId))
												} else {
													newChargeIds = [
														...new Map(
															[
																...(selectedJob?.charges?.filter((charge) => charge?.status === 'PENDING_AUTHENTICATION')?.map((charge) => charge?._id) || []),
																...selectedChargeIds,
															].map((chargeId) => [chargeId, chargeId])
														).values(),
													]
												}
												form.setFieldValue(['selectedChargeIds'], newChargeIds)
											}}
										/>
									</Form.Item>
									<p className='text-sm font-medium text-black-300 pl-4'>Select all charges</p>
								</div>
							)}
							<div className='!h-[452px]'>
								<DynamicTable
									loading={false}
									hideHeader={true}
									rowClassName='!h-16'
									selectedRowKeys={selectedChargeIds}
									onSelect={(selectedRowKeys) => {
										const selectExternal = selectedChargeIds?.filter((chargeId) => !selectedJob?.charges?.some((charge) => charge?._id === chargeId))
										form.setFieldValue(['selectedChargeIds'], [...new Map([...selectedRowKeys, ...selectExternal].map((chargeId) => [chargeId, chargeId])).values()])
									}}
									data={selectedJob?.charges?.filter((charge) => charge?.status === 'PENDING_AUTHENTICATION') || []}
									columns={() => [
										{
											key: 'chargeType',
											title: 'Charge Type',
											render: (row) => <p className='text-sm font-medium text-black-400 pl-2'>{toReadableString(row?.chargeType?.name)}</p>,
										},
										{
											key: 'amount',
											title: 'Amount',
											render: (row) => (
												<div className='text-sm font-semibold text-black-400 text-end pr-12'>
													{RenderSchema([{ type: 'input', name: [row?._id, 'amount'], inputType: 'number', initialValue: row?.amount, itemClassName: '!mb-0' }])}
												</div>
											),
										},
									]}
								/>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}
const { Step } = Steps
export const BulkAddCharges = ({ data, form, formWatch: watcher, modalSetState }) => {
	const { jobs } = data || {}
	const { loading } = useOutletContext()
	const [numCosts, setNumCosts] = useState(1)
	const [currPage, setCurrPage] = useState(1)
	const [formCopyState, setFormCopyState] = useState([])

	const chargeTypes = useRecoilValue(chargeTypesForFormSelector())
	const thirdParties = useRecoilValue(thirdPartiesAtom)
	const biltyInvestors = useRecoilValue(biltyInvestorsAtom)

	useFetch({ type: 'ChargeType', atomKey: 'chargeTypes' })
	useFetch({ type: 'ThirdParty', atomKey: 'thirdParties' })
	useFetch({ type: 'BiltyInvestors', atomKey: 'biltyInvestor' })
	useEffect(() => {
		modalSetState?.({
			numCosts,
			currPage,
		})
	}, [numCosts, currPage])
	const selectedChargeType = useMemo(
		() => chargeTypes?.find((chargeType) => chargeType?._id === watcher?.[currPage || 1]?.chargeType),
		[currPage, chargeTypes, watcher?.[currPage]?.chargeType]
	)
	const brokers = useMemo(() => [...new Map(jobs?.map((job) => [job?.broker?._id, job?.broker])).values()]?.filter((broker) => broker?._id), [jobs])
	const jobChargeTypes = useMemo(
		() => [
			...new Map(
				jobs
					?.map((job) => job?.charges)
					.flat()
					.map((charge) => [charge?.chargeType?._id, charge?.chargeType])
			).values(),
		],
		[jobs]
	)

	const bls = useMemo(
		() => [
			...new Map(
				jobs
					?.map((job) => {
						const jobBls = Object.values(job?.shipmentInfo || {})?.map((shipmentInfo) => shipmentInfo?.documentNumber)
						return jobBls
					})
					.flat()
					.map((bl) => [bl, bl])
			).values(),
		],
		[jobs]
	)
	useEffect(() => {
		if (!formCopyState?.[currPage]) {
			if (!(selectedChargeType?.payeeTypes?.[0] === 'BROKER' && (brokers?.length === 0 || brokers?.length > 1)) && jobs?.every((job) => job?.broker?._id)) {
				form.setFieldValue([currPage, 'payeeType'], selectedChargeType?.payeeTypes?.[0])
			} else if (selectedChargeType?.payeeTypes?.[1]) {
				form.setFieldValue([currPage, 'payeeType'], selectedChargeType?.payeeTypes?.[1])
			}
		}
	}, [selectedChargeType])

	useEffect(() => {
		if (!formCopyState?.[currPage]) {
			form.setFieldValue([currPage, 'entityId'], null)
		}
	}, [watcher?.[currPage]?.payeeType])

	useEffect(() => {
		if (formCopyState?.[currPage]) {
			form.setFieldsValue(!formCopyState?.[currPage])
		}
	}, [currPage])

	return (
		<div className='flex flex-col h-full'>
			<div className='flex flex-row flex-grow-0 px-6 py-3 w-full items-center border-b border-black-75 bg-white'>
				<div className='flex flex-row justify-between items-center w-full'>
					<div className='flex flex-row items-center grow-0'>
						<p className='text-black-400 font-semibold text-sm '>BL# (s) :</p>
						<p className='text-black-300 font-semibold text-sm pl-1 pr-2'>{bls.length > 2 ? bls.slice(0, 2).join(', ') : bls.join(',')}</p>
						{bls.length > 2 ? (
							<div className='bg-background border border-primary-800 rounded-xl px-2'>
								<p className='text-primary-800 font-semibold text-sm '>+ {bls.length - 3}</p>
							</div>
						) : null}
					</div>

					<Button
						onClick={() => {
							form.validateFields().then(() => {
								const values = form.getFieldsValue(true)
								setFormCopyState(values)
								setCurrPage(numCosts + 1)
								setNumCosts((prev) => prev + 1)
							})
						}}
						disabled={
							chargeTypes?.filter(
								(chargeType) =>
									!(
										jobChargeTypes?.some((jobChargeType) => jobChargeType?.name === chargeType?.name) ||
										Object.values(form.getFieldsValue(true) || {})?.some((page, index) => page?.chargeType === chargeType?._id && !(index + 1 === currPage))
									)
							)?.length <= 1
						}
						className='border border-dashed border-primary-800  text-primary-800  w-fit font-semibold flex flex-row items-center justify-center h-10 rounded-lg'
					>
						<p className='pr-1'>Add another charge</p>
						<Icon path={mdiPlus} className=' ' size={0.8} />
					</Button>
				</div>
			</div>
			{numCosts > 1 ? (
				<div className='flex flex-row flex-grow-0 px-6 py-3 w-full items-center border-b border-black-75 bg-white'>
					<Steps direction='horizontal' size='small' current={currPage - 1}>
						{Array.from({ length: numCosts })?.map((costNumber, index) => (
							<Step
								key={`step-${index}`}
								className='items-center'
								status={index < currPage - 1 ? 'finish' : 'process'}
								title={<div className='text-white text-base w-0'>.</div>}
								onStepClick={() => {
									const values = form.getFieldsValue(true)
									setFormCopyState(values)
									setCurrPage(index + 1)
								}}
								icon={
									index < currPage - 1 ? (
										<div className='bg-primary-800 rounded-full flex h-6 w-6 items-center justify-center '>
											<Icon path={mdiCheck} className='text-white' size={0.7} />
										</div>
									) : (
										<div
											className={`rounded-full h-6 w-6 flex items-center justify-center font-[500] text-xs ${
												index === currPage - 1 ? 'bg-primary-800 text-white' : 'bg-black-75 text-white'
											}`}
										>
											<p className=''>{index + 1}</p>
										</div>
									)
								}
							/>
						))}
					</Steps>
				</div>
			) : null}

			<Form preserve={true} form={form} layout='vertical' className={`${numCosts > 1 ? 'h-[80%]' : 'h-[90%]'}`}>
				<div className='flex flex-col h-full overflow-y-scroll px-6 pt-6  space-y-6 '>
					<SidebarCard>
						<div className='bg-white px-4 py-6'>
							{RenderSchema([
								{
									type: 'select',
									label: 'Charge Type',
									name: [currPage, 'chargeType'],
									valueProperty: '_id',
									displayProperty: 'name',
									options: chargeTypes
										?.filter(
											(chargeType) =>
												!(
													jobChargeTypes?.some((jobChargeType) => jobChargeType?.name === chargeType?.name) ||
													Object.values(form.getFieldsValue(true) || {})?.some((page, index) => page?.chargeType === chargeType?._id && !(index + 1 === currPage))
												)
										)
										?.map((chargeType) => ({ _id: chargeType._id, name: toReadableString(chargeType.name) })),
									showSearch: true,
									required: true,
								},
								{
									type: 'input',
									label: 'Amount',
									inputType: 'number',
									name: [currPage, 'amount'],
									addonBefore: (
										<div className='flex flex-row items-center justify-center px-2'>
											<p className='text-sm font-medium text-black-300'>Rs.</p>
										</div>
									),
									required: true,
								},
							])}
						</div>
						<div className='bg-white px-4 '>
							<div className='border-t border-dashed border-black-75 w-full h-[1px] pb-6' />
							{FormRender({
								type: 'input',
								label: 'Comment',
								inputType: 'textArea',
								name: [currPage, 'comment'],
								placeholder: 'Add Comment',
								required: selectedChargeType?.commentRequired,
							})}
							{selectedChargeType?.customFields?.length > 0
								? RenderSchema(selectedChargeType?.customFields?.map((field) => ({ ...field, name: [currPage, 'customFieldProperty', field.propertyKey] })))
								: null}
						</div>
					</SidebarCard>
					<SidebarCard title='Payee Details'>
						<div className='bg-white px-4 pt-6'>
							{RenderSchema([
								{
									type: 'select',
									name: [currPage, 'payeeType'],
									placeholder: 'Select Payee Type',
									options: [
										{
											_id: 'BROKER',
											name: 'Broker',
											disabled: brokers?.length > 1 || brokers?.length === 0 || jobs?.some((job) => !!!job?.broker?._id),
											disabledReason: brokers?.length > 1 ? 'More than one broker found in selected jobs' : 'No Broker found in some selected job(s)',
										},
										{ _id: 'BILTY_INVESTOR', name: 'Bilty Investor' },
										{ _id: 'THIRD_PARTY', name: 'Other' },
									]?.filter((payee) => selectedChargeType?.payeeTypes?.includes(payee?._id)),

									valueProperty: '_id',
									displayProperty: 'name',
									showSearch: true,
									required: true,
								},
							])}
						</div>

						{watcher?.[currPage]?.payeeType === 'BROKER' ? (
							<div className='bg-black-50 px-4 pt-4 flex flex-col border-t border-black-75'>
								<p className='text-sm font-medium text-black-300 pb-4'>Broker Information</p>
								{RenderSchema([
									{
										type: 'input',
										initialValue: brokers?.[0]?.name,
										name: [currPage, 'brokerName'],
										disabled: true,
									},
								])}
								<Form.Item key={`accountId`} name={[currPage, 'accountId']} noStyle={true} shouldUpdate={true} initialValue={brokers?.[0]?.accounts?.[0]?._id}>
									<AccountNumberSelector accounts={brokers?.[0]?.accounts} currPage={currPage} form={form} />
								</Form.Item>
							</div>
						) : ['BILTY_INVESTOR'].includes(watcher?.[currPage]?.payeeType) ? (
							<div className='bg-black-50 px-4 pt-4 flex flex-col border-t border-black-75'>
								<p className='text-sm font-medium text-black-300 pb-4'>Select {toReadableString(watcher?.[currPage]?.payeeType, 'ss_case')}</p>
								{RenderSchema([
									{
										type: 'select',
										placeholder: `Enter phone number or name`,
										name: [currPage, 'entityId'],
										elementClassName: 'bg-white',
										valueProperty: '_id',
										displayProperty: 'name',
										options: biltyInvestors?.map((investor) => ({
											_id: investor?._id,
											name: `${investor.name} (${formatPhoneNumber(investor.phoneNumber)})`,
										})),
										showSearch: true,
									},
								])}
								{watcher?.[currPage]?.entityId && (
									<Form.Item key={`accountId`} name={[currPage, 'accountId']} noStyle={true} shouldUpdate={true}>
										<AccountNumberSelector
											accounts={biltyInvestors?.find((investor) => investor?._id === watcher?.[currPage]?.entityId)?.accounts || []}
											currPage={currPage}
										/>
									</Form.Item>
								)}
							</div>
						) : watcher?.[currPage]?.payeeType === 'THIRD_PARTY' ? (
							<div className='bg-black-50 px-4 pt-4 flex flex-col border-t border-black-75'>
								<p className='text-sm font-medium text-black-300 pb-4'>Select Other</p>
								<div className='flex flex-row items-start'>
									<div className='w-2/3 flex flex-row items-center pr-4'>
										{FormRender({
											type: 'select',
											placeholder: `Enter account or name`,
											name: [currPage, 'entityId'],
											valueProperty: '_id',
											displayProperty: 'name',
											options: thirdParties?.map((party) => ({ _id: party?._id, name: `${party.name} (${formatPhoneNumber(party.phoneNumber)})` })),
											showSearch: true,
											elementClassName: 'bg-white',
											itemClassName: 'w-full',
										})}
									</div>
									<div className='w-1/3'>
										<Button
											onClick={() => {
												form.setFieldValue([currPage, 'entityId'], 'addNewThirdParty')
											}}
											className='border-2 border-dashed border-primary-800 bg-background text-primary-800  w-full font-semibold flex flex-row items-center justify-center h-12 rounded-lg'
										>
											Add New
										</Button>
									</div>
								</div>
								{watcher?.[currPage]?.entityId === 'addNewThirdParty' ? (
									<div className=' flex flex-col'>
										<div className='w-full'>
											{RenderSchema([
												{
													type: 'input',
													label: 'Third Party Name',
													name: [currPage, 'thirdParty', 'name'],
													required: true,
													placeholder: 'Enter name',
												},
												{
													type: 'phoneNumber',
													label: 'Phone Number',
													name: [currPage, 'thirdParty', 'phoneNumber'],
													required: true,
													placeholder: 'Enter Phone number',
												},
												{
													type: 'select',
													label: 'Third Party Type',
													name: [currPage, 'thirdParty', 'type'],
													valueProperty: '_id',
													displayProperty: 'name',
													options: [{ _id: 'LABOUR', name: 'Labour' }],
													required: true,
													elementClassName: 'bg-white',
													placeholder: 'Enter Type',
												},
											])}
										</div>
									</div>
								) : null}
								{watcher?.[currPage]?.entityId && (
									<Form.Item
										key={`accountId`}
										name={[currPage, 'accountId']}
										noStyle={true}
										shouldUpdate={true}
										initialValue={thirdParties?.find((party) => party?._id === watcher?.[currPage]?.entityId)?.accounts?.[0]}
									>
										<AccountNumberSelector
											accounts={thirdParties?.find((party) => party?._id === watcher?.[currPage]?.entityId)?.accounts || []}
											allowNew={true}
											currPage={currPage}
										/>
									</Form.Item>
								)}
							</div>
						) : null}
					</SidebarCard>
					<div className='pb-8' />
				</div>
			</Form>
		</div>
	)
}
