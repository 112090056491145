import { Dropdown, Button, Form, Input, Divider } from 'antd'
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { RenderSchema } from '../../helpers/FormRenderer'
import { FilterTypeEnums, PageFilterEnums } from '../../helpers/Enums'

import { Capitalize } from '../../helpers/Functions'
import Icon from '@mdi/react'
import { mdiFilterRemove, mdiNoteSearchOutline } from '@mdi/js'

export const Filter = ({ state, formState, filter, options, dispatch, prevState, className }) => {
	const { type, name, propertyKey, filterProps, showSearch, shouldShow } = filter
	const { trigger, schema, customOverlay, hideClear } = FilterTypeEnums[type]
	const [keyword, setKeyword] = useState('')
	const [open, setOpen] = useState(false)

	return !(schema || customOverlay) ? (
		<div
			key='primaryButton'
			className={
				(FilterTypeEnums[type]?.checkSelected(state)
					? '!bg-secondary-300 !text-white hover:opacity-90 max-w-[350px] overflow-hidden'
					: 'bg-white text-secondary-400 max-w-[250px]') +
				' transition-[max-width] duration-200 rounded-md  flex flex-row items-center border px-[13.5px] text-sm cursor-pointer h-[40px] mb-4 ' +
				(className ? ` ${className} ` : 'mr-4 ')
			}
		>
			<Form.Item key={`${propertyKey}-propertyKey`} name={[propertyKey, 'propertyKey']} noStyle={true} />
			<Form.Item key={`${propertyKey}-type`} name={[propertyKey, 'type']} noStyle={true} />
			<Form.Item key={`${propertyKey}-prevState`} name={[propertyKey, 'prevState']} noStyle={true} />
			{name}&nbsp;
			{FilterTypeEnums[type]?.customDisplay ? (
				FilterTypeEnums[type]?.customDisplay({ state, filter })
			) : (
				<div className='flex flex-row items-center overflow-hidden'>
					{FilterTypeEnums[type]?.checkSelected(state) && (
						<div className='flex flex-row overflow-ellipsis'>
							:&nbsp;
							<div className='text-white font-[600] '>{FilterTypeEnums[type]?.selectedDisplay({ values: state, options: options, filterProps, prevState })}</div>
						</div>
					)}
					{(FilterTypeEnums[type].schema || FilterTypeEnums[type].customOverlay) && (
						<CaretDownOutlined className={(FilterTypeEnums[type]?.checkSelected(state) ? ' !text-white ' : '!text-[#9F9F9F]') + ' pl-2  text-xs '} />
					)}
				</div>
			)}
		</div>
	) : (
		<Dropdown
			key={name}
			trigger={['click']}
			placement='bottomLeft'
			visible={open}
			onVisibleChange={(visible) => {
				setOpen(visible)

				if (!visible) {
					if (trigger === 'onClose') {
						dispatch({ type: 'change', payload: { key: propertyKey, values: { propertyKey: propertyKey, type: type, values: formState } } })
					} else {
						dispatch({ type: 'sync', payload: { key: propertyKey } })
					}
				}
			}}
			overlay={
				<div
					key={`overlay-${name}`}
					className={` bg-white w-fit max-w-[330px] min-w-[250px] rounded-sm  ${
						!(schema || customOverlay) ? ' hidden ' : 'shadow-[0px_1px_3px_1px_rgba(0,0,0,0.1)]'
					}`}
				>
					{FilterTypeEnums[type]?.customOverlay ? (
						<>{FilterTypeEnums[type].customOverlay({ filter, state })}</>
					) : (
						<div className='mb-2'>
							{showSearch && (
								<Input
									className='!h-[45px] !border !border-b-black-100 flex flex-row'
									placeholder='Search'
									onChange={(e) => setKeyword(e.target.value)}
									prefix={<SearchOutlined className='px-2' />}
								/>
							)}
							<div className={'scroll-visible px-3 pt-4 pb-2 max-h-[40vh] overflow-y-scroll ' + (trigger === 'button' ? 'border-0' : 'border-b ')}>
								{schema ? RenderSchema(schema({ filter, options, keyword }), 'filter') : <></>}
							</div>
							{trigger === 'button' && options && Object.keys(options).length === 0 && (
								<div className='pb-6 rounded-sm flex flex-col items-center justify-center align-middle text-center'>
									<Icon path={mdiNoteSearchOutline} size={2} className='text-black-75 mb-4' />
									<p className='text-black-75 text-lg font-medium'>No Data</p>
								</div>
							)}
						</div>
					)}
					{trigger === 'button' && (options ? Object.keys(options).length > 0 : true) ? (
						<div className='flex flex-row justify-end items-center px-3 mt-0 pb-4 border-t-0'>
							<Button
								key={`${name}-reset`}
								className='text-primary-800 mr-4 px-0 font-[600] text-sm border-0 shadow-none'
								onClick={() => {
									dispatch({ type: 'reset', payload: { key: propertyKey } })
									setOpen(false)
								}}
							>
								Reset
							</Button>
							<Button
								key={`${name}-done`}
								className='btn-primary h-[30px] px-3'
								onClick={() => {
									dispatch({ type: 'change', payload: { key: propertyKey, values: { propertyKey: propertyKey, type: type, values: formState } } })
									setOpen(false)
								}}
							>
								Done
							</Button>
						</div>
					) : (
						!hideClear &&
						(trigger === 'button' && options ? Object.keys(options).length > 0 : true) && (
							<Button
								key={`${name}-clear`}
								className='w-full bg-white border-none h-[45px] align-middle font-[600] text-sm text-primary-800'
								onClick={() => {
									dispatch({ type: 'reset', payload: { key: propertyKey } })
									setOpen(false)
								}}
							>
								{`${type === 'select' ? 'CLEAR SELECTION' : 'CLEAR ALL'}`}
							</Button>
						)
					)}
				</div>
			}
		>
			<Button
				key={`${name}-primaryButton`}
				onClick={(e) => {
					e.stopPropagation()
					setOpen(true)
				}}
				className={
					(FilterTypeEnums[type]?.checkSelected(state)
						? '!bg-secondary-300 !text-white hover:opacity-90 max-w-[350px] overflow-hidden'
						: 'bg-white text-secondary-400 max-w-[250px]') +
					' transition-[max-width] duration-200 rounded-md  flex flex-row items-center border px-[13.5px] text-sm cursor-pointer h-[40px] mb-4 ' +
					(className ? ` ${className} ` : 'mr-4 ')
				}
			>
				<Form.Item key={`${propertyKey}-propertyKey`} name={[propertyKey, 'propertyKey']} noStyle={true} />
				<Form.Item key={`${propertyKey}-type`} name={[propertyKey, 'type']} noStyle={true} />
				<Form.Item key={`${propertyKey}-prevState`} name={[propertyKey, 'prevState']} noStyle={true} />
				{name}&nbsp;
				{FilterTypeEnums[type]?.customDisplay ? (
					FilterTypeEnums[type]?.customDisplay({ state, filter })
				) : (
					<div className='flex flex-row items-center overflow-hidden'>
						{FilterTypeEnums[type]?.checkSelected(state) && (
							<div className='flex flex-row overflow-ellipsis'>
								:&nbsp;
								<div className='text-white font-[600] '>{FilterTypeEnums[type]?.selectedDisplay({ values: state, options: options, filterProps, prevState })}</div>
							</div>
						)}
						{(FilterTypeEnums[type].schema || FilterTypeEnums[type].customOverlay) && (
							<CaretDownOutlined className={(FilterTypeEnums[type]?.checkSelected(state) ? ' !text-white ' : '!text-[#9F9F9F]') + ' pl-2  text-xs '} />
						)}
					</div>
				)}
			</Button>
		</Dropdown>
	)
}

const FilterHeader = ({ props: { filterController, filterFormController, segment, tab, page, selectedFilters, primaryFilterProperties = [] } }) => {
	const { filterOptions, filterState, filterDispatch } = filterController
	const formState = Form.useWatch(undefined, filterFormController)
	const filters = useMemo(
		() =>
			PageFilterEnums[page]?.[segment !== 'vendorManagement' ? Capitalize(tab === 'lot' ? 'tab' : segment) : 'default']?.filter(
				(filter) => !primaryFilterProperties.includes(filter.propertyKey)
			),
		[page, segment, primaryFilterProperties]
	)
	const primaryFilters = useMemo(
		() =>
			PageFilterEnums[page]?.[segment !== 'vendorManagement' ? Capitalize(segment) : 'default']?.filter((filter) =>
				primaryFilterProperties.includes(filter.propertyKey)
			),
		[page, segment, primaryFilterProperties]
	)

	return (
		<React.Fragment>
			{primaryFilters?.length > 0 && (
				<React.Fragment>
					{primaryFilters?.map(
						(filter, index) =>
							(!filter.shouldShow || filter.shouldShow(filterState)) && (
								<Filter
									key={filter.propertyKey}
									filter={filter}
									state={filterState?.[filter.propertyKey]?.values}
									className={index === primaryFilters.length - 1 ? ' mr-2' : 'mr-4'}
									prevState={filterState?.[filter.propertyKey]?.prevState}
									formState={formState?.[filter.propertyKey]?.values}
									options={filterOptions?.[filter.propertyKey]}
									dispatch={filterDispatch}
								/>
							)
					)}
					<Divider type='vertical' className='h-[40px]' />
				</React.Fragment>
			)}

			{filters?.length > 0 &&
				filters
					?.sort((filter1, filter2) => selectedFilters.includes(filter2) - selectedFilters.includes(filter1))
					?.map((filter, index) => {
						return (
							(!filter.shouldShow || filter.shouldShow(filterState)) && (
								<Filter
									key={filter.propertyKey}
									filter={filter}
									className={index === 0 ? 'ml-2 mr-4' : ''}
									state={filterState?.[filter.propertyKey]?.values}
									prevState={filterState?.[filter.propertyKey]?.prevState}
									formState={formState?.[filter.propertyKey]?.values}
									options={filterOptions?.[filter.propertyKey]}
									dispatch={filterDispatch}
								/>
							)
						)
					})}

			{filters?.length > 0 && (
				<div
					onClick={() => filterDispatch({ type: 'reset', payload: {} })}
					className='border bg-white text-secondary-400 px-4 rounded-md flex flex-row items-center text-sm cursor-pointer h-[40px] mb-4 '
				>
					<Icon size={1} path={mdiFilterRemove} className='text-red-400' />
					<div className='pl-1  w-full text-center font-[600] text-red-400 text-xs'>CLEAR FILTERS</div>
				</div>
			)}
		</React.Fragment>
	)
}

export default FilterHeader
