// react
import { Layout } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import SearchHeader from '../components/layout/SearchHeader'
import DynamicTable from '../components/utility/DynamicTable'
import { managementColumns } from '../helpers/TableColumns'
import { useFetch } from '../hooks/graphql'

import moment from 'moment'
import { useRecoilValue } from 'recoil'
import { filteredManagementViewSelector } from '../recoil/selectors'
import useFiltrationData from '../hooks/useFiltrationData'

const { Content, Header } = Layout

const ManagementPage = (props) => {
	const navigator = useNavigate()
	const { segment } = useParams()
	const { tab } = useOutletContext()
	const [filterVariables, setFilterVariables] = useState({
		weightSlot: '5',
	})
	// console.log({filterDate: filterVariables.intakeDate })
	const loading = useFetch({
		type: 'ManagementData',
		atomKey: segment,
		variables: {
			type: segment,
			tab,
			weightSplit: parseInt(filterVariables.weightSlot),
			intakeDate: filterVariables.intakeDate?.low
				? filterVariables.intakeDate
				: { low: moment(new Date('22 March 2023 00:00:00 GMT+05:00')), high: moment().endOf('day') },
			// rtmDate: filterVariables.rtmDate,
		},
	})
	const data = useRecoilValue(filteredManagementViewSelector({ segment }))
	const filterDataLoading = useFiltrationData()
	return (
		<>
			<SearchHeader tab={tab} setExternalState={setFilterVariables} />

			<Content className='bg-white rounded-lg pb-2 shadow-[0_1px_8px_rgba(0,0,0,0.16)] overflow-y-hidden'>
				<DynamicTable className='rounded-table' columns={managementColumns} columnProps={{ segment, tab, navigator, data }} data={data} loading={loading} />
			</Content>
		</>
	)
}

export default ManagementPage
