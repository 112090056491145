// react imports
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

// mdi imports
import Icon from '@mdi/react'
import { mdiArrowLeft, mdiCircleMedium, mdiDeleteOutline } from '@mdi/js'

// antd imports
import { Button, Card, Divider, Form, Layout, Select, Space, Spin, notification } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'

// form imports
import { FormRender } from '../helpers/FormRenderer'

// recoil imports
import { useRecoilValue } from 'recoil'
import { brokerByIdSelector, getAllLanes, getLanesbyIds } from '../recoil/selectors'

// graphql imports
import { useFetch } from '../hooks/graphql'

// hooks imports
import { useAddEditVendor } from '../hooks/modalFinishHooks'

// helpers imports
import { removeNullUndefined } from '../helpers/Functions'

// loading component
const antIcon = (
	<LoadingOutlined
		style={{
			fontSize: 40,
		}}
		spin
	/>
)

// render 2 form inputs in a row
const CarrierInfo = ({ props }) => {
	const { firstProperty, secondProperty } = props
	return (
		<>
			<div className={`flex flex-row ${firstProperty?.justifyCenter === false ? 'ml-[44px]' : 'justify-center'}`}>
				<div className='flex flex-col'>
					<div class="text-stone-500 text-[13px] font-medium font-['Inter']">{firstProperty.label}</div>
					<div className={`w-[${firstProperty.width ? firstProperty.width : '298px'}] h-[50px] bg-white rounded-lg border border-gray-300`}>
						{FormRender({
							type: firstProperty.type,
							name: firstProperty.value,
							valueProperty: firstProperty?.valueProperty,
							displayProperty: firstProperty?.displayProperty,
							options: firstProperty?.options,
							required: firstProperty?.required || false,
							showSearch: firstProperty?.showSearch || false,
							initialValue: firstProperty.initialValue,
							...(firstProperty?.inputType && {
								inputType: firstProperty?.inputType,
							}),
						})}
					</div>
				</div>
				{secondProperty && (
					<div className=' flex flex-col ml-[39px]'>
						<div class="text-stone-500 text-[13px] font-medium font-['Inter'] "> {secondProperty?.label} </div>
						<div className='w-[298px] h-[50px] bg-white rounded-lg border border-gray-300'>
							{FormRender({
								type: secondProperty.type,
								name: secondProperty.value,
								valueProperty: '_id',
								displayProperty: 'name',
								options: [],
								required: secondProperty?.required || false,
								initialValue: secondProperty.initialValue,
								...(secondProperty?.inputType && {
									inputType: secondProperty?.inputType,
								}),
							})}
						</div>
					</div>
				)}
			</div>
		</>
	)
}

// cnic upload
const CnicButtons = ({ props }) => {
	const { firstProperty, secondProperty } = props
	const randomid = Date.now() + Math.random() * 100

	return (
		<>
			<div className='flex flex-row mx-[44px] mb-[44px]'>
				<div class='h-[124px] flex basis-1/3 bg-white rounded-lg border border-gray-300'>
					{FormRender({
						type: 'profile-picture-upload',
						key: 'profilePicture',
						name: firstProperty.value,
						side: 'Frontside',
						collection: 'broker',
						uploadLink: `${randomid}-cnic-front`,
					})}
				</div>
				{secondProperty && (
					<div class='h-[124px] flex basis-1/3 w-[44px] ml-[44px] bg-white rounded-lg border border-gray-300 '>
						{FormRender({
							type: 'profile-picture-upload',
							key: 'profilePicture',
							name: secondProperty.value,
							side: 'Backside',
							collection: 'broker',
							uploadLink: `${randomid}-cnic-back`,
						})}
					</div>
				)}
			</div>
		</>
	)
}

// section heading
const HeaderDiv = ({ value }) => {
	return (
		<>
			<div class='flex flex-col w-full h-12 bg-stone-50 mt-[43px] justify-center mb-[39px]'>
				<div class="w-[143px] h-[17px] text-zinc-800 text-sm font-bold font-['Inter'] mx-[44px]">{value}</div>
			</div>
		</>
	)
}

// component
const CarrierManagementPage = () => {
	// react initializations
	const { action, brokerId } = useParams()
	const navigate = useNavigate()
	const [brokerLoading, setLoading] = useState(false)

	// graphql initializations
	useFetch({
		type: 'Lanes',
		atomKey: 'lane',
	})

	// recoil initializations
	const brokerData = useRecoilValue(brokerByIdSelector({ _id: brokerId }))
	const lanes = useRecoilValue(getAllLanes())
	const initialLanes = useRecoilValue(getLanesbyIds({ _ids: brokerData?.Lanes?.map((lane) => lane._id) }))

	// form initializations
	const [carrierForm] = Form.useForm()

	// hooks initializations
	const [createNewVendor] = useAddEditVendor()

	// components initializations
	const [api, contextHolder] = notification.useNotification()

	// this is something similar to form schema but according to Mr. Mohid's legendary implementation
	const properties = {
		carrierTypeAndCompanyName: {
			firstProperty: {
				label: 'Carrier Type *',
				value: 'vendorType',
				initialValue: 'broker',
				type: 'select',
				displayProperty: 'label',
				valueProperty: 'value',
				options: [
					{ label: 'Broker', value: 'broker' },
					{ label: 'Owner', value: 'owner' },
					{ label: 'Hybrid-O', value: 'hybrido' },
					{ label: 'Hybrid-B', value: 'hybridb' },
					{ label: 'Investor', value: 'investor' },
				],
			},
			secondProperty: {
				label: 'Company Name *',
				value: 'CompanyName',
				required: true,
				type: 'input',
			},
		},
		nameAndContactNumber: {
			firstProperty: {
				label: 'Name *',
				value: ['User', 'name'],
				required: true,
				type: 'input',
			},
			secondProperty: {
				label: 'Contact Number *',
				value: ['User', 'phoneNumber'],
				required: true,
				type: 'phoneNumber',
			},
		},
		cnicAndNTN: {
			firstProperty: {
				label: 'CNIC Number',
				value: ['User', 'cnic'],
				type: 'input',
				inputType: 'number',
			},
			secondProperty: {
				label: 'NTN Number',
				value: 'NTN',
				type: 'input',
				inputType: 'number',
			},
		},
		cnic: {
			firstProperty: {
				label: 'CNIC Number',
				value: 'cnic',
				type: 'input',
				inputType: 'number',
				justifyCenter: false,
			},
		},
		brokerCNIC: {
			firstProperty: {
				value: ['User', 'cnicFront'],
			},
			secondProperty: {
				value: ['User', 'cnicBack'],
			},
		},
		pocCNIC: {
			firstProperty: {
				value: 'broker',
			},
			secondProperty: {
				value: 'broker',
			},
		},
		address: {
			firstProperty: {
				label: 'Address',
				value: 'officeAddress',
				type: 'input',
				required: true,
				justifyCenter: false,
				width: '635px',
			},
		},
		numberOfVehicles: {
			firstProperty: {
				label: 'Number of Vehicles',
				value: 'totalVehiclesOwned',
				required: false,
				type: 'input',
				justifyCenter: false,
			},
		},
		lanes: {
			firstProperty: {
				label: 'Available Lanes',
				value: 'lanes',
				type: 'select',
				justifyCenter: false,
				width: '635px',
			},
		},
		bankAndAccountNumber: {
			firstProperty: {
				label: 'Select Bank',
				value: 'bank',
				showSearch: true,
				type: 'select',
				required: true,
				options: [
					'Al Baraka Bank (Pakistan) Limited',
					'Allied Bank Limited (ABL)',
					'Askari Bank',
					'Bank Alfalah Limited (BAFL)',
					'Bank Al-Habib Limited (BAHL)',
					'BankIslami Pakistan Limited',
					'Bank of Punjab (BOP)',
					'Bank of Khyber',
					'Deutsche Bank A.G',
					'Dubai Islamic Bank Pakistan Limited (DIB Pakistan)',
					'Faysal Bank Limited (FBL)',
					'First Women Bank Limited',
					'Habib Bank Limited (HBL)',
					'Habib Metropolitan Bank Limited',
					'Industrial and Commercial Bank of China',
					'Industrial Development Bank of Pakistan',
					'JS Bank Limited',
					'MCB Bank Limited',
					'MCB Islamic Bank Limited',
					'Meezan Bank Limited',
					'National Bank of Pakistan (NBP)',
					'Summit Bank Pakistan',
					'Standard Chartered Bank (Pakistan) Limited (SC Pakistan)',
					'Sindh Bank',
					'The Bank of Tokyo-Mitsubishi UFJ (MUFG Bank Pakistan)',
					'United Bank Limited (UBL)',
					'Zarai Taraqiati Bank Limited',
				],
			},
			secondProperty: {
				label: 'Account Number',
				value: 'accountNumber',
				type: 'input',
				required: true,
			},
		},
		accountTitle: {
			firstProperty: {
				label: 'Account Title',
				value: 'accountTitle',
				type: 'input',
				justifyCenter: false,
				required: true,
			},
		},
	}

	// function to show notifications
	const openNotification = (text) => {
		api.open({
			message: 'Validation Error',
			description: text,
			duration: 0,
		})
	}

	// function to form a schema using `properties`
	const addNameToProperty = (propertyName, firstName, secondName) => {
		return {
			firstProperty: {
				...properties[propertyName].firstProperty,
				value: firstName,
			},
			...(secondName && {
				secondProperty: {
					...properties[propertyName].secondProperty,
					value: secondName,
				},
			}),
		}
	}

	// on form finish/submit
	const onFinish = async ({ values }) => {
		// configure input for mutation
		const pocs = values?.POCs?.map((poc) => {
			return removeNullUndefined({
				...poc,
				__typename: null,
				_id: null,
				cnicFront: poc?.cnicFront?.[0]?.url,
				cnicBack: poc?.cnicBack?.[0]?.url,
			})
		})

		const accounts = values?.accounts?.map((account) => {
			return removeNullUndefined({
				...account,
				type: 'BROKER_ACCOUNT',
				documents: account?.documents?.map((document) => document?.url),
			})
		})

		let deactivateAccounts = []
		for (const id in values?.deactivateAccounts) {
			if (values?.deactivateAccounts?.[id]) {
				deactivateAccounts.push(id)
			}
		}

		let activateAccounts = []
		for (const id in values?.activateAccounts) {
			if (values?.activateAccounts?.[id]) {
				activateAccounts.push(id)
			}
		}

		const user = {
			...values?.User,
			cnicFront: values?.User?.cnicFront?.[0]?.url,
			cnicBack: values?.User?.cnicBack?.[0]?.url,
		}

		const brokerInput = removeNullUndefined({
			...values,
			cnic: values?.User?.cnic,
			phoneNumber: values?.User?.phoneNumber?.replace('+92', ''),
			name: values?.User?.name,
			isBlocked: false,
			POCs: pocs,
			accounts,
			deactivateAccounts,
			activateAccounts,
			User: user,
		})

		// execute mutation
		setLoading(true)
		const resp = await createNewVendor({ values: brokerInput, props: { edit: action === 'edit', type: 'brokers', _id: brokerId } })
		if (resp.type === 'error') {
			api.error({
				message: action === 'edit' ? 'Carrier Updation Failed' : 'Carrier Creation Failed',
				description: resp?.message,
				duration: 10,
			})
			setLoading(false)
			return
		}
		setLoading(false)
		navigate(-1)
	}

	// initial values
	const user = {
		...brokerData?.User,
		name: (brokerData?.User?.firstName || '') + ' ' + (brokerData?.User?.lastName || ''),
		phoneNumber: brokerData?.User?.phoneNumber?.replace('+92', ''),
		...(brokerData?.User?.cnicFront && {
			cnicFront: [
				{
					uid: 0,
					name: 'cnicFront',
					status: 'done',
					url: brokerData?.User?.cnicFront,
				},
			],
		}),

		...(brokerData?.User?.cnicBack && {
			cnicBack: [
				{
					uid: 1,
					name: 'cnicBack',
					status: 'done',
					url: brokerData?.User?.cnicBack,
				},
			],
		}),
	}
	const pocs = brokerData?.POCs?.map((poc, index) => {
		return {
			...poc,
			cnicFront: [
				{
					uid: index,
					name: 'cnicFront',
					status: 'done',
					url: poc?.cnicFront,
				},
			],
			cnicBack: [
				{
					uid: index,
					name: 'cnicBack',
					status: 'done',
					url: poc?.cnicBack,
				},
			],
		}
	})
	const initialValues = {
		...brokerData,
		Lanes: initialLanes,
		User: user,
		POCs: pocs,
		accounts: null,
	}

	// component to render
	return (
		<>
			<Layout style={{ minHeight: '100vh' }}>
				<Header className='flex flex-row px-0 bg-white shadow' style={{ position: 'fixed', width: '100%', zIndex: 1 }}>
					<div className='flex flex-row h-[72px] w-full items-center justify-between px-6'>
						<div className='flex flex-row'>
							<div>
								<Icon className='w-[78.03px] h-8 bg-stone-50 rounded-[80px]' path={mdiArrowLeft} onClick={() => navigate(-1)} size={1} color={'gray'}></Icon>
							</div>
							<div class="text-zinc-800 text-base font-semibold font-['Inter'] px-4">Back</div>
						</div>
						<div class="text-center text-zinc-800 text-base font-bold font-['Inter']">{action === 'edit' ? 'Edit Carrier' : 'Add Carrier'}</div>
						<div>
							{brokerLoading ? (
								<Spin className='text-center' indicator={antIcon} />
							) : (
								<button onClick={() => carrierForm.submit()}>
									<div className="w-[168.74px] text-center text-white bg-sky-600 rounded text-sm font-bold font-['Inter'] px-1 py-3">CONFIRM</div>
								</button>
							)}
						</div>
					</div>
				</Header>
				<Content className='pb-6 bg-gray-100' style={{ marginTop: '72px', overflow: 'auto' }}>
					{contextHolder}
					<Form form={carrierForm} onFinish={(values) => onFinish({ values: values })} initialValues={initialValues}>
						<div className='flex flex-col'>
							{carrierForm.getFieldValue('BankInfo')?.length && carrierForm.getFieldValue('BankInfo')?.length === 0 && (
								<div className='error-message'>At least one 'Bank Info' section is required.</div>
							)}
							<div className='flex flex-col items-center '>
								<div className='flex flex-col items-start'>
									<div class="h-[17px] text-neutral-400 text-sm font-bold font-['Inter'] mt-[24px] mb-[16px] uppercase">Basic Carrier Information</div>
									<div className=' flex flex-col w-[729px] h-fit'>
										<div className='bg-white rounded-md py-[39px]'>
											<CarrierInfo props={properties.carrierTypeAndCompanyName} />
											<HeaderDiv value={'Personal Information'} />
											<CarrierInfo props={properties.nameAndContactNumber} />
											<div className='my-[39px]'>
												<CarrierInfo props={properties.cnicAndNTN} />
											</div>
											<div class="text-stone-500 text-[13px] font-medium font-['Inter'] ml-[44px] mb-[10px]">Upload CNIC</div>
											<CnicButtons props={properties?.brokerCNIC} />
											<div className='mb-[100px]'></div>
											<HeaderDiv value={'Office Address'} />
											<CarrierInfo props={properties.address} />
											<HeaderDiv value={'POC Information'} />
											<div className='flex flex-col'>
												<Form.List name='POCs'>
													{(fields, { add, remove }) => (
														<div>
															{fields.map(({ key, name, fieldKey, ...restField }, index) => (
																<div key={key}>
																	<CarrierInfo props={addNameToProperty('nameAndContactNumber', [name, 'name'], [name, 'phoneNumber'])} />
																	<div className='my-[18px]'>
																		<CarrierInfo props={addNameToProperty('cnic', [name, 'cnic'])} />
																	</div>
																	<CnicButtons props={addNameToProperty('pocCNIC', [name, 'cnicFront'], [name, 'cnicBack'])} />
																	{fields.length > 1 && (
																		<div className='flex flex-row justify-end mr-[44px]'>
																			<Button
																				type='text'
																				danger
																				icon={<DeleteOutlined />} // Red delete icon
																				onClick={() => {
																					remove(name)
																				}}
																			/>
																		</div>
																	)}

																	<div className='mt-[128px]'>
																		{FormRender({
																			type: 'divider',
																		})}
																	</div>
																	{index === fields.length - 1 && (
																		<Button
																			className='flex flex-row items-center ml-[44px] justify-center w-[635px] h-[50px] bg-white rounded-lg border-2 border-dashed border-sky-600'
																			type='dashed'
																			onClick={() => {
																				add()
																			}}
																		>
																			Add Another POC +
																		</Button>
																	)}
																</div>
															))}
															{fields.length === 0 && (
																<Button
																	className='flex flex-row items-center ml-[44px] justify-center w-[635px] h-[50px] bg-white rounded-lg border-2 border-dashed border-sky-600'
																	type='dashed'
																	onClick={() => {
																		add()
																	}}
																>
																	Add POC +
																</Button>
															)}
														</div>
													)}
												</Form.List>
											</div>

											<HeaderDiv value={'Owned Vehicles'} />
											<CarrierInfo props={properties.numberOfVehicles} />
										</div>
									</div>
									<div class="h-[17px] text-neutral-400 text-sm font-bold font-['Inter'] mt-[24px] mb-[16px] uppercase">Preferred Lanes</div>
									<div className=' flex flex-col w-[729px] h-fit'>
										<div className='bg-white rounded-md py-[39px]'>
											{/* <CarrierInfo props={properties.lanes} /> */}
											<div className='flex flex-col mx-[44px]'>
												<div class="text-stone-500 text-[13px] font-medium font-['Inter'] "> Available Lanes * </div>
												<div className=''>
													<Form.Item
														name='Lanes'
														rules={[
															{
																required: true,
																message: 'Please select at least one item',
																type: 'array',
															},
														]}
														initialValue={initialLanes}
													>
														<Select
															mode='multiple' // Allows multiple selections
															placeholder='Search and select items'
															style={{ width: '70%', color: '#0284c7' }}
															filterOption={(input, option) => {
																return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
															}}
															showSearch // Enables search functionality
															// defaultValue={initialLanes}
														>
															{lanes?.map((lane) => {
																return (
																	<Select.Option key={lane?.value} value={lane?.key}>
																		{lane?.label}
																	</Select.Option>
																)
															})}
														</Select>
													</Form.Item>
												</div>
											</div>
										</div>
									</div>
									<div class="h-[17px] text-neutral-400 text-sm font-bold font-['Inter'] mt-[24px] mb-[16px] uppercase">Bank Information</div>
									<div className=' flex flex-col w-[729px] h-fit'>
										<div className='bg-white rounded-md py-[39px]'>
											<div className='flex flex-col'>
												{brokerData?.accounts?.map((account, index) => (
													<Card
														className={`flex flex-col w-[641px] ${index !== 0 && 'mt-6'} place-self-center`}
														cover={
															<div className='flex h-10 px-3 text-sm items-center justify-between font-semibold capitalize text-primary-800 bg-primary-50 border border-[#f0f0f0]'>
																{account?.bankName}
																{account?.deactivated
																	? FormRender({
																			type: 'checkbox',
																			name: ['activateAccounts', account?._id],
																			label: 'Activate Account',
																	  })
																	: FormRender({
																			type: 'checkbox',
																			name: ['deactivateAccounts', account?._id],
																			label: 'Deactivate Account',
																	  })}
															</div>
														}
													>
														{account?.deactivated ? (
															<div className='flex flex-row items-center w-fit pr-2.5 text-xs font-semibold rounded-full uppercase text-[#be2525] bg-[#fff2f0]'>
																<Icon path={mdiCircleMedium} size={0.75} />
																inactive
															</div>
														) : (
															<div className='flex flex-row items-center w-fit pr-2.5 text-xs font-semibold rounded-full uppercase text-[#2f8f50] bg-[#e6f5e9]'>
																<Icon path={mdiCircleMedium} size={0.75} />
																active
															</div>
														)}
														<div className='flex flex-row mt-3 items-center'>
															<div className='w-1/2'>
																<Space className='flex flex-col items-start'>
																	<div className='text-sm font-semibold capitalize text-neutral-400'>Account Title</div>
																	<div className='text-sm font-semibold capitalize text-neutral-400'>Account Number</div>
																	<div className='text-sm font-semibold capitalize text-neutral-400'>Documents</div>
																</Space>
															</div>
															<div className='w-1/2'>
																<Space className='flex flex-col items-start'>
																	<div className='text-sm font-medium capitalize'>{account?.accountTitle ? account?.accountTitle : 'N/A'}</div>
																	<div className='text-sm font-medium capitalize'>{account?.accountNumber ? account?.accountNumber : 'N/A'}</div>
																	<div className='flex flex-row'>
																		{account?.documents?.length ? (
																			account?.documents?.map((document, index, array) => (
																				<div className='flex flex-row'>
																					<Link to={document} target='_blank' className='text-primary-800'>
																						Document {index + 1}
																					</Link>
																					{index !== array?.length - 1 ? ',\u00A0' : ''}
																				</div>
																			))
																		) : (
																			<div className='text-sm font-medium capitalize'>N/A</div>
																		)}
																	</div>
																</Space>
															</div>
														</div>
													</Card>
												))}
												<Form.List
													name='accounts'
													validateTrigger={['onSubmit']}
													rules={[
														{
															validator: async (_, values) => {
																if ((!values || values.length < 1) && !brokerData?.accounts?.length) {
																	openNotification('Add relevant Bank Details')
																}
															},
														},
													]}
												>
													{(fields, { add, remove }) => (
														<div>
															{brokerData?.accounts?.length ? <Divider /> : null}
															{fields.map(({ key, name, fieldKey, ...restField }, index) => (
																<div key={key}>
																	<CarrierInfo props={addNameToProperty('bankAndAccountNumber', [name, 'bankName'], [name, 'accountNumber'])} />
																	<div className='mt-[10px]'>
																		<CarrierInfo props={addNameToProperty('accountTitle', [name, 'accountTitle'])} />
																	</div>
																	<div className='flex basis-1/3 ml-[44px] bg-white mt-[20px] '>
																		{FormRender({
																			type: 'profile-picture-upload',
																			key: 'profilePicture',
																			name: [name, 'documents'],
																			collection: 'broker',
																			uploadLink: `${Date.now() + Math.random() * 100}-account-proof-${index}`,
																		})}
																	</div>
																	<div className='flex flex-row justify-center mr-[44px]'>
																		<Button
																			type='link'
																			icon={<Icon path={mdiDeleteOutline} size={0.75} className='text-[#BE2525]' />}
																			onClick={() => {
																				remove(name)
																			}}
																		/>
																	</div>
																	{FormRender({
																		type: 'divider',
																	})}
																	{index === fields.length - 1 && (
																		<Button
																			className='flex flex-row items-center ml-[44px] justify-center w-[635px] h-[50px] bg-white rounded-lg border-2 border-dashed border-sky-600'
																			type='dashed'
																			onClick={() => {
																				add()
																			}}
																		>
																			Add Another Account
																		</Button>
																	)}
																</div>
															))}
															{fields.length === 0 && (
																<Button
																	className='flex flex-row items-center ml-[44px] justify-center w-[635px] h-[50px] bg-white rounded-lg border-2 border-dashed border-sky-600'
																	type='dashed'
																	onClick={() => {
																		add()
																	}}
																>
																	{brokerData?.accounts?.length ? 'Add Another Account' : 'Add Account +'}
																</Button>
															)}
														</div>
													)}
												</Form.List>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Form>
				</Content>
			</Layout>
		</>
	)
}

// component export
export default CarrierManagementPage
