export const Pill = ({ bgClassName, textClassName, label, value }) => {
	const bgClass = bgClassName || 'bg-black-50'
	const textClass = textClassName || 'text-black-300'

	return (
		<div className={'flex flex-row text-xs my-1 rounded-[50px] w-fit py-1 px-2 ' + bgClass}>
			<div className={'font-[600] ' + textClass}>{`${label}: ${value}`}</div>
		</div>
	)
}

export const HeaderPill = ({ bgClassName, textClassName, borderColorClass, label }) => {
	const bgClass = bgClassName || 'bg-black-50'
	const textClass = textClassName || 'text-black-300'
	const borderClass = borderColorClass || 'border-black-200'

	return (
		<div className={borderClass + ' h-[40px] flex flex-row text-sm my-1 rounded-[50px] w-fit py-1 px-3 align-middle items-center ' + bgClass}>
			<div className={'font-[500] align-middle my-auto ' + textClass}>{`${label}`}</div>
		</div>
	)
}
