// react
import { Layout } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom'

import SearchHeader from '../layout/SearchHeader'
import DynamicTable from '../utility/DynamicTable'

import { useFetch, useMutate } from '../../hooks/graphql'
import { useRecoilValue } from 'recoil'
import { assignmentDataSelector, checkBulkSelector, filteredJobsSelector, jobByIDSelector, numFilteredJobsSelector } from '../../recoil/selectors'
import useFiltrationData from '../../hooks/useFiltrationData'

import { assignmentColumns } from '../../helpers/TableColumns'
import Icon from '@mdi/react'

import { mdiCheckDecagram, mdiChevronUp, mdiMapMarkerOutline } from '@mdi/js'

import moment from 'moment'
import { filtersAtom } from '../../recoil/atoms'
import ExpandableJobDrawer from '../drawers/ExpandableJobDrawer'
import FilteredRowsHeader from '../utility/FilteredRowsHeader'
import { DoStatuses } from '../../helpers/Enums'
import Pagination from '../utility/Pagination'
import { PageSizes } from '../../helpers/Enums'

const { Content, Footer } = Layout

const AssignmentTable = () => {
	const { segment, id, jobId } = useParams()
	const navigator = useNavigate()
	const { tab } = useOutletContext()
	const { pathname } = useLocation()
	const [pageInfo, setPageInfo] = useState({ pageNo: 1, pageSize: 10 })
	const [backendPageInfo, setBackendPageInfo] = useState({ pageNo: 1, pageSize: PageSizes[pageInfo.pageSize].backend })
	const [selectedRows, setSelectedRows] = useState([])

	const currLocation = pathname.split('/')?.[2]
	const editJob = pathname.split('/')?.reverse()?.[0] === 'edit'
	const variables = { type: segment, tab: tab, status: tab === 'lot' && segment === 'longHaul' ? ['Order Pending', 'Order Confirmed'] : ['Job Init'] }

	const subscriptionVariables = {
		type: segment,
		tab: tab,
		status:
			tab === 'lot' && segment === 'longHaul'
				? ['Order Pending', 'Order Confirmed', 'Order Ongoing', 'Order Cancelled']
				: ['Job Init', 'Job Ongoing', 'Job Cancelled'],
	}

	const [createStatus, statusLoading] = useMutate({ type: 'Status', action: 'create' })
	const [animation, setAnimation] = useState(false)

	useEffect(() => {
		setBackendPageInfo({ pageNo: 1, pageSize: PageSizes[pageInfo.pageSize].backend })
	}, [pageInfo.pageSize])

	useEffect(() => {
		setSelectedRows([])
	}, [segment, tab])
	// const loading = false
	// const filterDataLoading = false
	const loading = useFetch({
		type: 'Jobs',
		queryKey: tab === 'lot' && segment === 'longHaul' ? 'lotOrder' : 'default',
		atomKey: segment,
		variables: variables,
		subscriptionVariables: subscriptionVariables,
		pageInfo: { pageNo: backendPageInfo?.pageNo, pageSize: backendPageInfo?.pageSize },
	})

	const filterDataLoading = useFiltrationData()
	// useFetch({ type: 'Jobs', queryKey: 'count', atomKey: 'count' })

	const [collectionData, totalCount] = useRecoilValue(
		filteredJobsSelector({ segment: segment, tab: tab, pageNo: pageInfo.pageNo, pageSize: pageInfo.pageSize })
	)

	const enableBulk = useRecoilValue(checkBulkSelector({ selectedRows }))
	const enableSplit = useMemo(() => selectedRows?.length === 1, [selectedRows])
	const { total, filtered } = useRecoilValue(numFilteredJobsSelector({ segment, tab, type: 'assignment' }))
	const ExpandableJobColumn = useCallback(
		(record) => {
			const { freeDays, demurrageFreeDays, readyToMove, nocExpiry, assignedBy, freeDaysDate, loadingDate, shippingLine } = record
			const assignedAt = assignedBy?.assignedAt && moment(assignedBy?.assignedAt)?.format('DD MMM YYYY - LT')
			const jobInfos = []
			if (segment === 'import') {
				jobInfos.push({
					value: () =>
						DoStatuses?.[record?.doStatus]?.icon && (
							<div className={`flex flex-row rounded-[40px] px-2 py-1 my-auto ml-2 ${DoStatuses?.[record?.doStatus]?.background}`}>
								{<Icon path={DoStatuses?.[record?.doStatus]?.icon} size={0.6} color='#ffffff' />}
								<div className='text-[12px] font-[600] text-white pl-2 overflow-ellipsis'>{DoStatuses?.[record?.doStatus]?.text}</div>
							</div>
						),
				})
				tab === 'local' &&
					record?.movementType === 'Import' &&
					record?.subMovementType === 'ImportShortHaul' &&
					jobInfos.push({
						value: () => (
							<div className={`flex flex-row items-center rounded pl-2 pr-4 py-1 my-auto bg-primary-800`}>
								<Icon path={mdiMapMarkerOutline} size={0.7} className='text-white' />
								<div className='text-[12px] font-[600] text-white pl-1 overflow-ellipsis'>Local</div>
							</div>
						),
					})
			}
			readyToMove && jobInfos.push({ icon: mdiCheckDecagram, iconColor: '#4CAF50', value: 'Ready To Move' })
			if (segment === 'import') {
				jobInfos.push({
					heading: 'Empty Free Date',
					value: freeDaysDate ? `${moment(freeDaysDate).utc().format('DD MMM YYYY')} (${freeDays} Days)` : 'N/A',
					colorClass: 'text-red',
				})

				jobInfos.push({
					heading: 'Demurrage Free Days',
					value: demurrageFreeDays || 'N/A',
					colorClass: 'text-red',
				})

				jobInfos.push({
					heading: 'NOC Expiry',
					value: nocExpiry ? moment.unix(parseInt(nocExpiry) / 1000).format('DD MMM YYYY') : 'N/A',
					colorClass: 'text-black-300',
				})
			} else if (segment === 'export') {
				jobInfos.push({
					heading: 'Shipping Line',
					value: shippingLine || 'N/A',
					colorClass: 'text-black-300',
				})
				jobInfos.push({
					heading: 'Loading Date',
					value: loadingDate ? `${moment(loadingDate).format('DD MMM YYYY')}` : 'N/A',
					colorClass: 'text-black-300',
				})
				// jobInfos.push({
				// 	heading: 'Vessel Cutoff Date',
				// 	value: nocExpiry ? moment.unix(parseInt(nocExpiry) / 1000).format('DD MMM yyyy') : 'N/A',
				// 	colorClass: 'text-black-300',
				// })
			} else if (segment === 'longHaul') {
				jobInfos.push({
					heading: 'Loading Date',
					value: loadingDate ? `${moment(loadingDate).format('DD MMM YYYY')}` : 'N/A',
					colorClass: 'text-black-300',
				})
				// jobInfos.push({
				// 	heading: 'Vessel Cutoff Date',
				// 	value: nocExpiry ? moment.unix(parseInt(nocExpiry) / 1000).format('DD MMM yyyy') : 'N/A',
				// 	colorClass: 'text-black-300',
				// })
			}

			assignedBy?.name &&
				jobInfos.push({
					heading: 'Last Updated By',
					value: () =>
						assignedBy?.name ? (
							<div className='flex flex-row my-auto text-xs pl-2'>
								<div className='font-[700] text-black-300'>{assignedBy?.name}</div>
								<div className='pl-1 font-[400] text-black-300'>{`${' - ' + assignedAt}`}</div>
							</div>
						) : (
							<div className='font-[600] text-black-300 items-center text-sm my-auto pl-2'>N/A</div>
						),
				})
			return (
				jobInfos.length > 0 && (
					<div className={`${selectedRows?.includes(record?._id) ? 'bg-[#e6f7ff]' : 'bg-white'} w-full pl-[50px] pr-[21px] pb-[17px]`}>
						<div
							className={`${selectedRows?.includes(record?._id) ? 'bg-white' : 'bg-[#f5f5f5]'} rounded-[40px] py-2 pl-1 pr-3 flex flex-row ${
								segment === 'import' ? ' pl-1' : ' pl-3'
							}`}
							itemlayout='horizontal'
						>
							{jobInfos.map((info, index) => (
								<div className='flex flex-row items-center' key={'jobInfo_' + index}>
									{info.icon && <Icon className='mr-2' path={info.icon} size={0.8} color={info.iconColor} />}
									{info.heading && <div className={`${jobInfos.length > 5 ? 'text-[13px]' : 'text-sm'} font-[400] text-black-300 my-auto`}>{`${info.heading}`}</div>}
									{info.value && typeof info.value === 'function' ? (
										info.value()
									) : (
										<div
											className={`${jobInfos.length > 5 ? 'text-[13px]' : 'text-sm'} font-[600] my-auto ${info.colorClass ? info.colorClass : 'text-black-400'} pl-[3px]`}
										>
											{info.value}
										</div>
									)}
									{index < jobInfos?.length - 1 && <div className='px-2 my-auto'>|</div>}
								</div>
							))}
						</div>
					</div>
				)
			)
		},
		[selectedRows, tab]
	)
	return (
		<>
			<SearchHeader selectedRows={selectedRows} tab={tab} pageNo={backendPageInfo?.pageNo} pageSize={backendPageInfo?.pageSize} />
			<Layout className='bg-white rounded-lg overflow-y-hidden shadow-[0_1px_8px_rgba(0,0,0,0.16)]'>
				<FilteredRowsHeader
					selectedRows={selectedRows}
					total={total}
					filtered={filtered}
					currLocation={currLocation}
					tab={tab}
					enableBulk={enableBulk}
					enableSplit={enableSplit}
					navigator={navigator}
					name={tab === 'lot' && 'Orders'}
				/>

				<Content className='mb-14'>
					<DynamicTable
						// scroll={{ y: tableContainer.current ? tableContainer.current?.clientHeight - 120 : 700 }}
						data={collectionData?.map((order) => {
							return { ...order, createStatus, statusLoading, animation, setAnimation }
						})}
						autoResetSelection={true}
						className='flex-grow'
						loading={loading}
						rowKey={(record) => record?._id}
						rowHeight={150}
						columns={assignmentColumns}
						columnProps={{ segment, tab, navigator }}
						rowClassName={`${tab !== 'lot' && ' expanded-row'} hover:cursor-pointer `}
						expandedRowKeys={tab !== 'lot' && collectionData?.map((order) => order?._id)}
						selectedRowKeys={selectedRows}
						expandable={
							['import', 'export', 'longHaul'].includes(segment) && tab !== 'lot'
								? {
										expandIconColumnIndex: 7,
										expandedRowRender: ExpandableJobColumn,
										defaultExpandAllRows: true,
										expandIcon: () => <></>,
								  }
								: tab === 'lot'
								? {
										expandedRowRender: ExpandableJobDrawer,
										expandIcon: ({ expanded, onExpand, record }) =>
											expanded ? (
												<Icon path={mdiChevronUp} size={1.3} className='text-primary-800 ml-4 transition-all rotate-180' onClick={(e) => onExpand(record, e)} />
											) : (
												<Icon path={mdiChevronUp} size={1.3} className='text-primary-800 ml-4 transition-all rotate-90' onClick={(e) => onExpand(record, e)} />
											),
								  }
								: undefined
						}
						onRow={
							tab !== 'lot'
								? (record) => ({
										onClick: (e) => {
											navigator(`/${segment}/assignment/job/${record?._id}`)
										},
								  })
								: undefined
						}
						onSelect={
							tab !== 'lot'
								? (selectedRowKeys) => {
										setSelectedRows(selectedRowKeys)
								  }
								: undefined
						}
					/>
				</Content>
				<Footer className='flex justify-center items-center !bg-white !h-14 !p-0 !border-t-[0.5px] !border-[rgba(0, 0, 0, 0.12)]'>
					<Pagination setPageInfo={setPageInfo} setBackendPageInfo={setBackendPageInfo} totalCount={totalCount} pageInfo={pageInfo} />
				</Footer>
			</Layout>

			<Outlet
				context={{
					loading: loading,
					modalType:
						pathname.split('/')?.reverse()?.[0] === 'bulk-assign'
							? 'bulkAssignment'
							: pathname.split('/')?.reverse()?.[1] === 'split-job'
							? 'splitJob'
							: tab === 'lot' && pathname.split('/')?.reverse()?.[0] === 'finish-assignment'
							? 'finishLotAssignment'
							: tab === 'lot'
							? 'lotAssignment'
							: 'jobDetails',
					props: {
						edit: editJob,
						tab: tab,
						jobId: jobId,
						create: !jobId,
						data: {
							jobs: useRecoilValue(
								jobByIDSelector({
									segment,
									ids: pathname.split('/')?.reverse()?.[0] === 'bulk-assign' ? selectedRows : pathname.split('/')?.reverse()?.[1] === 'split-job' ? [jobId] : [id],
									tab,
								})
							),
							filterData: useRecoilValue(filtersAtom),
							formData: useRecoilValue(assignmentDataSelector()),
						},
					},
				}}
			/>
		</>
	)
}

export default AssignmentTable
