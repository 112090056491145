// react
import { Layout } from 'antd'
import React from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import SearchHeader from '../components/layout/SearchHeader'
import DynamicTable from '../components/utility/DynamicTable'
import { biltyInvestorManagementColumns, brokerManagementColumns, supplierManagementColumns } from '../helpers/TableColumns'
import { useFetch } from '../hooks/graphql'

import { useRecoilValue } from 'recoil'
import {
	biltyInvestorManagementByIDSelector,
	biltyInvestorManagementFilterDataSelector,
	brokerManagementByIDSelector,
	brokerManagementFilterDataSelector,
	filteredBiltyInvestorManagementSelector,
	filteredBrokerManagementSelector,
	filteredSupplierManagementSelector,
	numFilteredJobsSelector,
	supplierManagementByIDSelector,
	supplierManagementFilterDataSelector,
} from '../recoil/selectors'
import FilteredRowsHeader from '../components/utility/FilteredRowsHeader'

const { Content, Header } = Layout

const VendorManagementPage = (props) => {
	const { segment, action, brokerId } = useParams()
	const navigator = useNavigate()
	const { pathname } = useLocation()
	const currLocation = pathname.split('/')?.[2]
	const userEmail = JSON.parse(localStorage.getItem('user'))?.[0]?.email

	const loading = useFetch({
		type: currLocation === 'brokers' ? 'Brokers' : currLocation === 'bilty-investors' ? 'BiltyInvestors' : 'Suppliers',
		atomKey: currLocation === 'brokers' ? 'broker' : currLocation === 'bilty-investors' ? 'biltyInvestor' : 'supplier',
		fetchPolicy: 'network-only',
	})
	const laneloading = useFetch({
		type: 'Lanes',
		atomKey: 'lane',
	})
	const data = useRecoilValue(
		currLocation === 'brokers'
			? filteredBrokerManagementSelector()
			: currLocation === 'bilty-investors'
			? filteredBiltyInvestorManagementSelector()
			: filteredSupplierManagementSelector()
	)
	const { total, filtered } = useRecoilValue(numFilteredJobsSelector({ segment, type: currLocation }))
	return (
		<>
			<SearchHeader />

			<Layout className='bg-white rounded-lg pb-11  overflow-y-hidden shadow-[0_1px_8px_rgba(0,0,0,0.16)]'>
				<FilteredRowsHeader
					total={total}
					filtered={filtered}
					currLocation={currLocation}
					name={currLocation === 'brokers' ? 'Vendors' : currLocation === 'bilty-investors' ? 'Bilty Investors' : 'Suppliers'}
					navigator={navigator}
				/>
				<Content>
					<DynamicTable
						className='rounded-table'
						columns={
							currLocation === 'brokers' ? brokerManagementColumns : currLocation === 'bilty-investors' ? biltyInvestorManagementColumns : supplierManagementColumns
						}
						columnProps={{ navigator, userEmail }}
						data={data}
						loading={loading}
					/>
				</Content>
			</Layout>
			<Outlet
				context={{
					loading: loading,
					modalType: action === 'edit' || action === 'new' ? 'vendorDetails' : 'deleteBroker',
					props: {
						edit: action === 'edit',
						type: currLocation,
						data: {
							vendor: useRecoilValue(
								currLocation === 'brokers'
									? brokerManagementByIDSelector({ brokerId })
									: currLocation === 'bilty-investors'
									? biltyInvestorManagementByIDSelector({ brokerId })
									: supplierManagementByIDSelector({ brokerId })
							),
							formData: useRecoilValue(
								currLocation === 'brokers'
									? brokerManagementFilterDataSelector()
									: currLocation === 'bilty-investors'
									? biltyInvestorManagementFilterDataSelector()
									: supplierManagementFilterDataSelector()
							),
						},
					},
				}}
			/>
		</>
	)
}

export default VendorManagementPage
