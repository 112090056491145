import { gql } from '@apollo/client'

export const LOCATION_FIELDS = gql`
	fragment LocationFields on Location {
		_id
		formattedAddress
		additionalDetails
		coordinates {
			lat
			lng
		}
		Region {
			ParentRegion {
				name
				_id
			}
			name
			_id
		}
		type
		Region {
			_id
			name
		}
	}
`
export const CONTACT_FIELDS = gql`
	fragment ContactFields on Contacts {
		name
		phoneNumber
	}
`
export const USER_FIELDS = gql`
	fragment UserFields on User {
		_id
		firstName
		lastName
		phoneNumber
		email
		cnic
		cnicFront
		cnicBack
	}
`
export const SUPPLIER_FIELDS = gql`
	${USER_FIELDS}
	fragment SupplierFields on Supplier {
		_id
		isBlocked
		code
		isSuspended
		User {
			...UserFields
		}
		type
	}
`

export const BILTY_INVESTOR_FIELDS = gql`
	fragment BiltyInvestorFields on BiltyInvestor {
		_id
		name
		phoneNumber
		cnic
		accounts {
			_id
			bankName
			accountTitle
			accountNumber
			type
		}
	}
`

export const BROKER_FIELDS = gql`
	${USER_FIELDS}
	fragment BrokerFields on Broker {
		_id
		brokerCode
		User {
			...UserFields
		}
		POCs {
			_id
			cnicBack
			cnicFront
			name
			phoneNumber
			secondaryPhoneNumbers
			cnic
		}
		accounts {
			_id
			documents
			accountNumber
			accountTitle
			bankName
			NTN
			deactivated
		}
		CompanyName
		Lanes {
			_id
			from {
				_id
				name
			}
			to {
				_id
				name
			}
		}
		totalVehiclesOwned
		vendorType
		NTN
		officeAddress
	}
`
export const DO_STATUS_FIELDS = gql`
	fragment DoStatusFields on DoStatus {
		_id
		status
		comment
		documents {
			payOrder
			underTakings
			blStamped
			loloReceipt
			goodsDeclaration
		}
	}
`

export const QUOTATION_FIELDS = gql`
	${BROKER_FIELDS}
	${SUPPLIER_FIELDS}
	${USER_FIELDS}
	fragment QuotationFields on Quotation {
		_id
		cost
		driver
		driverAssignedAt
		DriverAssignedBy {
			...UserFields
		}
		approved
		slipped
		bidWon
		factorRequest
		factorStatus
		factoredAmount
		committedDays
		committedWeight
		#factorBase
		Broker {
			...BrokerFields
		}
		Supplier {
			...SupplierFields
		}
		paymentTerms {
			_id
			amount
			days
			index
			journeyPoint
			paymentType
		}
	}
`

export const VENDOR_PAYMENT_FIELDS = gql`
	fragment VendorPaymentFields on vendorPayments {
		_id
		paymentStatus
		costBreakdown {
			_id
			amanat
			kantaCharges
			loloCharges
			offLoadingCharges
			placementCharges
			repairingCharges
			rotationalCharges
			beneficiary
			cost
			deductions
			detentions
			dropoff
			extraWeight
			inaam
			advancePayment
			dehariAtTerminal
			dehariDueToOrderCancellation
			containerRejectionCharges
			pickupChangeCharges
			dehariAtLoading
			dehariAtUnloadingPort
			dehariAtUnloadingMill
			labourCharges
			munshianaPaid
			munshianaReceived
			otherCharges
			supplyComments
		}
	}
`
export const SHIPMENT_INFO_FIELDS = gql`
	fragment ShipmentInfoFields on ShipmentInfo {
		freightCount
		freightType
		freightWeight
		estimatedWeight
		estimatedCount
	}
`
export const IMPORT_EXPORT_PREFERENCES_FIELDS = gql`
	fragment ImportExportPreferencesFields on SpecialRequest {
		movementType
		doIssuance
		numberFlatbeds
		otherRequests
		fastLane
		refNumber
	}
`
export const LONGHAUL_PREFERENCES_FIELDS = gql`
	fragment LongHaulPreferencesFields on LongHaulSpecialRequest {
		lotOrder
		numberVehicles
		otherRequests
		refNumber
	}
`
export const LANE_RATE_FIELDS = gql`
	fragment LaneRateFields on LaneRate {
		_id
		Rate
		WeightRange
		createdAt
	}
`
export const LANE_FIELDS = gql`
	fragment LaneFields on Lane {
		_id
		from {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
		to {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
	}
`

export const COMMENT_FIELDS = gql`
	${USER_FIELDS}
	fragment CommentFields on Comment {
		_id
		Author {
			...UserFields
		}
		comment
		createdAt
	}
`

export const VEHICLE_FIELDS = gql`
	fragment VehicleFields on Vehicle {
		_id
		vehicleType
		vehicleSubType
		BrokerId
		SupplierId
		registrationNumber
		isBlocked
	}
`
export const DRIVER_FIELDS = gql`
	fragment DriverFields on Driver {
		driverType
		name
		phoneNumber
		User {
			firstName
			lastName
			phoneNumber
		}
		name
		phoneNumber
	}
`
export const BILTY_FIELDS = gql`
	fragment BiltyFields on Bilty {
		_id
		biltyNumber
		biltyCreatedAt
		documentNumber
		loloDocument
	}
`
export const JOURNEY_FIELDS = gql`
	fragment JourneyFields on Journey {
		_id
	}
`
export const CORPORATE_FIELDS = gql`
	fragment CorporateFields on Corporate {
		_id
		businessCode
		businessName
	}
`

export const CLIENT_FIELDS = gql`
	${USER_FIELDS}
	fragment ClientFields on Client {
		_id
		User {
			...UserFields
		}
	}
`
export const ORDER_LOCATION_FIELDS = gql`
	${LOCATION_FIELDS}
	${CONTACT_FIELDS}
	fragment OrderLocationFields on OrderLocation {
		_id
		locationType
		Location {
			...LocationFields
		}
		contacts {
			...ContactFields
		}
	}
`
export const CORPORATE_LOCATION_FIELDS = gql`
	${LOCATION_FIELDS}
	${CONTACT_FIELDS}
	fragment CorporateLocationFields on CorporateLocation {
		_id
		Location {
			...LocationFields
		}
		contacts {
			...ContactFields
		}
	}
`

export const CHARGE_FIELDS = gql`
	fragment ChargeFields on Charge {
		_id
		amount
		status
		comment
		isUrgent
		statusHistory {
			createdAt
			status
			_id
		}
		chargeType {
			_id
			name
		}
		customFieldValues {
			_id
			propertyKey
			value
		}
		statusHistory {
			_id
			status
			createdAt
		}
		invoiceProof {
			_id
			name
			documents
		}
		paymentInvoice {
			_id
			toAccount {
				_id
				accountNumber
				accountTitle
				bankName
				type
				accountOwner {
					... on ThirdParty {
						_id
					}
					... on Broker {
						_id
					}
					... on BiltyInvestor {
						_id
					}
				}
			}
		}
	}
`

export const REVENUE_FIELDS = gql`
	fragment RevenueFields on Revenue {
		_id
		amount
		status
		comment
		statusHistory {
			_id
			status
			createdAt
		}
		revenueType {
			_id
			name
		}
		invoiceProof {
			_id
			name
			documents
		}
		paymentInvoice {
			_id
			toAccount {
				_id
				accountNumber
				accountTitle
				bankName
				type
				accountOwner {
					... on ThirdParty {
						_id
					}
					... on Broker {
						_id
					}
				}
			}
		}
	}
`

export const JOB_FIELDS = gql`
	${VEHICLE_FIELDS}
	${DRIVER_FIELDS}
	${SUPPLIER_FIELDS}
	${BROKER_FIELDS}
	${BILTY_FIELDS}
	${LANE_FIELDS}
	${JOURNEY_FIELDS}
	${QUOTATION_FIELDS}
	${VENDOR_PAYMENT_FIELDS}
	${CHARGE_FIELDS}
	${REVENUE_FIELDS}
	fragment JobFields on Job {
		jobNumber
		_id
		status
		cost
		OrderDetails
		driverMatchedAt
		driverAssignedAt
		factorRequest
		factorStatus
		factoredAmount
		committedDays
		committedWeight
		vehicleType
		extraWeightChargeReason
		localVehicleType
		AssignedBy {
			_id
			firstName
			lastName
		}
		vendorPayments {
			...VendorPaymentFields
		}
		Vehicle {
			...VehicleFields
		}
		Driver {
			...DriverFields
		}
		Supplier {
			...SupplierFields
		}
		Broker {
			...BrokerFields
		}
		bilty {
			...BiltyFields
		}
		Journeys {
			...JourneyFields
		}
		Lane {
			...LaneFields
		}
		SuperLane {
			...LaneFields
		}
		Quotations {
			...QuotationFields
		}
		charges {
			...ChargeFields
		}
		revenues {
			...RevenueFields
		}
		paymentTerms {
			_id
			amount
			days
			index
			journeyPoint
			paymentType
		}
	}
`

export const SUB_IMPORT_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	${DO_STATUS_FIELDS}
	fragment SubImportFields on ChildImport {
		_id
		loadingDT
		subOrderNumber
		status
		freeDays
		containerNumber
		dimension
		freightType
		freightWeight
		DoStatuses {
			...DoStatusFields
		}
		OrderLocations {
			...OrderLocationFields
		}
	}
`
export const SUB_EXPORT_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	fragment SubExportFields on ChildExport {
		_id
		finalizedRate
		loadingDT
		subOrderNumber
		status
		containerNumber
		dimension
		freightType
		freightWeight
		OrderLocations {
			...OrderLocationFields
		}
	}
`
export const SUB_LONGHAUL_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	${SHIPMENT_INFO_FIELDS}
	fragment SubLongHaulFields on ChildLongHaul {
		_id
		loadingDT
		subOrderNumber
		status
		vehicleType
		vehicleSubType
		OrderLocations {
			...OrderLocationFields
		}
		shipmentInfo {
			...ShipmentInfoFields
		}
	}
`
export const MOVEMENT_FIELDS = gql`
	${JOB_FIELDS}
	${SUB_EXPORT_FIELDS}
	${SUB_IMPORT_FIELDS}
	${SUB_LONGHAUL_FIELDS}
	fragment MovementFields on Movement {
		ChildOrder {
			SubImport {
				...SubImportFields
			}
			SubExport {
				...SubExportFields
			}
			SubLongHaul {
				...SubLongHaulFields
			}
		}
		totalCount
		movedUnits
		movementType
		subMovementType
		status
		Job {
			...JobFields
		}
	}
`
export const PARENT_IMPORT_FIELDS = gql`
	${CONTACT_FIELDS}
	${SUB_IMPORT_FIELDS}
	${DO_STATUS_FIELDS}
	${IMPORT_EXPORT_PREFERENCES_FIELDS}
	${COMMENT_FIELDS}
	fragment ParentImportFields on ParentImport {
		_id
		orderNumber
		status
		document
		documentNumber
		shippingLine
		clearingAgents {
			name
			phoneNumber
		}
		doInfo {
			nocExpiry
		}
		SubImports {
			...SubImportFields
		}
		DoStatuses {
			...DoStatusFields
		}
		Comments {
			...CommentFields
		}
		preferences {
			...ImportExportPreferencesFields
		}
	}
`
export const PARENT_EXPORT_FIELDS = gql`
	${CONTACT_FIELDS}
	${SUB_EXPORT_FIELDS}
	${IMPORT_EXPORT_PREFERENCES_FIELDS}
	${COMMENT_FIELDS}
	fragment ParentExportFields on ParentExport {
		_id
		document
		documentNumber
		shippingLine
		orderNumber
		status
		vesselCutoffDT
		clearingAgents {
			name
			phoneNumber
		}
		Comments {
			...CommentFields
		}
		SubExports {
			...SubExportFields
		}
		preferences {
			...ImportExportPreferencesFields
		}
	}
`
export const PARENT_LONGHAUL_FIELDS = gql`
	${SUB_LONGHAUL_FIELDS}
	${LONGHAUL_PREFERENCES_FIELDS}
	${SHIPMENT_INFO_FIELDS}
	${COMMENT_FIELDS}
	fragment ParentLongHaulFields on ParentLongHaul {
		_id
		orderNumber
		status
		expectedMovementDeadline
		SubLongHauls {
			...SubLongHaulFields
		}
		Comments {
			...CommentFields
		}
		preferences {
			...LongHaulPreferencesFields
		}
		shipmentInfo {
			...ShipmentInfoFields
		}
	}
`

export const ORDER_FIELDS = gql`
	${PARENT_EXPORT_FIELDS}
	${PARENT_IMPORT_FIELDS}
	${PARENT_LONGHAUL_FIELDS}
	${MOVEMENT_FIELDS}
	${CLIENT_FIELDS}
	${CORPORATE_FIELDS}
	fragment OrderFields on Order {
		_id
		type
		businessCode
		clientEmails
		Client {
			...ClientFields
		}
		Corporate {
			...CorporateFields
		}
		ParentOrder {
			ParentLongHaul {
				...ParentLongHaulFields
			}
			ParentImport {
				...ParentImportFields
			}
			ParentExport {
				...ParentExportFields
			}
			Movements(where: $movementsWhere2) {
				...MovementFields
			}
		}
	}
`

export const SUBSCRIPTION_ORDER_FIELDS = gql`
	${PARENT_EXPORT_FIELDS}
	${PARENT_IMPORT_FIELDS}
	${PARENT_LONGHAUL_FIELDS}
	${MOVEMENT_FIELDS}
	${CLIENT_FIELDS}
	${CORPORATE_FIELDS}
	fragment SubOrderFields on Order {
		_id
		type
		businessCode
		clientEmails
		Client {
			...ClientFields
		}
		Corporate {
			...CorporateFields
		}
		ParentOrder {
			ParentLongHaul {
				...ParentLongHaulFields
			}
			ParentImport {
				...ParentImportFields
			}
			ParentExport {
				...ParentExportFields
			}
			Movements {
				...MovementFields
			}
		}
	}
`
