import { atom } from 'recoil'

export const userAtom = atom({ key: 'userAtom', default: undefined })
export const superUserAtom = atom({
	key: 'superUserAtom',
	default: ['szaidi@bridgelinxpk.com', 'ksaleem@bridgelinxpk.com', 'asajid@bridgelinxpk.com'],
})
export const supplyAdminAtom = atom({
	key: 'supplyAdminAtom',
	default: ['zshah@bridgelinxpk.com', 'szaidi@bridgelinxpk.com', 'ksaleem@bridgelinxpk.com', 'asajid@bridgelinxpk.com'],
})

// ['szaidi@bridgelinxpk.com']
export const importOrdersAtom = atom({ key: 'importOrdersAtom', default: [] })
export const exportOrdersAtom = atom({ key: 'exportOrdersAtom', default: [] })
export const longHaulOrdersAtom = atom({ key: 'longHaulOrdersAtom', default: [] })

export const importManagementAtom = atom({ key: 'importManagementAtom', default: [] })
export const exportManagementAtom = atom({ key: 'exportManagementAtom', default: [] })
export const longHaulManagementAtom = atom({ key: 'longHaulManagementAtom', default: [] })

export const importAtom = atom({ key: 'importAtom', default: [] })
export const exportAtom = atom({ key: 'exportAtom', default: [] })
export const longHaulAtom = atom({ key: 'longHaulAtom', default: [] })

export const suppliersAtom = atom({ key: 'suppliersAtom', default: [] })
export const brokersAtom = atom({ key: 'brokersAtom', default: [] })
export const biltyInvestorsAtom = atom({ key: 'biltyInvestorsAtom', default: [] })

export const emptyContainerAtom = atom({ key: 'emptyContainerAtom', default: [] })
export const lanesAtom = atom({ key: 'lanesAtom', default: [] })
export const corporatesAtom = atom({ key: 'corporatesAtom', default: [] })
export const containerTerminalsAtom = atom({ key: 'containerTerminalsAtom', default: [] })

export const filtersAtom = atom({ key: 'filtersAtom', default: [] })
export const tokenAtom = atom({ key: 'tokenAtom', default: {} })
export const jobCountAtom = atom({ key: 'jobCountAtom', default: {} })

export const chargeTypesAtom = atom({ key: 'chargeTypesAtom', default: [] })
export const revenueTypesAtom = atom({ key: 'revenueTypesAtom', default: [] })

export const thirdPartiesAtom = atom({ key: 'thirdPartiesAtom', default: [] })
