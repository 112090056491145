import { Form } from 'antd'

import { useEffect, useCallback, useMemo } from 'react'

import { Capitalize, initFilterState } from '../helpers/Functions'
import { FilterEnums, FilterTypeEnums, PageFilterEnums } from '../helpers/Enums'
import { useRecoilValue } from 'recoil'
import { filtersAtom } from '../recoil/atoms'
import { useRecoilStateCallback } from '../hooks/useRecoilStateCallback'

import {
	assignmentFiltersDataSelector,
	brokerManagementFilterDataSelector,
	managementViewFiltersDataSelector,
	postAssignmentFiltersDataSelector,
} from '../recoil/selectors'

import { debounce } from '../helpers/Functions'
import { useLocation, useNavigate } from 'react-router-dom'
const getFilterStateFromURL = (url, filters) => {
	const urlValues = url?.split('&')?.map((filter) => {
		const [property, val] = filter.split('=')
		const values = val.split('+')
		return {
			property: property,
			values:
				FilterTypeEnums[filters.find((filter) => filter.propertyKey === property)?.type]?.getValuesFromArray?.(
					values,
					filters.find((filter) => filter.propertyKey === property)?.filterProps
				) || {},
		}
	})
	const filterState = initFilterState(filters)
	urlValues?.forEach((urlValue) => {
		filterState[urlValue.property] = { ...filterState[urlValue.property], values: urlValue.values }
	})
	return filterState
}

const createFilterString = (filterState) => {
	return filterState
		? Object.entries(filterState)?.reduce((stateString, filter, index) => {
				const [property, state] = filter
				const filterObj = FilterEnums[property]
				return FilterTypeEnums[state?.type]?.checkSelected(state?.values)
					? `${stateString}${index !== 0 && stateString !== '' ? '&' : ''}${property}=${
							FilterTypeEnums[state?.type]?.stringifyState(state?.values, filterObj?.filterProps) || ''
					  }`
					: stateString
		  }, '')
		: {}
}
const useFilterState = ({ urlFilterString, page, segment, tab, type, setExternalState, pageNo , pageSize  }) => {
	const [filterState, setFilterState] = useRecoilStateCallback(filtersAtom)
	const navigator = useNavigate()
	const { pathname } = useLocation()
	const isFiltered = useMemo(() => pathname?.split('/')?.[3] === 'filters', [pathname])
	const filterOptions = useRecoilValue(
		page === 'assignment'
			? assignmentFiltersDataSelector({ segment: type, tab, pageNo, pageSize })
			: page === 'management'
			? managementViewFiltersDataSelector({ segment, tab })
			: page === 'brokers'
			? brokerManagementFilterDataSelector()
			: postAssignmentFiltersDataSelector({ segment: type, tab, pageNo, pageSize })
	)
	const [filterFormController] = Form.useForm()

	const filters = useMemo(() => PageFilterEnums[page]?.[segment !== 'vendorManagement' ? Capitalize(segment) : 'default'], [page, tab, segment])
	useEffect(() => {
		if (urlFilterString) {
			try {
				const urlFilterState = getFilterStateFromURL(urlFilterString, filters)
				setFilterState(urlFilterState, () => filterFormController.setFieldsValue(filterState))
				if (setExternalState && urlFilterState) {
					filters?.forEach((filter) => {
						if (filter.linkedState) {
							setExternalState((prev) => ({ ...prev, [filter.linkedState]: urlFilterState[filter.propertyKey]?.values }))
						}
					})
				}
			} catch (e) {
				navigator(`${pathname.split('/').slice(0, 3)?.join('/')}`)
			}
		} else {
			setFilterState(initFilterState(filters), () => filterFormController.setFieldsValue(filterState))
		}
	}, [])
	const onFilterFormChange = useCallback(
		debounce((changed, values) => {
			if (Object.keys(changed)?.[0] === 'search') {
				filterDispatch({ type: 'search', payload: values.search })
			} else {
				const autoFilter = filters?.find((filter) => filter?.propertyKey === Object.keys(changed)?.[0])
				if (FilterTypeEnums[autoFilter?.type].trigger === 'auto') {
					filterDispatch({
						type: 'change',
						payload: {
							key: Object.keys(changed)?.[0],
							values: { ...values[Object.keys(changed)?.[0]], propertyKey: autoFilter?.propertyKey, type: autoFilter?.type },
						},
					})
				}
			}
		}, 500),
		[pathname, segment, tab]
	)

	const filterDispatch = useCallback(
		(event) => {
			switch (event.type) {
				case 'search':
					setFilterState((prevState) => ({ ...prevState, search: event.payload }))
					break
				case 'change':
					const values = event.payload.values.values

					const optionKeys = values && typeof values === 'object' ? Object.keys(values).filter((key) => values[key]) : []
					const currState =
						values && typeof values === 'object' ? filterOptions?.[`${event.payload.key}`]?.[optionKeys?.[0]] : filterOptions[event.payload.key]?.[values]
					if (event.payload.key) {
						setFilterState(
							(prevState) => ({ ...prevState, [`${event.payload.key}`]: { ...event.payload.values, prevState: currState } }),
							(newState) => {
								const newfilterString = createFilterString(newState)
								navigator(`${isFiltered ? `${pathname.split('/').slice(0, 4)?.join('/')}/` : 'filters/'}${newfilterString}`)
							}
						)
						const linkedState = filters.find((filter) => filter?.propertyKey === event.payload.key)?.linkedState
						if (linkedState && setExternalState) {
							setExternalState((prev) => ({ ...prev, [linkedState]: values }))
						}
					}

					break
				case 'reset':
					setFilterState(
						(prevState) => initFilterState(filters, prevState, event.payload.key),
						(newState) => {
							filterFormController.setFieldsValue(newState)
							const newfilterString = createFilterString(newState)
							if (event.payload.key) {
								navigator(`${isFiltered ? `${pathname.split('/').slice(0, 4)?.join('/')}/` : 'filters/'}${newfilterString}`)
								const linkedState = filters.find((filter) => filter?.propertyKey === event.payload.key)?.linkedState
								if (linkedState && setExternalState) {
									setExternalState((prev) => ({ ...prev, [linkedState]: newState[event.payload.key]?.values }))
								}
							} else {
								const linkedStates = {}
								filters.forEach((filter) => {
									if (filter.linkedState && setExternalState) {
										linkedStates[filter.linkedState] = newState[filter.propertyKey]?.values
									}
								})
								if (Object.keys(linkedStates).length > 0) {
									setExternalState((prev) => ({ ...prev, ...linkedStates }))
								}
								navigator(`${pathname.split('/').slice(0, 3)?.join('/')}`)
							}
						}
					)

					break
				case 'sync':
					if (event.payload.key) {
						filterFormController.setFieldValue(event.payload.key, filterState[event.payload.key])
					} else {
						filterFormController.setFieldsValue(filterState)
					}
					break
			}
		},
		[pathname, segment, tab]
	)
	return { filterState, filterOptions, filterFormController, onFilterFormChange, filterDispatch }
}

export default useFilterState
