// react
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

// components
import { Layout } from 'antd'
import Header from '../components/layout/Header'
import NavDrawer from '../components/drawers/NavigationDrawer'
import { NavEnums } from '../helpers/Enums'

import { ErrorBoundary } from 'react-error-boundary'

import TableErrorBoundary from '../components/errors/TableError'
import HeaderError from '../components/errors/HeaderError'

const { Content } = Layout

const HomePage = () => {
	const [collapseMenu, setCollapseMenu] = useState(false)
	const [tab, setTab] = useState('normal')
	const { pathname } = useLocation()
	const { segment } = useParams()
	const navigator = useNavigate()

	useEffect(() => {
		if (!Object.keys(NavEnums).includes(segment)) {
			navigator('../import', { replace: true })
		}
		if (!pathname?.includes('assignment') && !pathname?.includes('management') && !pathname?.includes('vendorManagement')) {
			navigator(NavEnums[segment]?.children ? Object.values(NavEnums[segment]?.children)?.[0]?.link : 'assignment')
		}
	}, [pathname, segment]) //eslint-disable-line

	return (
		<Layout className='h-screen'>
			<ErrorBoundary FallbackComponent={HeaderError} onError={(error, info) => console.log(error)} resetKeys={[segment, tab, pathname]}>
				<Header props={{ collapseMenu, setCollapseMenu, setTab, tab }}></Header>
			</ErrorBoundary>
			<NavDrawer props={{ collapseMenu, setCollapseMenu }} />
			<Content className='!bg-secondaryBackground h-50 flex p-6'>
				<Layout className='h-full w-full rounded-lg !bg-secondaryBackground'>
					<ErrorBoundary FallbackComponent={TableErrorBoundary} onError={(error, info) => console.log(error)} resetKeys={[segment, tab, pathname]}>
						<Outlet context={{ tab }} />
					</ErrorBoundary>
				</Layout>
			</Content>
		</Layout>
	)
}

export default HomePage
