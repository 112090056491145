// react
import { Drawer } from 'antd'
import React, { useState, useEffect } from 'react'

import BridgelinxIcon from '../../logo.png'

import { NavEnums } from '../../helpers/Enums'
import { useParams, useLocation } from 'react-router-dom'

import { Link } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'

const NavDrawer = (props) => {
	const { setCollapseMenu, collapseMenu } = props.props
	const { pathname } = useLocation()
	const [selectedTab, setSelectedTab] = useState('')
	const [collapseChildren, setCollapseChildren] = useState(Object.keys(NavEnums).reduce((obj, tab) => ({ ...obj, [tab]: false }), {}))
	const { segment } = useParams()

	useEffect(() => {
		setSelectedTab(pathname?.split('/')?.[2])
	}, [pathname])
	return (
		<Drawer
			width={300}
			placement='left'
			visible={collapseMenu}
			onClose={() => {
				setCollapseMenu(false)
			}}
			closable={false}
		>
			<div className='w-full flex flex-col p-0 '>
				<div className='my-6 mx-[30px]'>
					<img className=' h-auto w-1/2' src={BridgelinxIcon} alt='' />
					<div className='text-[#4B535B] text-[10px] font-[500]'>SUPPLY OPS</div>
				</div>

				<hr className='border-dotted border-t-2 border-black-75' />
				<div className='w-full flex flex-col'>
					{Object.values(NavEnums).map((tab, index) => (
						<div key={`nav-${tab.link}-${index}`}>
							{tab.children ? (
								<div className={`flex flex-col ${collapseChildren[tab.link] ? 'border-dotted border-b-2' : ''}`}>
									<div
										className='flex flex-row items-center justify-between w-full px-7 cursor-pointer'
										onClick={() => setCollapseChildren({ ...collapseChildren, [tab.link]: !collapseChildren[tab.link] })}
									>
										<div className='flex flex-row items-center'>
											{tab.icon && <Icon path={tab.icon} size={1} className={segment === tab.link ? 'text-primary-800' : 'text-black-300'} />}
											<div className={`mx-4 my-4 ${segment === tab.link ? 'text-primary-800 font-bold' : 'text-black-400'}`}>{tab.title}</div>
										</div>
										<Icon
											path={mdiChevronUp}
											size={1}
											className={`text-black-200 transition-all !duration-[500ms] ${collapseChildren[tab.link] ? 'rotate-180' : 'rotate-90'}`}
										/>
									</div>
									<hr className='border-dotted border-t-2 border-black-75' />
									<div className={`${collapseChildren[tab.link] ? ' collapse-animation-open' : 'collapse-animation-close'}`}>
										<div className='justify-center flex flex-col pt-2'>
											{Object.values(tab.children).map((subTab, index) => (
												<Link
													className='w-full h-fit '
													key={`subNav-${tab.link}-${subTab.link}-${index}`}
													onClick={() => setCollapseMenu(!collapseMenu)}
													to={`../${tab.link}/${subTab.link}`}
												>
													<div className='px-7 '>
														<div className={`${selectedTab === subTab.link && segment === tab.link ? 'bg-background rounded-[100px]' : ''} px-6 flex flex-row items-center`}>
															{subTab.icon && (
																<Icon path={subTab.icon} size={0.8} className={`${selectedTab === subTab.link && segment === tab.link ? 'text-primary-800' : 'text-black-300'}`} />
															)}
															<div className={`mx-4 my-3 ${selectedTab === subTab.link && segment === tab.link ? 'text-primary-800 font-bold' : 'text-black-400'}`}>
																{subTab.title}
															</div>
														</div>
													</div>
												</Link>
											))}
											{/* <hr className='border-dotted border-t-2 border-black-75' /> */}
										</div>
									</div>
								</div>
							) : (
								<>
									<Link
										className={segment === tab.link ? 'bg-primary-50 w-full' : 'w-full'}
										key={index}
										onClick={() => setCollapseMenu(!collapseMenu)}
										to={`../${tab.link}`}
									>
										<div className='flex flex-row items-center px-7'>
											{tab.icon && <Icon path={tab.icon} size={1} className='text-black-300' />}
											<div className='mx-4 my-4 text-black-400'>{tab.title}</div>
										</div>
									</Link>
									<hr className='border-dotted border-t-2 border-black-75' />
								</>
							)}
						</div>
					))}
				</div>
			</div>
		</Drawer>
	)
}

export default NavDrawer
