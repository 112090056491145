import { Drawer, Tooltip } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { brokerByIdSelector } from '../recoil/selectors'
import { VendorTypeEnums } from '../helpers/Enums'
import Icon from '@mdi/react'
import { mdiAccountCircleOutline, mdiArrowRight, mdiCircleMedium, mdiCircleSmall, mdiFileJpgBox, mdiImage, mdiOfficeBuilding } from '@mdi/js'

const CarrierInfoSideBar = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const record = useRecoilValue(brokerByIdSelector({ _id: id }))

	return (
		<Drawer
			open={true}
			placement='right'
			onClose={() => {
				navigate(-1)
			}}
			width={409}
			className='ant-drawer-customize '
		>
			<div className='flex flex-col mx-[23px] mt-[8px] mb-[40px]'>
				<div className='flex flex-col'>
					<div className=' flex flex-row'>
						<div class="w-[170px] h-6 text-zinc-800 text-lg font-bold font-['Inter'] capitalize">{record?.User?.firstName + ' ' + record?.User?.lastName}</div>
						<div className='flex-row p-1 w-[65px] h-[22px] pr-2 py-[5px] bg-emerald-500 rounded justify-start items-center gap-1 inline-flex'>
							<Icon path={VendorTypeEnums[record?.vendorType]?.icon} className='text-white'></Icon>
							<div class="text-white text-[10px] font-medium font-['Inter']">{VendorTypeEnums[record?.vendorType]?.text}</div>
						</div>
					</div>
					<div class="w-[170px] mt-[5px] h-6 text-zinc-800 text-sm font-semibold font-['Inter'] capitalize">{'Carrier ID #' + record?.brokerCode}</div>
					<div class='w-[362px] h-[181px] bg-neutral-50 rounded-lg mt-[26px]'>
						<div className=' flex flex-col space-y-3 mt-[3px] mx-[16px]'>
							<div className=' flex flex-row  justify-between '>
								<div class="text-neutral-400 text-sm font-medium font-['Inter'] leading-normal">Contact #</div>
								<div class="text-stone-500 text-sm font-semibold font-['Inter'] leading-normal">{record?.User?.phoneNumber}</div>
							</div>
							<div className=' flex flex-row  justify-between '>
								<div class="text-neutral-400 text-sm font-medium font-['Inter'] leading-normal">CNIC #</div>
								<div class="text-stone-500 text-sm font-semibold font-['Inter'] leading-normal">{record?.User?.cnic}</div>
							</div>
							<div className=' flex flex-row  justify-between '>
								<div class="text-neutral-400 text-sm font-medium font-['Inter'] leading-normal">NTN #</div>
								<div class="text-stone-500 text-sm font-semibold font-['Inter'] leading-normal">{record?.NTN}</div>
							</div>
							<div className=' flex flex-row  justify-between '>
								<div class="text-neutral-400 text-sm font-medium font-['Inter'] leading-normal">No of Vehicles</div>
								<div class="text-stone-500 text-sm font-semibold font-['Inter'] leading-normal">{record?.totalVehiclesOwned}</div>
							</div>
							<div className=' flex flex-row space-x-24 justify-between '>
								<div class="text-neutral-400 text-sm font-medium font-['Inter'] leading-normal">Address</div>
								<div class="text-stone-500 text-sm font-semibold font-['Inter'] leading-normal">{record?.officeAddress}</div>
							</div>
						</div>
					</div>
				</div>

				{record?.User?.cnicFront && (
					<a href={record?.User?.cnicFront} download target='_blank'>
						<div className='flex flex-row items-center h-[34px] bg-neutral-50 rounded-lg mt-[18px] ml-[17px] space-x-2'>
							<Icon path={mdiFileJpgBox} size={1} color='#EB6854'></Icon>
							<div class="text-zinc-800 text-sm font-semibold font-['Inter'] leading-normal">ID Front.jpeg</div>
						</div>
					</a>
				)}
				{record?.User?.cnicBack && (
					<a href={record?.User?.cnicBack} download target='_blank'>
						<div className='flex flex-row items-center h-[34px] bg-neutral-50 rounded-lg mt-[18px] ml-[17px] space-x-2'>
							<Icon path={mdiFileJpgBox} size={1} color='#1C75BC'></Icon>
							<div class="text-zinc-800 text-sm font-semibold font-['Inter'] leading-normal">ID Back.jpeg</div>
						</div>
					</a>
				)}

				<div class="text-stone-500 text-xs font-semibold font-['Inter'] capitalize mt-[30px]">Preferred Lanes</div>
				<div className=' flex flex-col p-[16px] w-[362px] h-fit bg-white rounded shadow mt-[15px]'>
					<div className=' flex flex-row space-x-4 '>
						<div class="text-neutral-400 text-sm font-medium font-['Inter'] leading-normal">Total Lanes:</div>
						<div class="text-zinc-800 text-sm font-bold font-['Inter'] leading-normal">{record?.Lanes?.length || 0}</div>
					</div>
					{record?.Lanes?.map((lane) => {
						return (
							<div className='flex flex-row w-[334px] h-[34px] p-2 bg-neutral-50 rounded-lg border border-black border-opacity-10 mt-[14px] space-x-5 items-center'>
								<div class="text-zinc-800 text-sm font-semibold font-['Inter'] leading-normal">{lane?.from?.name}</div>
								<Icon path={mdiArrowRight} size={1} color={'#1C75BC'}></Icon>
								<div class="text-zinc-800 text-sm font-semibold font-['Inter'] leading-normal">{lane?.to?.name}</div>
							</div>
						)
					})}
				</div>
				<div class="text-stone-500 text-xs font-semibold font-['Inter'] capitalize mt-[30px]">POC Details</div>
				<div className='flex flex-col w-[362px] h-fit bg-white rounded shadow mt-[15px]'>
					{record?.POCs?.map((poc) => {
						return (
							<div className='mb-[12px]'>
								<div class='flex flex-row h-10 px-[16px] bg-blue-50 rounded-tl rounded-tr border-b border-black border-opacity-5 items-center'>
									<div class="w-[194px] text-sky-600 text-sm font-semibold font-['Inter'] leading-normal">{poc?.name}</div>
								</div>
								<div class='flex flex-row  w-[334px] h-[34px] p-2  rounded-lg mt-[14px] space-x-5'>
									<span className="text-zinc-800 text-sm font-normal font-['Inter'] leading-normal">Contact: </span>
									<span className="text-zinc-800 text-sm font-semibold font-['Inter'] leading-normal">{poc?.phoneNumber}</span>
								</div>
								<div class='flex flex-row  w-[334px] h-[34px] px-2  rounded-lg space-x-5'>
									<span className="text-zinc-800 text-sm font-normal font-['Inter'] leading-normal">CNIC: </span>
									<span className="text-zinc-800 text-sm font-semibold font-['Inter'] leading-normal">{poc?.cnic}</span>
								</div>
								{poc?.cnicFront && (
									<a href={poc?.cnicFront} download target='_blank'>
										<div className='flex flex-row items-center h-[34px] bg-neutral-50 rounded-lg mt-[18px] ml-[17px] space-x-2'>
											<Icon path={mdiFileJpgBox} size={1} color='#EB6854'></Icon>
											<div class="text-zinc-800 text-sm font-semibold font-['Inter'] leading-normal">ID Front.jpeg</div>
										</div>
									</a>
								)}

								{poc?.cnicBack && (
									<a href={poc?.cnicBack} download target='_blank'>
										<div className='flex flex-row items-center h-[34px] bg-neutral-50 rounded-lg mt-[18px] ml-[17px] space-x-2'>
											<Icon path={mdiFileJpgBox} size={1} color='#1C75BC'></Icon>
											<div class="text-zinc-800 text-sm font-semibold font-['Inter'] leading-normal">ID Back.jpeg</div>
										</div>
									</a>
								)}
							</div>
						)
					})}
				</div>

				<div class="text-stone-500 text-xs font-semibold font-['Inter'] capitalize mt-[30px]">Bank Details</div>
				<div className='flex flex-col w-[362px] h-fit bg-white mt-[15px]'>
					{record?.accounts?.map((account) => {
						return (
							<div className='mb-[12px] rounded shadow'>
								{/* <Tooltip title={account?.bankName}> */}
								<div class='flex flex-row px-[16px] py-2 justify-between bg-blue-50 rounded-tl rounded-tr border-b border-black border-opacity-5 items-center'>
									<div class="w-1/2 text-sky-600 text-sm font-semibold font-['Inter'] leading-normal">{account?.bankName}</div>
									{account?.deactivated ? (
										<div className='flex flex-row items-center w-fit pr-2.5 text-xs font-semibold rounded-full uppercase text-[#be2525] bg-[#fff2f0]'>
											<Icon path={mdiCircleMedium} size={0.75} />
											inactive
										</div>
									) : (
										<div className='flex flex-row items-center w-fit pr-2.5 text-xs font-semibold rounded-full uppercase text-[#2f8f50] bg-[#e6f5e9]'>
											<Icon path={mdiCircleMedium} size={0.75} />
											active
										</div>
									)}
								</div>
								{/* </Tooltip> */}
								<div class='flex flex-row  w-[334px] h-[34px] p-2  rounded-lg mt-[14px] space-x-5'>
									<span className="text-zinc-800 text-sm font-normal font-['Inter'] leading-normal">Title: </span>
									<span className="text-zinc-800 text-sm font-semibold font-['Inter'] leading-normal">{account?.accountTitle}</span>
								</div>
								<div class='flex flex-row  w-[334px] h-[34px] px-2  rounded-lg space-x-5'>
									<span className="text-zinc-800 text-sm font-normal font-['Inter'] leading-normal">A/C # </span>
									<span className="text-zinc-800 text-sm font-semibold font-['Inter'] leading-normal">{account?.accountNumber}</span>
								</div>
								{account?.documents && (
									<a href={account?.documents} download target='_blank'>
										<div className='flex flex-row items-center h-[34px] bg-neutral-50 rounded-lg mt-[18px] ml-[17px] space-x-2'>
											<Icon path={mdiFileJpgBox} size={1} color='#EB6854'></Icon>
											<div class="text-zinc-800 text-sm font-semibold font-['Inter'] leading-normal">Bank Screenshot.jpeg</div>
										</div>
									</a>
								)}
							</div>
						)
					})}
				</div>
			</div>
		</Drawer>
	)
}

export default CarrierInfoSideBar
