import { isArray } from '@apollo/client/cache/inmemory/helpers'
import { Spin } from 'antd'
import moment from 'moment'
import { BPaySlabs, FilterEnums, FilterTypeEnums, GraphQLEnums, PageFilterEnums, PageSearchEnums } from './Enums'

/* ============================================================================================== */
/* ===================================== UTILITY FUNCTIONS ====================================== */
/* ============================================================================================== */
export const check = (Param) => Param !== null && Param !== undefined
export const checkArray = (Param) => !!Param && Param.constructor === Array
export const checkObject = (Param) => !!Param && typeof Param === 'object' && !checkArray(Param)
export const isSuperUser = (user = {}, superUsers = []) => superUsers?.includes(user?.email)

export const currentCollection = (path) => path.split('/')[1]
export const selectedRecordId = (path) => path.split('/')[2]
export const ReadableString = (string) => string && string.replace(/([A-Z])/g, ' $1')
export const Loader = () => <Spin className='Spinner' tip={<p className='font-bold text-[16px] text-primary-800 mt-2'>Loading...</p>} size='large' />
export const Capitalize = (string) => string && string[0].toUpperCase() + string.slice(1)
export function range(start, end) {
	let array = new Array(end - start + 1)
	for (let i = 0; i < end - start + 1; i++) array[i] = start + i
	return array
}
export const get12HourDate = (time) => (time ? moment(time).format('DD-MM-YYYY hh:mm A') : '')
export const get24HourDate = (time) => (time ? moment(time).format('DD-MM-YYYY HH:mm') : '')

export const defaultDates = () => {
	const format = 'YYYY-MM-DD'
	const date = moment().startOf('day').format('YYYY-MM-DD')
	const startDate = moment(moment(date, format).subtract(15, 'days').format(format))
	const endDate = moment(moment().startOf('day').format('YYYY-MM-DD'))
	return [startDate, endDate]
}

export const startOfDay = moment().startOf('day').format('x')
export const endOfDay = moment().endOf('day').format('x')
export const removeTypeName = (obj) => {
	const newObj = Object.assign({}, obj)
	delete newObj.__typename
	return newObj
}
export const removeFields = (obj, fields) => {
	if (obj && fields) {
		const newObj = Object.assign({}, obj)
		fields.forEach((field) => {
			delete newObj[field]
		})
		return newObj
	} else {
		return obj
	}
}

export const getProps = (object = {}, props = []) =>
	props.reduce((newObj, prop) => {
		newObj[prop] = object[prop]
		return newObj
	}, {})

export const debounce = (func, timeout = 500) => {
	let timer
	return (...args) => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			func.apply(this, args)
		}, timeout)
	}
}

/* ============================================================================================== */
/* ===================================== GRAPHQL FUNCTIONS ====================================== */
/* ============================================================================================== */

export const updateAtom = (props) => {
	const { type, queryKey, prev, subscriptionData, setAtom } = props

	const segmentQuery = GraphQLEnums[`${type}`]?.fetch?.[queryKey || 'default']?.key
	const segmentSubscription = GraphQLEnums[`${type}`].subscription?.[queryKey || 'default']?.key
	const order = subscriptionData?.data?.[segmentSubscription]
	const remove = GraphQLEnums[`${type}`].subscription?.[queryKey || 'default']?.checkRemove?.(order)
	let existing = {}
	let orders = []
	console.log({ order, prev })
	Object.assign(existing, prev)
	Object.assign(orders, existing[segmentQuery])

	const index = orders.findIndex((oldOrder) => oldOrder?._id === order?._id)
	if (index >= 0) {
		if (remove) {
			orders.splice(index, 1)
		} else {
			orders[index] = order
		}
	} else {
		!remove && orders.push(order)
	}
	existing[segmentQuery] = orders
	setAtom(orders)
	console.log({ orders })
	return existing
}

/* ============================================================================================== */
/* ==================================== ASSIGNMENT FUNCTIONS ==================================== */
/* ============================================================================================== */

export const checkBulkAssignable = ({ jobs, filters }) => {
	const necessaryFilters = [
		{ name: 'lane', max: 1 },
		{ name: 'weight' },
		// { name: 'emptyReturn', max: 1 },
		{ name: 'vehicleType', max: 1 },
	]
	const jobCheck = jobs?.length > 1
	var filterValues = []
	const filterCheck = necessaryFilters.every((filter) => {
		if (filter.max) {
			filterValues = filters?.[filter.name]?.values && Object.values(filters?.[filter.name]?.values)?.filter((value) => value)
			return filterValues?.length <= filter.max && filterValues?.length > 0
		} else {
			return FilterTypeEnums[filters?.[filter.name]?.type]?.checkSelected(filters?.[filter.name]?.values) || false
		}
	})
	return jobCheck && filterCheck
}

/* ============================================================================================== */
/* ======================================= FILTER FUNCTIONS ===================================== */
/* ============================================================================================== */

export const filterData = ({ data, filterState, searchEnums }) => {
	const formattedInput = filterState?.search?.value?.replace(/[^a-zA-Z0-9\-#]/, '').toLowerCase()
	const inputRegex = new RegExp(`^${formattedInput}`)
	const searchFunction = searchEnums?.[filterState?.search?.property]?.comparisonFunc
	const selectedFilters = Object.values(filterState).filter((filter) => FilterTypeEnums[filter.type]?.checkSelected(filter?.values))
	const selectedFilterProps = selectedFilters?.reduce((allFilterProps, filter) => {
		allFilterProps[filter.propertyKey] = FilterEnums[filter.propertyKey]?.filterProps
		return allFilterProps
	}, {})
	return data.filter((row, index) => {
		const searchCheck =
			!formattedInput ||
			row?.searchProperties?.[filterState?.search?.property]?.some((value) => {
				return (
					searchFunction?.(formattedInput, value?.toString()?.toLowerCase()) ||
					value
						?.toString()
						?.replace(/[^a-zA-Z0-9\-#]/, '')
						?.toLowerCase()
						.includes(formattedInput)
				)
			})

		const filterCheck = row?.filterProperties
			? selectedFilters.length === 0 ||
			  (searchCheck &&
					selectedFilters.every((filter) => {
						return row?.filterProperties?.[filter.propertyKey]?.some((value) => {
							return FilterTypeEnums[filter.type]?.function(value, filter.values, selectedFilterProps[filter.propertyKey])
						})
					}))
			: true

		return searchCheck && filterCheck
	})
}

export const initFilterState = (filters, prevFilterState, propertyKey) => {
	if (propertyKey && prevFilterState) {
		const filter = filters.filter((filter) => filter.propertyKey === propertyKey)?.[0]
		const newFilterState = Object.assign({}, prevFilterState)
		const oldValues = Object.assign({}, prevFilterState?.[propertyKey]?.values)
		const initialValue = filter?.filterProps?.initialValue
		newFilterState[propertyKey] = {
			propertyKey: filter.propertyKey,
			type: filter.type,
			prevState: {},
			values:
				initialValue || (oldValues && Object.keys(oldValues)?.length > 0 ? FilterTypeEnums[filter?.type]?.initialValues(oldValues, filter?.filterProps) : {}),
		}
		return newFilterState
	} else if (prevFilterState) {
		const initialFilters = filters.reduce((filterState, filter) => {
			const oldValues = Object.assign({}, prevFilterState?.[filter.propertyKey]?.values)
			filterState[filter.propertyKey] = {
				propertyKey: filter.propertyKey,
				type: filter.type,
				prevState: {},
				values: filter?.filterProps?.initialValue || (oldValues ? FilterTypeEnums[filter?.type]?.initialValues(oldValues) : {}),
			}
			return filterState
		}, {})
		return { ...initialFilters, search: prevFilterState ? prevFilterState.search : {} }
	} else {
		const initialFilters = filters?.reduce((filterState, filter) => {
			filterState[filter.propertyKey] = {
				propertyKey: filter.propertyKey,
				type: filter.type,
				prevState: {},
				values: filter?.filterProps?.initialValue || FilterTypeEnums[filter?.type]?.initialValues?.({}) || {},
			}
			return filterState
		}, {})
		return { ...initialFilters, search: prevFilterState ? prevFilterState.search : {} }
	}
}

const findProperty = ({ path = [], data }) => {
	if (data === undefined) {
		return ['']
	} else if (path.length === 0) {
		return [data]
	} else {
		if (checkArray(data)) {
			return data.map((value) => findProperty({ path: path, data: value })).flat()
		} else {
			return findProperty({ path: path.slice(1), data: data?.[path?.[0]] })
		}
	}
}

const findMultipleProperties = ({ paths, data }) => {
	let result = ['']
	for (const path of paths) {
		result = findProperty({ path, data })
		if (result?.[0] !== '') {
			return result
		}
	}
	return result
}

export const findAllFilterProperties = (data, propertyEnum) =>
	propertyEnum?.reduce((filterProperties, filter) => {
		filterProperties[filter.propertyKey] = findMultipleProperties({ paths: filter.paths, data }).map((value) =>
			filter.propertyMapFunction ? filter.propertyMapFunction(value) : value
		)
		return filterProperties
	}, {})

export const prepareProperties = (data, page, segment) => {
	const filterProperties =
		PageFilterEnums[page] && findAllFilterProperties(data, PageFilterEnums?.[page]?.[segment !== 'vendorManagement' ? Capitalize(segment) : 'default'])
	const searchProperties =
		PageSearchEnums[page] &&
		findAllFilterProperties(data, Object.values(PageSearchEnums[page]?.[segment !== 'vendorManagement' ? Capitalize(segment) : 'default']))
	return [filterProperties, searchProperties]
}

export const createFilterString = (filterState) => {
	filterState && Object.keys(filterState)?.reduce()
}
export const createManagementFilterString = (record) => {
	// ['vehicleType']
	const properties = ['', ['lane', '_id'], 'client', 'weight']

	return properties.reduce((filterString, property, index) => {
		const filter = FilterEnums[isArray(property) ? property[0] : property]
		const value = isArray(property)
			? record?.[property?.[0]]
				? filterString +
				  `${property?.[0]}=${
						filter?.propertyMapFunction
							? filter.propertyMapFunction(findProperty({ path: property, data: record })?.[0])
							: findProperty({ path: property, data: record })
				  }${index + 1 !== properties.length ? '&' : ''}`
				: filterString
			: record?.[property]
			? filterString + `${property}=${record?.[property].replace('-', '+')}${index + 1 !== properties.length ? '&' : ''}`
			: filterString

		return value
	}, '')
}

export const checkJobSlipped = ({ values, props = {} } = {}) => {
	const { data } = props
	const job = data?.jobs?.[0]
	const quotationExists = job?.quotations?.length > 0
	const supplierSlipped = job?.supplier?._id && values?.supplier !== job?.supplier?._id
	const brokerSlipped = job?.broker?._id && values?.broker !== job?.broker?._id
	const vehicleDriverSlipped =
		(job?.vehicle?.registrationNumber || job?.driver?.phoneNumber) &&
		('+92' + values?.phoneNumber?.replace(' ', '') !== job?.driver?.phoneNumber ||
			values?.vehicleRegistration.toLowerCase() !== job?.vehicle?.registrationNumber?.toLowerCase())
	if (!values || (quotationExists && (supplierSlipped || brokerSlipped || vehicleDriverSlipped))) {
		return true
	} else {
		return false
	}
}

export const findSlab = (amount, reverse = false) => {
	const slabs = Object.entries(BPaySlabs)?.sort((a, b) => b[0] - a[0])
	let slabFound = -1
	let index = 0
	while (slabFound === -1 && index < slabs?.length - 1) {
		if (
			Math.floor(parseFloat(slabs[index]?.[0]) / 500) * 500 <=
			(reverse ? Math.floor((slabs[index][1] * (parseFloat(slabs[index][0]) / 100)) / 500) * 500 + parseFloat(amount || 0) : parseFloat(amount || 0))
		) {
			slabFound = index
		}
		index += 1
	}
	return slabFound === -1
		? Math.floor((slabs[slabs?.length - 1][1] * (parseFloat(slabs[slabs?.length - 1][0]) / 100)) / 500) * 500
		: Math.floor((slabs[slabFound][1] * (parseFloat(slabs[slabFound][0]) / 100)) / 500) * 500
}

export const checkPatternMatch = (path = '', pattern = '') => {
	const pathList = path?.split('/')
	const patternList = pattern.split('/')
	let isMatched = true
	let unmatchedOptionalCount = 0
	if (pathList.length < patternList.filter((pattern) => !pattern?.includes('?'))?.length) {
		isMatched = false
	} else {
		for (var i = 0; i < pathList.length; ++i) {
			if (patternList[i]?.[0] === ':' && pathList[i]) {
				continue
			} else if (patternList[i + unmatchedOptionalCount] === pathList[i]) {
				continue
			} else if (patternList[i]?.includes('?')) {
				unmatchedOptionalCount++
				continue
			} else {
				isMatched = false
				break
			}
		}
	}

	return isMatched
}

export const toReadableString = (s, format) => {
	if (format === 'ss_case') {
		const modifiedStringList = s?.split('_')
		return modifiedStringList?.map((element) => element?.charAt(0).toUpperCase() + element?.slice(1).toLowerCase())?.join(' ')
	} else {
		const modifiedString = s?.replace(/([A-Z])/g, ' $1').trim()
		return modifiedString?.charAt(0).toUpperCase() + modifiedString?.slice(1)
	}
}

export const toReadableAmount = (amount) => {
	return amount?.toLocaleString('en-US')
}

// function to remove null and undefined values from an object
export const removeNullUndefined = (object, parent = null) => {
	// this version handles empty arrays and nested empty objects
	// taken from https://stackoverflow.com/a/52368116
	// added a check to remove updatedAt if that is the only updated key
	if (!!!object) {
		return null
	}
	Object.entries(object).forEach(([k, v]) => {
		if (v && typeof v === 'object') {
			removeNullUndefined(v, k)
		}
		if ((v && typeof v === 'object' && !Object.keys(v).length) || v === null || v === undefined) {
			if (Array.isArray(object)) {
				object.splice(k, 1)
			} else {
				delete object[k]
			}
		}
	})
	if (Object.keys(object).length === 1 && object.updatedAt) {
		delete object.updatedAt
	}
	if (Object.keys(object).length === 1 && object._id && parent !== 'node') {
		delete object._id
	}
	return object
}
