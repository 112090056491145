// react
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { FormRender, RenderSchema } from '../../helpers/FormRenderer'
import { AddEditVendorScehma, CreateLotJobSchema, EditJobSchema, EditSupplierSchema, PaymentTermsSchema } from '../../helpers/FormSchemas'

import { Divider, Form, Input, Layout, Spin } from 'antd'
import {
	mdiCheck,
	mdiClose,
	mdiPackageVariantClosed,
	mdiPencil,
	mdiTruckOutline,
	mdiAccountCircleOutline,
	mdiAlertCircleOutline,
	mdiCheckCircleOutline,
	mdiCloseCircleOutline,
	mdiArrowRight,
	mdiLightningBolt,
	mdiCellphoneCheck,
} from '@mdi/js'

import Icon from '@mdi/react'
import { HeaderPill, Pill } from '../utility/Pills'
import DynamicTable from '../utility/DynamicTable'

import { assignSupplierColumns } from '../../helpers/TableColumns'
import { VehicleTypeEnums, Locations } from '../../helpers/Enums'
import { Capitalize, debounce, findSlab } from '../../helpers/Functions'
import { BlackListedVehicleBanner, CustomSwitchWrapper } from '../utility/Utility'

import * as dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import customParse from 'dayjs/plugin/customParseFormat'
import { useLazyFetch } from '../../hooks/graphql'
import { LoadingOutlined } from '@ant-design/icons'
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(customParse)

const { Header, Content } = Layout

/* ============================================================================================== */
/* ==================================== SINGLE ASSIGNMENT ======================================= */
/* ============================================================================================== */

// JOB DETAILS SIDE COLUMN
export const JobDetails = ({ data, segment, tab, jobId }) => {
	const { jobs } = data
	const record = jobs?.[0]
	const job = tab === 'lot' ? record?.jobs?.find((job) => job?._id === jobId) : record
	const loadings =
		record?.loading?.map((loading) => loading?.name) ||
		job?.orderLocations?.filter((loc) => loc?.locationType === 'loading')?.map((location) => location?.Location?.formattedAddress)
	const dropoffs =
		record?.dropoff?.map((dropoff) => dropoff?.name) ||
		job?.orderLocations?.filter((loc) => loc?.locationType === 'dropoff')?.map((location) => location?.Location?.formattedAddress)
	const emptyContainer = job?.orderLocations?.filter((loc) => loc?.locationType === 'Empty Container Dropoff')[0]?.Location?.formattedAddress
	const releaseTerminal = job?.orderLocations?.filter((loc) => loc?.locationType === 'Empty Container Pickup')[0]?.Location?.formattedAddress
	return (
		<div className='flex bg-secondaryBackground flex-col py-4 h-full'>
			<div className={'px-4 flex flex-col  ' + (tab !== 'lot' ? 'shadow-md pb-4' : '')}>
				<p className='text-xs text-black-200 font-[500] mb-2'>{tab === 'lot' ? 'JOB NO. & CLIENT' : 'JOB NO. & PRIORITY'}</p>
				<div className='flex flex-row items-center'>
					<p className='text-[20px] text-black-400 font-[600]'>{job?.jobNumber ? `J-${job?.jobNumber}` : 'New Job'}</p>
					{tab !== 'lot' && (
						<div className='my-auto bg-red-400 rounded-[84px] px-2 mx-4'>
							<div className='font-[600] text-[12px] text-white'>P1</div>
						</div>
					)}
				</div>
				{tab === 'lot' && <p className='text-sm text-black-300 font-[600]'>{record?.clientName}</p>}
			</div>
			{tab === 'lot' && <Divider color='#C8C8C8' />}
			<div className='overflow-y-scroll pb-5'>
				{tab !== 'lot' && (
					<>
						<div className='px-4 flex flex-col  pt-4'>
							<p className='text-xs text-black-200 font-[500] mb-2'>
								{segment === 'import' ? 'BL NO. & CLIENT' : segment === 'export' ? 'CRO NO. & CLIENT' : 'ClIENT DETAILS'}
							</p>
							<p className='text-[16px] text-black-400 font-[600] mb-2'>{job?.documentNumber}</p>
							<p className='text-sm text-black-300 font-[600]'>{job?.clientName}</p>
						</div>
						<Divider color='#C8C8C8' />
						<div className='px-4 flex flex-col'>
							<p className='text-xs text-black-200 font-[500] mb-2'> LANE</p>
							<p className='text-[16px] text-black-400 font-[600]'> {`${job?.superLane?.from} --> ${job?.superLane?.to}`}</p>
							<p className='text-[14px] text-black-300 font-[500]'> {`${job?.lane?.from} --> ${job?.lane?.to}`}</p>
						</div>
					</>
				)}
				{tab === 'lot' ? (
					<div className='px-4 flex flex-col'>
						<p className='text-xs text-black-200 font-[500] mb-2'>ROUTE</p>
						{loadings?.map((loading, index) => (
							<div key={'loading_' + index} title={loading} className='flex flex-row text-sm text-black-300 font-[500] pr-2 items-center pb-2'>
								<div className='flex flex-row items-center'>
									<Icon className={Locations?.['loading']?.background + ' p-1 rounded-[50px] text-white mr-2'} path={Locations?.['loading'].icon} size={0.7} />
									<div className=' text-sm text-black-400 font-[600] pr-2'>Loading:</div>
								</div>
								<p className=' text-sm text-black-400 font-[400] overflow-ellipsis'>{loading ? `${loading}` : 'N/A'}</p>
							</div>
						))}
						{dropoffs?.map((dropoff, index) => (
							<div key={'dropoff_' + index} title={dropoff} className='flex flex-row text-sm text-black-300 font-[500] pr-2 items-center'>
								<div className='flex flex-row items-center'>
									<Icon className={Locations?.['dropoff']?.background + ' p-1 rounded-[50px] text-white mr-2'} path={Locations?.['dropoff'].icon} size={0.7} />
									<div className=' text-sm text-black-400 font-[600] pr-2'>Dropoff:</div>
								</div>
								<p className=' text-sm text-black-400 font-[400] overflow-ellipsis'>{dropoff ? `${dropoff}` : 'N/A'}</p>
							</div>
						))}
					</div>
				) : (
					<div className='px-4 flex flex-col mt-4 py-4 bg-black-75 '>
						{segment === 'export' && (
							<div>
								<p className='text-sm text-black-300 font-[500] mb-1'>
									<span className={`rounded-[40px] px-1 py-.5 my-auto mr-2 ${Locations?.['Empty Container Dropoff']?.background}`}>
										<Icon className='inline text-white' path={Locations?.['Empty Container Dropoff'].icon} size={0.4} />
									</span>
									RELEASE TERMINAL
								</p>
								<p className='ml-6  text-xs text-black-400 font-[500] mb-2'>{releaseTerminal ? `${releaseTerminal}` : 'N/A'}</p>
							</div>
						)}
						{loadings?.map((loading, index) => (
							<div key={'loading_' + index}>
								<p className='text-sm text-black-300 font-[500] mb-1'>
									<span className={`rounded-[40px] px-1 py-.5 my-auto mr-2 ${Locations?.['loading']?.background}`}>
										<Icon className='inline text-white my-auto' path={Locations?.['loading'].icon} size={0.4} />
									</span>
									{`${Locations?.['loading'].title} ${loadings.length > 1 ? index + 1 : ''}`}
								</p>
								<p className='ml-6 text-xs text-black-400 font-[500] mb-2'>{loading ? `${loading}` : 'N/A'}</p>
							</div>
						))}
						{dropoffs?.map((dropoff, index) => (
							<div key={'dropoff_' + index}>
								<p className='text-sm text-black-300 font-[500] mb-1'>
									<span className={`rounded-[40px] px-1 py-.5 my-auto mr-2 ${Locations?.['dropoff']?.background}`}>
										<Icon className='inline text-white' path={Locations?.['dropoff'].icon} size={0.4} />
									</span>
									{`${Locations?.['dropoff'].title} ${dropoffs.length > 1 ? index + 1 : ''}`}
								</p>
								<p className='ml-6 text-xs  text-black-400 font-[500] mb-2'>{dropoff ? `${dropoff}` : 'N/A'}</p>
							</div>
						))}
						{segment === 'import' && (
							<>
								<div>
									<p className='text-sm text-black-300 font-[500] mb-1'>
										<span className={`rounded-[40px] px-1 py-.5 my-auto mr-2 ${Locations?.['Empty Container Dropoff']?.background}`}>
											<Icon className='inline text-white' path={Locations?.['Empty Container Dropoff'].icon} size={0.4} />
										</span>
										{`${Locations?.['Empty Container Dropoff'].title}`}
									</p>
									<p className='ml-6  text-xs text-black-400 font-[500] mb-2'>{emptyContainer ? `${emptyContainer}` : 'N/A'}</p>
								</div>
								<div className='flex flex-row px-2 py-1 my-auto justify-center'>
									<Pill
										bgClassName='bg-background'
										textClassName='text-primary-800'
										label='No. of Free Days'
										value={job?.freeDays ? ('0' + `${job?.freeDays}`).slice(-2) : 'N/A'}
									/>
								</div>
							</>
						)}
					</div>
				)}
				{tab === 'lot' ? (
					<div className='px-4 flex flex-col'>
						<Divider color='#C8C8C8' />
						<p className='text-xs text-black-200 font-[500] mb-2'>GOOD TYPE</p>
						<div className='flex flex-row text-[16px] items-center pt-2 pb-1 '>
							<div className='font-[600]'>{record?.shipmentInfo?.freightType || 'N/A'}</div>
						</div>
						<Divider color='#C8C8C8' />
						<p className='text-xs text-black-200 font-[500] mb-2'>TOTAL WEIGHT TO BE MOVED</p>
						<div className='flex flex-row text-[16px] items-center pt-2 pb-1 '>
							<Icon className='mr-2' path={mdiPackageVariantClosed} size={1} color='#9F9F9F' />

							<div className='font-[600]'>{`${record?.totalWeight || 'N/A'} Tons`}</div>
						</div>
					</div>
				) : (
					<div className='px-4 flex flex-col pt-4'>
						<p className='text-xs text-black-200 font-[500] mb-2'>CONTAINER & VEHICLE</p>
						{job?.containerInfo?.map(
							(container, index) =>
								segment === 'import' && (
									<div className='flex flex-row text-sm py-1' key={`${job?._id}-${index}`}>
										<div className='font-[400] text-black-300'>Container #:&nbsp;</div>
										<div className='font-[600] text-black-400'>{container?.containerNumber}</div>
									</div>
								)
						)}
						<div className='flex flex-wrap text-sm py-1 items-start'>
							<div className='flex flex-row pr-4 items-center'>
								<Icon className='mr-2' path={mdiTruckOutline} size={1} color='#9F9F9F' />
								{segment === 'longHaul' || tab === 'destuffing' ? (
									<div className='font-[600]'>{`${
										(VehicleTypeEnums[job?.vehicleType] || VehicleTypeEnums[Capitalize(job?.vehicleType)])?.text + (job?.subVehicleType ? '-' + job?.subVehicleType : '')
									}`}</div>
								) : (
									<div className='font-[600] overflow-ellipsis'>
										{job?.containerInfo?.length > 1 && job?.containerInfo?.[0]?.containerDimensions === 'xl20ft'
											? VehicleTypeEnums['xl2020ft']?.text
											: VehicleTypeEnums[job?.containerInfo?.[0]?.containerDimensions]?.text}
									</div>
								)}
							</div>

							<div className='flex flex-row items-center '>
								<Icon className='mr-2' path={mdiPackageVariantClosed} size={1} color='#9F9F9F' />
								<div className='font-[600] overflow-ellipsis'>{`${
									segment === 'longHaul'
										? job?.shipmentInfo?.freightWeight
										: job?.containerInfo?.[0]?.containerWeight && job?.containerInfo?.[0]?.containerWeight !== 'NaN'
										? job?.containerInfo?.reduce((totalWeight, container) => (parseInt(totalWeight) + parseInt(container?.containerWeight)).toString(), '0')
										: '---'
								} Tons`}</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

// SINGLE ASSIGNMENT FORM
export const SingleJobLotAssignment = ({ jobId, tab, data, form, formWatch, setPromiseDisabled }) => {
	// console.log({ edit, jobId, tab, navigator, data, form, formWatch, modalRefState })
	const { jobs, formData } = data
	const { brokers, suppliers } = formData
	const record = jobs?.[0]
	const job = tab === 'lot' ? record?.jobs?.find((job) => job?._id === jobId) : record
	const remainingWeight = record?.remainingWeight
	const [editCost, setEditCost] = useState(false)
	const [vehicle, setVehicle] = useState(job?.vehicle || {})
	const [fetchData, loading, queryData] = useLazyFetch({ type: 'Vehicles' })

	useEffect(() => {
		setPromiseDisabled(loading)
	}, [loading]) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		setVehicle(queryData?.vehicles?.[0])
	}, [queryData])

	const handleDebouncedFunction = (event) => {
		fetchData({ variables: { where: { registrationNumber: event.target.value?.toLowerCase() } } })
	}
	const debouncedFunction = useCallback(debounce(handleDebouncedFunction, 500), []) // eslint-disable-line react-hooks/exhaustive-deps
	const handleOnChange = (event) => debouncedFunction(event)

	const [rate, setRate] = useState(
		(job?.cost && job?.shipmentInfo?.freightWeight ? parseFloat(job?.cost) / parseFloat(job?.shipmentInfo?.freightWeight) : 0).toFixed(2) || '0'
	)
	useEffect(() => {
		if (formWatch?.rate) {
			setRate(formWatch?.rate)
		}
		if (formWatch && formWatch?.weight && formWatch?.rate) {
			form.setFieldValue('cost', (parseFloat(formWatch?.weight) * parseFloat(formWatch?.rate)).toString())
		}
	}, [formWatch])
	return (
		<div className=' bg-white p-5 flex flex-col h-full overflow-scroll'>
			{RenderSchema(CreateLotJobSchema({ record, job, tab, suppliers, brokers, form, remainingWeight, onChange: handleOnChange, loading }))}
			{vehicle?.isBlocked && <BlackListedVehicleBanner registrationNumber={vehicle?.registrationNumber} />}
			<div className='flex flex-row w-full justify-between items-center'>
				{FormRender({
					type: 'input',
					label: 'Cost',
					name: ['cost'],
					disabled: true,
					required: true,
					placeholder: 'Updated according to weight entered',
					itemClassName: 'w-[290px] mr-4 my-auto',
					initialValue: job?.cost || '',
				})}
				{editCost ? (
					<div className='flex flex-row items-end'>
						{FormRender({
							type: 'input',
							label: 'Rate Per Ton',
							name: ['rate'],
							required: true,
							itemClassName: 'w-[290px] mr-4 my-auto',
							initialValue:
								formWatch?.rate ||
								(job?.cost && job?.shipmentInfo?.freightWeight ? parseFloat(job?.cost) / parseFloat(job?.shipmentInfo?.freightWeight) : 0).toFixed(2) ||
								'',
						})}
						<Icon className='cursor-pointer mt-2 mb-2 text-black-300' path={mdiCheck} size={1.2} onClick={() => setEditCost(false)} />
					</div>
				) : (
					<div className='flex flex-row items-end'>
						<div className='flex flex-col items-end justify-center pt-6'>
							<div className='font-[600] text-[16px] text-black-400'>Rs. {rate || '0'}</div>
							<div className='font-[400] text-xs text-black-200 italic'>Rate Per Ton</div>
						</div>
						<Icon className='cursor-pointer ml-4 mb-2 text-black-200' path={mdiPencil} size={1} onClick={() => setEditCost(true)} />
					</div>
				)}
			</div>
		</div>
	)
}

export const SingleJobSupplierAssignment = ({ edit, jobId, tab, navigator, data, form, formWatch }) => {
	const { jobs, formData } = data
	const { suppliers } = formData
	const record = jobs?.[0]
	const job = tab === 'lot' ? record?.jobs.find((job) => job?._id === jobId) : record
	const isBPay = suppliers?.find((supplier) => supplier?._id === formWatch?.supplier)?.name?.includes('BPay') && tab !== 'local'
	return (
		<div className={`bg-white ${isBPay ? '' : ' justify-center'} flex flex-col h-full w-full py-5 overflow-scroll content-center `}>
			{!isBPay && (
				<div className='place-self-center p-5 bg-[#EEEEEE] rounded-full'>
					<Icon className='text-secondary-300' path={mdiAccountCircleOutline} size={2} />
				</div>
			)}
			{edit ? (
				<>
					<div className={`text-center px-12 `}>
						<p className='p-2 text-[16px] text-black-400 font-[600] my-2'>Assign Supplier</p>
						{RenderSchema(EditSupplierSchema({ job, tab, suppliers }))}
					</div>
					<div>
						{isBPay && (
							<div className='px-2'>
								<div className='h-0 border-b-2 border-dashed border-black-75 my-4' />
								<div className=' px-10 '>
									{FormRender({
										type: 'select',
										label: 'Broker Name',
										name: ['broker'],
										valueProperty: '_id',
										displayProperty: 'name',
										options: formData?.brokers,
										required: true,
										showSearch: true,
										placeholder: 'Select Broker for assignment',
										initialValue: job?.broker?._id,
									})}
									<div className='h-0 border-b-2 border-dashed border-black-75 my-4' />
									<div className='flex flex-row items-center pb-4'>
										<p className='text-xs font-[500] text-black-300 pr-2'>Cost Type :&nbsp;</p>
										{FormRender({
											type: 'radioGroup',
											key: 'costType',
											name: ['costType'],
											options: [
												{ key: 'base', long: 'Base Cost' },
												{ key: 'factored', long: 'Factored Cost' },
											],
											itemClassName: '!mb-0 !pb-0',
											className: 'text-xs',
											initialValue: formWatch?.costType || job?.costType || 'base',
										})}
									</div>
									<div className='flex flex-row items-end justify-between pb-2'>
										{FormRender({
											type: 'number',
											key: 'cost',
											name: ['cost'],
											required: true,
											placeholder: 'Enter Cost',
											itemClassName: 'w-1/3 mr-4 my-auto',
											initialValue: formWatch?.cost || job?.cost || '',
										})}
										{formWatch?.cost && parseFloat(formWatch?.cost) > 0 && (
											<>
												<div className='rounded-lg py-2 px-4 bg-background flex flex-row items-center w-fit h-fit !mb-0'>
													<div className='rounded-full border-2 border-black-300 p-[1px] mr-2'>
														<Icon path={mdiLightningBolt} className='text-black-300' size={0.7} />
													</div>

													<div className='flex flex-col'>
														<p className='text-xs font-[500] text-black-300'>BPay Rate</p>
														<p className='text-sm font-bold text-black-400'>{findSlab(formWatch?.cost, formWatch?.costType === 'factored')}</p>
													</div>
													<div className='!h-[30px] border-l-2 border-black-100 mx-4' />
													<div className='flex flex-col'>
														<p className='text-xs font-[500] text-black-300'>{formWatch?.costType === 'base' ? 'Final Cost' : 'Base Cost'}</p>
														<p className='text-sm font-bold text-black-400'>
															{formWatch?.costType === 'base'
																? parseFloat(formWatch?.cost) - findSlab(formWatch?.cost)
																: parseFloat(formWatch?.cost) + findSlab(formWatch?.cost, true)}
														</p>
													</div>
												</div>
											</>
										)}
									</div>
									<div className='h-0 border-b-2 border-dashed border-black-75 my-4' />

									<div className='flex flex-row items-center justify-between pt-2'>
										<p className='text-sm font-[500] text-black-300'>Include Other Vendor Costs in BPay Rate </p>
										<Form.Item
											key={`includeVendorPayments`}
											name={['includeVendorPayments']}
											noStyle={true}
											shouldUpdate={true}
											initialValue={job?.factorBase && (job?.factorBase === 'accumulativeCost' ? true : false)}
										>
											<CustomSwitchWrapper />
										</Form.Item>
									</div>
								</div>
							</div>
						)}
					</div>
				</>
			) : (
				<div className='text-center'>
					<p className='p-2 text-[16px] text-black-400 font-[600]'>Assign Supplier</p>
					<div className='flex flex-row justify-center content-end '>
						<Input type='text' className='font-[600] !w-[70%] text-sm text-secondary-300 bg-secondary-300 py-2' disabled value={job?.supplier?.name || '---'} />
						<Icon className='w-[20%] cursor-pointer ml-4 m-2 text-black-200 align-middle' path={mdiPencil} size={1} onClick={() => navigator('./edit')} />
					</div>
				</div>
			)}
		</div>
	)
}

export const SingleJobDriverAssignment = ({ edit, jobId, tab, navigator, data, form, formWatch, goBack, setPromiseDisabled }) => {
	const { jobs, formData } = data
	const record = jobs?.[0]
	const job = tab === 'lot' ? record?.jobs.find((job) => job?._id === jobId) : record
	const { suppliers, brokers } = formData
	const { supplier } = form.getFieldsValue(true)
	const supplyPerson = suppliers.filter((supplyPerson) => supplyPerson._id === supplier)[0]
	const remainingWeight = record?.remainingWeight
	const [vehicle, setVehicle] = useState(job?.vehicle || {})
	const [fetchData, loading, queryData] = useLazyFetch({ type: 'Vehicles' })

	useEffect(() => {
		setPromiseDisabled(loading)
	}, [loading]) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		setVehicle(queryData?.vehicles?.[0])
	}, [queryData])

	const handleDebouncedFunction = (event) => {
		fetchData({ variables: { where: { registrationNumber: event.target.value?.toLowerCase() } } })
	}
	const debouncedFunction = useCallback(debounce(handleDebouncedFunction, 500), []) // eslint-disable-line react-hooks/exhaustive-deps
	const handleOnChange = (event) => debouncedFunction(event)

	return (
		<div className='bg-white py-5 px-3 mb-2 flex flex-col h-full w-full overflow-scroll'>
			{job?.factorRequest ? (
				<div className='flex flex-col bg-white mx-6 mt-3 mb-4 rounded-[4px] shadow-[0_0_4px_rgba(0,0,0,0.21)]'>
					<div className='flex flex-col items-start px-6 py-4'>
						{[
							{ title: 'Supplier Associate', value: job?.supplier?.name },
							{ title: 'Broker Name', value: job?.broker?.name },
							{ title: 'Vehicle Cost', value: job?.cost },
							{ title: 'Factored Cost', value: job?.factoredAmount },
						]?.map((item) => (
							<div className='flex flex-row w-full text-sm py-1 '>
								<div className='font-[400] text-black-300 basis-1/2'>{item?.title}</div>
								<div className='font-[600] text-black-400 pl-2 overflow-ellipsis basis-1/2'>{item?.value}</div>
							</div>
						))}
					</div>
					<button
						className={'cursor-pointer bg-[#EDF7FF] flex flex-row justify-center items-center p-[10px] '}
						onClick={() => {
							goBack()
						}}
					>
						<Icon path={mdiPencil} size={0.8} className={'text-[#1C75BC] mr-[5px]'} />
						<p className={'text-[#1C75BC] font-[500]'}>Edit Assignment Details</p>
					</button>
				</div>
			) : (
				<div className='flex flex-col px-3 pt-2 pb-4 '>
					<div className='flex flex-row  justify-between w-full items-center'>
						<div className='flex flex-col'>
							<p>Assigned Supplier</p>
							<div className='text-base font-bold'>{supplyPerson?.name || job?.supplier?.name || '---'}</div>
						</div>
						<div className='flex flex-row text-primary-500 cursor-pointer' onClick={() => goBack()}>
							<Icon className='cursor-pointer text-primary-500 mr-2' path={mdiPencil} size={0.7} />
							<p className='text-xs'>Change Supplier</p>
						</div>
					</div>
				</div>
			)}
			{edit ? (
				!(tab === 'lot') ? (
					job?.factorRequest ? (
						<div className='flex flex-col'>
							<div className='w-full h-0 border-b-2 border-dashed border-black-75 my-4' />
							<p className='text-black-200 text-xs font-[300] italic px-6'>Driver information added by broker via application</p>
							<div className=' px-6 pt-2'>
								{RenderSchema([
									{
										type: 'phoneNumber',
										label: 'Driver Mobile Number',
										name: ['phoneNumber'], //disable if it is already assigned
										required: true,
										message: 'Phone Number is Required',
										initialValue: job?.driver?.phoneNumber,
									},
									[
										{
											type: 'vehicleRegistration',
											label: 'Vehicle Number',
											name: ['vehicleRegistration'],
											required: true,
											onChange: handleOnChange,
											className: 'blacklist-loading',
											addonAfter: loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} />} /> : null,
											message: ' Vehicle Number is Required',
											initialValue: job?.vehicle?.registrationNumber,
										},
										{
											type: 'select',
											label: 'Vehicle Type',
											name: ['vehicleType'],
											valueProperty: 'key',
											form: form,
											displayProperty: 'text',
											options: Object.values(VehicleTypeEnums),
											initialValue: job?.vehicleInfo?.vehicleType || 'Trolly-xl20ft',
										},
									],
								])}
							</div>
							{vehicle?.isBlocked && <BlackListedVehicleBanner registrationNumber={vehicle?.registrationNumber} />}
						</div>
					) : (
						<div className='py-5 px-3 my-2'>
							{RenderSchema(EditJobSchema({ job, tab, brokers, onChange: handleOnChange, loading }))}
							{vehicle?.isBlocked && <BlackListedVehicleBanner registrationNumber={vehicle?.registrationNumber} />}
							<div className='flex flex-row w-full justify-between items-center'>
								{FormRender({
									type: 'dateTime',
									className: 'mr-2',
									label: 'Committed Days',
									name: ['committedDays'],
									initialValue: job?.quotations?.[0]?.committedDays ? dayjs(parseInt(job?.quotations?.[0]?.committedDays), 'x') : '',
									required: tab === 'direct' ? true : false,
								})}
								{tab !== 'nonLot' &&
									FormRender({
										type: 'input',
										inputType: 'number',
										itemClassName: 'ml-2',
										placeholder: 'Enter Committed Weight',
										addonAfter: 'Tons',
										label: 'Committed Weight',
										name: ['committedWeight'],
										initialValue: job?.quotations?.[0]?.committedWeight || '',
									})}
							</div>
							<div className='flex flex-row w-full justify-between items-center'>
								<div className='flex flex-col pb-10'>
									{FormRender({
										type: 'number',
										label: 'Cost',
										name: ['cost'],
										required: true,
										placeholder: 'Enter Cost',
										itemClassName: 'w-[290px] mr-4 my-auto',
										initialValue: formWatch?.cost || job?.cost || '',
									})}
								</div>

								<div className='flex flex-col items-end justify-center pb-10'>
									<div className='font-[600] text-[16px] text-black-400'>{`${tab === 'local' ? '---' : 'Rs. 100,000'}`}</div>
									<div className='font-[400] text-xs text-black-300 italic'>Market Quoted Rate</div>
								</div>
							</div>
							{tab === 'local' &&
								FormRender({
									type: 'select',
									label: 'Local Vehicle Type',
									name: ['localVehicleType'],
									initialValue: 'static',
									options: [
										{ key: 'static', label: 'Static' },
										{ key: 'dynamic', label: 'Dynamic' },
									],
									valueProperty: 'key',
									displayProperty: 'label',
									required: tab === 'local' ? true : false,
								})}
						</div>
					)
				) : (
					<>
						{RenderSchema(CreateLotJobSchema({ record, job, tab, suppliers, brokers, form, remainingWeight, onChange: handleOnChange, loading }))}
						{vehicle?.isBlocked && <BlackListedVehicleBanner registrationNumber={vehicle?.registrationNumber} />}
						<div className='flex flex-row w-full justify-between items-center'>
							<div className='flex flex-row items-end'>
								{FormRender({
									type: 'input',
									label: 'Rate Per Ton',
									name: ['rate'],
									required: true,
									itemClassName: 'w-[290px] mr-4 my-auto',
									initialValue:
										formWatch?.rate ||
										(job?.cost && job?.shipmentInfo?.freightWeight ? parseFloat(job?.cost) / parseFloat(job?.shipmentInfo?.freightWeight) : 0).toFixed(2) ||
										'',
								})}
							</div>
						</div>
					</>
				)
			) : (
				<div className='bg-white py-5 px-3 mb-2 flex flex-col h-full w-full overflow-scroll'>
					{[
						{ title: 'Broker Name', value: `${job?.broker?.name || '---'}` },
						{ title: 'Driver Mobile Number', value: `${job?.driver?.phoneNumber || '---'}` },
						{ title: 'Vehicle Number', value: `${job?.vehicle?.registrationNumber || '---'}` },
					].map((obj) => (
						<div className='flex flex-col items-start justify-center pb-10'>
							<div className='font-[600] text-[16px] text-black-400'>{obj?.title}</div>
							<div className='font-[400] text-xs text-black-300 italic'>{obj?.value}</div>
						</div>
					))}
					<div className='flex flex-row justify-between h-full items-end pb-10'>
						<div className='flex flex-col items-end justify-center pb-10'>
							<div className='font-[600] text-[16px] text-black-400'>Cost</div>
							<div className='font-[400] text-xs text-black-300 italic'>{job?.cost || '---'}</div>
						</div>
						<div className='flex flex-col items-end justify-center pb-10'>
							<div className='font-[600] text-[16px] text-black-400'>Rs. ---</div>
							<div className='font-[400] text-xs text-black-300 italic'>Market Quoted Rate</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export const SingleJobPaymentTermsAssignment = ({ edit, jobId, tab, navigator, data, form, formWatch, goBack }) => {
	const { jobs, formData } = data
	const record = jobs?.[0]
	const job = tab === 'lot' ? record?.jobs.find((job) => job?._id === jobId) : record || data?.job
	const { cost = job?.cost, costType } = form.getFieldsValue(true)

	const cumulativeSum =
		formWatch?.paymentTerms?.reduce((acc, term) => {
			if (term) {
				return acc + (term?.amount === '' ? 0 : parseFloat(term?.amount))
			} else {
				return acc
			}
		}, 0) || 0

	return (
		<div className='bg-white px-6 py-6 mb-2 flex flex-col h-full w-full overflow-scroll min-h-[20rem]'>
			<div
				className={`mb-7 justify-center w-full items-center text-center text-sm font-normal py-4 text-black-300 rounded-lg ${
					cumulativeSum == (job?.factoredAmount || costType === 'factored' ? job?.factoredAmount || cost + findSlab(cost, true) : cost)
						? 'bg-green-100'
						: cumulativeSum > (job?.factoredAmount || costType === 'factored' ? job?.factoredAmount || cost + findSlab(cost, true) : cost)
						? 'bg-red-50'
						: 'bg-background'
				}`}
			>
				{cumulativeSum == (job?.factorStatus === 'approved' || costType === 'factored' ? job?.factoredAmount || cost + findSlab(cost, true) : cost) ? (
					<div className='flex flex-row items-center text-[#467D48] font-[600] text-sm justify-center'>
						<Icon size={1} path={mdiCheckCircleOutline} className='mr-4' />
						<p>You have succesfully settled the amount</p>
					</div>
				) : cumulativeSum > (job?.factorStatus === 'approved' || costType === 'factored' ? job?.factoredAmount || cost + findSlab(cost, true) : cost) ? (
					<div className='flex flex-row items-center text-red-400 font-[600] text-sm justify-center'>
						<Icon size={1} path={mdiCloseCircleOutline} className='mr-4' />
						<p>You have exceeded the total amount</p>
					</div>
				) : (
					<p>
						Amount to be settled in payment terms :&nbsp;&nbsp;
						<span className=' text-black-400 font-bold'>Rs.&nbsp;{parseFloat(cost || data?.job?.cost || '0') - cumulativeSum}</span>
					</p>
				)}
			</div>
			{RenderSchema(PaymentTermsSchema({ job, formWatch, cost }))}
		</div>
	)
}

export const BPayAssignmentResult = ({
	jobId,
	navigator,
	tab,
	showResultPage: {
		result: {
			response: {
				0: { data: { updateJob: jobResult = {}, createQuotation: createQuotationResult = {}, updateQuotation: updateQuotationResult = {} } = {} } = {},
			} = [],
		} = {},
	},
	data: { jobs: { 0: { jobNumber, broker, cost, supplier, vehicle } = {} } = {} } = {},
}) => (
	<div className='flex flex-col items-center text-center align-middle justify-center py-[8rem] rounded-lg bg-white'>
		<div className='rounded-full p-5 bg-green-50'>
			<Icon path={mdiCellphoneCheck} size={2.3} className='text-green-400' />
		</div>
		<p className='text-lg text-black-400 font-[600] px-[10rem] py-8'>
			<span className='text-primary-800'>J-{tab === 'local' ? jobResult?.jobNumber : jobNumber}</span> has been successfully&nbsp;
			{tab === 'local' ? 'matched to' : vehicle?.registrationNumber ? 'assigned to' : 'sent to'}
			&nbsp;
			{!(tab === 'local' ? jobResult?.Vehicle : vehicle)?.registrationNumber ? (
				<span
					className='text-primary-800 underline underline-offset-8 cursor-pointer hover:text-primary-500'
					onClick={() => navigator(`/vendorManagement/${broker?._id ? 'brokers/broker' : 'suppliers/supplier'}/${broker?._id || supplier?._id}/action/edit`)}
				>
					{broker?._id ? broker?.name : `Supply Associate ${supplier?.name}`}'s&nbsp;
				</span>
			) : (
				<span className='text-primary-800'>Vehicle #&nbsp;{(tab === 'local' ? jobResult?.Vehicle : vehicle)?.registrationNumber.toUpperCase()}</span>
			)}
			{(tab === 'local' ? jobResult?.Vehicle : vehicle)?.registrationNumber ? '' : 'mobile application'}
		</p>
		<div className='flex flex-col rounded border border-black-200 bg-black-50 p-5'>
			{(tab === 'local' || cost) && (
				<div className='flex flex-row justify-between items-center space-x-8'>
					<div className='flex flex-row'>
						<Icon path={mdiTruckOutline} size={0.8} className='text-black-300 mr-3' />
						<p className='font-[400] text-sm text-black-300'>Vehicle Cost</p>
					</div>
					<p className='font-bold text-sm text-black-400'>{cost || jobResult?.cost}</p>
				</div>
			)}
			{supplier?.name?.includes('BPay') && tab !== 'local' && (
				<div className='flex flex-row justify-between items-center pt-4'>
					<div className='flex flex-row'>
						<div className='rounded-full border-2 border-black-300 p-[1px] mr-3'>
							<Icon path={mdiLightningBolt} className='text-black-300' size={0.6} />
						</div>
						<p className='font-[400] text-sm text-black-300'>BPay Rate</p>
					</div>
					<p className='font-bold text-sm text-black-400'>{findSlab(cost || 0)}</p>
				</div>
			)}
		</div>
	</div>
)
/* ============================================================================================== */
/* ===================================== BULK ASSIGNMENT ======================================== */
/* ============================================================================================== */

/* ================================== FIRST PAGE LANE RATES ===================================== */

export const ToggleEditItem = ({ lane, value, onChange, form, rate }) => {
	const toggleEditable = useCallback((type) => {
		if (type === 'edit') {
			onChange?.(true)
			rate.current[lane._id] = form.getFieldValue(['laneRates', lane?._id, 'cost'])
		} else if (type === 'close') {
			onChange?.(false)
			form.setFieldValue(['laneRates', lane?._id, 'cost'], rate.current[lane._id])
		} else if (type === 'confirm') {
			onChange?.(false)
		}
	})
	return (
		<div className='flex flex-row items-center ml-[20px]'>
			{value && <Icon className='mr-[20px] cursor-pointer' path={mdiClose} size={1} onClick={() => toggleEditable('close')} />}
			{value && <Icon className='cursor-pointer' path={mdiCheck} size={1} onClick={() => toggleEditable('confirm')} />}
			{!value && <Icon className='cursor-pointer' path={mdiPencil} size={1} onClick={() => toggleEditable('edit')} />}
		</div>
	)
}

const LaneRateItem = ({ laneData = {}, form, formWatch, rate, jobsTotal }) => {
	const { superLane, lane, cost } = laneData

	return (
		<div className='flex flex-row justify-between items-center px-4 border-y-[0.5px] h-[50px]'>
			<div className='px-2'>
				<div className={`text-black-400 ${superLane ? 'text-[16px] font-[600]' : 'text-sm font-[400]'}`}>{`${lane?.from} --> ${lane?.to}`}</div>
				{superLane ? (
					<div>
						Total <span className='font-bold'>{jobsTotal}</span> Jobs
					</div>
				) : (
					<div>
						<span className='font-bold'>{jobsTotal}</span> jobs
					</div>
				)}
			</div>
			<div className='text-[16px] font-[500] text-black-300 flex flex-row items-center '>
				{formWatch?.[lane?._id]?.editable || true ? (
					<div className='flex flex-row items-center'>
						<div className='font-bold'>Rs.&nbsp;</div>
						{FormRender({
							name: ['laneRates', `${lane?._id}`, 'cost'],
							type: 'number',
							key: `${lane?._id}`,
							initialValue: cost,
							itemClassName: 'my-auto',
							elementClassName: 'h-[40px] rounded-lg',
						})}
					</div>
				) : (
					<Form.Item name={['laneRates', `${lane?._id}`, 'cost']} initialValue={cost} noStyle={true}>
						<div className='font-bold'>{`Rs. ${formWatch?.[lane?._id]?.cost}`}</div>
					</Form.Item>
				)}
				{/* <Form.Item name={['laneRates', `${lane?._id}`, 'editable']} initialValue={false} noStyle={true}>
					<ToggleEditItem form={form} lane={lane} rate={rate} />
				</Form.Item> */}
			</div>
		</div>
	)
}

export const AssignLaneRates = ({ form, data, formWatch, modalRefState }) => {
	const { filterData, formData, jobs } = data
	const { lanes } = formData
	const { lane, vehicleType, weight } = filterData
	const selectedLaneId = lane?.values && Object.entries(lane?.values)?.filter((lane) => lane[1])?.[0]?.[0]
	const selectedWeightRange = `${weight?.values?.low}-${weight?.values?.high}`
	const rate = modalRefState
	const filtersLane = (jobs) => {
		let results = []
		const orderLocations = jobs?.map((job) => job?.orderLocations)
		orderLocations?.forEach((orderLocation) => {
			const obj = {}
			orderLocation.forEach((lane) => {
				if (lane?.locationType === 'loading') {
					obj['from'] = lane?.Location?.Region?.name
				} else if (lane?.locationType === 'dropoff') {
					obj['to'] = lane?.Location?.Region?.name
				}
			})

			results.push(obj)
		})
		return results
	}
	const countJobsOccuringOnSameLane = (lanes) => {
		let uniqueLanes = new Set()
		const totalJobsPerLane = {}
		lanes?.forEach((lane) => {
			let key = `${lane?.from}-${lane?.to}`
			if (uniqueLanes.has(key)) {
				totalJobsPerLane[key].count += 1
			} else {
				uniqueLanes.add(key)
				totalJobsPerLane[key] = {
					from: lane?.from,
					to: lane?.to,
					count: 1,
				}
			}
		})
		return totalJobsPerLane
	}
	const filterLanesandJobs = countJobsOccuringOnSameLane(filtersLane(jobs))
	return (
		<Layout>
			<Header className='flex flex-row justify-between px-0'>
				<div className='flex flex-row h-[58px] bg-secondaryBackground w-full items-center justify-between px-6'>
					<span className='flex flex-row'>
						<div className='text-black-300 text-sm font-[400]'>Rate for Sub-Lanes of&nbsp;</div>
						<div className='text-black-300 text-sm font-[600]'>{`${lanes?.[selectedLaneId]?.superLane?.from} --> ${lanes[selectedLaneId]?.superLane?.to}`}</div>
					</span>
					<HeaderPill
						bgClassName='bg-background'
						textClassName='text-primary-800'
						borderColorClass='border border-primary-200'
						label={`${selectedWeightRange} Tons`}
					/>
				</div>
			</Header>
			<Content className='flex flex-col h-[380px] transition-[height] bg-secondaryBackground duration-[50ms]'>
				<div className='bg-white h-fit'>
					<LaneRateItem
						rate={rate}
						jobsTotal={jobs?.length}
						form={form}
						formWatch={formWatch}
						laneData={{
							superLane: true,
							lane: lanes[selectedLaneId]?.superLane,
							cost: lanes[selectedLaneId]?.superLane?.laneRates?.[selectedWeightRange]?.rate || '0',
						}}
					/>

					{lanes[selectedLaneId]?.subLanes
						?.filter((lane) => filterLanesandJobs.hasOwnProperty(`${lane?.from}-${lane?.to}`))
						.map((lane) => {
							return (
								<LaneRateItem
									rate={rate}
									jobsTotal={filterLanesandJobs[`${lane?.from}-${lane?.to}`]?.count}
									form={form}
									formWatch={formWatch}
									laneData={{ lane: lane, cost: lane?.laneRates?.[selectedWeightRange]?.rate || '0' }}
								/>
							)
						})}
				</div>
			</Content>
		</Layout>
	)
}

/* ================================== SECOND PAGE ASSIGN SUPPLIERS ==================================== */

// SUB HEADER
export const AssignBulkSupplierHeader = ({ formWatch, data }) => {
	const { jobs, filterData, formData } = data
	const { lanes } = formData
	const { lane, vehicleType, weight } = filterData
	const selectedLaneId = lane?.values && Object.entries(lane?.values)?.filter((lane) => lane[1])?.[0]?.[0]
	const selectedWeightRange = `${weight?.values?.low}-${weight?.values?.high}`
	const selectedVehicleType = vehicleType?.values && Object.entries(vehicleType?.values)?.filter((vehicleType) => vehicleType[1])?.[0]?.[0]
	const totalVehicles = jobs?.length
	const currVehicles = formWatch && Object.values(formWatch).reduce((total, curr) => total + parseInt(curr?.numVehicles || '0'), 0)

	const bgClassName =
		currVehicles > totalVehicles || currVehicles < 0 ? 'bg-red-100' : currVehicles === totalVehicles ? 'bg-green-100' : 'bg-background'
	const textClassName =
		currVehicles > totalVehicles || currVehicles < 0 ? ' text-red-400' : currVehicles === totalVehicles ? 'text-green-400' : 'text-primary-800'
	const borderColorClass =
		'border ' +
		(currVehicles > totalVehicles || currVehicles < 0 ? 'border-red-400' : currVehicles === totalVehicles ? 'border-green' : 'border-primary-200')

	return (
		<div className='flex flex-row h-[84px] bg-secondaryBackground justify-between p-4 items-center'>
			<div className='flex flex-row h-full divide-x-[1px] divide-black-100'>
				<div className='flex flex-col mr-4'>
					<p className='font-[600] text-[16px] text-black-400 pb-3'>{VehicleTypeEnums[selectedVehicleType]?.long}</p>
					<p className='font-[600] text-sm text-black-300'>{`${lanes[selectedLaneId]?.superLane?.from} --> ${lanes[selectedLaneId]?.superLane?.to}`}</p>
				</div>
				<div className='flex flex-col pl-4'>
					<p className='font-[500] text-xs text-black-200 pb-3'>WEIGHT RANGE</p>
					<div className='flex flex-row font-[600] text-sm text-black-300 items-center'>
						<Icon path={mdiPackageVariantClosed} size={1} />
						<p className='pl-2'>{`${selectedWeightRange} Tons`}</p>
					</div>
				</div>
			</div>

			<HeaderPill
				bgClassName={bgClassName}
				textClassName={textClassName}
				borderColorClass={borderColorClass}
				label={`${currVehicles ? (currVehicles > totalVehicles || currVehicles < 0 ? '?? ' : currVehicles) : '-- '}/${totalVehicles} Vehicles`}
			/>
		</div>
	)
}

// SUPPLY ASSOCIATES SEARCH TABLE
export const SupplierSelectionTable = ({ form, formWatch, data }) => {
	const { formData, jobs } = data
	const { suppliers } = formData
	const totalVehicles = jobs?.length
	const currVehicles = (formWatch && Object.values(formWatch).reduce((total, curr) => total + parseInt(curr?.numVehicles || '0'), 0)) || 0
	return (
		<Layout>
			<Content className='flex flex-col h-[354px] transition-[height]'>
				<div className='bg-white flex flex-col h-fit w-full '>
					<div className='h-[40px] border-b my-auto flex flex-row items-center pl-4 font-[600] text-sm text-black-400'>
						Select Supply Associates For Assignment
					</div>
				</div>
				<DynamicTable
					hideHeader={true}
					loading={false}
					onSelect={(selectedKeys) => {
						suppliers.forEach((row) => {
							form.setFieldValue(['suppliers', `${row?._id}`, 'selected'], selectedKeys.includes(row?._id))
							if (!selectedKeys.includes(row?._id)) {
								form.setFieldValue(['suppliers', `${row?._id}`, 'numVehicles'], '0')
							}
						})
						form.validateFields()
					}}
					columns={assignSupplierColumns}
					columnProps={{ suppliers: formWatch, totalVehicles, currVehicles }}
					data={Object.assign([], suppliers).sort((supplier1, supplier2) => formWatch?.[supplier2._id]?.selected - formWatch?.[supplier1._id]?.selected)}
				/>
			</Content>
		</Layout>
	)
}

// SELECTED SUPPLY ASSOCIATES COLUMN
export const SelectedSupplierColumn = ({ formWatch, data }) => {
	const { formData } = data
	const { suppliers } = formData
	const selectedSuppliers = suppliers?.filter((row) => formWatch?.[row?._id]?.selected)
	return (
		<div className='flex flex-col bg-white border-l h-full '>
			<div className='bg-white h-[40px] flex flex-row items-center w-full border-b pl-4 font-[600] text-sm text-black-400'>Selected Supply Associates</div>
			<div className='flex-1 flex-col h-max overflow-y-scroll'>
				{selectedSuppliers?.map((supplier) =>
					SelectedSupplierCard({ name: supplier?.name, phoneNumber: supplier?.phoneNumber, numVehicles: formWatch[supplier?._id].numVehicles || '0' })
				)}
			</div>
		</div>
	)
}

export const SelectedSupplierCard = ({ name, phoneNumber, numVehicles }) => (
	<div className='mx-4 my-4 py-2 px-3 bg-background flex flex-row justify-between rounded-lg'>
		<div className='flex flex-col items-start'>
			<div className='font-bold text-sm text-black-400'>{name}</div>
			<div className='font-[500] text-sm text-black-300'>{phoneNumber}</div>
		</div>
		<div className='my-auto bg-primary-800 rounded-[84px] px-2 py-[0.5px]'>
			<div className='font-[600] text-[16px] text-white'>{numVehicles}</div>
		</div>
	</div>
)

/* ==================================== VENDOR MANAGEMENT ==================================== */

export const AddEditVendor = ({ data, type, edit }) => {
	return <div className='mx-6 mt-6 mb-8'>{RenderSchema(AddEditVendorScehma({ data, type, edit }))}</div>
}
export const DeleteVendor = ({ data }) => {
	return (
		<div className=' flex flex-col items-center justify-center bg-white py-7 px-10'>
			<Icon path={mdiAlertCircleOutline} size={3} className='text-red-400 ' />
			<div className='text-black-300 font-bold text-[16px] pt-6 text-center'>Are You Sure You Want To Delete This Broker ?</div>
		</div>
	)
}

/* ======================================== SPLIT JOB  ======================================= */

export const SplitJobModal = ({ data, segment, formWatch: numJobs, modalRefState }) => {
	const { jobs } = data
	const record = jobs?.[0]
	const transformedJobs = useMemo(() => {
		if (numJobs && numJobs > 0) {
			const containerCopy = record?.containerInfo?.slice()
			const finalJobs = Array.from(Array(parseInt(numJobs)), (item) => [])
			let i = 0
			while (containerCopy?.length) {
				finalJobs[i % numJobs].push(containerCopy?.pop())
				i++
			}
			return finalJobs
		} else {
			return []
		}
	}, [numJobs])

	useEffect(() => {
		const newHeight = 60 * numJobs + 32
		document.getElementById('expanding-container').style.height = (newHeight > 328 ? 328 : newHeight) + 'px'
		document.getElementById('expanding-container').style.justifyContent = numJobs <= 1 ? 'center' : 'flex-start'
	}, [numJobs])

	return (
		<div className='flex flex-col'>
			<div className='bg-white py-2 px-5 flex flex-row items-center border-b border-black-75'>
				<p className='text-black-400 font-[400] pr-2'>Split into</p>

				{FormRender({
					type: 'input',
					inputType: 'number',
					itemClassName: '!mb-0 mx-2',
					elementClassName: 'my-1 rounded text-center',
					name: 'numJobs',
					initialValue: '2',
					minValue: 2,
					maxValue: record?.containerInfo?.length,

					required: true,
				})}
				<p className='text-black-400 font-[400] pl-2'>Jobs</p>
			</div>
			<div className='py-4 px-6 flex flex-row max-h-[360px]'>
				<div className='basis-2/5 text-center align-middle my-auto'>
					<div className='bg-white rounded-lg p-4 my-auto flex flex-col justify-center border border-black-75'>
						<div className='flex flex-row items-center'>
							<p className='font-bold text-sm text-black-400 pb-1'>{`J-${record?.jobNumber}`}</p>
							<div className='bg-background rounded-lg w-fit px-2 py-[2px] ml-4'>
								<p className='font-[500] text-sm text-primary-800'>{`${record?.containerInfo?.length} Container${record?.containerInfo?.length > 1 ? 's' : ''}`}</p>
							</div>
						</div>
						<div className='flex flex-row text-xs pt-4 items-start'>
							<div className='flex flex-row pr-4 items-center'>
								<Icon className='mr-2' path={mdiTruckOutline} size={0.8} color='#9F9F9F' />
								<div className='font-[600]'>{`${
									((VehicleTypeEnums[record?.vehicleType] || VehicleTypeEnums[Capitalize(record?.vehicleType)])?.text || 'N/A') +
									(record?.subVehicleType ? '-' + record?.subVehicleType : '')
								}`}</div>
							</div>

							<div className='flex flex-row items-center '>
								<Icon className='mr-2' path={mdiPackageVariantClosed} size={0.8} color='#9F9F9F' />
								<div className='font-[600] overflow-ellipsis'>{`${
									segment === 'longHaul'
										? record?.shipmentInfo?.freightWeight
										: record?.containerInfo?.[0]?.containerWeight && record?.containerInfo?.[0]?.containerWeight !== 'NaN'
										? record?.containerInfo?.reduce((totalWeight, container) => (parseFloat(totalWeight) + parseFloat(container?.containerWeight)).toString(), '0')
										: '---'
								} Tons`}</div>
							</div>
						</div>
					</div>
				</div>
				<div className='basis-1/5 my-auto text-center align-middle mx-auto'>
					<div className='rounded-full p-3  bg-black-75 w-fit h-fit mx-auto text-center align-middle'>
						<Icon path={mdiArrowRight} className='text-black-300' size={1.2} />
					</div>
				</div>
				<div className='basis-2/5 text-center align-middle'>
					<div className='flex flex-col w-full overflow-y-scroll max-h-[340px] transition-[height] duration-500' id='expanding-container'>
						{transformedJobs?.map((newJob, index) => (
							<div className='bg-white rounded-lg p-4 flex flex-col my-2 border border-black-75'>
								<div className='flex flex-row items-center'>
									<p className='font-bold text-sm text-black-400 pb-1'>{`Job ${index + 1}`}</p>
									<div className='bg-background rounded-lg w-fit px-2 py-[2px] ml-4'>
										<p className='font-[500] text-sm text-primary-800'>{`${newJob?.length} Container${newJob?.length > 1 ? 's' : ''}`}</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

/* =================================== FINISH LOT ASSIGNMENT ================================= */

export const FinishLotAssignment = ({ data }) => {
	return (
		<div className=' flex flex-col items-center justify-center bg-white py-5 px-8'>
			<Icon path={mdiAlertCircleOutline} size={3} className='text-red-400' />
			<div className='text-black-300 font-bold text-[16px] pt-6 text-center'>Are You Sure You Want To Finish Lot Assignment?</div>
		</div>
	)
}
