import {
	ContainerDimensionsEnum,
	CostsEnums,
	NewChargeStatusEnums,
	PaymentPointEnums,
	PaymentStatusEnums,
	VehicleTypeEnums,
} from '../../helpers/Enums'
import { Drawer, Button, Tooltip, Form, Breadcrumb, Checkbox, Steps, Radio, Spin, Progress, notification, Tag } from 'antd'
import {
	mdiTruckOutline,
	mdiPackageVariantClosed,
	mdiPencil,
	mdiReceiptTextCheckOutline,
	mdiReceiptTextPlusOutline,
	mdiPlus,
	mdiFileDocumentAlertOutline,
	mdiLinkVariant,
	mdiChevronLeft,
	mdiChevronDown,
	mdiFileDocument,
	mdiTextBoxOutline,
	mdiPhone,
	mdiClockCheck,
	mdiClockOutline,
	mdiMenuDown,
	mdiDotsVertical,
	mdiCheck,
	mdiHistory,
	mdiOpenInNew,
} from '@mdi/js'
import Icon from '@mdi/react'
import { Outlet, useNavigate, Link, useParams, useLocation, useOutletContext } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
	amountTotalsSelector,
	biltyInvestorManagementSelector,
	chargeTypesForFormSelector,
	formatName,
	formatPhoneNumber,
	jobChargeSelector,
	jobChargesByStatusSelector,
	jobRevenueSelector,
	jobRevenuesByStatusSelector,
	postAssignmentDataSelector,
	postAssignmentJobByIDSelector,
	postAssignmentSidebarSelector,
} from '../../recoil/selectors'
import { useFetch } from '../../hooks/graphql'
import { useMutate } from '../../hooks/graphql'
import { GraphQLEnums } from '../../helpers/Enums'
import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Capitalize, checkArray, checkPatternMatch, isSuperUser, removeTypeName, toReadableAmount, toReadableString } from '../../helpers/Functions'
import { biltyInvestorsAtom, chargeTypesAtom, revenueTypesAtom, superUserAtom, supplyAdminAtom, thirdPartiesAtom, userAtom } from '../../recoil/atoms'
import moment from 'moment'
import { FormRender, RenderSchema } from '../../helpers/FormRenderer'
import { CustomSwitchWrapper } from '../utility/Utility'
import { Timeline } from '@bridgelinx-technologies/fastlane'

const { Step } = Steps

// const AssignmentDetailsCard = (job = {}, loading) => (
// 	<div className='flex flex-col bg-white mx-6 mt-3 mb-4 rounded-[4px] shadow-[0_0_4px_rgba(0,0,0,0.21)]'>
// 		<div className='flex flex-col items-start px-6 py-4'>
// 			<div className='flex flex-row w-full text-sm py-1 '>
// 				<div className='font-[400] text-black-300 basis-1/2'>Supplier Associate</div>
// 				<div className='font-[600] text-black-400 overflow-ellipsis basis-1/2'>{job?.supplier?.name?.length > 0 ? job?.supplier?.name : '---'}</div>
// 			</div>
// 			<div className='flex flex-row w-full text-sm py-1 '>
// 				<div className='font-[400] text-black-300 basis-1/2'>Phone Number</div>
// 				<div className='font-[600] text-black-400 overflow-ellipsis basis-1/2'>{job?.driverPhone || '---'}</div>
// 			</div>
// 			<div className='flex flex-row w-full text-sm py-1 '>
// 				<div className='font-[400] text-black-300 basis-1/2'>Vehicle Number</div>
// 				<div className='font-[600] text-black-400 overflow-ellipsis basis-1/2'>{job?.driverVehicleRegistration || '---'}</div>
// 			</div>
// 			<div className='flex flex-col bg-white mt-3  rounded-md border border-black-75  w-full'>
// 				<div className='flex flex-row items-center rounded-t-md bg-black-50 justify-between py-2 w-full text-sm px-3 border-b border-black-75 mb-2'>
// 					<div className='font-[600] text-black-300  '>Broker Information</div>
// 					{job?.broker?._id && (
// 						<Link to={`/vendorManagement/brokers/broker/${job?.broker?._id}/action/edit`} className={loading ? 'no-hover' : ''}>
// 							<div className='flex flex-row items-center bg-black-50  border-l  border-black-100 pl-4 py-1 '>
// 								<div className='font-[600] text-primary-800 overflow-ellipsis pr-2'>View Details</div>

// 								<Icon path={mdiLinkVariant} size={0.8} className={(loading ? 'text-white' : 'text-[#1C75BC]') + ' '} />
// 							</div>
// 						</Link>
// 					)}
// 				</div>
// 				<div className='flex flex-row items-center w-full text-sm px-3'>
// 					<div className='font-[400] text-black-300 basis-1/2'>Broker Company</div>
// 					<div className='flex flex-row justify-between items-center basis-1/2 text-sm py-1 '>
// 						<div className='font-[600] text-black-400 overflow-ellipsis pr-2'>{job?.broker?.CompanyName?.length > 0 ? job?.broker?.CompanyName : '---'}</div>
// 					</div>
// 				</div>
// 				<div className='flex flex-row w-full text-sm px-3 pt-1 pb-3'>
// 					<div className='font-[400] text-black-300 basis-1/2'>Phone Number</div>
// 					<div className='font-[600] text-black-400 overflow-ellipsis basis-1/2'>{job?.broker?.phoneNumber?.length > 0 ? job?.broker?.phoneNumber : '---'}</div>
// 				</div>
// 			</div>
// 		</div>
// 		{!['Order Completed']?.includes(job?.orderStatus) && (
// 			<Link to={`edit/assignment`} className={loading ? 'no-hover' : ''}>
// 				<div className={(loading ? 'bg-black-50' : 'cursor-pointer bg-[#EDF7FF]') + ' flex flex-row justify-center items-center p-[10px] '}>
// 					<Icon path={mdiPencil} size={0.8} className={(loading ? 'text-white' : 'text-[#1C75BC]') + ' mr-[5px]'} />
// 					<p className={(loading ? 'text-white' : 'text-[#1C75BC]') + ' font-[500]'}>Edit Assignment Details</p>
// 				</div>
// 			</Link>
// 		)}
// 	</div>
// )

const PaymentDetailsCard = (job = {}, superUser, supplyAdmin, dataLoading) => {
	const pendingStatuses = ['pendingFreightCostApproval', 'pendingApproval']
	const paymentStatus = job?.vendorPayments?.paymentStatus || 'pendingFreightCostApproval'
	const { factorRequest, factorStatus, factoredAmount } = job
	const [updateJob, loading] = useMutate({ type: 'Jobs', action: 'update' })

	let totalCost = 0
	const freightCost = (job?.cost && parseFloat(job?.cost)) || 0
	const hasPaymentTerms = job?.paymentTerms && job?.paymentTerms?.length > 0
	const BreakdownCopy = job?.vendorPayments?.costBreakdown && JSON.parse(JSON.stringify(job?.vendorPayments?.costBreakdown))
	BreakdownCopy && delete BreakdownCopy.beneficiary
	BreakdownCopy && delete BreakdownCopy.__typename
	BreakdownCopy && delete BreakdownCopy.supplyComments
	BreakdownCopy && delete BreakdownCopy._id
	BreakdownCopy && delete BreakdownCopy.deductions
	BreakdownCopy && delete BreakdownCopy.munshianaReceived
	totalCost = (BreakdownCopy && Object.values(BreakdownCopy).reduce((a, b) => a + b, 0)) || 0
	totalCost -= job?.vendorPayments?.costBreakdown?.deductions ? parseFloat(job?.vendorPayments?.costBreakdown?.deductions) : 0
	totalCost -= job?.vendorPayments?.costBreakdown?.munshianaReceived ? parseFloat(job?.vendorPayments?.costBreakdown?.munshianaReceived) : 0

	const bpayCost = totalCost + freightCost - (factorStatus === 'approved' ? parseFloat(factoredAmount) : totalCost)
	const costs = [
		{ key: 'cost', label: 'Freight Cost', value: job?.cost },
		...CostsEnums.map((cost) => ({ key: cost.key, label: cost.label, value: job?.vendorPayments?.costBreakdown?.[cost.key] })),
		...(factorStatus === 'approved' ? [{ key: 'bpayRate', label: 'BPay Rate', value: bpayCost }] : []),
	]
	const oldVendorPayments = {
		_id: job?.vendorPayments?._id,
		costBreakdown: removeTypeName(job?.vendorPayments?.costBreakdown),
	}
	const confirmPayment = useCallback(
		async (type) => {
			const approvalVariables = {
				[`${GraphQLEnums['Jobs'].update.createKey}`]: {
					_id: job?._id,
					vendorPayments: {
						...oldVendorPayments,
						paymentStatus: type === 'freight' ? 'pendingApproval' : 'pendingPayment',
					},
				},
			}
			return await updateJob({ variables: approvalVariables })
		},
		[oldVendorPayments]
	)
	return (
		<div className='flex flex-col bg-white mx-6 mt-3 mb-4 rounded-[4px] shadow-[0_0_4px_rgba(0,0,0,0.21)]'>
			<div className='bg-background px-6 py-3 flex flex-row w-full align-middle'>
				<div className='font-[400] text-black-300 text-sm basis-1/2 my-auto'>Total Payable Amount</div>
				<div className='font-[600] text-black-400 text-[18px] pl-10 overflow-ellipsis basis-1/2'>
					Rs {totalCost + freightCost - (factorStatus === 'approved' ? bpayCost : 0) || '---'}
				</div>
			</div>
			{PaymentStatusEnums[paymentStatus] && (
				<div className={`${PaymentStatusEnums[paymentStatus]?.background} px-6 py-2 flex flex-row w-full align-middle`}>
					<Icon path={PaymentStatusEnums[paymentStatus]?.icon} size={0.7} className={`text-${PaymentStatusEnums[paymentStatus]?.otherTextColor} mr-2`} />
					<div className={`my-auto font-[500] text-${PaymentStatusEnums[paymentStatus]?.otherTextColor} text-[12px] overflow-ellipsis`}>
						{PaymentStatusEnums[paymentStatus]?.text}
					</div>
				</div>
			)}
			<div className='flex flex-col items-start px-6 py-4'>
				{costs.map((cost) => (
					<div key={cost.key} className='flex flex-row text-sm py-1 w-full'>
						<div className='font-[400] text-black-300 basis-1/2'>{cost.label}</div>
						<div className='font-[600] text-black-400 pl-10 overflow-ellipsis basis-1/2'>Rs {cost.value || '---'}</div>
					</div>
				))}
			</div>
			{paymentStatus === 'pendingFreightCostApproval' && (superUser || supplyAdmin) && (
				<Tooltip
					title={
						freightCost <= 0
							? 'Please Enter Freight Cost to Authenticate'
							: !hasPaymentTerms && factorStatus !== 'approved'
							? 'Please Add Payment Terms to Authenticate'
							: !job?.broker?._id
							? 'Please Add Broker to Authenticate'
							: ''
					}
				>
					<div className=' mx-6'>
						<Button
							className='btn-submit flex flex-row justify-center p-[10px] mb-4 w-full'
							disabled={freightCost <= 0 || (!hasPaymentTerms && factorStatus !== 'approved') || !job?.broker?._id || !job?.supplier?._id || dataLoading}
							loading={loading}
							onClick={() => confirmPayment('freight')}
						>
							<Icon path={mdiReceiptTextCheckOutline} size={0.8} className='text-white mr-2' />
							<p className='text-white font-[700] text-sm'>Authenticate Freight Cost</p>
						</Button>
					</div>
				</Tooltip>
			)}
			{paymentStatus === 'pendingApproval' && superUser && (
				<Button
					className='btn-submit flex flex-row justify-center mx-6 p-[10px] mb-4'
					disabled={dataLoading}
					loading={loading}
					onClick={() => confirmPayment('all')}
				>
					<Icon path={mdiReceiptTextCheckOutline} size={0.8} className='text-white mr-2' />
					<p className='text-white font-[700] text-sm'>Approve Payment</p>
				</Button>
			)}
			<button disabled={dataLoading}>
				<Link to={`edit/payments`} className={dataLoading ? 'no-hover' : ''}>
					<div
						className={
							(dataLoading ? 'border-black-50 bg-black-50' : 'cursor-pointer border-primary-800 bg-[#EDF7FF]') +
							' flex flex-row justify-center rounded-[4px]  mx-6 mb-4 items-center p-[10px]  border'
						}
					>
						<Icon
							path={pendingStatuses.includes(paymentStatus) ? mdiPencil : mdiReceiptTextPlusOutline}
							size={0.8}
							className={dataLoading ? 'text-white' : 'text-[#1C75BC]' + ' mr-2'}
						/>
						<p className={(dataLoading ? 'text-white' : 'text-primary-800 ') + ' font-[700] text-sm'}>
							{pendingStatuses.includes(paymentStatus) ? 'Manage Charges' : 'Add Missing Charges'}
						</p>
					</div>
				</Link>
			</button>
		</div>
	)
}

const ContainerDetailsCard = (job, segment, tab) => (
	<div className='mt-6 p-[10px] bg-[#FFFFFF] rounded-lg'>
		<div className='flex flex-col w-full pl-[8px]'>
			{job?.containerInfo?.containerNumber?.map(
				(containerNumber, index) =>
					segment === 'import' && (
						<div className='flex flex-row text-sm py-1' key={`${job?._id}-${index}`}>
							<div className='font-[400] text-black-300'>Container #:&nbsp;</div>
							<div className='font-[600] text-black-400'>{containerNumber}</div>
						</div>
					)
			)}
			<div className='flex flex-row text-sm py-1 items-start'>
				<div className='flex flex-row pr-4 items-center'>
					<Icon className='mr-2' path={mdiTruckOutline} size={1} color='#9F9F9F' />
					{segment === 'longHaul' ? (
						<div className='font-[600]'>{`${
							(VehicleTypeEnums[job?.vehicleInfo?.vehicleType] || VehicleTypeEnums[Capitalize(job?.vehicleInfo?.vehicleType)])?.text +
							(job?.vehicleInfo?.subVehicleType ? '-' + job?.vehicleInfo?.subVehicleType : '')
						}`}</div>
					) : (
						<div className='font-[600]'>
							{job?.containerInfo?.containerNumber?.length > 1 &&
							job?.containerInfo?.containerDimensions === 'xl20ft' &&
							!['ImportShifting', 'ExportShifting'].includes(job?.movementType)
								? VehicleTypeEnums['xl2020ft']?.text
								: VehicleTypeEnums[job?.containerInfo?.containerDimensions]?.text}
						</div>
					)}
				</div>

				<div className='flex flex-row items-center '>
					<Icon className='mr-2' path={mdiPackageVariantClosed} size={1} color='#9F9F9F' />
					<div className='font-[600]'>{`${
						job?.containerInfo?.containerWeight?.toFixed(2) || (job?.shipmentInfo?.freightWeight && parseFloat(job?.shipmentInfo?.freightWeight)?.toFixed(2))
					} Tons`}</div>
				</div>
			</div>
		</div>
	</div>
)

const PaymentTermCard = (job = {}, loading) => {
	const { paymentTerms, factorRequest, factorStatus, factoredAmount } = job
	const BPayTerms = [
		{
			_id: 'bpayTerm',
			amount: factoredAmount,
			days: 0,

			journeyPoint: 'completed',
			paymentType: 'IBFT',
		},
	]

	return (
		<div className='flex flex-col bg-white mx-6 mt-3 mb-4 rounded-[4px] shadow-[0_0_4px_rgba(0,0,0,0.21)]'>
			{paymentTerms?.length > 0 || factorStatus === 'approved' ? (
				(paymentTerms?.length > 0 ? paymentTerms : BPayTerms)?.map((item, index) => (
					<div className={`flex flex-row pl-5 pr-6 py-5 justify-between ${index + 1 < paymentTerms.length ? ' border-b border-black-200 ' : ''} w-full`}>
						<div className='flex flex-col'>
							<div className='font-[400] text-sm text-black-300'>{item?.days} days</div>
							<div className='text-black-400 text-sm font-[600] pb-2'>{PaymentPointEnums[item?.journeyPoint]?.label}</div>
							<div className='flex flex-row items-center rounded-[6px] p-1 h-[2em] font-[400] !text-[#676767] bg-[#F5F5F5]'>
								<div className='mr-2'>
									<svg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<path d='M20 0H11V2H20V0Z' fill='#676767' />
										<path d='M20 8H11V10H20V8Z' fill='#676767' />
										<path d='M20 4H14V6H20V4Z' fill='#676767' />
										<path d='M11 5L6 0V4H0V6H6V10L11 5Z' fill='#676767' />
									</svg>
								</div>
								<div>{item?.paymentType}</div>
							</div>
						</div>
						<div className='flex flex-row items-center justify-end h-auto w-[50%] font-bold text-[#676767]'>Rs. {item?.amount}</div>
					</div>
				))
			) : (
				<div className='flex flex-col items-center justify-center py-5'>
					<div className='rounded-full bg-red-50 p-6'>
						<Icon path={mdiFileDocumentAlertOutline} className='text-red-400' size={2} />
					</div>
					<div className='pt-3 text-base font-semibold'>Payment Terms Missing</div>
					<div className='py-2 text-xs font-light'>
						Amount to be settled in payment terms : <span className='font-semibold'>Rs. {job?.cost || 0}</span>
					</div>
					<Tooltip title={!(job?.cost && job?.cost > 0) ? 'Please Enter Freight Cost To Add Payment Terms' : ''}>
						<Link to={`edit/paymentTerms`} className={loading ? 'no-hover' : ''}>
							<div
								className={`flex flex-row items-center rounded-[4px] py-2 px-3 text-sm font-[500] ${
									job?.cost && job?.cost > 0 ? ' text-primary-800 bg-background cursor-pointer ' : 'text-black-75 bg-black-50 cursor-not-allowed'
								} my-2`}
								disabled={loading || !(job?.cost && job?.cost > 0)}
							>
								<Icon path={mdiPlus} size={1} className='mr-2' />
								<span>Add Payment Terms </span>
							</div>
						</Link>
					</Tooltip>
				</div>
			)}
		</div>
	)
}

const SalmanGulRequestCard = (job = {}, loading) => {
	const [fileLoading, setFileLoading] = useState(false)
	const [form] = Form.useForm()
	const [updateJob, jobLoading] = useMutate({ type: 'Jobs', action: 'update' })
	const [updateBilty, biltyLoading] = useMutate({ type: 'Bilties', action: 'update' })

	const addOtherCharges = useCallback(async () => {
		const variables = form.getFieldsValue(null)
		const jobVariables = {
			[`${GraphQLEnums['Jobs'].update.createKey}`]: {
				_id: job?._id,
				extraWeightChargeReason: variables?.extraWeightChargeReason,
			},
		}
		const biltyVariables = {
			[`${GraphQLEnums['Bilties'].update.createKey}`]: {
				bilties: [
					{
						_id: job?.bilty?._id,
						loloDocument: [variables?.loloDocument?.[0]?.url],
					},
				],
			},
		}
		let mutations = []
		if (variables?.extraWeightChargeReason) {
			mutations.push(updateJob({ variables: jobVariables }))
		}
		if (variables?.loloDocument?.[0]?.url) {
			mutations.push(updateBilty({ variables: biltyVariables }))
		}

		return await Promise.all(mutations)
	}, [])

	return (
		<div className='flex flex-col bg-white mx-6 mt-3 mb-4 rounded-[4px] shadow-[0_0_4px_rgba(0,0,0,0.21)]'>
			<div className='px-6 pt-4'>
				<Form form={form} layout='vertical' colon={false}>
					{RenderSchema([
						{
							type: 'upload',
							uploadType: 'button',
							label: 'Lolo Receipt',
							name: 'loloDocument',
							initialValue: (job?.bilty?.loloDocument || [])?.map((file) => ({ url: file, name: 'Lolo Receipt' })),
							uploadMessage: 'UPLOAD LOLO RECEIPT',
							uploadLink: job?._id,
							setLoading: setFileLoading,
							collection: 'import_export',
							required: true,
							message: 'Lolo Document is required',
						},

						...(job?.vendorPayments?.costBreakdown?.extraWeight
							? [
									{
										type: 'select',
										label: 'Extra Weight Charges Reason',
										name: 'extraWeightChargeReason',
										valueProperty: 'value',
										displayProperty: 'label',
										options: [
											{ label: 'BL delta', value: 'blDelta' },
											{ label: 'BLX assignment decision', value: 'blxAssignmentDecisions' },
										],
										message: 'Extra Weight Charges Reason is Required',
										initialValue: job?.extraWeightChargeReason || 'blDelta',
										required: true,
									},
							  ]
							: []),
					])}
				</Form>
			</div>

			<Button
				className='btn-submit flex flex-row justify-center mx-6 p-[10px] mb-4'
				disabled={fileLoading}
				loading={loading || jobLoading || biltyLoading}
				onClick={() => {
					form.validateFields().then(async () => {
						await addOtherCharges()
					})
				}}
			>
				<Icon path={mdiReceiptTextCheckOutline} size={0.8} className='text-white mr-2' />
				<p className='text-white font-[700] text-sm'>Update Job</p>
			</Button>
		</div>
	)
}
// components

const SliverList = ({ items = [], heightClass }) => {
	const headerRefs = useRef(Array.from({ length: items?.length || 0 }, (_, i) => createRef()))
	const contentRefs = useRef(Array.from({ length: items?.length || 0 }, (_, i) => createRef()))
	const scrollContainerRef = useRef()

	return (
		<div className={`relative ${heightClass} overflow-clip `}>
			<div
				onScroll={(e) => {
					// console.log('1', e.currentTarget.scrollHeight)
					// console.log('2', scrollContainerRef.current.clientHeight)
					// console.log('3', e.currentTarget.scrollTop)
				}}
				ref={scrollContainerRef}
				className=' overflow-y-scroll h-full'
			>
				{items.map((item, i) => {
					return (
						<div className='flex flex-col'>
							<item.header ref={headerRefs[i]} />
							<item.content ref={contentRefs[i]} />
						</div>
					)
				})}
			</div>
		</div>
	)
}

export const SidebarCard = ({ title, children } = {}) => {
	return (
		<div className='flex flex-col rounded-lg overflow-clip border border-black-75'>
			{!title ? null : typeof title === 'function' ? (
				title()
			) : (
				<div className='px-4 py-4 border-b border-black-75 bg-white'>
					<p className='text-sm font-semibold text-black-300'>{title}</p>
				</div>
			)}

			{children}
		</div>
	)
}

export const AccountNumberSelector = ({ accounts, allowNew, currPage, value, onChange, form, editPage }) => {
	const [editable, setEditable] = useState(false)
	const [addNewSelected, setAddNewSelected] = useState(false)
	const accountNumber = Form.useWatch(editPage ? [1, 'accountNumber'] : [currPage, 'accountNumber'], form)
	const payeeType = Form.useWatch(editPage ? [1, 'payeeType'] : [currPage, 'payeeType'], form)
	const entityId = Form.useWatch(editPage ? [1, 'entityId'] : [currPage, 'entityId'], form)

	useEffect(() => {
		if (accounts?.length === 0) {
			if (allowNew) {
				setEditable(true)
				setAddNewSelected(true)
				onChange('addNew')
			}
		} else {
			setAddNewSelected(false)

			if (!value) {
				onChange(accounts?.[0]?._id)
			}
		}
	}, [accounts])

	useEffect(() => {
		if (accounts?.[0]?._id && !(value && accounts?.find((account) => account._id === value))) {
			onChange(accounts?.[0]?._id)
		}
	}, [payeeType, entityId])
	return (
		<div className='flex flex-col rounded-lg overflow-clip border border-black-75 w-full mb-6'>
			{!editable ? (
				value ? (
					<div className='flex flex-row items-center justify-between px-4 py-4 w-full bg-white'>
						<p className='text-sm font-semibold text-black-300 tracking-wider'>
							{accounts?.find((account) => account._id === value)?.accountNumber || accountNumber}
						</p>
						{!editable && (
							<button onClick={() => setEditable(true)}>
								<Icon path={mdiPencil} size={0.7} className='text-primary-800' />
							</button>
						)}
					</div>
				) : (
					<div className='flex flex-row items-center justify-between px-4 py-4 w-full bg-white'>
						<p className='text-sm font-medium text-red-400 w-full text-center'>No accounts available</p>
					</div>
				)
			) : (
				<React.Fragment>
					{accounts?.map((account, index) => (
						<Radio
							onClick={(e) => {
								onChange(account?._id)
							}}
							onChange={() => {
								setAddNewSelected(false)
							}}
							checked={value === account._id}
							className={`bg-white  ${allowNew || index !== accounts?.length - 1 ? 'border-b border-black-75' : ''}  px-4  w-full `}
						>
							<div className=' flex flex-row items-center py-4 '>
								<p className='text-sm font-semibold text-black-300 tracking-wider'>{account?.accountNumber}</p>
							</div>
						</Radio>
					))}
					{allowNew ? (
						<div className='flex flex-col'>
							<Radio
								onClick={(e) => {
									onChange('addNew')
								}}
								onChange={() => {
									setAddNewSelected(true)
								}}
								checked={value === 'addNew'}
								className={` bg-white ${addNewSelected ? 'border-b border-black-75' : ''} px-4  w-full`}
							>
								<div className=' flex flex-row items-center py-4 '>
									<p className='text-sm font-semibold text-black-300 tracking-wide '>Add New Account</p>
								</div>
							</Radio>
							{addNewSelected ? (
								<div className='px-4 pt-4'>
									{RenderSchema([
										{
											type: 'select',
											name: [currPage, 'bankName'],
											label: 'Bank Name',
											elementClassName: 'bg-white',
											placeholder: 'Select Bank Name',
											options: [
												'Al Baraka Bank (Pakistan) Limited',
												'Allied Bank Limited (ABL)',
												'Askari Bank',
												'Bank Alfalah Limited (BAFL)',
												'Bank Al-Habib Limited (BAHL)',
												'BankIslami Pakistan Limited',
												'Bank of Punjab (BOP)',
												'Bank of Khyber',
												'Deutsche Bank A.G',
												'Dubai Islamic Bank Pakistan Limited (DIB Pakistan)',
												'Faysal Bank Limited (FBL)',
												'First Women Bank Limited',
												'Habib Bank Limited (HBL)',
												'Habib Metropolitan Bank Limited',
												'Industrial and Commercial Bank of China',
												'Industrial Development Bank of Pakistan',
												'JS Bank Limited',
												'MCB Bank Limited',
												'MCB Islamic Bank Limited',
												'Meezan Bank Limited',
												'National Bank of Pakistan (NBP)',
												'Summit Bank Pakistan',
												'Standard Chartered Bank (Pakistan) Limited (SC Pakistan)',
												'Sindh Bank',
												'The Bank of Tokyo-Mitsubishi UFJ (MUFG Bank Pakistan)',
												'United Bank Limited (UBL)',
												'Zarai Taraqiati Bank Limited',
											],
											showSearch: true,
											required: true,
										},
										{
											type: 'input',
											label: 'Account Title',
											name: [currPage, 'accountTitle'],
											placeholder: 'Enter Account Title',
											required: true,
										},
										{
											type: 'input',
											inputType: 'number',
											label: 'Account Number',
											name: [currPage, 'accountNumber'],
											placeholder: 'Enter Account Number',
											required: true,
										},
									])}
								</div>
							) : null}
						</div>
					) : null}
				</React.Fragment>
			)}
		</div>
	)
}

const ChargesByStatus = ({ charges, status, setSelectedChargeIds, selectedChargeIds }) => {
	const [open, setOpen] = useState(true)
	return (
		<div className='flex flex-col'>
			<div className='flex flex-row items-center justify-between px-4 py-3 border-b border-black-75 bg-black-50'>
				<div className='flex flex-row items-center'>
					<Icon path={mdiClockOutline} size={0.75} className='text-yellow-400' />
					<p className='text-sm font-medium text-black-400 px-2'>{status}</p>
					{/* <Icon path={mdiDotsVertical} size={0.75} className='text-black-400' /> */}
				</div>
				<button onClick={() => setOpen(!open)}>
					<Icon
						path={mdiChevronDown}
						size={1}
						className={`text-black-300 ${open ? 'rotate-180 transition-[transform] duration-300' : 'rotate-0 transition-[transform] duration-300 '} `}
					/>
				</button>
			</div>
			<div
				className={`flex flex-col transition-[max-height] ease-in-out duration-500 overflow-hidden`}
				style={{ maxHeight: open ? charges?.length * 43 : 0 }}
			>
				{charges?.map((charge) => (
					<div className='flex flex-row items-center justify-between px-4 py-[10px] border-b border-black-75 bg-white'>
						<div className='flex flex-row items-center justify-start'>
							{status === 'PENDING_AUTHENTICATION' ? (
								<Checkbox
									onClick={() => {
										if (selectedChargeIds?.find((id) => charge?._id === id)) {
											setSelectedChargeIds((prev) => prev.filter((id) => id !== charge?._id))
										} else {
											setSelectedChargeIds((prev) => [...prev, charge?._id])
										}
									}}
									value={selectedChargeIds?.find((id) => charge?._id === id)}
								/>
							) : null}
							<Link to={`charge/${charge._id}`}>
								<p className={`text-sm font-medium text-black-300 ${status === 'PENDING_AUTHENTICATION' ? 'pl-2' : ''}`}>
									{toReadableString(charge.chargeType?.name)}
								</p>
							</Link>
						</div>
						<div className='flex flex-row items-center justify-end'>
							<p className='text-sm font-bold text-black-400 pr-2'>Rs {toReadableAmount(charge?.amount)}</p>
							{/* <Icon path={mdiDotsVertical} size={0.8} className='text-black-400' /> */}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

const DeductionsByStatus = ({ revenues, status, selectedRevenueIds, setSelectedRevenueIds }) => {
	const [open, setOpen] = useState(true)
	return (
		<div className='flex flex-col'>
			<div className='flex flex-row items-center justify-between px-4 py-3 border-b border-black-75 bg-black-50'>
				<div className='flex flex-row items-center'>
					<Icon path={mdiClockOutline} size={0.75} className='text-yellow-400' />
					<p className='text-sm font-medium text-black-400 px-2'>{status}</p>
					{/* <Icon path={mdiDotsVertical} size={0.75} className='text-black-400' /> */}
				</div>
				<button onClick={() => setOpen(!open)}>
					<Icon
						path={mdiChevronDown}
						size={1}
						className={`text-black-300 ${open ? 'rotate-180 transition-[transform] duration-300' : 'rotate-0 transition-[transform] duration-300 '} `}
					/>
				</button>
			</div>
			<div
				className={`flex flex-col transition-[max-height] ease-in-out duration-500 overflow-hidden`}
				style={{ maxHeight: open ? revenues?.length * 43 : 0 }}
			>
				{revenues?.map((revenue) => (
					<div className='flex flex-row items-center justify-between px-4 py-[10px] border-b border-black-75 bg-white'>
						<div className='flex flex-row items-center justify-start'>
							{status === 'PENDING_AUTHENTICATION' ? (
								<Checkbox
									onClick={() => {
										if (selectedRevenueIds?.find((id) => revenue?._id === id)) {
											setSelectedRevenueIds((prev) => prev.filter((id) => id !== revenue?._id))
										} else {
											setSelectedRevenueIds((prev) => [...prev, revenue?._id])
										}
									}}
									value={selectedRevenueIds?.find((id) => revenue?._id === id)}
								/>
							) : null}
							<Link to={`revenue/${revenue._id}`}>
								<p className={`text-sm font-medium text-black-300 ${status === 'PENDING_AUTHENTICATION' ? 'pl-2' : ''}`}>
									{toReadableString(revenue.revenueType?.name)}
								</p>
							</Link>
						</div>
						<div className='flex flex-row items-center justify-end'>
							<p className='text-sm font-bold text-black-400 pr-2'>Rs {toReadableAmount(revenue?.amount)}</p>
							{/* <Icon path={mdiDotsVertical} size={0.8} className='text-black-400' /> */}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
// charge details components

const StatusDetailCard = ({ status, index, length }) => {
	const [open, setOpen] = useState(false)
	return (
		<div className='flex flex-col'>
			<div className={`flex flex-row h-11 items-center justify-between ${index !== length - 1 || open ? 'border-b border-black-75' : ''}  `}>
				<div className='flex flex-row items-center px-4 h-full  basis-[42%] bg-black-50 border-r border-black-75'>
					<p className='text-sm font-semibold text-black-300'>{status.name}</p>
				</div>
				<div className='flex flex-row items-center  justify-between px-4 h-full  basis-[58%] bg-white'>
					<div className={`${NewChargeStatusEnums[status.status]?.color} flex flex-row items-center w-fit rounded-lg pl-2 pr-3 py-1`}>
						<Icon size={0.8} className={` text-white`} path={NewChargeStatusEnums[status.status]?.icon} />
						<p className='text-xs font-medium text-white pl-2'>{NewChargeStatusEnums[status.status]?.text}</p>
					</div>
					{/* <p className='text-sm font-medium text-black-400'>{NewChargeStatusEnums[status.status].text}</p> */}
					<button onClick={() => setOpen(!open)}>
						<Icon
							path={open ? mdiChevronDown : mdiHistory}
							size={0.85}
							className={`text-black-300 ${open ? 'rotate-180 transition-[transform] duration-300' : 'rotate-0 transition-[transform] duration-300 '} `}
						/>
					</button>
				</div>
			</div>
			<div
				className={`flex flex-col transition-[max-height] ease-in-out duration-500 overflow-hidden w-full  ${
					open && index !== length - 1 ? 'border-b  border-black-75' : ''
				}`}
				style={{ maxHeight: open ? status?.statusHistory?.length * 64 : 0 }}
			>
				<div className='bg-white py-4 px-5'>
					<div className='relative'>
						<Timeline
							items={status.statusHistory?.map((item) => ({
								children: (
									<div className='flex flex-col items-start'>
										<p className='text-sm font-semibold text-black-400 pb-2'>{NewChargeStatusEnums[item.status]?.text}</p>
										<p className='text-xs font-normal text-black-300'>{moment(item?.createdAt)?.format('DD-MM-yy, hh:mm a')}</p>
									</div>
								),
								dot: <Icon size={0.8} className={`${NewChargeStatusEnums[item.status]?.textColor}`} path={NewChargeStatusEnums[item.status]?.icon} />,
							}))}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
const PayeeInformation = ({ charge, revenue }) => {
	const { paymentInvoice } = charge || revenue || {}
	const { toAccount } = paymentInvoice || {}
	return (
		<SidebarCard title={'Payee Information'}>
			<div className='flex flex-col'>
				<div className={`flex flex-row items-center justify-between border-b border-black-75`}>
					<div className='flex flex-row items-center px-4 py-3  w-1/3 bg-black-50 border-r border-black-75'>
						<p className='text-sm font-semibold text-black-300'>Bank Name</p>
					</div>
					<div className='flex flex-row items-center  justify-between px-4 py-3  w-2/3 bg-white'>
						<Tooltip title={toAccount?.bankName}>
							<p className='text-sm font-semibold text-black-400 truncate'>{toAccount?.bankName}</p>
						</Tooltip>
					</div>
				</div>
				<div className={`flex flex-row items-center justify-between border-b border-black-75`}>
					<div className='flex flex-row items-center px-4 py-3  w-1/3 bg-black-50 border-r border-black-75'>
						<p className='text-sm font-semibold text-black-300'>Account Title</p>
					</div>
					<div className='flex flex-row items-center  justify-between px-4 py-3  w-2/3 bg-white'>
						<Tooltip title={toAccount?.accountTitle}>
							<p className='text-sm font-semibold text-black-400 truncate'>{toAccount?.accountTitle}</p>
						</Tooltip>
					</div>
				</div>
				<div className={`flex flex-row items-center justify-between`}>
					<div className='flex flex-row items-center px-4 py-3  w-1/3 bg-black-50 border-r border-black-75'>
						<p className='text-sm font-semibold text-black-300'>Account Number</p>
					</div>
					<div className='flex flex-row items-center  justify-between px-4 py-3  w-2/3 bg-white'>
						<Tooltip title={toAccount?.accountNumber}>
							<p className='text-sm font-semibold text-black-400 truncate '>{toAccount?.accountNumber}</p>
						</Tooltip>
					</div>
				</div>
			</div>
		</SidebarCard>
	)
}
const CommentsCard = ({ charge, revenue }) => {
	return (
		<SidebarCard title='Comments'>
			<div className='flex flex-col'>
				<div className='flex flex-col px-4 py-4 bg-black-50'>
					<div className='bg-white rounded-lg border border-black-75 flex flex-col items-start p-4 w-full'>
						<p className='text-black-300 font-medium text-sm text-ellipsis line-clamp-6 '>{charge?.comment || revenue?.comment}</p>
						<div className='w-full border-b border-black-75 my-2 h-[1px]' />
						<p className='text-black-200 font-medium text-xs'>Thursday, 25 March 2024 - 12:30 PM</p>
					</div>
				</div>
			</div>
		</SidebarCard>
	)
}
const AttachmentsCard = ({ charge, revenue }) => {
	const attachments = charge?.invoiceProof?.documents || revenue?.invoiceProof?.documents || []

	return (
		<SidebarCard title={'Attachments'}>
			<div className='flex flex-col items-center px-4 pt-4 bg-black-50'>
				{attachments?.map((attachment, index) => (
					<div className={`flex flex-col rounded-lg overflow-clip border border-black-75 w-full bg-white py-4 px-4 mb-4`}>
						<div className='flex flex-row justify-between items-center'>
							<div className='flex flex-row justify-start items-center'>
								<div className='h-9 w-9 rounded-lg flex flex-row items-center justify-center'>
									<div className='h-full w-full bg-red-50 rounded-lg flex flex-row items-center justify-center'>
										<Icon path={mdiFileDocument} size={1} className='text-red-400' />
									</div>
								</div>
								<p className='font-semibold text-sm text-black-300 pl-4'>
									{`${charge?.invoiceProof?.name ? toReadableString(charge?.invoiceProof?.name) : `Attachment`} ${attachments.length > 1 ? ` (${index + 1})` : ''}`}
								</p>
							</div>
							<a href={attachment} target='_blank'>
								<div className='flex flex-row items-center justify-end'>
									<p className='text-sm font-semibold text-primary-800 mr-2'>Open</p>
									<Icon path={mdiOpenInNew} size={0.8} className='text-primary-800' />
								</div>
							</a>
						</div>
					</div>
				))}
			</div>
		</SidebarCard>
	)
}

// job details components
const PaymentTermsCard = ({ job, loading }) => {
	const { paymentTerms, factorRequest, factorStatus, factoredAmount } = job || {}
	const BPayTerms = [
		{
			_id: 'bpayTerm',
			amount: factoredAmount,
			days: 0,

			journeyPoint: 'completed',
			paymentType: 'IBFT',
		},
	]
	return (
		<SidebarCard title='Payment Terms'>
			{paymentTerms?.length > 0 || factorStatus === 'approved' ? (
				(paymentTerms?.length > 0 ? paymentTerms : BPayTerms)?.map((item, index) => (
					<div
						className={`flex flex-row pl-5 pr-6 py-5 justify-between ${index + 1 < paymentTerms.length ? ' border-b border-black-200 ' : ''} w-full bg-white`}
					>
						<div className='flex flex-col'>
							<div className='font-[400] text-sm text-black-300'>{item?.days} days</div>
							<div className='text-black-400 text-sm font-[600] pb-2'>{PaymentPointEnums[item?.journeyPoint]?.label}</div>
							<div className='flex flex-row items-center rounded-[6px] p-1 h-[2em] font-[400] !text-[#676767] bg-[#F5F5F5]'>
								<div className='mr-2'>
									<svg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<path d='M20 0H11V2H20V0Z' fill='#676767' />
										<path d='M20 8H11V10H20V8Z' fill='#676767' />
										<path d='M20 4H14V6H20V4Z' fill='#676767' />
										<path d='M11 5L6 0V4H0V6H6V10L11 5Z' fill='#676767' />
									</svg>
								</div>
								<div>{item?.paymentType}</div>
							</div>
						</div>
						<div className='flex flex-row items-center justify-end h-auto w-[50%] font-bold text-[#676767]'>Rs. {item?.amount}</div>
					</div>
				))
			) : (
				<div className='flex flex-col items-center justify-center py-5 bg-white'>
					<div className='rounded-full bg-red-50 p-6'>
						<Icon path={mdiFileDocumentAlertOutline} className='text-red-400' size={2} />
					</div>
					<div className='pt-3 text-base font-semibold'>Payment Terms Missing</div>
					<div className='py-2 text-xs font-light'>
						Amount to be settled in payment terms : <span className='font-semibold'>Rs. {job?.cost || 0}</span>
					</div>
				</div>
			)}
			{!paymentTerms?.length > 0 || factorStatus === 'approved' ? (
				<Tooltip title={!(job?.cost && job?.cost > 0) ? 'Please Enter Freight Cost To Add Payment Terms' : ''}>
					<Link to={`edit/paymentTerms`} className={!'loading' ? 'no-hover' : ''}>
						<div className={(!'loading' ? 'bg-black-50' : 'cursor-pointer bg-[#EDF7FF]') + ' flex flex-row justify-center items-center p-[10px] '}>
							<Icon path={mdiPencil} size={0.8} className={(!'loading' ? 'text-white' : 'text-[#1C75BC]') + ' mr-[5px]'} />
							<p className={(!'loading' ? 'text-white' : 'text-[#1C75BC]') + ' font-[500]'}>Edit Payment Terms</p>
						</div>
					</Link>
				</Tooltip>
			) : null}
		</SidebarCard>
	)
}
const DeductionDetailsCard = () => {
	const { id, segment } = useParams()
	const { tab } = useOutletContext()
	const statuses = useRecoilValue(jobRevenuesByStatusSelector({ id, segment, tab }))
	const [selectedRevenueIds, setSelectedRevenueIds] = useState([])
	const [authenticateRevenues, authenticateRevenuesLoading] = useMutate({ type: 'AuthenticateRevenues', action: 'create' })

	const handleClick = () => {
		authenticateRevenues({ variables: { authenticateRevenuesInput: { revenueIds: selectedRevenueIds } } })
			.then((response) => {
				if (response?.data?.authenticateRevenues && checkArray(response?.data?.authenticateRevenues) && response?.data?.authenticateRevenues?.length > 0) {
					notification['success']({
						message: 'Revenues Authenticated',
					})
				} else {
					notification['error']({
						message: 'Revenues Authentication Failed',
					})
				}
			})
			.catch((e) => {
				console.log({ e })
				notification['error']({
					message: 'Revenues Authentication Failed',
				})
			})
	}
	return (
		<SidebarCard title='Deductions'>
			{Object.entries(statuses || [])?.map(([status, revenues]) => (
				<DeductionsByStatus selectedRevenueIds={selectedRevenueIds} setSelectedRevenueIds={setSelectedRevenueIds} status={status} revenues={revenues} />
			))}
			{selectedRevenueIds?.length > 0 ? (
				<button
					onClick={handleClick}
					loading={authenticateRevenuesLoading}
					className={`cursor-pointer bg-background flex flex-row justify-center items-center p-[10px] ${authenticateRevenuesLoading ? 'opacity-80' : ''}`}
				>
					{authenticateRevenuesLoading ? (
						<Spin size='small' className='text-[#1C75BC]' />
					) : (
						<div className='flex flex-row items-center justify-center'>
							<Icon path={mdiReceiptTextCheckOutline} size={0.8} className='text-primary-800 mr-2' />
							<p className={'text-[#1C75BC] font-[600]'}>
								Authenticate {selectedRevenueIds?.length} Revenue{selectedRevenueIds?.length > 1 ? 's' : ''}
							</p>
						</div>
					)}
				</button>
			) : (
				<Link to={`new-revenue`} className={!'loading' ? 'no-hover' : ''}>
					<div className={(!'loading' ? 'bg-black-50' : 'cursor-pointer bg-[#EDF7FF]') + ' flex flex-row justify-center items-center p-[10px] '}>
						<Icon path={mdiPlus} size={0.8} className={(!'loading' ? 'text-white' : 'text-[#1C75BC]') + ' mr-[5px]'} />
						<p className={(!'loading' ? 'text-white' : 'text-[#1C75BC]') + ' font-[500]'}>Add Deduction</p>
					</div>
				</Link>
			)}
		</SidebarCard>
	)
}
const ChargesDetailsCard = () => {
	const { id, segment } = useParams()
	const { tab } = useOutletContext()
	const [selectedChargeIds, setSelectedChargeIds] = useState([])
	const statuses = useRecoilValue(jobChargesByStatusSelector({ id, segment, tab }))
	const [authenticateCharges, authenticateChargesLoading] = useMutate({ type: 'AuthenticateCharges', action: 'create' })
	const handleClick = () => {
		authenticateCharges({ variables: { authenticateChargesInput: { chargeIds: selectedChargeIds } } })
			.then((response) => {
				if (response?.data?.authenticateCharges && checkArray(response?.data?.authenticateCharges) && response?.data?.authenticateCharges?.length > 0) {
					notification['success']({
						message: 'Charges Authenticated',
					})
				} else {
					notification['error']({
						message: 'Charges Authentication Failed',
					})
				}
			})
			.catch((e) => {
				console.log({ e })
				notification['error']({
					message: 'Charges Authentication Failed',
				})
			})
	}
	return (
		<SidebarCard title='Charges'>
			{Object.entries(statuses || [])?.map(([status, charges]) => (
				<ChargesByStatus selectedChargeIds={selectedChargeIds} setSelectedChargeIds={setSelectedChargeIds} status={status} charges={charges} />
			))}

			{/* <ChargesByStatus status='Paid' charges={[1, 2]} /> */}
			{selectedChargeIds?.length > 0 ? (
				<button
					onClick={handleClick}
					className={`cursor-pointer bg-background flex flex-row justify-center items-center p-[10px] ${authenticateChargesLoading ? 'opacity-80' : ''}`}
				>
					{authenticateChargesLoading ? (
						<Spin size='small' className='text-[#1C75BC]' />
					) : (
						<div className='flex flex-row items-center justify-center'>
							<Icon path={mdiReceiptTextCheckOutline} size={0.8} className='text-primary-800 mr-2' />
							<p className={'text-[#1C75BC] font-[600]'}>
								Authenticate {selectedChargeIds?.length} Charge{selectedChargeIds?.length > 1 ? 's' : ''}
							</p>
						</div>
					)}
				</button>
			) : (
				<Link to={`new-charge`} className={!'loading' ? 'no-hover' : ''}>
					<div className={(!'loading' ? 'bg-black-50' : 'cursor-pointer bg-[#EDF7FF]') + ' flex flex-row justify-center items-center p-[10px] '}>
						<Icon path={mdiPlus} size={0.8} className={(!'loading' ? 'text-white' : 'text-[#1C75BC]') + ' mr-[5px]'} />
						<p className={(!'loading' ? 'text-white' : 'text-[#1C75BC]') + ' font-[500]'}>Add Charge</p>
					</div>
				</Link>
			)}
		</SidebarCard>
	)
}
const ChargesOverviewCard = (props) => {
	const { segment, id } = useParams()
	const { tab } = useOutletContext()
	const { total, totalRemaining, totalPaid } = useRecoilValue(amountTotalsSelector({ segment, tab, id }))
	return (
		<SidebarCard>
			<div className='flex flex-row items-center justify-evenly bg-white py-4'>
				<div className='flex flex-col border-r border-black-75 items-center justify-center grow'>
					<p className='text-sm font-semibold text-black-300 text-center pb-2'>Total Amount</p>
					<p className='text-sm font-semibold text-black-400 text-center'>Rs {toReadableAmount(total)}</p>
				</div>
				<div className='flex flex-col border-r border-black-75 items-center justify-center grow'>
					<p className='text-sm font-semibold text-black-300 text-center pb-2'>Paid</p>
					<p className='text-sm font-semibold text-green-400 text-center'>Rs {toReadableAmount(totalPaid)}</p>
				</div>
				<div className='flex flex-col items-center justify-center grow '>
					<p className='text-sm font-semibold text-black-300 text-center pb-2'>Remaining</p>
					<p className='text-sm font-semibold text-red-400 text-center'>Rs {toReadableAmount(totalRemaining)}</p>
				</div>
			</div>
		</SidebarCard>
	)
}
const AssignmentDetailsCard = ({ job }) => {
	const { broker, supplier, driverVehicleRegistration, driverPhone, driverName, jobVehicleType } = job || {}
	const items = [
		{ title: 'Vehicle', value: driverVehicleRegistration?.toUpperCase(), vehicleType: VehicleTypeEnums?.[jobVehicleType]?.text || jobVehicleType },
		{ title: 'Supplier', value: supplier?.name, phoneNumber: formatPhoneNumber(supplier?.phoneNumber) },
		{ title: 'Broker', value: broker?.CompanyName, phoneNumber: formatPhoneNumber(broker?.phoneNumber) },
		{ title: 'Driver', value: driverName, phoneNumber: formatPhoneNumber(driverPhone) },
	]

	return (
		// <SidebarCard>
		<div className='flex flex-col'>
			{items.map((item) => (
				<div className={`flex flex-row items-center  border-b  border-black-75 bg-black-50`}>
					<div className='flex flex-row items-center justify-start   border-r border-black-75 py-[10px] w-[20%]'>
						<p className='text-xs font-medium text-black-200 pl-4'>{item.title}</p>
					</div>
					<div className={`flex flex-row items-center justify-start  border-r border-black-75 py-[10px] bg-white w-[45%]`}>
						<Tooltip title={item.value}>
							<p className='text-xs font-semibold text-black-400 pl-4  truncate'>{item.value}</p>
						</Tooltip>
					</div>
					{item.phoneNumber ? (
						<div className='w-[35%] flex flex-row items-center justify-start bg-white py-[10px] pl-4'>
							{/* <Icon path={mdiPhone} size={0.8} color='#9F9F9F' /> */}
							<p className='font-[600] text-black-400 text-xs'>{item.phoneNumber}</p>
						</div>
					) : (
						<div className='w-[35%] flex flex-row items-center justify-start bg-white py-[10px] pl-4'>
							{/* <Icon path={mdiTruckOutline} size={0.8} color='#9F9F9F' /> */}
							<p className='font-[600] text-black-400 text-xs'>{item.vehicleType}</p>
						</div>
					)}
				</div>
			))}
			<Link to={`edit/assignment`} className={!'loading' ? 'no-hover' : ''}>
				<div className={(!'loading' ? 'bg-black-50' : 'cursor-pointer bg-[#EDF7FF]') + ' flex flex-row justify-center items-center p-[10px] '}>
					<Icon path={mdiPencil} size={0.8} className={(!'loading' ? 'text-white' : 'text-[#1C75BC]') + ' mr-[5px]'} />
					<p className={(!'loading' ? 'text-white' : 'text-[#1C75BC]') + ' font-[500]'}>Edit Assignment Details</p>
				</div>
			</Link>
		</div>
		// </SidebarCard>
	)
}

const ContainerDetails = ({ container }) => {
	const { containerDimension, containerWeight, containerNumber } = container || {}
	return (
		<div className='flex flex-row items-center justify-evenly   px-4 py-[10px] border-b border-black-75 bg-white'>
			<div className='flex flex-row items-start  border-black-75 grow'>
				<p className='text-sm font-medium text-black-200 pr-2'>Cont #</p>
				<p className='text-sm font-medium text-black-300'>{containerNumber}</p>
			</div>
			<div className='flex flex-row items-center grow'>
				<Icon className='mr-2' path={mdiTruckOutline} size={0.8} color='#9F9F9F' />
				<p className='font-[600] text-black-400'>{ContainerDimensionsEnum[containerDimension]?.text || containerDimension}</p>
			</div>

			<div className='flex flex-row items-center grow'>
				<Icon className='mr-2' path={mdiPackageVariantClosed} size={0.8} color='#9F9F9F' />
				<p className='font-[600] overflow-ellipsis text-black-400'>{containerWeight} Tons</p>
			</div>
		</div>
	)
}
const DocumentDetails = ({ documentNumber, containers, index }) => {
	return (
		<div className='flex flex-col'>
			<div className='flex flex-row items-center justify-start px-4 py-3 border-b border-black-75 bg-white'>
				<div className='flex flex-row items-center'>
					<p className='text-sm font-semibold text-black-400 '>
						{index}. {documentNumber}
					</p>
				</div>
			</div>
			<div className='flex flex-col'>
				{containers.map((item) => (
					<ContainerDetails container={item} />
				))}
			</div>
		</div>
	)
}
const ShipmentDetailsCard = ({ job }) => {
	const { shipmentInfo, businessName, businessCode, committedDays, status, terminal } = job || {}
	return (
		<SidebarCard>
			<div className='flex flex-col'>
				<div className='flex flex-row items-center justify-between px-4 py-[10px] border-b border-black-75 bg-white'>
					<p className='text-sm font-medium text-black-200'>Client Name</p>
					<p className='text-sm font-medium text-black-300'>{`${businessName} (${businessCode})`}</p>
				</div>
				{terminal?.[0]?.Location?.additionalDetails && (
					<div className='flex flex-row items-center justify-between px-4 py-[10px] border-b border-black-75 bg-white'>
						<p className='text-sm font-medium text-black-200'>Empty Return </p>
						<p className='text-sm font-medium text-black-300'>{`${terminal?.[0]?.Location?.additionalDetails}`}</p>
					</div>
				)}
				<div className='flex flex-row items-center justify-between px-4 py-[10px] border-b border-black-75 bg-white'>
					<p className='text-sm font-medium text-black-200'>Vendor Commited Date</p>
					<p className='text-sm font-medium text-black-300'>
						{committedDays && committedDays !== 'NaN' ? moment(committedDays, 'x').format('DD MMM yyyy') : 'N/A'}
						{status === 'Job Ongoing' && committedDays && committedDays !== 'NaN' && (
							<span className={`${moment(committedDays, 'x').diff(moment(), 'days') > 0 ? 'text-yellow-400' : 'text-red-400'}`}>
								( {moment(committedDays, 'x').fromNow()} )
							</span>
						)}
					</p>
				</div>
				<div className='flex flex-col'>
					<div className='flex flex-row items-center justify-between px-4 py-3 border-b border-black-75 bg-black-50'>
						<div className='flex flex-row items-center'>
							<Icon path={mdiPackageVariantClosed} size={0.75} className='text-primary-800' />
							<p className='text-sm font-semibold text-black-300 pl-2'>Shipment Details</p>
						</div>
						<Icon path={mdiChevronDown} size={1} className='text-black-300' />
					</div>
					<div className='flex flex-col'>
						{Object.values(shipmentInfo || {}).map((item, index) => (
							<DocumentDetails documentNumber={item.documentNumber} containers={item.containers} index={index + 1} />
						))}
					</div>
				</div>
				<div className='flex flex-row items-center justify-between px-4 py-3 border-b border-black-75 bg-black-50'>
					<div className='flex flex-row items-center'>
						<Icon path={mdiTruckOutline} size={0.75} className='text-primary-800' />
						<p className='text-sm font-semibold text-black-300 pl-2'>Assignment Details</p>
					</div>
					<Icon path={mdiChevronDown} size={1} className='text-black-300' />
				</div>
				<AssignmentDetailsCard job={job} />
			</div>
		</SidebarCard>
	)
}

// pages
export const AddEditCostPage = () => {
	const { pathname } = useLocation()
	const { segment, id, chargeId } = useParams()
	const { loading, tab } = useOutletContext()
	const [form] = Form.useForm()
	const navigator = useNavigate()
	const [numCosts, setNumCosts] = useState(1)
	const [currPage, setCurrPage] = useState(1)
	const [createCharge, createChargeLoading] = useMutate({ type: 'Charge', action: 'create' })
	const [createCharges, createChargesLoading] = useMutate({ type: 'MultipleCharges', action: 'create' })
	const [updateCharge, updateChargeLoading] = useMutate({ type: 'Charge', action: 'update' })

	const job = useRecoilValue(postAssignmentSidebarSelector({ segment, tab, id }))
	const charge = useRecoilValue(jobChargeSelector({ segment, tab, id, chargeId }))
	const chargeTypes = useRecoilValue(chargeTypesForFormSelector())
	const thirdParties = useRecoilValue(thirdPartiesAtom)
	const biltyInvestors = useRecoilValue(biltyInvestorsAtom)

	const [chargeLoaded, setChargeLoaded] = useState(false)

	useFetch({ type: 'ChargeType', atomKey: 'chargeTypes' })
	useFetch({ type: 'ThirdParty', atomKey: 'thirdParties' })
	useFetch({ type: 'BiltyInvestors', atomKey: 'biltyInvestor' })

	const watcher = Form.useWatch(null, form)
	const editPage = pathname?.split('/')?.at(-1) === 'edit'
	const accountTypes = {
		THIRD_PARTY: 'THIRD_PARTY_ACCOUNT',
		BROKER: 'BROKER_ACCOUNT',
		BILTY_INVESTOR: 'BILTY_INVESTOR_ACCOUNT',
	}
	const accountPayeeMapping = {
		THIRD_PARTY_ACCOUNT: 'THIRD_PARTY',
		BROKER_ACCOUNT: 'BROKER',
		BILTY_INVESTOR_ACCOUNT: 'BILTY_INVESTOR',
	}
	const previousInvoiceProofs = useMemo(() => {
		const values = form.getFieldsValue(true)
		return Object.values(values || {})?.reduce((allInvoiceProofs, page, index) => {
			if (page?.invoiceName) {
				return [...allInvoiceProofs, { _id: `prev_invoice_${index + 1}`, name: toReadableString(page?.invoiceName), pageNumber: index + 1 }]
			} else {
				return allInvoiceProofs
			}
		}, [])
	}, [numCosts, watcher])

	const selectedChargeType = useMemo(
		() => chargeTypes?.find((chargeType) => chargeType?._id === (watcher?.[currPage || 1]?.chargeType || charge?.chargeType?._id)),
		[currPage, chargeTypes, watcher?.[currPage]?.chargeType]
	)
	useEffect(() => {
		if (!editPage) {
			form.setFieldValue([currPage, 'payeeType'], selectedChargeType?.payeeTypes?.[0])
		}
	}, [selectedChargeType])
	useEffect(() => {
		if (!(charge && editPage && chargeLoaded)) {
			form.setFieldValue([currPage, 'entityId'], null)
		} else {
			setChargeLoaded(false)
		}
	}, [watcher?.[currPage]?.payeeType])
	useEffect(() => {
		if (charge && editPage && !chargeLoaded) {
			const initialValues = {
				1: {
					chargeType: charge?.chargeType?._id,
					amount: charge?.amount,
					comment: charge?.comment,
					isUrgent: charge?.isUrgent,
					payeeType: accountPayeeMapping[charge?.paymentInvoice?.toAccount?.type],
					accountId: charge?.paymentInvoice?.toAccount?._id,
					entityId: charge?.paymentInvoice?.toAccount?.accountOwner?._id,
					invoiceProofId: charge?.invoiceProof?._id,
					...(charge?.customFieldValues && charge?.customFieldValues?.length > 0
						? {
								customFieldProperty: charge?.customFieldValues?.reduce((fields, field) => {
									if (!field.value) {
										return fields
									} else if (selectedChargeType?.customFields?.find((customField) => customField.propertyKey === field.propertyKey)?.type === 'dateTime') {
										return {
											...fields,
											[field.propertyKey]: {
												value: moment(field.value),
												_id: field._id,
											},
										}
									} else {
										return {
											...fields,
											[field.propertyKey]: {
												value: field.value,
												_id: field._id,
											},
										}
									}
								}, {}),
						  }
						: {}),
				},
			}
			setChargeLoaded(true)
			form.setFieldsValue(initialValues)
		}
	}, [charge])
	useEffect(() => {
		if (watcher?.[currPage]?.invoiceProofId) {
			if (watcher?.[currPage]?.invoiceProofId?.includes('prev_invoice')) {
				const invoicePage = watcher?.[currPage]?.invoiceProofId?.split('_')?.[2]
				const attachments = form.getFieldsValue(true)?.[invoicePage]?.attachments
				form.setFieldValue([currPage, 'attachments'], attachments)
			} else {
				const charge = job?.charges?.find((charge) => charge?.invoiceProof?._id === watcher?.[currPage]?.invoiceProofId)

				const attachments = charge?.invoiceProof?.documents?.map((doc) => ({
					url: doc,
					name: toReadableString(charge?.invoiceProof?.name),
					percent: 100,
					status: 'done',
				}))
				form.setFieldValue([currPage, 'attachments'], attachments)
			}
		}
	}, [watcher?.[currPage]?.invoiceProofId])

	const handleFinish = () => {
		form.validateFields().then(async () => {
			const values = form.getFieldsValue(true)
			if (Object.values(values || {})?.some((value) => value?.attachments?.some((attachment) => !attachment?.url))) {
				notification['warning']({
					message: 'File upload is still in progress',
				})
			} else {
				if (editPage) {
					const variables = Object.values(values || {})?.map((value) => ({
						updateChargeInput: {
							_id: charge?._id,
							amount: parseFloat(value?.amount),
							jobId: id,
							...(value?.attachments
								? {
										invoiceProof: {
											_id: charge?.invoiceProof?._id,
											name: value?.invoiceName || charge?.invoiceProof?.name,
											documents: value?.attachments?.map((attachment) => attachment?.url),
										},
								  }
								: {}),
							isUrgent: value?.isUrgent,
							comment: value?.comment,
							...(value?.accountId === 'addNew' || value?.entityId === 'addNewThirdParty'
								? {
										account: {
											accountNumber: value?.accountNumber,
											accountTitle: value?.accountTitle,
											bankName: value?.bankName,
											type: accountTypes[value?.payeeType],
											...(value?.entityId === 'addNewThirdParty'
												? {
														thirdParty: value?.thirdParty,
												  }
												: { entityId: value?.entityId }),
										},
								  }
								: value?.accountId !== charge?.paymentInvoice?.toAccount?._id
								? {
										accountId: value?.accountId,
								  }
								: {}),
							...(value?.customFieldProperty
								? {
										customFieldValues: Object.entries(value?.customFieldProperty).map((customField) => ({
											propertyKey: customField[0],
											value: customField[1]?.value,
											_id: customField[1]?._id,
										})),
								  }
								: {}),
						},
					}))?.[0]
					updateCharge({
						variables: variables,
					})
						.then((response) => {
							if (response?.data?.updateCharge?._id) {
								notification['success']({
									duration: 5,
									message: 'Charge updated successfully',
								})
								navigator('.')
							} else {
								notification['error']({
									duration: 5,
									message: 'There was an error in updating charge',
								})
							}
						})
						.catch((e) => {
							notification['error']({
								duration: 5,
								message: 'There was an error in updating charge',
								description: `${e.message}`,
							})
						})
				} else {
					const variables = Object.values(values || {})?.map((value) => ({
						amount: parseFloat(value?.amount),
						chargeTypeId: value?.chargeType,
						jobId: id,
						...(value?.customFieldProperty
							? {
									customFieldValues: Object.entries(value?.customFieldProperty)
										.map((customField) => {
											console.log({ key: selectedChargeType?.customFields?.find((field) => field.propertyKey === customField[0]), field: customField[1] })
											return {
												propertyKey: customField[0],
												value:
													selectedChargeType?.customFields?.find((field) => field.propertyKey === customField[0])?.type === 'upload'
														? customField[1]?.[0]?.url
														: customField[1],
											}
										})
										?.filter((val) => val.value && val.propertyKey),
							  }
							: {}),
						...(value?.invoiceProofId && !value?.invoiceProofId?.includes('prev_invoice')
							? {
									invoiceProofId: value?.invoiceProofId,
							  }
							: value?.attachments
							? {
									invoiceProof: value?.invoiceProofId?.includes('prev_invoice')
										? {
												name: values?.[value?.invoiceProofId?.split('_')?.[2]]?.invoiceName,
												documents: values?.[value?.invoiceProofId?.split('_')?.[2]]?.attachments?.map((attachment) => attachment?.url),
										  }
										: {
												name: value?.invoiceName,
												documents: value?.attachments?.map((attachment) => attachment?.url),
										  },
							  }
							: {}),
						isUrgent: value?.isUrgent,
						comment: value?.comment,
						...(value?.accountId === 'addNew' || value?.entityId === 'addNewThirdParty'
							? {
									account: {
										accountNumber: value?.accountNumber,
										accountTitle: value?.accountTitle,
										bankName: value?.bankName,
										type: accountTypes[value?.payeeType],
										...(value?.entityId === 'addNewThirdParty'
											? {
													thirdParty: value?.thirdParty,
											  }
											: { entityId: value?.entityId }),
									},
							  }
							: {
									accountId: value?.accountId,
							  }),
					}))
					console.log({ variables })
					createCharges({
						variables: {
							createChargesForJobsInput: {
								jobs: [
									{
										jobId: id,
										charges: variables,
									},
								],
							},
						},
					})
						.then((response) => {
							console.log({ response })
							if (response?.data?.createChargesForJobs?.every((charge) => charge?._id)) {
								notification['success']({
									duration: 5,
									message: 'Charge added successfully',
								})
								navigator('.')
							} else {
								notification['error']({
									duration: 5,
									message: 'There was an error in adding charge',
								})
							}
						})
						.catch((e) => {
							console.log({ e })
							notification['error']({
								duration: 5,
								message: 'There was an error in adding charge',
								description: `${e.message}`,
							})
						})
				}
			}
		})
	}
	return (
		<div className='flex flex-col h-full'>
			{numCosts > 1 ? (
				<div className='flex flex-row flex-grow-0 px-6 py-3 w-full items-center border-b border-black-75 bg-white'>
					<Steps direction='horizontal' size='small' current={currPage - 1}>
						{Array.from({ length: numCosts })?.map((costNumber, index) => (
							<Step
								key={`step-${index}`}
								className='items-center'
								status={index < currPage - 1 ? 'finish' : 'process'}
								title={<div className='text-white text-base w-0'>.</div>}
								icon={
									index < currPage - 1 ? (
										<div className='bg-primary-800 rounded-full flex h-6 w-6 items-center justify-center '>
											<Icon path={mdiCheck} className='text-white' size={0.7} />
										</div>
									) : (
										<div
											className={`rounded-full h-6 w-6 flex items-center justify-center font-[500] text-xs ${
												index === currPage - 1 ? 'bg-primary-800 text-white' : 'bg-black-75 text-white'
											}`}
										>
											<p className=''>{index + 1}</p>
										</div>
									)
								}
							/>
						))}
					</Steps>
				</div>
			) : null}
			<Form form={form} layout='vertical' className={`${numCosts > 1 ? 'h-[80%]' : 'h-[90%]'}`}>
				<div className='flex flex-col h-full overflow-y-scroll px-6 pt-6  space-y-6 '>
					<SidebarCard>
						<div className='bg-white px-4 py-6'>
							{RenderSchema([
								{
									type: 'select',
									label: 'Charge Type',
									name: [currPage, 'chargeType'],
									valueProperty: '_id',
									displayProperty: 'name',
									options: chargeTypes
										?.filter(
											(chargeType) =>
												editPage ||
												!(
													job?.charges?.some((charge) => charge?.chargeType?.name === chargeType?.name) ||
													Object.values(form.getFieldsValue(true) || {})?.some((page, index) => page?.chargeType === chargeType?._id && !(index + 1 === currPage))
												)
										)
										?.map((chargeType) => ({ _id: chargeType._id, name: toReadableString(chargeType.name) })),
									showSearch: true,
									required: true,
									disabled: editPage,
								},
								{
									type: 'input',
									label: 'Amount',
									inputType: 'number',
									name: [currPage, 'amount'],
									addonBefore: (
										<div className='flex flex-row items-center justify-center px-2'>
											<p className='text-sm font-medium text-black-300'>Rs.</p>
										</div>
									),
									disabled: editPage && charge?.status !== 'PENDING_AUTHENTICATION',
									required: true,
								},
							])}
							<div className='flex flex-row items-center'>
								<p className='text-[13px] font-medium text-black-300 pr-1'>Mark as Urgent</p>
								<Form.Item key={`isUrgent`} name={[currPage, 'isUrgent']} noStyle={true} shouldUpdate={true}>
									<CustomSwitchWrapper size='small' />
								</Form.Item>
							</div>
						</div>
						<div className='bg-white px-4 '>
							<div className='border-t border-dashed border-black-75 w-full h-[1px] pb-6' />
							{FormRender({
								type: 'input',
								label: 'Comment',
								inputType: 'textArea',
								initialValue: charge?.comment,
								name: [currPage, 'comment'],
								placeholder: 'Add Comment',
								required: selectedChargeType?.commentRequired,
							})}
							{selectedChargeType?.customFields?.length > 0
								? RenderSchema(
										selectedChargeType?.customFields?.map((field) =>
											editPage
												? [
														{ ...field, name: [currPage, 'customFieldProperty', field.propertyKey, 'value'] },
														{ type: 'input', hidden: true, name: [currPage, 'customFieldProperty', field.propertyKey, '_id'] },
												  ]
												: { ...field, name: [currPage, 'customFieldProperty', field.propertyKey] }
										)
								  )
								: null}
						</div>
					</SidebarCard>
					<SidebarCard title='Payee Details'>
						<div className='bg-white px-4 pt-6'>
							{RenderSchema([
								{
									type: 'select',
									name: [currPage, 'payeeType'],
									placeholder: 'Select Payee Type',
									options: [
										{ _id: 'BROKER', name: 'Broker' },
										{ _id: 'BILTY_INVESTOR', name: 'Bilty Investor' },
										{ _id: 'THIRD_PARTY', name: 'Other' },
									]?.filter((payee) => selectedChargeType?.payeeTypes?.includes(payee?._id)),
									valueProperty: '_id',
									displayProperty: 'name',
									showSearch: true,
									required: true,
								},
							])}
						</div>

						{watcher?.[currPage]?.payeeType === 'BROKER' ? (
							<div className='bg-black-50 px-4 pt-4 flex flex-col border-t border-black-75'>
								<p className='text-sm font-medium text-black-300 pb-4'>Broker Information</p>
								{RenderSchema([
									{
										type: 'input',
										initialValue: job?.broker?.name,
										name: [currPage, 'brokerName'],
										disabled: true,
									},
								])}
								<Form.Item key={`accountId`} name={[currPage, 'accountId']} noStyle={true} shouldUpdate={true} initialValue={job?.broker?.accounts?.[0]?._id}>
									<AccountNumberSelector accounts={job?.broker?.accounts} currPage={currPage} form={form} editPage={editPage} />
								</Form.Item>
							</div>
						) : ['BILTY_INVESTOR'].includes(watcher?.[currPage]?.payeeType) ? (
							<div className='bg-black-50 px-4 pt-4 flex flex-col border-t border-black-75'>
								<p className='text-sm font-medium text-black-300 pb-4'>Select {toReadableString(watcher?.[currPage]?.payeeType, 'ss_case')}</p>
								{RenderSchema([
									{
										type: 'select',
										placeholder: `Enter phone number or name`,
										name: [currPage, 'entityId'],
										elementClassName: 'bg-white',
										valueProperty: '_id',
										displayProperty: 'name',
										options: biltyInvestors?.map((investor) => ({
											_id: investor?._id,
											name: `${investor.name} (${formatPhoneNumber(investor.phoneNumber)})`,
										})),
										showSearch: true,
									},
								])}
								{watcher?.[currPage]?.entityId && (
									<Form.Item key={`accountId`} name={[currPage, 'accountId']} noStyle={true} shouldUpdate={true}>
										<AccountNumberSelector
											accounts={biltyInvestors?.find((investor) => investor?._id === watcher?.[currPage]?.entityId)?.accounts || []}
											currPage={currPage}
											editPage={editPage}
										/>
									</Form.Item>
								)}
							</div>
						) : watcher?.[currPage]?.payeeType === 'THIRD_PARTY' ? (
							<div className='bg-black-50 px-4 pt-4 flex flex-col border-t border-black-75'>
								<p className='text-sm font-medium text-black-300 pb-4'>Select Other</p>
								<div className='flex flex-row items-start'>
									<div className='w-2/3 flex flex-row items-center pr-4'>
										{FormRender({
											type: 'select',
											placeholder: `Enter account or name`,
											name: [currPage, 'entityId'],
											valueProperty: '_id',
											displayProperty: 'name',
											options: thirdParties?.map((party) => ({ _id: party?._id, name: `${party.name} (${formatPhoneNumber(party.phoneNumber)})` })),
											showSearch: true,
											elementClassName: 'bg-white',
											itemClassName: 'w-full',
										})}
									</div>
									<div className='w-1/3'>
										<Button
											onClick={() => {
												form.setFieldValue([currPage, 'entityId'], 'addNewThirdParty')
											}}
											className='border-2 border-dashed border-primary-800 bg-background text-primary-800  w-full font-semibold flex flex-row items-center justify-center h-12 rounded-lg'
										>
											Add New
										</Button>
									</div>
								</div>
								{watcher?.[currPage]?.entityId === 'addNewThirdParty' ? (
									<div className=' flex flex-col'>
										<div className='w-full'>
											{RenderSchema([
												{
													type: 'input',
													label: 'Third Party Name',
													name: [currPage, 'thirdParty', 'name'],
													required: true,
													placeholder: 'Enter name',
												},
												{
													type: 'phoneNumber',
													label: 'Phone Number',
													name: [currPage, 'thirdParty', 'phoneNumber'],
													required: true,
													placeholder: 'Enter Phone number',
												},
												{
													type: 'select',
													label: 'Third Party Type',
													name: [currPage, 'thirdParty', 'type'],
													valueProperty: '_id',
													displayProperty: 'name',
													options: [{ _id: 'LABOUR', name: 'Labour' }],
													required: true,
													elementClassName: 'bg-white',
													placeholder: 'Enter Type',
												},
											])}
										</div>
									</div>
								) : null}
								{watcher?.[currPage]?.entityId && (
									<Form.Item
										key={`accountId`}
										name={[currPage, 'accountId']}
										noStyle={true}
										shouldUpdate={true}
										initialValue={!editPage && thirdParties?.find((party) => party?._id === watcher?.[currPage]?.entityId)?.accounts?.[0]}
									>
										<AccountNumberSelector
											accounts={thirdParties?.find((party) => party?._id === watcher?.[currPage]?.entityId)?.accounts || []}
											allowNew={true}
											currPage={currPage}
											editPage={editPage}
										/>
									</Form.Item>
								)}
							</div>
						) : null}
					</SidebarCard>
					<SidebarCard title='Attachments'>
						<div
							className='bg-black-50 px-4 pt-4 flex flex-col'
							style={{
								maxHeight:
									100 +
									watcher?.[currPage]?.attachments?.length * 100 +
									(watcher?.[currPage]?.attachments?.length > 0 && !watcher?.[currPage]?.invoiceProofId ? 80 : 0),
								transitionProperty: 'max-height',
								transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
								transitionDuration: '1000ms',
							}}
						>
							<div className='flex flex-row items-center'>
								<div className='basis-2/3'>
									{FormRender({
										type: 'select',
										key: 'invoiceProofId',
										name: [currPage, 'invoiceProofId'],
										elementClassName: 'bg-white',
										valueProperty: '_id',
										displayProperty: 'name',
										placeholder: 'Select Existing Invoice',
										options:
											[...(job?.charges?.filter((charge) => charge?.invoiceProof?._id) || []), ...(previousInvoiceProofs || [])]?.length > 0
												? [
														...new Map(
															[
																...(job?.charges
																	?.filter((charge) => charge?.invoiceProof?._id)
																	?.map((charge) => ({ _id: charge?.invoiceProof?._id, name: toReadableString(charge?.invoiceProof?.name) })) || []),
																...(previousInvoiceProofs || []),
															].map((option) => [JSON.stringify(option), option])
														).values(),
												  ]
												: [],
										required: (selectedChargeType?.invoiceProofRequired && !watcher?.[currPage]?.attachments) || false,
									})}
								</div>
								<div className='basis-1/3 pl-[10px]'>
									{FormRender({
										type: 'upload',
										uploadType: 'button',
										name: [currPage, 'attachments'],
										allowMultiple: true,
										placeholder: 'Upload New',
										required: (selectedChargeType?.invoiceProofRequired && !watcher?.[currPage]?.invoiceProofId) || false,
										// required: true,
										showUploadList: false,
									})}
								</div>
							</div>
							{watcher?.[currPage]?.attachments?.length > 0 && !watcher?.[currPage]?.invoiceProofId ? (
								<div>
									{FormRender({
										type: 'input',
										name: [currPage, 'invoiceName'],
										placeholder: 'Select Invoice Name',
										required: true,
									})}
								</div>
							) : null}
							{watcher?.[currPage]?.attachments?.map((attachment, index) => {
								return (
									<div
										className={`flex flex-col rounded-lg overflow-clip border border-black-75 w-full bg-white py-4 px-4 mb-4 transition-[max-height] duration-1000  ease-in-out ${
											attachment.status === 'uploading' ? 'max-h-24' : 'max-h-[72px]'
										}`}
									>
										<div className='flex flex-row justify-between items-center'>
											<div className='flex flex-row justify-start items-center'>
												<div className='h-9 w-9 rounded-lg flex flex-row items-center justify-center'>
													{attachment.status === 'uploading' ? (
														<Spin size='default' className='pt-1' />
													) : (
														<div className='h-full w-full bg-red-50 rounded-lg flex flex-row items-center justify-center'>
															<Icon path={mdiFileDocument} size={1} className='text-red-400' />
														</div>
													)}
												</div>
												<p className='font-semibold text-sm text-black-300 pl-4'>{attachment.name || `Attachment ${index + 1}`}</p>
											</div>
										</div>

										<Progress
											className={`transition-[opacity] duration-500 ease-in-out ${attachment.status === 'uploading' ? 'opacity-100' : 'opacity-0'}`}
											percent={parseInt(attachment.percent)}
										/>
									</div>
								)
							})}
						</div>
					</SidebarCard>
					<div className='pb-32' />
				</div>
			</Form>
			<div className='w-full right-0 left-0 absolute bottom-0 bg-white rounded-t-lg py-6 shadow-[0_-5px_10px_0_rgba(0,0,0,0.1)] px-6'>
				{numCosts === 1 ? (
					<div className='flex flex-col items-center justify-center w-full'>
						{!editPage ? (
							<Button
								onClick={() => {
									setCurrPage(numCosts + 1)
									setNumCosts((prev) => prev + 1)
								}}
								className='border-2 border-dashed border-primary-800 bg-background text-primary-800  w-full font-semibold flex flex-row items-center justify-center h-12 rounded-lg'
							>
								Add More
							</Button>
						) : null}
						<div className={`flex flex-row w-full ${editPage ? '' : 'pt-4'}`}>
							<Button onClick={handleFinish} loading={createChargeLoading || updateChargeLoading || createChargesLoading} className='btn btn-primary w-full'>
								Submit
							</Button>
						</div>
					</div>
				) : (
					<div className='flex flex-col items-center justify-center w-full'>
						<Button
							onClick={() => {
								setCurrPage(numCosts + 1)

								setNumCosts((prev) => prev + 1)
							}}
							className='border-2 border-dashed border-primary-800 bg-background text-primary-800  w-full font-semibold flex flex-row items-center justify-center h-12 rounded-lg'
						>
							Add More
						</Button>

						<div className='flex flex-row space-x-4 w-full pt-4'>
							<Button
								onClick={() => setCurrPage((prev) => prev - 1)}
								disabled={currPage === 1}
								className=' !bg-black-300 !text-white hover:!opacity-50 hover:!text-white hover:border-none  w-1/2 font-semibold flex flex-row items-center justify-center h-12 rounded-lg'
							>
								Back
							</Button>
							{currPage === numCosts ? (
								<Button loading={createChargeLoading || createChargesLoading} onClick={handleFinish} className='btn btn-primary w-1/2 hover:opacity-50'>
									Submit
								</Button>
							) : (
								<Button onClick={() => setCurrPage((prev) => prev + 1)} className='btn btn-primary w-1/2'>
									Next
								</Button>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

const AddEditDeductionPage = (props) => {
	const { pathname } = useLocation()
	const { segment, id, revenueId } = useParams()
	const { loading, tab } = useOutletContext()
	const [form] = Form.useForm()
	const navigator = useNavigate()
	const [revenueLoaded, setRevenueLoaded] = useState(false)
	const [createRevenue, createRevenueLoading] = useMutate({ type: 'Revenue', action: 'create' })
	const [updateRevenue, updateRevenueLoading] = useMutate({ type: 'Revenue', action: 'update' })

	const job = useRecoilValue(postAssignmentSidebarSelector({ segment, tab, id }))
	const revenue = useRecoilValue(jobRevenueSelector({ segment, tab, id, revenueId }))

	const revenueTypes = useRecoilValue(revenueTypesAtom)
	const thirdParties = useRecoilValue(thirdPartiesAtom)

	useFetch({ type: 'RevenueType', atomKey: 'revenueTypes' })
	useFetch({ type: 'ThirdParty', atomKey: 'thirdParties' })

	const watcher = Form.useWatch(null, form)
	const editPage = pathname?.split('/')?.at(-1) === 'edit'

	const selectedRevenueType = useMemo(
		() => revenueTypes?.find((revenueType) => revenueType?._id === watcher?.revenueType),
		[revenueTypes, watcher?.revenueType]
	)
	useEffect(() => {
		if (revenue && editPage && !revenueLoaded) {
			const initialValues = {
				revenueType: revenue?.revenueType?._id,
				amount: revenue?.amount,
				comment: revenue?.comment,
				paymentInvoice: revenue?.paymentInvoice?._id,
				invoiceProofId: revenue?.invoiceProof?._id,
			}
			console.log({ initialValues })
			setRevenueLoaded(true)
			form.setFieldsValue(initialValues)
		}
	}, [revenue])
	useEffect(() => {
		if (watcher?.invoiceProofId) {
			const revenue = job?.revenues?.find((revenue) => revenue?.invoiceProof?._id === watcher?.invoiceProofId)

			const attachments = revenue?.invoiceProof?.documents?.map((doc) => ({
				url: doc,
				name: toReadableString(revenue?.invoiceProof?.name),
				percent: 100,
				status: 'done',
			}))
			console.log({ attachments })
			form.setFieldValue('attachments', attachments)
		}
	}, [watcher?.invoiceProofId])
	const handleFinish = () => {
		form.validateFields().then(async (values) => {
			console.log({ values })
			if (values?.attachments?.some((attachment) => !attachment?.url)) {
				notification['warning']({
					message: 'File upload is still in progress',
				})
			} else {
				if (editPage) {
					const variables = {
						updateRevenueInput: {
							_id: revenue?._id,
							amount: parseFloat(values?.amount),
							comment: values?.comment,
							...(values?.paymentInvoice !== revenue?.paymentInvoice?._id ? { paymentInvoiceId: values?.paymentInvoice } : {}),
							...(values?.attachments
								? {
										invoiceProof: {
											_id: revenue?.invoiceProof?._id,
											name: values?.invoiceName || revenue?.invoiceProof?.name,
											documents: values?.attachments?.map((attachment) => attachment?.url),
										},
								  }
								: {}),
						},
					}
					console.log({ variables })
					updateRevenue({
						variables: variables,
					})
						.then((response) => {
							console.log({ response })
							if (response?.data?.updateRevenueForJob?._id) {
								notification['success']({
									duration: 5,
									message: 'Deduction updated successfully',
								})
								navigator('.')
							} else {
								notification['error']({
									duration: 5,
									message: 'There was an error in updating deduction',
								})
							}
						})
						.catch((e) => {
							notification['error']({
								duration: 5,
								message: 'There was an error in updating deduction',
								description: `${e.message}`,
							})
						})
				} else {
					const variables = {
						createRevenueInput: {
							amount: parseFloat(values?.amount),
							revenueTypeId: values?.revenueType,
							jobId: id,
							paymentInvoiceId: values?.paymentInvoice,
							comment: values?.comment,
							...(values?.attachments
								? {
										invoiceProof: {
											documents: values?.attachments?.map((attachment) => attachment?.url),
										},
								  }
								: {}),
						},
					}
					console.log({ variables })
					createRevenue({
						variables: variables,
					})
						.then((response) => {
							console.log({ response })
							if (response?.data?.createRevenueForJob?._id) {
								notification['success']({
									duration: 5,
									message: 'Deduction added successfully',
								})
								navigator('.')
							} else {
								notification['error']({
									duration: 5,
									message: 'There was an error in adding deduction',
								})
							}
						})
						.catch((e) => {
							notification['error']({
								duration: 5,
								message: 'There was an error in adding deduction',
								description: `${e.message}`,
							})
						})
				}
			}
		})
	}
	return (
		<div className='flex flex-col h-full'>
			<Form form={form} layout='vertical' className={'h-[90%]'}>
				<div className='flex flex-col h-full overflow-y-scroll px-6 pt-6  space-y-6 '>
					<SidebarCard>
						<div className='bg-white px-4 pt-6'>
							{RenderSchema([
								[
									{
										type: 'select',
										label: 'Revenue Type',
										name: ['revenueType'],
										valueProperty: '_id',
										displayProperty: 'name',
										options: revenueTypes.map((revenueType) => ({ _id: revenueType._id, name: toReadableString(revenueType.name) })),
										showSearch: true,
										required: true,
										disabled: editPage,
									},
									{
										type: 'input',
										label: 'Amount',
										inputType: 'number',
										name: ['amount'],
										addonBefore: (
											<div className='flex flex-row items-center justify-center px-2'>
												<p className='text-sm font-medium text-black-300'>Rs.</p>
											</div>
										),
										disabled: editPage && revenue?.status !== 'PENDING_AUTHENTICATION',
										required: true,
									},
								],
								{
									type: 'select',
									name: ['paymentInvoice'],
									label: 'Deduct From',
									placeholder: 'Select who to deduct from',
									valueProperty: '_id',
									displayProperty: 'name',
									options:
										job?.charges || job?.revenues
											? [
													...new Map(
														[...job?.revenues, ...job?.charges].map((revenue) => [
															JSON.stringify({ _id: revenue?.paymentInvoice?._id, name: revenue?.paymentInvoice?.toAccount?.accountTitle }),
															{ _id: revenue?.paymentInvoice?._id, name: revenue?.paymentInvoice?.toAccount?.accountTitle },
														])
													).values(),
											  ]
											: [],
									showSearch: true,
									required: true,
								},
								{
									type: 'input',
									label: 'Comment',
									inputType: 'textArea',
									name: ['comment'],
									placeholder: 'Add Comment',
									required: selectedRevenueType?.commentRequired,
									// required: true,
								},
							])}
						</div>
					</SidebarCard>
					<SidebarCard title='Attachments'>
						<div
							className='bg-black-50 px-4 pt-4 flex flex-col'
							style={{
								maxHeight: 100 + watcher?.attachments?.length * 100,
								transitionProperty: 'max-height',
								transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
								transitionDuration: '1000ms',
							}}
						>
							<div className='flex flex-row items-center'>
								<div className='w-full'>
									{FormRender({
										type: 'input',
										hidden: true,
										name: ['invoiceProofId'],
									})}
									{FormRender({
										type: 'upload',
										uploadType: 'button',
										name: ['attachments'],
										allowMultiple: true,
										placeholder: 'Upload New',
										required: selectedRevenueType?.invoiceProofRequired,
										showUploadList: false,
									})}
								</div>
							</div>
							{watcher?.attachments?.map((attachment, index) => {
								return (
									<div
										className={`flex flex-col rounded-lg overflow-clip border border-black-75 w-full bg-white py-4 px-4 mb-4 transition-[max-height] duration-1000  ease-in-out ${
											attachment.status === 'uploading' ? 'max-h-24' : 'max-h-[72px]'
										}`}
									>
										<div className='flex flex-row justify-between items-center'>
											<div className='flex flex-row justify-start items-center'>
												<div className='h-9 w-9 rounded-lg flex flex-row items-center justify-center'>
													{attachment.status === 'uploading' ? (
														<Spin size='default' className='pt-1' />
													) : (
														<div className='h-full w-full bg-red-50 rounded-lg flex flex-row items-center justify-center'>
															<Icon path={mdiFileDocument} size={1} className='text-red-400' />
														</div>
													)}
												</div>
												<p className='font-semibold text-sm text-black-300 pl-4'>{attachment.name || `Attachment ${index + 1}`}</p>
											</div>
										</div>

										<Progress
											className={`transition-[opacity] duration-500 ease-in-out ${attachment.status === 'uploading' ? 'opacity-100' : 'opacity-0'}`}
											percent={parseInt(attachment.percent)}
										/>
									</div>
								)
							})}
						</div>
					</SidebarCard>
					<div className='pb-32' />
				</div>
			</Form>
			<div className='w-full right-0 left-0 absolute bottom-0 bg-white rounded-t-lg py-6 shadow-[0_-5px_10px_0_rgba(0,0,0,0.1)] px-6'>
				<div className='flex flex-col items-center justify-center w-full'>
					<div className='flex flex-row w-full pt-4'>
						<Button onClick={handleFinish} loading={createRevenueLoading || updateRevenueLoading} className='btn btn-primary w-full'>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}
const SlackThreadPage = (props) => {}
const ChargeDetailsPage = (props) => {
	const { segment, id, chargeId, revenueId } = useParams()
	const { loading, tab } = useOutletContext()
	const charge = useRecoilValue(jobChargeSelector({ segment, tab, id, chargeId }))
	const revenue = useRecoilValue(jobRevenueSelector({ segment, tab, id, revenueId }))
	return (
		<div className='flex flex-col px-6 py-6 space-y-6 overflow-y-scroll h-full'>
			<SidebarCard title='Statuses'>
				<div className='flex flex-col'>
					{[{ name: 'Authentication Status', status: (charge || revenue)?.status, statusHistory: (charge || revenue)?.statusHistory }]?.map((status, index) => (
						<StatusDetailCard status={status} index={index} length={1} />
					))}
				</div>
			</SidebarCard>
			<PayeeInformation charge={charge} revenue={revenue} />
			{(charge?.comment || revenue?.comment) && <CommentsCard charge={charge} revenue={revenue} />}
			{(charge?.invoiceProof || revenue?.invoiceProof) && <AttachmentsCard charge={charge} revenue={revenue} />}
		</div>
	)
}
const JobDetailsPage = ({ modalData, setModalData }) => {
	const { segment, id } = useParams()
	const { loading, tab } = useOutletContext()
	const job = useRecoilValue(postAssignmentSidebarSelector({ segment, tab, id }))
	const supplierLoading = useFetch({ type: 'Suppliers', atomKey: 'supplier' })
	const brokerLoading = useFetch({ type: 'Brokers', atomKey: 'broker' })
	const user = useRecoilValue(userAtom)?.[0]
	const superUsers = useRecoilValue(superUserAtom)
	const supplyAdmins = useRecoilValue(supplyAdminAtom)
	const superUser = isSuperUser(user, superUsers)
	const supplyAdmin = isSuperUser(user, supplyAdmins)
	const formData = useRecoilValue(postAssignmentDataSelector())

	useEffect(() => {
		setModalData({
			data: {
				superUser,
				supplyAdmin,
				job,
				formData: formData,
			},
		})
	}, [superUser, supplyAdmin, job, formData])

	return (
		<SliverList
			heightClass='h-[100%]'
			items={[
				{
					header: () => (
						<div className=' absolute top-0 z-10 w-full px-6 py-3 border-b border-black-75 bg-white flex flex-row items-center justify-between'>
							<p className='font-semibold text-sm  text-black-300'>Movement Details</p>
							<Icon path={mdiChevronDown} size={1.2} className='text-black-300' />
						</div>
					),
					content: () => (
						<div className='flex flex-col px-6 pt-20 pb-6  space-y-6'>
							<ShipmentDetailsCard job={job} />
						</div>
					),
				},
				{
					header: () => (
						<div className='  w-full px-6 py-3 border-y border-black-75 bg-white flex flex-row items-center justify-between'>
							<p className='font-semibold text-sm text-black-300'>Payment Details</p>
							<Icon path={mdiChevronDown} size={1.2} className='text-black-300' />
						</div>
					),
					content: () => (
						<div className='flex flex-col px-6 py-6  space-y-6'>
							<ChargesOverviewCard />
							<ChargesDetailsCard job={job} />
							<DeductionDetailsCard job={job} />
							<PaymentTermsCard job={job} />
						</div>
					),
				},
			]}
		/>
	)
}

const DrawerTitle = ({ currLocation, canBack, canEdit }) => {
	const { pathname } = useLocation()

	return (
		<div className={`w-full border-b border-black-75 flex flex-row items-center justify-between h-[72px] ${canBack ? 'pl-3' : 'pl-6'} pr-6`}>
			<div className='flex flex-row items-center justify-start'>
				{canBack ? (
					<Link to={-1}>
						<Icon path={mdiChevronLeft} size={1.6} className='text-primary-800' />
					</Link>
				) : null}
				<p className={`text-lg text-black-400 font-semibold ${canBack ? '' : ''} `}>{currLocation}</p>
			</div>
			{canEdit ? (
				<Link to={`${pathname}/edit`}>
					<div className='flex flex-row items-center justify-end'>
						<p className={`text-base text-primary-800 font-semibold pr-1`}>Edit</p>
						<Icon path={mdiPencil} size={0.7} className='text-primary-800' />
					</div>
				</Link>
			) : null}
		</div>
	)
}

const JobSidebar = (props) => {
	const { segment, id, chargeId, revenueId } = useParams()
	const { loading, tab } = useOutletContext()
	const navigator = useNavigate()
	const { pathname } = useLocation()
	const currLocation = pathname.split('/')?.reverse()?.[0]
	const job = useRecoilValue(postAssignmentJobByIDSelector({ segment, tab, id }))
	const [modalData, setModalData] = useState({})

	const basePattern = '/:segment/post-assignment/job/:id'
	const pages = useMemo(
		() => [
			{
				pattern: basePattern + '/new-charge/add-third-party?',
				breadCrumbs: [`Job ${job?.jobNumber}`, 'Add Charge'],
				title: `Add Charge`,
				component: (props) => <AddEditCostPage {...props} modalData={modalData} setModalData={setModalData} />,
				canBack: true,
				// showBreadCrumbs: true,
			},
			{
				pattern: basePattern + '/new-revenue',
				breadCrumbs: [`Job ${job?.jobNumber}`, 'Add Deduction'],
				title: `Add Deduction`,
				component: (props) => <AddEditDeductionPage {...props} modalData={modalData} setModalData={setModalData} />,
				canBack: true,
				// showBreadCrumbs: true,
			},
			{
				pattern: basePattern + '/charge/:chargeId',
				title: `${toReadableString(job?.charges?.find((charge) => charge?._id === chargeId)?.chargeType?.name)}`,
				component: (props) => <ChargeDetailsPage {...props} modalData={modalData} setModalData={setModalData} />,
				canBack: true,
				canEdit: true,
			},
			{
				pattern: basePattern + '/revenue/:revenueId',
				title: `${toReadableString(job?.revenues?.find((revenue) => revenue?._id === revenueId)?.revenueType?.name)}`,
				component: (props) => <ChargeDetailsPage {...props} modalData={modalData} setModalData={setModalData} />,
				canBack: true,
				canEdit: true,
			},
			{
				pattern: basePattern + '/edit?/action?',
				title: `Job ${job?.jobNumber}`,
				component: (props) => <JobDetailsPage {...props} modalData={modalData} setModalData={setModalData} />,
			},
			{
				pattern: basePattern + '/charge/:chargeId/edit',
				title: `Edit Charge`,
				component: (props) => <AddEditCostPage {...props} modalData={modalData} setModalData={setModalData} />,
				canBack: true,
			},
			{
				pattern: basePattern + '/revenue/:revenueId/edit',
				title: `Edit Deduction`,
				component: (props) => <AddEditDeductionPage {...props} modalData={modalData} setModalData={setModalData} />,
				canBack: true,
			},
		],
		[job, pathname, chargeId, id]
	)
	const currPage = useMemo(() => pages.find((page) => checkPatternMatch(pathname, page.pattern)), [pathname, pages])
	return (
		<>
			<Form.Provider>
				<Drawer
					width={500}
					open={true}
					title={<DrawerTitle currLocation={currPage?.title} canBack={currPage?.canBack} canEdit={currPage?.canEdit} />}
					closable={false}
					placement='right'
					onClose={() => navigator(`/${segment}/post-assignment`)}
				>
					<div className='flex flex-col h-full w-full bg-secondaryBackground overflow-clip relative'>
						{currPage.showBreadCrumbs ? (
							<div className={`w-full bg-white border-b border-black-75 flex flex-row items-center justify-between py-3 px-6`}>
								<Breadcrumb>
									{currPage?.breadCrumbs?.map((breadcrumb) => (
										<Breadcrumb.Item>{breadcrumb.title}</Breadcrumb.Item>
									))}
								</Breadcrumb>
							</div>
						) : null}
						<currPage.component />
					</div>
				</Drawer>
				<Outlet
					context={{
						loading: loading,
						modalType:
							currLocation === 'payments'
								? 'editPayments'
								: currLocation === 'paymentTerms'
								? 'editPaymentTerms'
								: currLocation === 'add-third-party'
								? 'addThirdParty'
								: 'editAssignment',
						props: modalData,
					}}
				/>
			</Form.Provider>
		</>
	)
}

export default JobSidebar
