import { Button } from 'antd'
import { useLocation } from 'react-router-dom'
import { Layout } from 'antd'

const FilteredRowsHeader = ({ selectedRows, filtered, total, currLocation, tab, enableBulk, enableSplit, navigator, name, enableBulkAuth }) => {
	const { pathname } = useLocation()

	return (
		<Layout.Header className='bg-white pl-[18px] pr-0 rounded-t-lg border-b overflow-y-hidden !h-[64px]'>
			<div className={' text-sm flex flex-row items-center justify-between h-full'}>
				{!selectedRows || selectedRows?.length <= 0 ? (
					<div className='flex flex-row text-black-400'>
						<div className='font-[400]'>Showing&nbsp;</div>
						<div className='font-[700] pr-1'>{filtered}</div>
						<div className='font-[400]'>{(filtered === total ? 'total ' : 'filtered ') + `${name || 'jobs'}`}</div>
					</div>
				) : (
					<div className='flex flex-row'>
						<div className='font-[400] text-black-400 pr-1'>Selected</div>
						<div className='font-[700] text-primary-500 pr-1'>{selectedRows?.length}</div>
						<div className='font-[400] text-black-400 pr-1'>of</div>
						<div className='font-[700] text-black-400 pr-1'>{`${filtered}`}</div>
						<div className='font-[400] text-black-400'>{(filtered === total ? 'total ' : 'filtered ') + `${name || 'jobs'}`}</div>
					</div>
				)}
				<div className='flex flex-row'>
					{currLocation === 'assignment' && tab !== 'lot' && (
						<Button
							title='Please select a job to split'
							className='btn-primary my-auto h-[40px] mr-4'
							disabled={!enableSplit}
							onClick={() => navigator(`${pathname?.split('/')?.slice(0, 3)?.join('/')}/split-job/${selectedRows?.[0]}`)}
						>
							Split Job
						</Button>
					)}
					{currLocation === 'assignment' && tab !== 'local' && tab !== 'lot' && (
						<Button
							title='Please filter on lane, weight and vehicle type to enable button'
							className='btn-primary my-auto h-[40px] mr-4'
							disabled={!enableBulk}
							onClick={() => navigator(`${pathname?.split('/')?.slice(0, 3)?.join('/')}/bulk-assign`)}
						>
							Assign Jobs
						</Button>
					)}
					{currLocation === 'post-assignment' && (
						<Button
							title='Please select one or more jobs to enable bulk actions'
							className='btn-primary my-auto h-[40px] mr-4'
							disabled={!enableBulkAuth}
							onClick={() => navigator(`${pathname?.split('/')?.slice(0, 3)?.join('/')}/bulk-authenticate`)}
						>
							Authenticate Charges
						</Button>
					)}
					{currLocation === 'post-assignment' && (
						<Button
							title='Please select one or more jobs to enable bulk actions'
							className='btn-primary my-auto h-[40px] mr-4'
							disabled={!enableBulkAuth}
							onClick={() => navigator(`${pathname?.split('/')?.slice(0, 3)?.join('/')}/bulk-add-charges`)}
						>
							Add new charges
						</Button>
					)}
				</div>

				{['brokers', 'suppliers', 'bilty-investors'].includes(currLocation) && (
					<Button
						className='btn-primary my-auto h-[40px] mr-4'
						onClick={() => navigator(`${currLocation === 'brokers' ? 'broker' : currLocation === 'bilty-investors' ? 'bilty-investor' : 'supplier'}/action/new`)}
					>
						Add New
					</Button>
				)}
			</div>
		</Layout.Header>
	)
}

export default FilteredRowsHeader
