import { gql } from '@apollo/client'
import { BILTY_FIELDS, BROKER_FIELDS, JOB_FIELDS, QUOTATION_FIELDS, SUB_LONGHAUL_FIELDS, SUPPLIER_FIELDS, VEHICLE_FIELDS } from './fragments'

export const CREATE_QUOTATION = gql`
	${QUOTATION_FIELDS}
	mutation CreateQuotation($createQuotationInput: CreateQuotationInput) {
		createQuotation(createQuotationInput: $createQuotationInput) {
			...QuotationFields
		}
	}
`

export const UPDATE_QUOTATION = gql`
	${QUOTATION_FIELDS}
	mutation UpdateQuotation($updateQuotationInput: UpdateQuotationInput) {
		updateQuotation(updateQuotationInput: $updateQuotationInput) {
			...QuotationFields
		}
	}
`

export const DELETE_QUOTATION = gql`
	mutation DeleteQuotation($deleteQuotationInput: DeleteQuotationInput) {
		deleteQuotation(deleteQuotationInput: $deleteQuotationInput)
	}
`

export const UPDATE_JOB = gql`
	${JOB_FIELDS}
	mutation UpdateJob($updateJobInput: UpdateJobInput!) {
		updateJob(updateJobInput: $updateJobInput) {
			...JobFields
		}
	}
`
export const UPDATE_BILTY = gql`
	${BILTY_FIELDS}
	mutation UpdateBilties($updateBiltiesInput: UpdateBiltiesInput!) {
		updateBilties(updateBiltiesInput: $updateBiltiesInput) {
			...BiltyFields
		}
	}
`

export const ASSIGN_JOBS_TO_SUPPLIER = gql`
	mutation AssignJobstoSupplier($assignJobstoSupplierInput: [assignJobstoSupplierInput!]!) {
		assignJobstoSupplier(assignJobstoSupplierInput: $assignJobstoSupplierInput)
	}
`
export const SLIP_DRIVER_IN_JOB = gql`
	mutation SlipDriverInJob($id: String!) {
		slipDriverInJob(_id: $id) {
			_id
			jobNumber
		}
	}
`

export const CREATE_LONGHAUL_SUBORDER = gql`
	mutation CreateSubLongHaulOrder($createSubLongHaulOrderInput: CreateSubLongHaulOrderInput) {
		createSubLongHaulOrder(createSubLongHaulOrderInput: $createSubLongHaulOrderInput) {
			_id
		}
	}
`

export const UPDATE_LONGHAUL_ORDER = gql`
	mutation UpdateLongHaulOrder($updateLongHaulOrderInput: UpdateLongHaulOrderInput!) {
		updateLongHaulOrder(updateLongHaulOrderInput: $updateLongHaulOrderInput) {
			_id
		}
	}
`

export const MATCH_ORDER_TO_JOB = gql`
	mutation MatchOrderToJob($matchOrderToJobInput: MatchOrderToJobInput) {
		matchOrderToJob(matchOrderToJobInput: $matchOrderToJobInput) {
			JobId
			JobNumber
			message
		}
	}
`

export const CREATE_BROKER = gql`
	${BROKER_FIELDS}
	mutation CreateBroker($createBrokerInput: CreateBrokerInput) {
		createBroker(createBrokerInput: $createBrokerInput) {
			...BrokerFields
		}
	}
`

export const UPDATE_BROKER = gql`
	${BROKER_FIELDS}
	mutation UpdateBroker($updateBrokerInput: UpdateBrokerInput) {
		updateBroker(updateBrokerInput: $updateBrokerInput) {
			...BrokerFields
		}
	}
`

export const CREATE_SUPPLIER = gql`
	${SUPPLIER_FIELDS}
	mutation CreateSupplier($createSupplierInput: CreateSupplierInput) {
		createSupplier(createSupplierInput: $createSupplierInput) {
			...SupplierFields
		}
	}
`

export const UPDATE_SUPPLIER = gql`
	${SUPPLIER_FIELDS}
	mutation UpdateSupplier($updateSupplierInput: UpdateSupplierInput) {
		updateSupplier(updateSupplierInput: $updateSupplierInput) {
			_id
			...SupplierFields
		}
	}
`

export const SPLIT_JOB = gql`
	${JOB_FIELDS}
	mutation SplitJob($splitJobInput: SplitJobInput!) {
		splitJob(splitJobInput: $splitJobInput) {
			...JobFields
		}
	}
`

export const UPDATE_VEHICLE = gql`
	${VEHICLE_FIELDS}
	mutation UpdateVehicles($where: VehicleWhere, $update: VehicleUpdateInput) {
		updateVehicles(where: $where, update: $update) {
			vehicles {
				...VehicleFields
			}
		}
	}
`

export const CREATE_VEHICLE = gql`
	${VEHICLE_FIELDS}
	mutation CreateVehicle($createVehicleInput: CreateVehicleInput) {
		createVehicle(createVehicleInput: $createVehicleInput) {
			...VehicleFields
		}
	}
`

export const CREATE_STATUS = gql`
	mutation CreateStatus($createStatusInput: CreateStatusInput) {
		createStatus(createStatusInput: $createStatusInput)
	}
`

export const CREATE_PARENT_STATUS = gql`
	mutation CreateParentStatus($createParentStatusInput: CreateParentStatusInput) {
		createParentStatus(createParentStatusInput: $createParentStatusInput) {
			_id
		}
	}
`

export const CREATE_CHARGE = gql`
	mutation CreateCharge($createChargeInput: CreateChargeInput!) {
		createCharge(createChargeInput: $createChargeInput) {
			_id
			amount
			chargeType {
				_id
				name
			}
		}
	}
`

export const CREATE_MULTIPLE_CHARGES = gql`
	mutation CreateChargesForJobs($createChargesForJobsInput: CreateChargesForJobsInput) {
		createChargesForJobs(createChargesForJobsInput: $createChargesForJobsInput) {
			_id
			amount
			chargeType {
				_id
				name
			}
		}
	}
`

export const CREATE_REVENUE = gql`
	mutation CreateRevenueForJob($createRevenueInput: CreateRevenueForJobInput!) {
		createRevenueForJob(createRevenueInput: $createRevenueInput) {
			_id
			amount
			revenueType {
				_id
				name
			}
		}
	}
`

export const UPDATE_CHARGE = gql`
	mutation UpdateCharge($updateChargeInput: UpdateChargeInput!) {
		updateCharge(updateChargeInput: $updateChargeInput) {
			_id
			amount
			status
			comment
			chargeType {
				_id
				name
			}
		}
	}
`

export const UPDATE_REVENUE = gql`
	mutation UpdateRevenueForJob($updateRevenueInput: UpdateRevenueInput!) {
		updateRevenueForJob(updateRevenueInput: $updateRevenueInput) {
			_id
			amount
			status
			comment
			revenueType {
				_id
				name
			}
		}
	}
`

export const AUTHENTICATE_REVENUES = gql`
	mutation AuthenticateRevenues($authenticateRevenuesInput: AuthenticateRevenuesInput!) {
		authenticateRevenues(authenticateRevenuesInput: $authenticateRevenuesInput) {
			_id
			amount
			status
			comment
			revenueType {
				_id
				name
			}
		}
	}
`

export const AUTHENTICATE_CHARGES = gql`
	mutation AuthenticateCharges($authenticateChargesInput: AuthenticateChargesInput!) {
		authenticateCharges(authenticateChargesInput: $authenticateChargesInput) {
			_id
			status
			amount
			comment
			chargeType {
				_id
				name
			}
		}
	}
`

export const AUTHENTICATE_AND_UPDATE_CHARGES = gql`
	mutation AuthenticateAndUpdateCharges($authenticateAndUpdateChargesInput: AuthenticateAndUpdateChargesInput!) {
		authenticateAndUpdateCharges(authenticateAndUpdateChargesInput: $authenticateAndUpdateChargesInput) {
			_id
			status
			amount
			comment
			chargeType {
				_id
				name
			}
		}
	}
`

export const CREATE_BILTY_INVESTOR = gql`
	mutation CreateBiltyInvestor($createBiltyInvestorInput: CreateBiltyInvestorInput!) {
		createBiltyInvestor(createBiltyInvestorInput: $createBiltyInvestorInput) {
			_id
		}
	}
`
export const UPDATE_BILTY_INVESTOR = gql`
	mutation UpdateBiltyInvestor($updateBiltyInvestorInput: UpdateBiltyInvestorInput!) {
		updateBiltyInvestor(updateBiltyInvestorInput: $updateBiltyInvestorInput) {
			_id
		}
	}
`
