import { Table } from 'antd'
// import { VariableSizeGrid as Grid } from 'react-window'
// import ResizeObserver from 'rc-resize-observer'
import { useState, useEffect, useRef } from 'react'

const DynamicTable = (props) => {
	const {
		data,
		loading,
		columns,
		columnProps,
		onRow,
		onSelect,
		hideHeader,
		className,
		expandable,
		selectedRowKeys: globalSelectedRowKeys,
		expandedRowKeys,
		rowClassName,
		autoResetSelection,
		scroll,
		rowHeight,
	} = props
	const finalColumns = columns(columnProps)
	const [selectedRowKeys, setSelectedRowsKeys] = useState([])
	// const [tableWidth, setTableWidth] = useState(0)
	// const widthColumnCount = finalColumns.filter(({ width }) => !width).length || 1
	// const mergedColumns = finalColumns.map((column) => {
	// 	if (column.width) {
	// 		return column
	// 	}
	// 	return {
	// 		...column,
	// 		width: Math.floor(tableWidth / widthColumnCount),
	// 	}
	// })
	// const gridRef = useRef()
	// const tableRef = useRef()
	// const [connectObject] = useState(() => {
	// 	const obj = {}
	// 	Object.defineProperty(obj, 'scrollLeft', {
	// 		get: () => {
	// 			if (gridRef.current) {
	// 				return gridRef.current?.state?.scrollLeft
	// 			}
	// 			return null
	// 		},
	// 		set: (scrollLeft) => {
	// 			if (gridRef.current) {
	// 				gridRef.current.scrollTo({
	// 					scrollLeft,
	// 				})
	// 			}
	// 		},
	// 	})
	// 	return obj
	// })
	// const resetVirtualGrid = () => {
	// 	gridRef.current?.resetAfterIndices({
	// 		columnIndex: 0,
	// 		shouldForceUpdate: true,
	// 	})
	// }
	// const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
	// 	ref.current = connectObject
	// 	const totalHeight = rawData.length * rowHeight
	// 	console.log({ totalHeight, rowHeight, rowCount: rawData.length, columnCount: mergedColumns.length, tableWidth })
	// 	return (
	// 		<Grid
	// 			ref={gridRef}
	// 			className='virtual-grid'
	// 			columnCount={mergedColumns.length}
	// 			columnWidth={(index) => {
	// 				const { width } = mergedColumns[index]
	// 				console.log(index, width, totalHeight > tableRef.current?.clientHeight && index === mergedColumns.length - 1 ? width - scrollbarSize - 1 : width)
	// 				return totalHeight > tableRef.current?.clientHeight && index === mergedColumns.length - 1 ? width - scrollbarSize - 1 : width
	// 			}}
	// 			height={tableRef.current?.clientHeight}
	// 			rowCount={rawData.length}
	// 			rowHeight={() => rowHeight}
	// 			width={tableWidth}
	// 			onScroll={({ scrollLeft }) => {
	// 				onScroll({
	// 					scrollLeft,
	// 				})
	// 			}}
	// 		>
	// 			{({ columnIndex, rowIndex, style }) => (
	// 				<div className={'virtual-table-cell ' + (columnIndex === mergedColumns.length - 1 ? 'virtual-table-cell-last' : '')} style={{ ...style }}>
	// 					{mergedColumns[columnIndex].render(rowIndex, rawData[rowIndex])}
	// 				</div>
	// 			)}
	// 		</Grid>
	// 	)
	// }
	// useEffect(() => resetVirtualGrid, [tableWidth])
	// tableRef.current?.clientHeight && console.log(tableRef.current?.clientHeight)
	// useEffect(() => {
	// 	if (autoResetSelection && onSelect) {
	// 		onSelect([])
	// 		setSelectedRowsKeys([])
	// 	}
	// }, [data])

	useEffect(() => {
		setSelectedRowsKeys(globalSelectedRowKeys)
	}, [globalSelectedRowKeys])
	return (
		// <ResizeObserver
		// 	onResize={({ width }) => {
		// 		setTableWidth(width)
		// 	}}
		// >
		<Table
			// ref={tableRef}
			rowKey={(record) => record?._id}
			showHeader={!hideHeader}
			className={className + ' virtual-table'}
			columns={!loading && finalColumns}
			onRow={onRow}
			expandedRowKeys={expandedRowKeys}
			expandable={expandable}
			dataSource={data}
			// components={{
			// 	body: renderVirtualList,
			// }}
			rowSelection={
				onSelect && {
					type: 'checkbox',
					onChange: (selectedRows) => {
						onSelect(selectedRows)
						setSelectedRowsKeys(selectedRows)
					},
					selectedRowKeys: selectedRowKeys,
				}
			}
			loading={{ spinning: loading, size: 'large', tip: <p className='font-bold text-[16px] text-primary-800 mt-2'>Loading...</p> }}
			pagination={false}
			rowClassName={typeof rowClassName === 'function' ? rowClassName : 'align-middle pl-2 h-[70px] ' + rowClassName}
			size='small'
			scroll={{ y: 'calc(100%)' }}
			// scroll={{ y: tableRef.current?.clientHeight }}
		/>
		// </ResizeObserver>
	)
}

export default DynamicTable
