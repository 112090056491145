import { useRecoilValue } from 'recoil'
import { GraphQLEnums } from '../helpers/Enums'
import { userAtom } from '../recoil/atoms'
import { useMutate } from './graphql'
import moment from 'moment'
import { notification } from 'antd'
import { findSlab, removeFields } from '../helpers/Functions'
import { chargeTypesForFormSelector, formatName } from '../recoil/selectors'
import { useNavigate } from 'react-router-dom'

export const useSingleAssign = () => {
	const [updateJob, loading] = useMutate({ type: 'Jobs', action: 'update' })
	const [createQuotation, createQuotationLoading] = useMutate({ type: 'Quotations', action: 'create' })
	const [updateQuotation, updateQuotationLoading] = useMutate({ type: 'Quotations', action: 'update' })
	const [deleteQuotation] = useMutate({ type: 'Quotations', action: 'delete' })
	const [matchOrderToJob] = useMutate({ type: 'MatchOrderToJob', action: 'create' })
	const [updateVehicle] = useMutate({ type: 'Vehicles', action: 'update' })
	const [createVehicle] = useMutate({ type: 'Vehicles', action: 'create' })

	const assignSingleJob = async ({ values, props }) => {
		const { data } = props
		const job = data?.jobs?.[0]
		const quotationExists = job?.quotations?.length > 0
		const supplierSlipped = job?.supplier?._id && values?.supplier && values?.supplier !== job?.supplier?._id
		const brokerSlipped = job?.broker?._id && values?.broker && values?.broker !== job?.broker?._id
		const vehicleDriverSlipped =
			(job?.vehicle?.registrationNumber || job?.driver?.phoneNumber) &&
			('+92' + values?.phoneNumber?.replace(' ', '') !== job?.driver?.phoneNumber ||
				values?.vehicleRegistration.toLowerCase() !== job?.vehicle?.registrationNumber?.toLowerCase())
		const driver = values?.phoneNumber && values?.vehicleRegistration
		const driverExists = job?.vehicle?.registrationNumber || job?.driver?.phoneNumber
		const brokerExists = job?.broker?._id
		const paymentTerms = values?.paymentTerms
		const newPaymentTerms = paymentTerms?.map((term, index) => ({ ...removeFields(term, ['__typename', '_id']), index: index.toString() }))
		const variables = {
			SupplierId: values?.supplier,
			committedWeight: values?.committedWeight,
			committedDays: String(values?.committedDays?.valueOf()),
			cost: values?.costType === 'factored' ? (parseFloat(values?.cost) + findSlab(values?.cost, true)).toString() : values?.cost,
			// factorBase: values?.includeVendorPayments ? 'accumulativeCost' : 'freightCost',
			BrokerId: values?.broker,
			...(driver && {
				driver: {
					phoneNumber: values?.phoneNumber && '+92' + values?.phoneNumber.replace(' ', ''),
					vehicleRegistrationNumber: values?.vehicleRegistration && values?.vehicleRegistration.toLowerCase(),
					vehicleType: values?.vehicleType,
				},
			}),
			...(paymentTerms && {
				paymentTerms: newPaymentTerms.map((_, idx) => {
					_.index = idx.toString()
					return _
				}),
			}),
		}
		console.log({ variables, paymentTerms, newPaymentTerms })
		const createquotationVariables = {
			[`${GraphQLEnums['Quotations']?.create.createKey}`]: {
				JobId: job?._id,
				bidWon: true,
				approved: true,
				...variables,
			},
		}
		const updateQuotationVariables = {
			[`${GraphQLEnums['Quotations']?.update.createKey}`]: {
				_id: job?.quotations?.[0]?._id,
				...(!brokerExists && { BrokerId: values?.broker }),
				bidWon: true,
				approved: true,
				committedWeight: values?.committedWeight,
				committedDays: String(values?.committedDays?.valueOf()),
				cost: values?.costType === 'factored' ? (parseFloat(values?.cost) + findSlab(values?.cost, true)).toString() : values?.cost,
				...(!driverExists &&
					driver && {
						driver: {
							phoneNumber: values?.phoneNumber && '+92' + values?.phoneNumber.replace(' ', ''),
							vehicleRegistrationNumber: values?.vehicleRegistration && values?.vehicleRegistration.toLowerCase(),
							vehicleType: values?.vehicleType,
						},
					}),
				...(paymentTerms && {
					paymentTerms: newPaymentTerms,
				}),
			},
		}
		const deleteQuotationVariables = {
			[`${GraphQLEnums['Quotations']?.delete.createKey}`]: {
				_id: job?.quotations?.[0]?._id,
			},
		}

		const assignJobsResult = []
		let jobId
		if (job?.subMovementType === 'ImportShortHaul') {
			jobId = (
				await matchOrderToJob({
					variables: {
						[`${GraphQLEnums['MatchOrderToJob'].create.createKey}`]: {
							OrderIds: [
								{
									ChildId: job?.childOrderId,
									ParentId: job?.parentOrderId,
									subMovementType: job?.subMovementType,
								},
							],
							bilty: {
								isCancelled: false,
							},
							movementType: job?.movementType,
							...(driver && {
								driver: {
									phoneNumber: values?.phoneNumber && '+92' + values?.phoneNumber.replace(' ', ''),
									vehicleRegistrationNumber: values?.vehicleRegistration && values?.vehicleRegistration.toLowerCase(),
								},
							}),
							cost: values?.costType === 'factored' ? (parseFloat(values?.cost) + findSlab(values?.cost, true)).toString() : values?.cost,
						},
					},
				})
			)?.data?.matchOrderToJob?.JobId
			if (jobId) {
				assignJobsResult.push(
					updateJob({
						variables: {
							[`${GraphQLEnums['Jobs'].update.createKey}`]: {
								SupplierId: values?.supplier,
								BrokerId: values?.broker,
								cost: values?.cost,
								_id: job?._id,
								localVehicleType: values?.localVehicleType,
								...(paymentTerms && {
									paymentTerms: newPaymentTerms,
								}),
							},
						},
					})
				)
			} else {
				assignJobsResult.push(Promise.reject({ type: 'error' }))
			}
		} else {
			if (quotationExists && !(supplierSlipped || brokerSlipped || vehicleDriverSlipped)) {
				assignJobsResult.push(updateQuotation({ variables: updateQuotationVariables }))
			} else {
				quotationExists && (await deleteQuotation({ variables: deleteQuotationVariables }))
				assignJobsResult.push(createQuotation({ variables: createquotationVariables }))
			}
		}

		const finalResult = Promise.all(assignJobsResult)
			.catch((e) => {
				return { type: 'error' }
			})
			.then((response) => {
				console.log({ response })
				if (values?.vehicleRegistration) {
					updateVehicle({
						variables: {
							where: {
								registrationNumber: values?.vehicleRegistration.toLowerCase(),
							},
							update: {
								vehicleType: values?.vehicleType,
							},
						},
					})
						.catch((e) => {
							console.log('vehicle updation failed', e)
						})
						.then((response) => {
							if (response?.data?.updateVehicles?.vehicles?.length === 0) {
								createVehicle({
									variables: {
										createVehicleInput: {
											registrationNumber: values?.vehicleRegistration.toLowerCase(),
											vehicleType: values?.vehicleType,
										},
									},
								}).catch((e) => {
									console.log('vehicle creation failed', e)
								})
							}
						})
				}

				if (response.type === 'error') {
					return { type: 'error', message: `Assignment to Job ${job?.jobNumber} was unsuccessful` }
				} else {
					return { type: 'success', message: `Assignment to Job ${job?.jobNumber} was successfull`, response }
				}
			})

		return finalResult
	}
	return [assignSingleJob, loading || createQuotationLoading || updateQuotationLoading]
}

export const useLotJobAssign = () => {
	const [updateJob, loading] = useMutate({ type: 'Jobs', action: 'update' })
	const [createSubOrder, createSubOrderloading] = useMutate({ type: 'CreateSubLongHaul', action: 'create' })
	const [updateSubOrder, updateSubOrderLoading] = useMutate({ type: 'CreateSubLongHaul', action: 'update' })

	const user = useRecoilValue(userAtom)?.[0]

	const assignSingleJob = async ({ values, props }) => {
		const { data, create, jobId } = props
		const order = data?.jobs?.[0]
		const job = order?.jobs?.find((job) => job?._id === jobId)
		const OrderId = order?._id
		const defaultChildId = order?.initialSubOrder?._id
		const childId = job?.childId
		const driver = values?.phoneNumber && values?.vehicleRegistration
		const supplierSlipped = values?.supplier !== job?.supplier?._id
		const brokerSlipped = values?.broker !== job?.broker?._id
		const vehicleDriverSlipped =
			'+92' + values?.phoneNumber?.replace(' ', '') !== job?.driver?.phoneNumber ||
			values?.vehicleRegistration.toLowerCase() !== job?.vehicle?.registrationNumber?.toLowerCase()
		const createSubOrderVariables = {
			[`${GraphQLEnums['CreateSubLongHaul'].create.createKey}`]: {
				OrderId: OrderId,
				defaultChildId: defaultChildId,
				freightWeight: values?.weight,
				freightCount: values?.freightCount || null,
				cost: values?.cost,
				BrokerId: values?.broker,
				SupplierId: values?.supplier,
				vehicleType: values?.vehicleType,
				...(driver && {
					driver: {
						phoneNumber: values?.phoneNumber && '+92' + values?.phoneNumber.replace(' ', ''),
						vehicleRegistrationNumber: values?.vehicleRegistration && values?.vehicleRegistration.toLowerCase(),
					},
				}),
				createdThrough: {
					userPlatform: 'Web - Supply Portal',
				},
			},
		}
		const updateJobVariables = {
			[`${GraphQLEnums['Jobs'].update.createKey}`]: {
				_id: job?._id,
				...(supplierSlipped && { SupplierId: values?.supplier }),
				cost: values?.cost,
				...(brokerSlipped && { BrokerId: values?.broker }),
				...(driver &&
					vehicleDriverSlipped && {
						driver: {
							phoneNumber: values?.phoneNumber && '+92' + values?.phoneNumber.replace(' ', ''),
							vehicleRegistrationNumber: values?.vehicleRegistration && values?.vehicleRegistration.toLowerCase(),
						},
					}),
				DriverAssignedBy: user?._id,
				driverAssignedAt: moment().format('x'),
				committedWeight: values?.committedWeight,
				committedDays: String(values?.committedDays?.valueOf()),
			},
		}

		const updateSubOrderVariables = {
			updateLongHaulOrderInput: {
				ChildLongHauls: [
					{
						_id: childId,
						childShipmentInfo: {
							freightType: job?.shipmentInfo?.freightType,
							freightWeight: values?.weight,
						},
						vehicleType: values?.vehicleType,
					},
					{
						_id: defaultChildId,
						childShipmentInfo: {
							freightType: job?.shipmentInfo?.freightType,
							freightWeight: (order?.remainingWeight + parseFloat(job?.shipmentInfo?.freightWeight) - parseFloat(values?.weight)).toString(),
						},
					},
				],
				_id: OrderId,
			},
		}
		const results = []
		if (create) {
			results.push(createSubOrder({ variables: createSubOrderVariables }))
		} else {
			results.push(
				updateJob({
					variables: {
						[`${GraphQLEnums['Jobs'].update.createKey}`]: {
							_id: job?._id,
							cost: values?.cost,
							committedWeight: values?.committedWeight,
							committedDays: String(values?.committedDays?.valueOf()),
						},
					},
				})
			)

			results.push(updateSubOrder({ variables: updateSubOrderVariables }))
		}
		const finalResult = Promise.all(results)

		return finalResult
	}
	return [assignSingleJob, loading || createSubOrderloading || updateSubOrderLoading]
}

export const useBulkAssign = () => {
	const [assignJobs, assignJobsloading] = useMutate({ type: 'AssignJobsToSuppliers', action: 'create' })
	const assignBulkJobs = async ({ values, props }) => {
		const suppliers = values?.suppliers && Object.values(values?.suppliers).filter((supplier) => supplier.selected)
		const { data } = props
		const { jobs } = data
		let jobIndex = 0
		let currJob = {}
		let assignedJobs = []
		for await (const supplier of suppliers) {
			for (let jobNumber = 0; jobNumber < supplier.numVehicles; jobNumber++) {
				currJob = Object.assign({}, jobs[jobIndex])
				assignedJobs = [...assignedJobs, { JobId: currJob?._id, SupplierId: supplier?._id, cost: values?.laneRates[currJob?.lane?._id].cost || '0' }]
				jobIndex = jobIndex + 1
			}
		}
		const assignVariables = { [`${GraphQLEnums['AssignJobsToSuppliers'].create.createKey}`]: assignedJobs }
		return assignJobs({ variables: assignVariables })
	}
	return [assignBulkJobs, assignJobsloading]
}

export const useEditAssignment = () => {
	const [updateJob, jobLoading] = useMutate({ type: 'Jobs', action: 'update' })
	const [slipDriver, slipDriverLoading] = useMutate({ type: 'SlipJob', action: 'create' })
	const [createBroker] = useMutate({ type: 'Brokers', action: 'create' })
	const [createQuotation, createQuotationLoading] = useMutate({ type: 'Quotations', action: 'create' })

	const user = useRecoilValue(userAtom)?.[0]
	let queryResponse
	let newJob
	const updateJobAssignment = async ({ values, props }) => {
		const { data } = props
		const { job } = data
		const slipped = values.slipped
		const driverChanged = false
		const driver = values?.phoneNumber && values?.vehicleRegistration
		let newBroker
		if (values?.broker === 'other') {
			const createUserResponse = await fetch(`https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/createUserWithPhoneNumber`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json;charset=utf-8' },
				body: JSON.stringify({ phoneNumber: '+92' + values?.brokerPhoneNumber.replace(' ', '') }),
				// body: JSON.stringify({ email: `a${values.brokerPhoneNumber?.replace(' ','')}@bridgelinxpk.com` }),
			})
			if (createUserResponse.ok) {
				const uid = (await createUserResponse.json())?.uid
				newBroker = (
					await createBroker({
						variables: {
							createBrokerInput: {
								User: {
									_id: uid,
									firstName: values.brokerName?.split(' ')?.[0],
									lastName: values.brokerName?.split(' ')?.[1],
									phoneNumber: values?.brokerPhoneNumber && '+92' + values?.brokerPhoneNumber.replace(' ', ''),
								},
							},
						},
					})
				)?.data?.createBroker
			}
		}
		const variables = {
			SupplierId: values?.supplier,
			...(driver
				? {
						driver: {
							phoneNumber: values?.phoneNumber && '+92' + values?.phoneNumber.replace(' ', ''),
							vehicleRegistrationNumber: values?.vehicleRegistration && values?.vehicleRegistration.toLowerCase(),
						},
				  }
				: {}),
			BrokerId: values?.broker === 'other' ? newBroker?._id : values?.broker,
			committedWeight: values?.committedWeight,
			committedDays: String(values?.committedDays?.valueOf()),
		}
		const updateJobValues = {
			[`${GraphQLEnums['Jobs'].update.createKey}`]: {
				_id: job?._id,
				...variables,
			},
		}
		if (slipped || driverChanged) {
			newJob = (await slipDriver({ variables: { id: job?._id } }))?.data?.slipDriverInJob
			notification[newJob ? 'success' : 'error']({
				message: 'Job Slipped',
				description: newJob ? `Job # ${job?.jobNumber} has been replaced with Job # ${newJob?.jobNumber}.` : 'Job did not slip. Please contact Product team.',
				duration: '5',
			})
			queryResponse = await createQuotation({
				variables: {
					[`${GraphQLEnums['Quotations']?.create.createKey}`]: {
						JobId: newJob?._id,
						bidWon: true,
						approved: true,
						...variables,
					},
				},
			})
		} else {
			queryResponse = await updateJob({
				variables: updateJobValues,
			})
		}

		return queryResponse
	}
	return [updateJobAssignment, jobLoading || slipDriverLoading || createQuotationLoading]
}

export const useFinishLotAssignment = () => {
	const [createStatus, statusLoading] = useMutate({ type: 'Status', action: 'create' })
	const finishLotAssignment = async ({
		props: {
			data: { jobs },
		},
	}) => {
		const queryResponse = await createStatus({
			variables: {
				[`${GraphQLEnums['Status'].create.createKey}`]: {
					_id: jobs?.[0]?.parentOrderId,
					status: 'Order Ongoing',
					type: 'Lot',
				},
			},
		})
			.catch((e) => {
				return { type: 'error' }
			})
			.then((response) => {
				if (response.type === 'error') {
					return { type: 'error', message: `The following operation was unsuccessful. Kindly contact BridgeLinx Product team for resolution.` }
				} else {
					return { type: 'success', message: `Status for order # ${jobs?.[0]?.orderNumber} has been changed.Lot Assignment finished successfully`, response }
				}
			})
		return queryResponse
	}
	return [finishLotAssignment, statusLoading]
}

export const useEditPayments = () => {
	const [updateJob, jobLoading] = useMutate({ type: 'Jobs', action: 'update' })

	const updateJobPayments = async ({ values, props }) => {
		const { data } = props
		const { job } = data
		const vendorPaymentId = job?.vendorPayments?._id
		const costBreakdownId = job?.vendorPayments?.costBreakdown?._id
		const paymentStatus = job?.vendorPayments?.paymentStatus
		let processedCosts = Object.entries(values)?.reduce((costs, cost) => {
			const key = cost[0]
			const value = (cost[1] && parseFloat(cost[1])) || undefined
			costs[key] = value
			return costs
		}, {})
		const newCost = values.cost || '0'
		delete processedCosts.cost
		const vendorPayments = processedCosts && {
			vendorPayments: {
				_id: vendorPaymentId,
				paymentStatus,
				costBreakdown: {
					_id: costBreakdownId,
					...processedCosts,
				},
			},
		}
		const updateJobValues = {
			[`${GraphQLEnums['Jobs'].update.createKey}`]: {
				_id: job?._id,
				cost: newCost,
				...((vendorPaymentId || processedCosts) && vendorPayments),
			},
		}
		const queryResponse = await updateJob({
			variables: updateJobValues,
		})
		return queryResponse
	}
	return [updateJobPayments, jobLoading]
}

export const useEditPaymentTerms = () => {
	const [updateJob, jobLoading] = useMutate({ type: 'Jobs', action: 'update' })
	const updatePaymentTerms = async ({ values, props }) => {
		const { data } = props
		const { job } = data
		const { paymentTerms } = values
		const updateJobValues = {
			[`${GraphQLEnums['Jobs'].update.createKey}`]: {
				_id: job?._id,
				...(paymentTerms && {
					paymentTerms: paymentTerms.map((_, idx) => {
						_.index = idx.toString()
						return _
					}),
				}),
			},
		}
		const queryResponse = await updateJob({
			variables: updateJobValues,
		})
		return queryResponse
	}
	return [updatePaymentTerms, jobLoading]
}

export const useAddEditVendor = () => {
	const [createBroker, createBrokerLoading] = useMutate({ type: 'Brokers', action: 'create' })
	const [updateBroker, updateBrokerLoading] = useMutate({ type: 'Brokers', action: 'update' })
	const [createSupplier, createSupplierLoading] = useMutate({ type: 'Suppliers', action: 'create' })
	const [updateSupplier, updateSupplierLoading] = useMutate({ type: 'Suppliers', action: 'update' })
	const [createBiltyInvestor, createBiltyInvestorLoading] = useMutate({ type: 'BiltyInvestors', action: 'create' })
	const [updateBiltyInvestor, updateBiltyInvestorLoading] = useMutate({ type: 'BiltyInvestors', action: 'update' })
	const createNewVendor = async ({ values, props }) => {
		const result = []
		const name = values?.name?.split(' ')
		const firstName = name?.[0]
		const lastName = name?.slice(1)?.join(' ')
		if (props.edit && props.type === 'brokers') {
			const variables = {
				updateBrokerInput: {
					_id: props?._id,
					...values,
					User: {
						firstName: firstName,
						lastName: lastName,
						cnicFront: values?.User?.cnicFront,
						cnicBack: values?.User?.cnicBack,
						// phoneNumber: values?.phoneNumber && '+92' + values?.phoneNumber.replace(' ', ''),
						...(values?.cnic && { cnic: values?.cnic }),
					},
					Lanes: values?.Lanes?.[0]?.value ? values?.Lanes?.map((lane) => lane.value) : values?.Lanes,
					// Lanes: values?.Lanes?.map((lane) => lane.value),
					vendorType: values?.vendorType,
					totalVehiclesOwned: parseInt(values?.totalVehiclesOwned),
				},
			}
			console.log({ check: values?.Lanes?.[0]?.value, variables })
			delete variables.updateBrokerInput.cnic
			delete variables.updateBrokerInput.phoneNumber
			delete variables.updateBrokerInput.name
			delete variables.updateBrokerInput.__typename

			result.push(updateBroker({ variables }))
		} else if (props.edit && props.type === 'supplier') {
			const vendor = props?.data?.vendor

			const firstNameChanged = vendor?.User?.firstName !== firstName
			const lastNameChanged = vendor?.User?.lastName !== lastName
			const cnicChanged = vendor?.User?.cnic !== values?.cnic

			const variables = {
				[props.type === 'brokers' ? 'updateBrokerInput' : 'updateSupplierInput']: {
					_id: props?.data?.vendor?._id,
					...(props.type === 'brokers' && { vendorType: values?.vendorType }),
					User: {
						...(firstNameChanged && { firstName }),
						...(lastNameChanged && { lastName }),
						...(cnicChanged && { cnic: values?.cnic }),
					},
				},
			}
			result.push(props.type === 'brokers' ? updateBroker({ variables }) : updateSupplier({ variables }))
		} else if (props.edit && props.type === 'bilty-investors') {
			// edit bilty investor
			const { phoneNumber, accounts, ...restInput } = values
			const updateBiltyInvestorInput = {
				...restInput,
				_id: props._id,
				newAccounts: accounts
					?.filter((account) => !account?._id)
					?.map((account) => ({
						...account,
						type: 'BILTY_INVESTOR_ACCOUNT',
					})),
				updateAccounts: accounts
					?.filter((account) => account?._id)
					?.map((account) => ({
						_id: account?._id,
						accountNumber: account?.accountNumber,
						accountTitle: account?.accountTitle,
						bankName: account?.bankName,
					})),
			}
			result.push(
				updateBiltyInvestor({
					variables: {
						updateBiltyInvestorInput: updateBiltyInvestorInput,
					},
				})
			)
		} else {
			const name = values?.name?.split(' ')
			const firstName = name?.[0]
			const lastName = name?.slice(1)?.join(' ')

			if (props.type === 'brokers') {
				const createbrokerInput = {
					...values,
					User: {
						// _id: uid,
						firstName: firstName,
						lastName: lastName,
						cnicFront: values?.User?.cnicFront,
						cnicBack: values?.User?.cnicBack,
						phoneNumber: values?.phoneNumber && '+92' + values?.phoneNumber.replace(' ', ''),
						...(values?.cnic && { cnic: values?.cnic }),
					},
					vendorType: values?.vendorType,
					totalVehiclesOwned: parseInt(values?.totalVehiclesOwned),
				}
				delete createbrokerInput.cnic
				delete createbrokerInput.phoneNumber
				delete createbrokerInput.name

				result.push(
					createBroker({
						variables: {
							createBrokerInput: createbrokerInput,
						},
					})
				)
			} else if (props.type === 'bilty-investors') {
				// add bilty investor
				const createBiltyInvestorInput = {
					...values,
					phoneNumber: `+92${values?.phoneNumber}`,
					accounts: values?.accounts?.map((account) => ({
						...account,
						type: 'BILTY_INVESTOR_ACCOUNT',
					})),
				}
				result.push(
					createBiltyInvestor({
						variables: {
							createBiltyInvestorInput: createBiltyInvestorInput,
						},
					})
				)
			} else {
				const createUserResponse = await fetch(`https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/createUserWithPhoneNumber`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json;charset=utf-8' },
					body: JSON.stringify({ phoneNumber: '+92' + values?.phoneNumber.replace(' ', '') }),
				})
				if (!createUserResponse.ok) {
					return { type: 'error', message: `${props.edit ? 'Updation' : 'Creation'} of vendor was unsuccessful` }
				}
				if (createUserResponse.ok) {
					const uid = (await createUserResponse.json())?.uid
					result.push(
						createSupplier({
							variables: {
								createSupplierInput: {
									User: {
										_id: uid,
										firstName: firstName,
										lastName: lastName,
										phoneNumber: values?.phoneNumber && '+92' + values?.phoneNumber.replace(' ', ''),
										...(values?.cnic && { cnic: values?.cnic }),
									},
								},
							},
						})
					)
				}
			}
		}
		return Promise.all(result)
			.catch((e) => {
				return { type: 'error', error: e }
			})
			.then((response) => {
				if (response.type === 'error') {
					return { type: 'error', message: response?.error?.message }
				} else {
					return {
						type: 'success',
						message: `${props.edit ? 'Updation' : 'Creation'} of vendor ${formatName(firstName, lastName)} was successfull`,
					}
				}
			})
	}
	return [createNewVendor, createBrokerLoading || updateBrokerLoading || createSupplierLoading || updateSupplierLoading]
}

export const useDeleteVendor = () => {
	const [deleteBroker, createBrokerLoading] = useMutate({ type: 'Brokers', action: 'create' })
	const [updateBroker, updateBrokerLoading] = useMutate({ type: 'Brokers', action: 'update' })
	const result = []
	const removeBroker = async ({ values, props }) => {
		const firstName = props?.data?.Vendor?.User?.firstName
		const lastName = props?.data?.Vendor?.User?.lastName
		const variables = {
			updateBrokerInput: {
				_id: props?.data?.vendor?._id,
				isBlocked: true,
			},
		}
		result.push(updateBroker({ variables }))
		return Promise.all(result)
			.catch((e) => {
				return { type: 'error' }
			})
			.then((response) => {
				if (response.type === 'error') {
					return { type: 'error', message: `Deletion of vendor was unsuccessful` }
				} else {
					return {
						type: 'success',
						message: `Deletion of vendor ${formatName(firstName, lastName)} was successfull`,
					}
				}
			})
	}
	return [removeBroker, createBrokerLoading]
}

export const useSplitJob = () => {
	const [splitJobFuntion, splitJobLoading] = useMutate({ type: 'SplitJob', action: 'create' })
	const splitJob = async ({
		values: { numJobs },
		props: {
			data: {
				jobs: {
					0: { _id, jobNumber },
				},
			},
		},
	}) => {
		const variables = {
			splitJobInput: {
				_id: _id,
				numberOfNewJobs: numJobs ? parseInt(numJobs) : 1,
				createdThrough: {
					versionName: 'Web - Supply Portal',
				},
			},
		}
		return splitJobFuntion({ variables })
			.catch((e) => {
				return { type: 'error' }
			})
			.then((response) => {
				if (response.type === 'error') {
					return { type: 'error', message: `Job Splitting was unsuccessful` }
				} else {
					const newJobsNumbers = response?.data?.splitJob?.reduce(
						(jobNums, job, index) =>
							`${jobNums}${
								index === response?.data?.splitJob?.length - 1 ? ` and J-${job?.jobNumber}` : index === 0 ? `J-${job?.jobNumber}` : `, J-${job?.jobNumber}`
							}`,
						''
					)
					return {
						type: 'success',
						message: `J-${jobNumber} was split into ${newJobsNumbers} successfully`,
					}
				}
			})
	}
	return [splitJob, splitJobLoading]
}

export const useBulkAuthenticate = () => {
	const [authenticateAndUpdateCharges, createChargesLoading] = useMutate({ type: 'AuthenticateAndUpdateCharges', action: 'create' })
	const authenticateCharges = async ({ values, props }) => {
		const { selectedChargeIds, ...charges } = values
		delete charges['undefined']
		const variables = Object.entries(charges || {})
			?.filter((entry) => entry?.[1]?.['selectAll'] === undefined)
			.map((charge) => {
				const [chargeId, chargeDetails] = charge
				return {
					...(typeof chargeDetails?.amount === 'string' ? { amount: parseFloat(chargeDetails?.amount) } : {}),
					authenticate: selectedChargeIds?.includes(chargeId),
					chargeId: chargeId,
				}
			})

		return authenticateAndUpdateCharges({
			variables: {
				authenticateAndUpdateChargesInput: {
					charges: variables,
				},
			},
		})
			.then((response) => {
				if (response?.data?.authenticateAndUpdateCharges?.every((charge) => charge?._id)) {
					return {
						type: 'success',
						message: 'Charges authenticated successfully',
					}
				} else {
					return { type: 'error', error: 'There was an error in authenticating some charges' }
				}
			})
			.catch((e) => {
				console.log({ e })
				return { type: 'error', error: e.message }
			})
	}

	return [authenticateCharges, createChargesLoading]
}

export const useBulkAdd = () => {
	const [createCharges, createChargesLoading] = useMutate({ type: 'MultipleCharges', action: 'create' })
	const chargeTypes = useRecoilValue(chargeTypesForFormSelector())
	const navigator = useNavigate()
	const accountTypes = {
		THIRD_PARTY: 'THIRD_PARTY_ACCOUNT',
		BROKER: 'BROKER_ACCOUNT',
		BILTY_INVESTOR: 'BILTY_INVESTOR_ACCOUNT',
	}
	const accountPayeeMapping = {
		THIRD_PARTY_ACCOUNT: 'THIRD_PARTY',
		BROKER_ACCOUNT: 'BROKER',
		BILTY_INVESTOR_ACCOUNT: 'BILTY_INVESTOR',
	}
	const addCharges = async ({ values, props }) => {
		const jobs = props?.data?.jobs
		const chargeVariables = Object.values(values || {})?.map((value) => {
			const selectedChargeType = chargeTypes?.find((chargeType) => chargeType?._id === value?.chargeType)
			return {
				amount: parseFloat(value?.amount),
				chargeTypeId: value?.chargeType,
				...(value?.customFieldProperty
					? {
							customFieldValues: Object.entries(value?.customFieldProperty)
								.map((customField) => {
									console.log({ key: selectedChargeType?.customFields?.find((field) => field.propertyKey === customField[0]), field: customField[1] })
									return {
										propertyKey: customField[0],
										value:
											selectedChargeType?.customFields?.find((field) => field.propertyKey === customField[0])?.type === 'upload'
												? customField[1]?.[0]?.url
												: customField[1],
									}
								})
								?.filter((val) => val.value && val.propertyKey),
					  }
					: {}),

				comment: value?.comment,
				...(value?.accountId === 'addNew' || value?.entityId === 'addNewThirdParty'
					? {
							account: {
								accountNumber: value?.accountNumber,
								accountTitle: value?.accountTitle,
								bankName: value?.bankName,
								type: accountTypes[value?.payeeType],
								...(value?.entityId === 'addNewThirdParty'
									? {
											thirdParty: value?.thirdParty,
									  }
									: { entityId: value?.entityId }),
							},
					  }
					: {
							accountId: value?.accountId,
					  }),
			}
		})
		const variables = jobs.map((job) => ({
			jobId: job?._id,
			charges: chargeVariables?.map((charge) => ({
				...charge,
				jobId: job?._id,
			})),
		}))
		return createCharges({
			variables: {
				createChargesForJobsInput: {
					jobs: variables,
				},
			},
		})
			.then((response) => {
				if (response?.data?.createChargesForJobs?.every((charge) => charge?._id)) {
					return {
						type: 'success',
						message: 'Charges added successfully',
					}
				} else {
					return { type: 'error', message: 'There was an error in adding charge' }
				}
			})
			.catch((e) => {
				console.log({ e })
				return { type: 'error', message: e.message }
			})
	}

	return [addCharges, createChargesLoading]
}
