import { mdiCheck, mdiPackageVariantClosed, mdiPencil, mdiPhone, mdiPlus, mdiTruck, mdiTruckOutline } from '@mdi/js'
import { VehicleTypeEnums } from '../../helpers/Enums'
import Icon from '@mdi/react'
import { Link } from 'react-router-dom'

const LotJobCard = ({ OrderId, job, loading }) => {
	return (
		<div className='flex flex-col bg-white rounded-lg mr-5 shadow-[0_0_4px_rgba(0,_0,_0,_0.15)] !min-w-[300px] overflow-x-hidden justify-between'>
			<div className='flex flex-col p-4 justify-between h-full '>
				<div className='flex flex-col'>
					<div className='flex flex-wrap justify-between'>
						<div className='font-bold text-lg text-black-400 pr-4'>{`J-${job?.jobNumber}`}</div>

						<div className='flex flex-row text-xs py-1 items-start'>
							<div className='flex flex-row pr-4 items-center'>
								<Icon className='mr-2' path={mdiTruckOutline} size={0.8} color='#9F9F9F' />
								<div className='font-[600] text-black-400'>{`${
									VehicleTypeEnums[job?.vehicleType]?.text + (job?.subVehicleType ? '-' + job?.subVehicleType : '')
								}`}</div>
							</div>

							<div className='flex flex-row items-center '>
								<Icon className='mr-2' path={mdiPackageVariantClosed} size={0.8} color='#9F9F9F' />
								<div className='font-[600] overflow-ellipsis text-black-400'>{`${job?.shipmentInfo?.freightWeight || '---'} Tons`}</div>
							</div>
						</div>
					</div>
					<div className='flex font-[600] text-sm text-black-400 mt-4 mb-2'>{job?.supplier?.name || 'No Supplier'}</div>
					<div className='flex font-[600] text-sm text-black-400 mb-4'>{job?.supplier?.phoneNumber || '---'}</div>
				</div>

				<div className='flex flex-col w-full p-2 bg-secondaryBackground border border-black-100 rounded-[5px]'>
					<div className='flex flex-row text-sm'>
						<Icon className=' mr-2' path={mdiPhone} size={0.8} color='#676767' />
						<div className='font-[500] text-black-400'>{job?.driver?.phoneNumber || '---'}</div>
					</div>
					<div className='flex flex-row text-sm pt-2'>
						<Icon className=' mr-2' path={mdiTruck} size={0.8} color='#676767' />
						<div>{job?.vehicle?.registrationNumber || '---'}</div>
					</div>
				</div>
			</div>
			<Link to={`job/${OrderId}/${job?._id}/edit`} className={loading ? 'no-hover' : ''}>
				<div className={(loading ? 'bg-black-50' : 'cursor-pointer bg-[#EDF7FF]') + ' flex flex-row justify-center items-center p-3 rounded-b-lg'}>
					<Icon path={mdiPencil} size={0.8} className={(loading ? 'text-white' : 'text-primary-800') + ' mr-[5px]'} />
					<p className={(loading ? 'text-white' : 'text-primary-800') + ' font-[500] text-sm'}>Edit Assignment</p>
				</div>
			</Link>
		</div>
	)
}

const ExpandableJobDrawer = (record) => {
	return (
		<div className='flex flex-row overflow-x-scroll bg-[#F2F3F7] border-t justify-start py-4 px-4'>
			{record?.jobs?.map((job) => (
				<LotJobCard job={job} OrderId={record?._id} loading={false} key={'job_' + job?._id} />
			))}

			<Link to={`job/${record?._id}/create-job`} title='Create New Job'>
				<div className='cursor-pointer flex !min-w-[300px] flex-col rounded-lg mr-5 w-[300px] h-full border-dashed border-2 border-black-100 justify-center items-center align-middle min-h-[220px]'>
					<Icon path={mdiPlus} className='text-black-100' size={2} />
				</div>
			</Link>

			<Link to={`job/${record?._id}/finish-assignment`} title='Finish Assignment'>
				<div className='cursor-pointer flex !min-w-[300px] flex-col rounded-lg mr-5 w-[300px] h-full border-dashed border-2 border-green-400 justify-center items-center align-middle min-h-[220px]'>
					<Icon path={mdiCheck} color='#16aa42' size={2} />
					<div className='text-lg font-bold mx-auto py-2 text-green-400'>Finish Assignment</div>
				</div>
			</Link>
		</div>
	)
}

export default ExpandableJobDrawer
