import {
	GET_USER,
	GET_ALL_JOBS,
	GET_ALL_ORDERS,
	GET_ALL_CORPORATES,
	GET_ALL_LANE_RATES,
	GET_ALL_SUPPLIERS,
	GET_ALL_BROKERS,
	GET_ALL_EMPTY_TERMINALS,
	GET_ALL_JOB_COUNTS,
	GET_MANAGEMENT_VIEW_DATA,
	GET_VEHICLE,
	GET_JOB_CHARGE_TYPES,
	GET_THIRD_PARTIES,
	GET_JOB_REVENUE_TYPES,
	GET_ALL_BILTY_INVESTORS,
} from '.././graphql/queries'
import {
	userAtom,
	importOrdersAtom,
	exportOrdersAtom,
	longHaulOrdersAtom,
	importAtom,
	exportAtom,
	longHaulAtom,
	corporatesAtom,
	lanesAtom,
	suppliersAtom,
	brokersAtom,
	containerTerminalsAtom,
	jobCountAtom,
	importManagementAtom,
	exportManagementAtom,
	longHaulManagementAtom,
	chargeTypesAtom,
	thirdPartiesAtom,
	revenueTypesAtom,
	biltyInvestorsAtom,
} from '../recoil/atoms'

import {
	mdiViewGridOutline,
	mdiTextBoxCheckOutline,
	mdiTextBoxOutline,
	mdiClockCheckOutline,
	mdiCheckboxMarkedCircle,
	mdiProgressCheck,
	mdiClockOutline,
	mdiMapMarker,
	mdiDatabaseArrowLeftOutline,
	mdiDatabaseArrowRightOutline,
	mdiInboxFullOutline,
	mdiChartBoxOutline,
	mdiClipboardClockOutline,
	mdiClipboardCheckOutline,
	mdiTruckOutline,
	mdiAccountCircleOutline,
	mdiFormatListChecks,
} from '@mdi/js'
import moment from 'moment'

import {
	JobDetails,
	SingleJobSupplierAssignment,
	SingleJobDriverAssignment,
	SingleJobPaymentTermsAssignment,
	AssignLaneRates,
	SupplierSelectionTable,
	AssignBulkSupplierHeader,
	SelectedSupplierColumn,
	AddEditVendor,
	DeleteVendor,
	SingleJobLotAssignment,
	SplitJobModal,
	BPayAssignmentResult,
	FinishLotAssignment,
} from '../components/modals/AssignmentModals'

import {
	useBulkAssign,
	useEditAssignment,
	useEditPayments,
	useLotJobAssign,
	useSingleAssign,
	useEditPaymentTerms,
	useAddEditVendor,
	useDeleteVendor,
	useSplitJob,
	useFinishLotAssignment,
	useBulkAuthenticate,
	useBulkAdd,
} from '../hooks/modalFinishHooks'
import {
	ASSIGN_JOBS_TO_SUPPLIER,
	CREATE_QUOTATION,
	UPDATE_JOB,
	UPDATE_QUOTATION,
	SLIP_DRIVER_IN_JOB,
	CREATE_LONGHAUL_SUBORDER,
	UPDATE_LONGHAUL_ORDER,
	DELETE_QUOTATION,
	MATCH_ORDER_TO_JOB,
	CREATE_BROKER,
	UPDATE_BROKER,
	CREATE_SUPPLIER,
	UPDATE_SUPPLIER,
	SPLIT_JOB,
	UPDATE_VEHICLE,
	CREATE_VEHICLE,
	CREATE_PARENT_STATUS,
	UPDATE_BILTY,
	CREATE_CHARGE,
	CREATE_REVENUE,
	UPDATE_CHARGE,
	UPDATE_REVENUE,
	AUTHENTICATE_CHARGES,
	AUTHENTICATE_REVENUES,
	CREATE_MULTIPLE_CHARGES,
	CREATE_BILTY_INVESTOR,
	UPDATE_BILTY_INVESTOR,
	AUTHENTICATE_AND_UPDATE_CHARGES,
} from '../graphql/mutations'
import { CheckboxSchema, RangeSchema, SelectSchema } from './FormSchemas'
import { Capitalize, checkArray, checkJobSlipped, findSlab } from './Functions'
import { BulkAddCharges, BulkAuthenticateCharges, EditAssignmentModal, EditPaymentsModal } from '../components/modals/PostAssignmentModals'
import {
	BILTY_INVESTOR_UPDATED,
	BROKER_UPDATED_SUBSCRIPTION,
	JOBS_UPDATED_SUBSCRIPTION,
	ORDERS_UPDATED_SUBSCRIPTION,
	PARENT_ORDERS_UPDATED_SUBSCRIPTION,
	SUPPLIER_UPDATED_SUBSCRIPTION,
} from '../graphql/subscriptions'
import { isArray } from '@apollo/client/cache/inmemory/helpers'
import { Form } from 'antd'
import CustomStepper from '../components/utility/CustomStepper'
import { CustomSwitchWrapper } from '../components/utility/Utility'
import { formatName, formatPhoneNumber } from '../recoil/selectors'

export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID
export const bPayAdmins = PROJECT_ID === 'bridgelinx-dev' ? ['szaidi@bridgelinxpk.com'] : ['atanveer@bridgelinxpk.com', 'sgul@bridgelinxpk.com']
export const OrderStatusEnum = {
	'Order Pending': {
		text: 'Pending',
		long: 'Order Pending',
		background: '#F2F2F2',
		color: '#808080',
	},
	'Order Confirmed': {
		text: 'Confirmed',
		long: 'Order Confirmed',
		background: '#E5F4FF',
		color: '#008EFF',
	},
	'Order Ongoing': {
		text: 'Ongoing',
		long: 'Order Ongoing',
		background: '#FFF4E5',
		color: '#FF9500',
	},
	'Order Completed': {
		text: 'Finished',
		long: 'Order Completed',
		background: '#E5FFE7',
		color: '#40BF4A',
	},
	'Order Cancelled': {
		text: 'Cancelled',
		long: 'Order Cancelled',
		background: '#FFE5EA',
		color: '#FF1500',
	},
}
export const PaymentPointEnums = {
	driverMovingToDropoffLocation: { value: 'driverMovingToDropoffLocation', label: 'After Loading' },
	completed: { value: 'completed', label: 'After POD' },
	loadingCompleted: { value: 'loadingCompleted', label: 'After Loading' },
}

export const ContainerDimensionsEnum = {
	xl20ft: { key: 'xl20ft', text: '20Feet', long: '20 Feet', icon: '#xl20ft', sortIndex: 0 },
	xl40ft: { key: 'xl40ft', text: '40Feet', long: '40 Feet', icon: '#xl40ft', sortIndex: 2 },
	xl2020ft: { key: 'xl2020ft', text: '2x20 Ft', long: 'Double 20 Feet', icon: '#xl2020ft', sortIndex: 1 },
	'Flatbed-Large': { key: 'Flatbed-Large', text: '45ft Flatbed', long: '45 Feet Flatbed', icon: '#other' },
	'Flatbed-Medium': { key: 'Flatbed-Medium', text: '43ft Flatbed', long: '43 Feet Flatbed', icon: '#other' },
	'Flatbed-Small': { key: 'Flatbed-Small', text: 'Small Flatbed', long: 'Small Flatbed', icon: '#other' },
	'Private-Container': { key: 'Private-Container', text: 'Private Container', long: 'Private Container', icon: '#other' },
	'Trolly-xl20ft': { key: 'xl20ft', text: '20 Ft', long: '20 Feet', icon: '#xl20ft' },
	'Trolly-xl40ft': { key: 'xl40ft', text: '40 Ft Standard', long: '40 Feet', icon: '#xl40ft' },
	'Trolly-xl40ftHC': { key: 'xl40ftHC', text: '40 Ft High Cube', long: '40 Feet - High Cube', icon: '#xl40ftHC' },
	'Trolly-xl40ftOT': { key: 'xl40ftOT', text: '40 Ft Open Top', long: '40 Feet - Open Top', icon: '#xl40ftOT' },
	xl40ftHC: {
		key: 'xl40ft',
		text: '40 Ft HC',
		long: '40 Feet - High Cube',
		icon: '#xl40ftHC',
		sortIndex: 3,
	},
	xl40ftOT: {
		key: 'xl40ft',
		text: '40 Ft OT',
		long: '40 Feet - Open Top',
		icon: '#xl40ftOT',
		sortIndex: 4,
	},
	Mazda: { key: 'Mazda', text: 'Mazda', long: 'Mazda', icon: '#other' },
	other: { key: 'other', text: 'Other', long: 'Other', icon: '#other', sortIndex: 7 },
	Flatbed: { key: 'Flatbed', text: 'Flatbed', long: 'Flatbed', icon: '#other', sortIndex: 5 },
	superx: { key: 'superx', text: 'Super X', long: 'Super X', icon: '#other', sortIndex: 6 },
}
export const VehicleTypeEnums = {
	'Flatbed-Large': { key: 'Flatbed-Large', text: '45ft Flatbed', long: '45 Feet Flatbed', icon: '#other' },
	'Flatbed-Medium': { key: 'Flatbed-Medium', text: '43ft Flatbed', long: '43 Feet Flatbed', icon: '#other' },
	'Flatbed-Small': { key: 'Flatbed-Small', text: 'Small Flatbed', long: 'Small Flatbed', icon: '#other' },
	'Lowbed-Standard': { key: 'Lowbed-Standard', text: 'Lowbed', icon: '' },
	'Lowbed-MultiAxle': { key: 'Lowbed-MultiAxle', text: 'Multi-Axle Lowbed', icon: '' },
	// 'Private-Container': { key: 'Private-Container', text: 'Private Container', long: 'Private Container', icon: '#other' },
	'Trolly-xl20ft': { key: 'Trolly-xl20ft', text: '20 Ft', long: '20 Feet Trolly', icon: '#xl20ft' },
	'Trolly-xl40ft': { key: 'Trolly-xl40ft', text: '40 Ft', long: '40 Feet Trolly', icon: '#xl40ft' },

	'Trolly-xl2020ft': { key: 'Trolly-xl2020ft', text: 'Double Twenty', long: 'Double Twenty', icon: '#xl40ft' },
	Mazda: { key: 'Mazda', text: 'Mazda', long: 'Mazda', icon: '#other' },
	// xl20ft: { key: 'xl20ft', text: '20 Ft', long: '20 Feet Trolly', icon: '#xl20ft' },
	// xl40ft: { key: 'xl40ft', text: '40 Ft', long: '40 Feet Trolly', icon: '#xl40ft' },
	// xl2020ft: { key: 'xl2020ft', text: 'Double Twenty', long: 'Double Twenty', icon: '#xl40ft' },
	// 'Trolly-xl40ftHC': { key: 'Trolly-xl40ftHC', text: '40 Ft High Cube', long: '40 Feet - High Cube', icon: '#xl40ftHC' },
	// 'Trolly-xl40ftOT': { key: 'Trolly-xl40ftOT', text: '40 Ft Open Top', long: '40 Feet - Open Top', icon: '#xl40ftOT' },
	// flatbed: { key: 'flatbed', text: 'Flatbed', icon: '#flatbed' },
	dumper: { key: 'dumper', text: 'Dumper', icon: '#dumper' },
	halfBody: { key: 'halfBody', text: 'Half Body', icon: '#halfBody' },
	other: { key: 'other', text: 'Other', long: 'Other', icon: '#other' },
}
export const VendorTypeEnums = {
	owner: {
		key: 'owner',
		text: 'Owner',
		icon: mdiTruckOutline,
		background: 'bg-primary-800',
	},
	broker: {
		key: 'broker',
		text: 'Broker',
		icon: mdiAccountCircleOutline,
		background: 'bg-info-green',
	},
	hybrido: {
		key: 'hybrido',
		text: 'HybridO',
		icon: mdiAccountCircleOutline,
		background: 'bg-info-blue',
	},
	hybridb: {
		key: 'hybridb',
		text: 'HybridB',
		icon: mdiAccountCircleOutline,
		background: 'bg-info-yellow',
	},
	investor: {
		key: 'investor',
		text: 'Investor',
		icon: mdiAccountCircleOutline,
		background: 'bg-info-blue',
	},
}
export const DoStatuses = {
	'NOT STARTED': {
		key: 'notStarted',
		text: 'NOT STARTED',
		icon: mdiClockCheckOutline,
		background: 'bg-doStatus-grey',
	},
	'DOC RECEIVED': {
		key: 'docReceived',
		text: 'DOC RECEIVED',
		icon: mdiTextBoxOutline,
		background: 'bg-doStatus-yellow',
	},
	'DOC SUBMITTED': {
		key: 'docSubmitted',
		text: 'DOC SUBMITTED',
		icon: mdiTextBoxOutline,
		background: 'bg-doStatus-blue',
	},
	'DOC RETURNED': {
		key: 'docReturned',
		text: 'DOC RETURNED',
		icon: mdiTextBoxOutline,
		background: 'bg-doStatus-red',
	},
	'GATE PASS': {
		key: 'gatePass',
		text: 'GATE PASS',
		icon: mdiClockCheckOutline,
		background: 'bg-doStatus-teal',
	},
	'DO ISSUED - ONLY': {
		key: 'doIssuedOnly',
		text: 'DO ISSUED - ONLY',
		icon: mdiTextBoxCheckOutline,
		background: 'bg-doStatus-grey',
	},
	'VEHICLE ONLY': {
		key: 'vehicleOnly',
		text: 'VEHICLE ONLY',
		icon: mdiTextBoxCheckOutline,
		background: 'bg-doStatus-grey',
	},
	'DO ISSUED': {
		key: 'doIssued',
		text: 'DO ISSUED',
		icon: mdiTextBoxCheckOutline,
		background: 'bg-doStatus-green',
	},
}

export const Locations = {
	loading: { key: 'loading', title: 'LOADING', icon: mdiMapMarker, background: 'bg-doStatus-blue' },
	dropoff: { key: 'dropoff', title: 'DROP OFF', icon: mdiMapMarker, background: 'bg-doStatus-red' },
	'Empty Container Dropoff': { key: 'Empty Container Dropoff', title: 'EMPTY RETURN', icon: mdiMapMarker, background: 'bg-doStatus-green' },
}

export const PaymentStatusEnums = {
	pendingFreightCostApproval: {
		key: 'pendingFreightCostApproval',
		text: 'Pending Authentication',
		icon: mdiClockOutline,
		textColor: 'text-black-400',
		borderColor: 'border-yellow-400',
		otherTextColor: 'black-400',
		iconColor: '#FF9800',
		background: 'bg-yellow-100',
		secondaryBackground: 'bg-yellow-100',
	},
	pendingApproval: {
		key: 'pendingApproval',
		text: 'Pending Approval',
		icon: mdiFormatListChecks,
		textColor: 'text-black-400',
		borderColor: 'border-yellow-400',
		otherTextColor: 'black-400',
		iconColor: '#607D8B',
		background: 'bg-[#ECEFF1]',
		secondaryBackground: 'bg-[#ECEFF1]',
	},
	pendingPayment: {
		key: 'pendingPayment',
		text: 'Payment Approved',
		icon: mdiProgressCheck,
		textColor: 'text-black-800',
		borderColor: 'border-primary-800',
		otherTextColor: 'black-400',
		iconColor: '#1C75BC',
		background: 'bg-background',
		secondaryBackground: 'bg-background',
	},
	paid: {
		key: 'paid',
		text: 'Payment Processed',
		icon: mdiCheckboxMarkedCircle,
		textColor: 'text-black-400',
		borderColor: 'border-green-400',
		otherTextColor: 'black-400',
		iconColor: '#49CA4E',
		background: 'bg-green-100',
		secondaryBackground: 'bg-green-100',
	},
}
export const NewChargeStatusEnums = {
	PENDING_AUTHENTICATION: {
		icon: mdiClockOutline,
		text: 'Pending Authentication',
		color: 'bg-yellow-400',
		textColor: 'text-yellow-400',
	},
	AUTHENTICATED: {
		icon: mdiFormatListChecks,
		text: 'Authenticated',
		color: 'bg-primary-800',
		textColor: 'text-primary-800',
	},
	REJECTED: {},
	NOT_REQUIRED: {},
	PENDING_URGENT_APPROVAL: {},
	URGENT_APPROVED: {},
	URGENT_REJECTED: {},
	PENDING_SETTLEMENT: {},
	PARTIALLY_SETTLED: {},
	SETTLED: {},
	PENDING_PAYMENT: {},
	PARTIALLY_PAID: {},
	PAID: {},
}

export const CostsEnums = [
	{ key: 'kantaCharges', label: 'Kanta' },
	{ key: 'loloCharges', label: 'LOLO' },
	{ key: 'detentions', label: 'Detentions' },
	{ key: 'deductions', label: 'Deductions' },
	{ key: 'inaam', label: 'Inaam (Reward)' },
	{ key: 'extraWeight', label: 'Extra Weight' },
	{ key: 'dropoff', label: 'Dropoff Charges' },
	{ key: 'advancePayment', label: 'Advance Payment' },
	{ key: 'dehariAtTerminal', label: 'Dehari at Terminal' },
	{ key: 'dehariDueToOrderCancellation', label: 'Order Cancellation Dehari' },
	{ key: 'containerRejectionCharges', label: 'Container Rejection Charges' },
	{ key: 'pickupChangeCharges', label: 'Pickup Change Charges' },
	{ key: 'dehariAtLoading', label: 'Dehari At Loading' },
	{ key: 'dehariAtUnloadingPort', label: 'Dehari At Unloading - Port' },
	{ key: 'dehariAtUnloadingMill', label: 'Dehari At Unloading - Mill' },
	{ key: 'otherCharges', label: 'Other Charges' },
	{ key: 'labourCharges', label: 'Labour Charges' },
	{ key: 'munshianaPaid', label: ' Munshiana Paid' },
	{ key: 'munshianaReceived', label: ' Munshiana Received' },
]

export const PageSizesEnum = {
	10: { frontEnd: 10, backend: 50 },
	20: { frontEnd: 20, backend: 100 },
	50: { frontEnd: 50, backend: 250 },
	100: { frontEnd: 100, backend: 500 },
}
export const BPaySlabs = {
	25000: 4,
	40000: 3.75,
	50000: 4,
	60000: 4.17,
	70000: 4.29,
	80000: 4.38,
	90000: 4.44,
	100000: 4.5,
	110000: 4.55,
	120000: 4.58,
	130000: 4.62,
	140000: 4.64,
	150000: 4.67,
	160000: 4.69,
	170000: 4.71,
	180000: 4.72,
	190000: 4.74,
	200000: 4.75,
	210000: 4.76,
	220000: 4.77,
	230000: 4.78,
	240000: 4.79,
	250000: 4.8,
	260000: 4.81,
	270000: 4.81,
	280000: 4.82,
	290000: 4.83,
	300000: 4.83,
	310000: 4.84,
}

export const NavEnums = {
	import: {
		title: 'Import',
		link: 'import',
		icon: mdiDatabaseArrowLeftOutline,
		children: {
			assignment: { title: 'Unassigned Jobs', link: 'assignment', icon: mdiChartBoxOutline },
			'post-assignment': { title: 'Assigned Jobs', link: 'post-assignment', icon: mdiClipboardClockOutline },
			management: { title: 'Management Layer', link: 'management', icon: mdiClipboardCheckOutline },
		},
	},
	export: {
		title: 'Export',
		link: 'export',
		icon: mdiDatabaseArrowRightOutline,
		children: {
			assignment: { title: 'Unassigned Jobs', link: 'assignment', icon: mdiChartBoxOutline },
			'post-assignment': { title: 'Assigned Jobs', link: 'post-assignment', icon: mdiClipboardClockOutline },
			management: { title: 'Management Layer', link: 'management', icon: mdiClipboardCheckOutline },
		},
	},
	longHaul: {
		title: 'Bulk',
		link: 'longHaul',
		icon: mdiInboxFullOutline,
		children: {
			assignment: { title: 'Unassigned Jobs', link: 'assignment', icon: mdiChartBoxOutline },
			'post-assignment': { title: 'Assigned Jobs', link: 'post-assignment', icon: mdiClipboardClockOutline },
			management: { title: 'Management Layer', link: 'management', icon: mdiClipboardCheckOutline },
		},
	},
	vendorManagement: {
		title: 'Manage Vendors',
		link: 'vendorManagement',
		icon: mdiInboxFullOutline,
		children: {
			brokers: { title: 'Owners and Brokers', link: 'brokers', icon: mdiInboxFullOutline },
			suppliers: { title: 'Supply Associates', link: 'suppliers', icon: mdiInboxFullOutline },
			'bilty-investors': { title: 'Bilty Investors', link: 'bilty-investors', icon: mdiInboxFullOutline },
		},
	},
}

export const TabNavEnums = {
	Import: [
		{ key: 'direct', text: 'Direct Seal', icon: mdiViewGridOutline },
		{ key: 'shifting', text: 'Shifting', icon: mdiTextBoxCheckOutline },
		{ key: 'destuffing', text: 'Destuffing', icon: mdiTextBoxOutline },
		{ key: 'local', text: 'Short Haul', icon: mdiTextBoxOutline },
	],
	Export: [],
	LongHaul: [
		{ key: 'nonLot', text: 'Non-Lot Orders', icon: mdiTextBoxOutline },
		{ key: 'lot', text: 'Lot Orders', icon: mdiViewGridOutline },
	],
}

export const SearchEnums = {
	blNumber: {
		name: 'BL #',
		propertyKey: 'blNumber',
		paths: [['OrderDetails', 'documentNumber']],
	},
	croNumber: {
		name: 'CRO #',
		propertyKey: 'blNumber',
		paths: [['OrderDetails', 'documentNumber']],
	},
	subOrderNumber: {
		name: 'Sub Order #',
		propertyKey: 'subOrderNumber',
		paths: [['OrderDetails', 'subOrderNumber']],
	},
	jobNumber: {
		name: 'Job #',
		propertyKey: 'jobNumber',
		paths: [['jobNumber']],
	},
	containerNumber: {
		name: 'Container #',
		propertyKey: 'containerNumber',
		paths: [['OrderDetails', 'containerNumber']],
	},
	vehicleRegistration: {
		name: 'Vehicle',
		propertyKey: 'vehicleRegistration',
		paths: [['Quotations', 'driver']],
		propertyMapFunction: (driver) => driver && typeof driver === 'object' && JSON.parse(driver)?.vehicleRegistrationNumber,
	},
	driverPhoneNumber: {
		name: 'Driver Phone #',
		propertyKey: 'driverPhoneNumber',
		paths: [['Quotations', 'driver']],
		propertyMapFunction: (driver) => driver && typeof driver === 'object' && JSON.parse(driver)?.phoneNumber,
		comparisonFunc: (input, phoneNumber) => {
			const inputRegex = new RegExp(`^${input}`, 'g')
			const altInputRegex = new RegExp(`^\\+92${input}`, 'g')
			const secondInputRegex = new RegExp(`^0${input}`, 'g')
			const thirdInputRegex = new RegExp(`${input?.replace('92', '')}`, 'g')
			return inputRegex.test(phoneNumber) || altInputRegex.test(phoneNumber) || secondInputRegex.test(phoneNumber) || thirdInputRegex.test(phoneNumber)
		},
	},
	orderLocations: {
		name: 'Locations',
		propertyKey: 'orderLocations',
		paths: [['OrderDetails', 'orderLocations', 'Location', 'formattedAddress']],
	},
	brokerName: {
		name: 'Name',
		propertyKey: 'brokerName',
		paths: [['User']],
		propertyMapFunction: (user) => formatName(user?.firstName, user?.lastName),
	},
	brokerPhone: {
		name: 'Phone Number',
		propertyKey: 'brokerPhone',
		paths: [['User']],
		propertyMapFunction: (user) => formatPhoneNumber(user?.phoneNumber)?.replace('-', ''),
	},
	supplierName: {
		name: 'Name',
		propertyKey: 'supplierName',
		paths: [['User']],
		propertyMapFunction: (user) => formatName(user?.firstName, user?.lastName),
	},
	supplierPhone: {
		name: 'Phone Number',
		propertyKey: 'supplierPhone',
		paths: [['User']],
		propertyMapFunction: (user) => formatPhoneNumber(user?.phoneNumber)?.replace('-', ''),
	},
	investorName: {
		name: 'Name',
		propertyKey: 'name',
		paths: [['name']],
	},
	investorPhone: {
		name: 'Phone Number',
		propertyKey: 'phoneNumber',
		paths: [['phoneNumber']],
	},
}

export const PostSearchEnums = {
	blNumber: {
		name: 'BL #',
		propertyKey: 'blNumber',
		paths: [['documentNumber']],
	},
	subOrderNumber: {
		name: 'Sub Order #',
		propertyKey: 'subOrderNumber',
		paths: [['subOrderNumber']],
		propertyMapFunction: (subOrderNumber) => subOrderNumber?.[0],
	},
	croNumber: {
		name: 'CRO #',
		propertyKey: 'blNumber',
		paths: [['documentNumber']],
	},
	jobNumber: {
		name: 'Job #',
		propertyKey: 'jobNumber',
		paths: [['jobNumber']],
	},
	containerNumber: {
		name: 'Container ##',
		propertyKey: 'containerNumber',
		paths: [['containerInfo', 'containerNumber']],
	},
	vehicleRegistration: {
		name: 'Vehicle',
		propertyKey: 'vehicleRegistration',
		paths: [['driverVehicleRegistration']],
	},
	driverPhoneNumber: {
		name: 'Driver',
		propertyKey: 'driverPhoneNumber',
		paths: [['driverPhone']],
		comparisonFunc: (input, phoneNumber) => {
			const inputRegex = new RegExp(`^${input}`, 'g')
			const altInputRegex = new RegExp(`^\\+92${input}`, 'g')
			const secondInputRegex = new RegExp(`^0${input}`, 'g')
			const thirdInputRegex = new RegExp(`${input?.replace('92', '')}`, 'g')
			return inputRegex.test(phoneNumber) || altInputRegex.test(phoneNumber) || secondInputRegex.test(phoneNumber) || thirdInputRegex.test(phoneNumber)
		},
	},
	client: {
		name: 'Client',
		propertyKey: 'client',
		paths: [['businessName']],
	},
	orderLocations: {
		name: 'Locations',
		propertyKey: 'orderLocations',
		paths: [['loading'], ['dropoff']],
	},
}

export const FilterEnums = {
	readyToMove: {
		name: 'Ready to Move',
		type: 'toggle',
		propertyKey: 'readyToMove',
		paths: [['OrderDetails', 'doInfo', 'readyToMove']],
		filterProps: {
			displayProperty: 'text',
			key: '_id',
			placeholder: 'Select Option',
		},
	},
	managementReadyToMove: {
		name: 'Ready to Move',
		type: 'toggle',
		propertyKey: 'readyToMove',
		paths: [['readyToMove']],
		filterProps: {
			disableFilter: true,
		},
		linkedState: 'readyToMove',
	},
	paymentTerms: {
		name: 'Missing Payment Terms',
		type: 'toggle',
		propertyKey: 'paymentTerms',
		paths: [['paymentTerms']],
		propertyMapFunction: (paymentTerms) => {
			return !paymentTerms || paymentTerms?.length === 0
		},
	},
	bPay: {
		name: 'BPay',
		type: 'toggle',
		propertyKey: 'bPay',
		paths: [['factorStatus']],
		propertyMapFunction: (factorStatus) => {
			return factorStatus === 'approved'
		},
	},
	completedJobs: {
		name: 'Show Completed',
		type: 'toggle',
		propertyKey: 'completedJobs',
		paths: [['status']],
		filterProps: {
			disableFilter: true,
		},
		linkedState: 'showCompletedJobs',
	},
	lane: {
		name: 'Lane',
		type: 'checkbox',
		propertyKey: 'lane',
		showSearch: true,

		paths: [['SuperLane', '_id']],
		initialValues: {},
		filterProps: {
			key: '_id',
			render: (option) => <div key={option?._id} className='font-[500]'>{`${option?.superLane?.from} --> ${option?.superLane?.to}`}</div>,
		},
		searchFunction: ({ keyword, option }) => {
			return `${option?.superLane?.from} ${option?.superLane?.to}`?.toLowerCase().includes(keyword?.toLowerCase())
		},
	},
	subLane: {
		name: 'Sub Lane',
		type: 'checkbox',
		propertyKey: 'subLane',
		showSearch: true,
		shouldShow: (filterState) => FilterTypeEnums.checkbox.checkSelected(filterState?.['lane']?.values),
		paths: [['Lane', '_id']],
		initialValues: {},
		filterProps: {
			key: '_id',
			render: (option) => <div key={option?._id} className='font-[500]'>{`${option?.from} --> ${option?.to}`}</div>,
		},
		searchFunction: ({ keyword, option }) => {
			return `${option?.from} ${option?.to}`?.toLowerCase().includes(keyword?.toLowerCase())
		},
	},
	emptyReturn: {
		name: 'Empty Return',
		type: 'checkbox',
		propertyKey: 'emptyReturn',
		paths: [['OrderDetails', 'orderLocations'], ['terminal']],
		filterProps: {
			key: '_id',
			render: (option) => <div key={option?._id} className='font-[500]'>{`${option?.name}`}</div>,
		},
		propertyMapFunction: (locations) => {
			const terminal = isArray(locations) && locations?.find((location) => location?.locationType === 'Empty Container Dropoff')
			return terminal?.Location?._id || ''
		},
	},
	freeDays: {
		name: 'Free Days',
		type: 'range',
		propertyKey: 'freeDays',
		paths: [['OrderDetails', 'emptyReturnDate']],
		filterProps: {
			unit: 'Days',
			lowTitle: 'Min',
			highTitle: 'Max',
			inputType: 'number',
		},
		propertyMapFunction: (emptyReturnDate) => {
			const freeDays = emptyReturnDate && moment(emptyReturnDate).diff(moment(), 'days')
			return parseInt(freeDays) > 0 ? parseInt(freeDays) : 0
		},
	},
	vehicleType: {
		name: 'Vehicle Type',
		type: 'checkbox',
		propertyKey: 'vehicleType',
		paths: [['OrderDetails'], ['vehicleTypeInfo'], ['vehicleType']],
		filterProps: {
			key: 'key',
			comparisonFunc: (value, other) => {
				return other?.[value] === true || other?.[`Trolly-${value}`] === true
			},
			render: (option) => <div key={option?.key}>{option?.long}</div>,
		},
		propertyMapFunction: (orderDetails) => {
			let vehicleType
			if (isArray(orderDetails)) {
				vehicleType = orderDetails?.map((order) => order?.vehicleType || order?.containerDimensions)
			} else if (typeof orderDetails === 'object') {
				vehicleType = [orderDetails?.vehicleType || orderDetails?.containerDimensions]
			} else {
				vehicleType = [orderDetails]
			}
			return (isArray(orderDetails) ? orderDetails?.length > 1 : orderDetails?.subOrderNumber?.length > 1) && vehicleType?.[0] === 'xl20ft'
				? 'xl2020ft'
				: vehicleType?.[0]?.includes('xl40ft')
				? 'xl40ft'
				: vehicleType?.[0] === 'flatbed'
				? 'Flatbed'
				: vehicleType?.[0]?.includes('Trolly-')
				? vehicleType?.[0]?.slice(7)
				: vehicleType?.[0]
		},
	},
	weight: {
		name: 'Weight',
		type: 'range',
		propertyKey: 'weight',
		paths: [['OrderDetails'], ['weightInfo']],
		filterProps: {
			unit: 'Tons',
			lowTitle: 'Lowest',
			highTitle: 'Highest',
			inputType: 'number',
		},
		propertyMapFunction: (orderDetails) => {
			if (isArray(orderDetails)) {
				return orderDetails?.reduce((totalWeight, order) => {
					const weight = order.weight || order?.childShipmentInfo?.freightWeight || order?.freightWeight
					return (weight ? (typeof weight === 'string' ? parseFloat(weight) : weight) : 0) + totalWeight
				}, 0)
			} else {
				return parseFloat(orderDetails?.containerWeight) > 0 ? orderDetails?.containerWeight : orderDetails?.freightWeight
			}
		},
	},
	weightSlot: {
		name: 'Weight Slot',
		type: 'stepper',
		propertyKey: 'weightSlot',
		paths: [['weight']],
		filterProps: {
			unit: 'Tonnes',
			initialValue: '5',
			title: 'Slot in terms of tonnage',
			description: 'Default slot is 5 tons. But you can increase or decrease slots to further breakdown your management view.',
		},
		linkedState: 'weightSlot',
	},
	intakeDate: {
		name: 'Intake Date',
		type: 'range',
		propertyKey: 'intakeDate',
		paths: [['createdAt']],
		filterProps: {
			unit: '',
			lowTitle: 'From',
			highTitle: 'To',
			inputType: 'dateTime',
			disableFilter: true,
			comparisonFunc: (value, low, high) => parseInt(value) - low.valueOf() >= 0 && parseInt(value) - high.valueOf() <= 0,
			stringify: (date) => date && moment(date).format('DD/MM/YY').replaceAll('/', '-'),
			parse: (date) => date && moment(date.replaceAll('-', '/'), 'DD/MM/YY'),
		},
		linkedState: 'intakeDate',
	},
	orderIntakeDate: {
		name: 'Intake Date',
		type: 'range',
		propertyKey: 'orderIntakeDate',
		paths: [['OrderDetails', 'createdAt']],
		filterProps: {
			unit: '',
			lowTitle: 'From',
			highTitle: 'To',
			inputType: 'dateTime',
			comparisonFunc: (value, low, high) =>
				moment(value)?.diff(low.startOf('day'), 'minutes') >= 0 && moment(value).diff(high.endOf('day'), 'minutes') <= 0,
			stringify: (date) => date && moment(date).format('DD/MM/YY').replaceAll('/', '-'),
			parse: (date) => date && moment(date.replaceAll('-', '/'), 'DD/MM/YY'),
		},
	},
	biltyDate: {
		name: 'Bilty Date',
		type: 'range',
		propertyKey: 'biltyDate',
		paths: [['driverMatchedAt']],
		filterProps: {
			unit: '',
			lowTitle: 'From',
			highTitle: 'To',
			inputType: 'dateTime',
			comparisonFunc: (value, low, high) => {
				return moment(value)?.diff(low.startOf('day'), 'minutes') >= 0 && moment(value).diff(high.endOf('day'), 'minutes') <= 0
			},
			stringify: (date) => date && moment(date).format('DD/MM/YY').replaceAll('/', '-'),
			parse: (date) => date && moment(date.replaceAll('-', '/'), 'DD/MM/YY'),
		},
	},
	rtmDate: {
		name: 'RTM Date',
		type: 'range',
		propertyKey: 'rtmDate',
		paths: [['rtmDate']],
		filterProps: {
			unit: '',
			lowTitle: 'From',
			highTitle: 'To',
			inputType: 'dateTime',
			// disableFilter: true,
			comparisonFunc: (value, low, high) => parseInt(value) - low.valueOf() >= 0 && parseInt(value) - high.valueOf() <= 0,
			stringify: (date) => date && moment(date, 'x').format('DD/MM/YY').replaceAll('/', '-'),
			parse: (date) => date && moment(date.replaceAll('-', '/'), 'DD/MM/YY'),
		},
		linkedState: 'rtmDate',
	},
	client: {
		name: 'Client Name',
		type: 'select',
		propertyKey: 'client',
		paths: [['OrderDetails', 'clientName'], ['businessName'], ['clientName']],
		filterProps: {
			displayProperty: 'clientName',
		},
		linkedState: 'client',
	},
	supplier: {
		name: 'Supplier Name',
		type: 'select',
		propertyKey: 'supplier',
		paths: [
			['Quotations', 'Supplier', '_id'],
			['supplier', '_id'],
		],
		filterProps: {
			displayProperty: 'name',
		},
	},
	broker: {
		name: 'Broker Name',
		type: 'select',
		propertyKey: 'broker',
		paths: [
			['Quotations', 'Broker', '_id'],
			['broker', '_id'],
		],
		filterProps: {
			displayProperty: 'name',
		},
	},
	paymentStatus: {
		name: 'Payment Status',
		type: 'select',
		propertyKey: 'paymentStatus',
		paths: [['vendorPayments', 'paymentStatus']],
		filterProps: {
			key: 'key',
			displayProperty: 'text',
		},
		propertyMapFunction: (status) => (status ? status : 'pendingFreightCostApproval'),
	},
	vendorType: {
		name: 'Vendor Type',
		type: 'select',
		propertyKey: 'vendorType',
		paths: [['vendorType']],
		filterProps: {
			displayProperty: 'name',
		},
	},
}

export const FilterTypeEnums = {
	range: {
		schema: RangeSchema,
		function: (value, filterValues, filterProps) => {
			return (
				filterProps?.disableFilter ||
				(filterProps?.comparisonFunc
					? filterProps?.comparisonFunc(value, filterValues.low, filterValues.high)
					: value >= parseFloat(filterValues.low) && value <= parseFloat(filterValues.high))
			)
		},
		checkSelected: (values) => values?.low > 0 || values?.high > 0,
		selectedDisplay: ({ values, filterProps }) =>
			` ${filterProps.stringify ? filterProps.stringify(values?.low) : values?.low || '_'} to ${
				filterProps.stringify ? filterProps.stringify(values?.high) : values?.high || '_'
			} ${filterProps.unit || ''}`,
		trigger: 'button',
		initialValues: (values) => ({ low: null, high: null }),
		getValuesFromArray: (values, filterProps) => {
			return {
				low: filterProps.parse ? filterProps.parse(values?.[0]) : values?.[0],
				high: filterProps.parse ? filterProps.parse(values?.[1]) : values?.[1],
			}
		},
		stringifyState: (values, filterProps) =>
			`${filterProps.stringify ? filterProps.stringify(values?.low) : values?.low}+${
				filterProps.stringify ? filterProps.stringify(values?.high) : values?.high
			}`,
	},
	select: {
		schema: SelectSchema,
		function: (value, filterValues, filterProps) => {
			return filterProps?.disableFilter || value === filterValues
		},
		checkSelected: (values) => values && Object.keys(values).length > 0,
		trigger: 'auto',
		selectedDisplay: ({ values, options, filterProps, prevState }) =>
			` ${options?.[values]?.[filterProps.displayProperty || '_id'] || prevState?.[filterProps.displayProperty || '_id']}`,
		initialValues: (values) => null,
		getValuesFromArray: (values) => values?.[0],
		stringifyState: (values) => values,
	},
	checkbox: {
		schema: CheckboxSchema,
		function: (value, filterValues, filterProps) =>
			filterProps?.disableFilter || (filterProps?.comparisonFunc ? filterProps?.comparisonFunc(value, filterValues) : filterValues?.[value] === true),
		checkSelected: (values) => values && Object.values(values).some((value) => value),
		trigger: 'button',
		selectedDisplay: ({ values, options, filterProps, prevState }) => {
			const filteredValues = values && Object.keys(values).filter((key) => values[key])
			const length = filteredValues?.length
			return (
				<div className='flex flex-row items-center'>
					<div>{filterProps?.render(options?.[filteredValues[0]] || prevState)}</div>
					{length > 1 && <div className='mr-2 ml-3 text-xs bg-[#000000] bg-opacity-25 rounded-[100px] pl-1 pr-[5px] py-1 text-white'>{` +${length - 1}`}</div>}
				</div>
			)
		},
		initialValues: (values) =>
			Object.keys(values).reduce((newValues, property) => {
				newValues[property] = false
				return newValues
			}, {}),
		getValuesFromArray: (values) =>
			values?.reduce((state, value) => {
				state[value] = true
				return state
			}, {}),
		stringifyState: (values) =>
			values &&
			Object.entries(values)?.reduce(
				(stateString, value, index) => (value?.[1] === true ? `${stateString}${index !== 0 ? '+' : ''}${value?.[0]}` : stateString),
				''
			),
	},
	stepper: {
		customOverlay: ({ filter, state }) => {
			const { filterProps } = filter
			return (
				<div className='flex flex-col mx-4 w-[250px]'>
					<p className='mt-4 font-[500] text-xs text-black-400'>{filterProps.title}</p>
					<Form.Item key={`${filter.propertyKey}-values`} name={[filter.propertyKey, 'values']} noStyle={true}>
						<CustomStepper state={state} />
					</Form.Item>
					<p className='mb-4 font-[400] text-xs text-black-300'>{filterProps.description}</p>
				</div>
			)
		},
		hideClear: true,
		function: (value, filterValues) => true,
		checkSelected: (values) => true,
		selectedDisplay: ({ values, filterProps }) => ` ${values || '_'} ${filterProps.unit || ''}`,
		trigger: 'onClose',
		initialValues: (values, filterProps) => filterProps?.initialValue || null,
		getValuesFromArray: (values) => values?.[0] || null,
		stringifyState: (values) => `${values}`,
	},
	toggle: {
		function: (value, filterValues, filterProps) => filterProps?.disableFilter || (filterValues === true ? value === filterValues : true),
		checkSelected: (values) => values === true,
		customDisplay: ({ state, filter }) => {
			return (
				<Form.Item key={`${filter.propertyKey}-values`} name={[filter.propertyKey, 'values']} noStyle={true} shouldUpdate={true}>
					<CustomSwitchWrapper state={state} />
				</Form.Item>
			)
		},
		trigger: 'auto',
		hideClear: true,
		initialValues: (values, filterProps) => false,
		getValuesFromArray: (values) => values?.[0] === 'true' || null,
		stringifyState: (values) => `${values}`,
	},
}

// different enums for different pages. combine at one place

export const AssignmentFilterEnums = {
	Import: [
		FilterEnums.readyToMove,
		FilterEnums.lane,
		FilterEnums.subLane,
		FilterEnums.emptyReturn,
		FilterEnums.freeDays,
		FilterEnums.vehicleType,
		FilterEnums.weight,
		FilterEnums.client,
		FilterEnums.supplier,
		FilterEnums.broker,
		FilterEnums.orderIntakeDate,
	],

	Export: [
		FilterEnums.lane,
		FilterEnums.subLane,
		FilterEnums.vehicleType,
		FilterEnums.weight,
		FilterEnums.client,
		FilterEnums.supplier,
		FilterEnums.broker,
		FilterEnums.orderIntakeDate,
	],
	LongHaul: [
		FilterEnums.lane,
		FilterEnums.subLane,
		FilterEnums.vehicleType,
		FilterEnums.weight,
		FilterEnums.client,
		FilterEnums.supplier,
		FilterEnums.broker,
		FilterEnums.orderIntakeDate,
	],
	ImportShifting: [
		FilterEnums.readyToMove,
		FilterEnums.lane,
		FilterEnums.subLane,
		FilterEnums.vehicleType,
		FilterEnums.weight,
		FilterEnums.client,
		FilterEnums.supplier,
		FilterEnums.broker,
		FilterEnums.orderIntakeDate,
	],
}

export const PostAssignmentFilterEnums = {
	Import: [
		FilterEnums.completedJobs,
		FilterEnums.biltyDate,
		FilterEnums.lane,
		FilterEnums.subLane,
		FilterEnums.emptyReturn,
		FilterEnums.vehicleType,
		FilterEnums.weight,
		FilterEnums.client,
		FilterEnums.supplier,
		FilterEnums.broker,
		FilterEnums.paymentTerms,
		FilterEnums.paymentStatus,
		FilterEnums.bPay,
	],
	Export: [
		FilterEnums.completedJobs,
		FilterEnums.biltyDate,
		FilterEnums.lane,
		FilterEnums.subLane,
		FilterEnums.vehicleType,
		FilterEnums.weight,
		FilterEnums.client,
		FilterEnums.supplier,
		FilterEnums.broker,
		FilterEnums.paymentTerms,
		FilterEnums.paymentStatus,
		FilterEnums.bPay,
	],
	LongHaul: [
		FilterEnums.completedJobs,
		FilterEnums.biltyDate,
		FilterEnums.lane,
		FilterEnums.subLane,
		FilterEnums.vehicleType,
		FilterEnums.weight,
		FilterEnums.client,
		FilterEnums.supplier,
		FilterEnums.broker,
		FilterEnums.paymentTerms,
		FilterEnums.paymentStatus,
		FilterEnums.bPay,
	],
	ImportShifting: [
		FilterEnums.completedJobs,
		FilterEnums.biltyDate,
		FilterEnums.lane,
		FilterEnums.subLane,
		FilterEnums.vehicleType,
		FilterEnums.weight,
		FilterEnums.client,
		FilterEnums.supplier,
		FilterEnums.broker,
		FilterEnums.paymentTerms,
		FilterEnums.paymentStatus,
		FilterEnums.bPay,
	],
}

export const ManagementFilterEnums = {
	Import: [
		FilterEnums.lane,
		FilterEnums.vehicleType,
		FilterEnums.weightSlot,
		FilterEnums.intakeDate,
		// FilterEnums.managementReadyToMove,
		FilterEnums.rtmDate,
		FilterEnums.client,
	],
	Export: [
		FilterEnums.lane,
		FilterEnums.vehicleType,
		FilterEnums.weightSlot,
		FilterEnums.intakeDate,
		FilterEnums.managementReadyToMove,
		FilterEnums.client,
	],
	LongHaul: [
		FilterEnums.lane,
		FilterEnums.vehicleType,
		FilterEnums.weightSlot,
		FilterEnums.intakeDate,
		// FilterEnums.managementReadyToMove,
		FilterEnums.client,
	],
	ImportShifting: [
		FilterEnums.lane,
		FilterEnums.vehicleType,
		FilterEnums.weightSlot,
		FilterEnums.intakeDate,
		// FilterEnums.managementReadyToMove,
		FilterEnums.client,
	],
}
export const VendorManagementFilterEnums = {
	default: [FilterEnums.vendorType],
}

export const PageFilterEnums = {
	assignment: AssignmentFilterEnums,
	'post-assignment': PostAssignmentFilterEnums,
	management: ManagementFilterEnums,
	brokers: VendorManagementFilterEnums,
}
export const AssignmentSearchEnums = {
	Import: [
		SearchEnums.blNumber,
		SearchEnums.jobNumber,
		SearchEnums.containerNumber,
		SearchEnums.vehicleRegistration,
		SearchEnums.driverPhoneNumber,
		SearchEnums.orderLocations,
	],
	Export: [SearchEnums.croNumber, SearchEnums.jobNumber, SearchEnums.vehicleRegistration, SearchEnums.driverPhoneNumber, SearchEnums.orderLocations],
	LongHaul: [
		SearchEnums.jobNumber,
		SearchEnums.subOrderNumber,
		SearchEnums.vehicleRegistration,
		SearchEnums.driverPhoneNumber,
		SearchEnums.orderLocations,
	],
	ImportShifting: [
		SearchEnums.jobNumber,
		SearchEnums.subOrderNumber,
		SearchEnums.vehicleRegistration,
		SearchEnums.driverPhoneNumber,
		SearchEnums.orderLocations,
	],
	Lot: [SearchEnums.subOrderNumber, SearchEnums.jobNumber],
}

export const PostAssignmentSearchEnums = {
	Import: [
		PostSearchEnums.blNumber,
		PostSearchEnums.jobNumber,
		PostSearchEnums.containerNumber,
		PostSearchEnums.vehicleRegistration,
		PostSearchEnums.driverPhoneNumber,
		PostSearchEnums.orderLocations,
		PostSearchEnums.client,
	],
	Export: [
		PostSearchEnums.croNumber,
		PostSearchEnums.jobNumber,
		PostSearchEnums.vehicleRegistration,
		PostSearchEnums.driverPhoneNumber,
		PostSearchEnums.orderLocations,
		PostSearchEnums.client,
	],
	LongHaul: [
		PostSearchEnums.jobNumber,
		PostSearchEnums.subOrderNumber,
		PostSearchEnums.vehicleRegistration,
		PostSearchEnums.driverPhoneNumber,
		PostSearchEnums.orderLocations,
		PostSearchEnums.client,
	],
}
export const VendorManagementSearchEnums = {
	default: [SearchEnums.brokerName, SearchEnums.brokerPhone],
}
export const SupplierManagementSearchEnums = {
	default: [SearchEnums.supplierName, SearchEnums.supplierPhone],
}
export const BiltyInvestorManagementSearchEnums = {
	default: [SearchEnums.investorName, SearchEnums.investorPhone],
}
export const PageSearchEnums = {
	assignment: AssignmentSearchEnums,
	'post-assignment': PostAssignmentSearchEnums,
	brokers: VendorManagementSearchEnums,
	suppliers: SupplierManagementSearchEnums,
	'bilty-investors': BiltyInvestorManagementSearchEnums,
}
export const ModalEnums = {
	jobDetails: ({ edit }) => ({
		title: 'Manage Job',
		showTimeline: true,
		customResultPage: {
			width: 700,
			columns: [{ widthClassName: 'w-[100%]', element: BPayAssignmentResult }],
		},
		startingPage: ({ data }) => {
			const job = data?.jobs?.[0]
			return job?.supplier?._id ? 1 : 0
		},
		resetFormValues: ({ form, props }) => {
			const { data: { jobs: { 0: { supplier, broker, cost, factoredAmount } = {} } = {} } = {} } = props
			const formValues = form.getFieldsValue(true)
			const newValues = {
				supplier: formValues?.supplier || supplier?._id,
				broker: formValues?.broker || broker?._id,
				cost: formValues?.cost || cost,
				factoredAmount: formValues?.factoredAmount || factoredAmount,
			}
			form.setFieldsValue(newValues)
		},
		pages: [
			{
				title: 'Assign Supplier',
				watchField: 'all',
				width: 700,
				columns: [
					{ widthClassName: 'w-[230px]', element: JobDetails },
					{ widthClassName: 'w-[470px]', element: SingleJobSupplierAssignment },
				],
				buttons: edit
					? [
							{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
							{ type: 'submit', title: 'SUBMIT SUPPLIER DETAILS', className: 'btn-primary' },
							{ type: 'next', title: 'PROCEED', className: 'btn-primary' },
					  ]
					: [
							{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
							{ type: 'next', title: 'Next', className: 'btn-primary' },
					  ],
				checkButtonDisable: ({ formWatch, tab, buttonType }) => {
					if (edit)
						return (
							!formWatch?.supplier ||
							(formWatch?.supplier === 'Bpay' && !(formWatch?.broker && formWatch?.cost)) ||
							(tab === 'local' && buttonType === 'submit') ||
							(formWatch?.supplier === 'Bpay' && buttonType === 'submit')
						)
				},
				useFinishFunction: useSingleAssign,
				warning: {
					function: checkJobSlipped,
					data: {
						title: 'Quotation Slippage Warning',
						message: 'This action will mark current applied supplier as slipped.',
					},
				},
			},
			{
				title: 'Assign Driver',
				watchField: 'all',
				width: 700,
				hidePage: ({ values, props: { data, tab } }) => {
					return (
						data?.formData?.suppliers?.find((supplier) => supplier?._id === values?.supplier)?.name?.includes('BPay') &&
						!data?.jobs?.[0]?.supplier?._id &&
						tab !== 'local'
					)
				},
				columns: [
					{ widthClassName: 'w-[230px]', element: JobDetails },
					{ widthClassName: 'w-[470px]', element: SingleJobDriverAssignment },
				],
				useFinishFunction: useSingleAssign,
				buttons: edit
					? [
							{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
							{ type: 'submit', title: 'SUBMIT DETAILS', className: 'btn-primary' },
							{ type: 'next', title: 'PROCEED', className: 'btn-primary' },
					  ]
					: [
							{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
							{ type: 'next', title: 'Next', className: 'btn-primary' },
					  ],
				checkButtonDisable: ({ formWatch, data, buttonType, tab }) => {
					if (edit) return data?.jobs?.[0]?.factorRequest ? !(formWatch?.phoneNumber && formWatch?.vehicleRegistration) : buttonType === 'submit'
				},
			},
			{
				title: 'Manage Payment Terms',
				watchField: 'all',
				width: 700,
				columns: [{ widthClassName: 'w-[100%]', element: SingleJobPaymentTermsAssignment }],
				buttons: edit
					? [{ type: 'submit', title: 'SUBMIT DETAILS', className: 'btn-primary' }]
					: [{ type: 'cancel', title: 'Close', className: 'btn-delete' }],
				useFinishFunction: useSingleAssign,
				checkButtonDisable: ({ formWatch, values, data }) => {
					const { cost = data?.jobs?.[0]?.cost } = values
					const factoredAmount = data?.jobs?.[0]?.factoredAmount
					const costType = formWatch?.costType
					const cumulativeSum =
						formWatch?.paymentTerms?.reduce((acc, term) => {
							if (term) return acc + parseFloat(term?.amount)
						}, 0) || 0
					return !(cumulativeSum == parseFloat(factoredAmount || costType === 'factored' ? factoredAmount || cost + findSlab(cost, true) : cost))
				},
			},
		],
	}),
	lotAssignment: ({ create = true }) => ({
		title: '',
		showTimeline: false,
		pages: [
			{
				title: create ? 'Create Job' : 'Edit Assignment',
				watchField: 'all',
				width: 750,
				columns: [
					{ widthClassName: 'w-[230px]', element: JobDetails },
					{ widthClassName: 'w-[520px]', element: SingleJobLotAssignment }, //change it to single job assignment
				],
				useFinishFunction: useLotJobAssign,
				buttons: [
					{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
					{ type: 'submit', title: 'SUBMIT DETAILS', className: 'btn-primary' },
				],
			},
		],
	}),
	finishLotAssignment: () => ({
		title: '',
		showTimeline: false,
		pages: [
			{
				title: 'Finish Lot Assignment',
				width: 550,
				columns: [{ widthClassName: 'w-[100%]', element: FinishLotAssignment }],
				buttons: [
					{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
					{ type: 'submit', title: 'FINISH ASSIGNMENT', className: 'btn-primary' },
				],
				useFinishFunction: useFinishLotAssignment,
			},
		],
	}),
	bulkAssignment: () => ({
		title: '',
		showTimeline: '',
		pages: [
			{
				title: 'Assign Suppliers',
				width: 700,
				watchField: 'laneRates',
				buttons: [
					{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
					{ type: 'next', title: 'NEXT', className: 'btn-primary' },
				],
				checkButtonDisable: ({ formWatch, values }) => {
					return formWatch
						? Object.values(formWatch).some((laneRate) => (parseInt(laneRate.cost) || 0) <= 0)
						: Object.values(values?.laneRates).some((laneRate) => (parseInt(laneRate.cost) || 0) <= 0)
				},
				columns: [{ widthClassName: 'w-[700px]', element: AssignLaneRates }],
			},
			{
				title: 'Assign Suppliers',
				width: 800,
				watchField: 'suppliers',
				subHeader: AssignBulkSupplierHeader,
				checkButtonDisable: ({ formWatch, data }) => {
					const { jobs } = data
					return (formWatch && Object.values(formWatch).reduce((total, curr) => total + parseInt(curr?.numVehicles || '0'), 0)) !== jobs?.length
				},
				buttons: [
					{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
					{ type: 'submit', title: 'SUBMIT DETAILS', className: 'btn-primary' },
				],
				useFinishFunction: useBulkAssign,
				columns: [
					{ widthClassName: 'w-[520px]', element: SupplierSelectionTable },
					{ widthClassName: 'w-[280px]', element: SelectedSupplierColumn },
				],
			},
		],
	}),
	splitJob: () => ({
		title: '',
		pages: [
			{
				title: 'Split Job',
				width: 620,
				watchField: 'numJobs',
				buttons: [
					{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
					{ type: 'submit', title: 'SUBMIT', className: 'btn-primary' },
				],
				useFinishFunction: useSplitJob,
				columns: [{ widthClassName: 'w-full', element: SplitJobModal }],
			},
		],
	}),
	editAssignment: () => ({
		title: '',
		showTimeline: false,
		pages: [
			{
				title: 'Edit Assignment Details',
				width: 590,
				watchField: 'slipped',
				buttons: [
					{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
					{ type: 'submit', title: 'SUBMIT', className: 'btn-primary' },
				],
				useFinishFunction: useEditAssignment,
				columns: [{ widthClassName: 'w-full', element: EditAssignmentModal }],
			},
		],
	}),
	editPayments: () => ({
		title: '',
		showTimeline: false,
		pages: [
			{
				title: 'Edit Payment Details',
				width: 620,
				buttons: [
					{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
					{ type: 'submit', title: 'SUBMIT DETAILS', className: 'btn-primary' },
				],
				useFinishFunction: useEditPayments,
				columns: [{ widthClassName: 'w-full', element: EditPaymentsModal }],
			},
		],
	}),
	editPaymentTerms: () => ({
		title: '',
		showTimeline: false,
		pages: [
			{
				title: 'Manage Payment Terms',
				watchField: 'all',
				width: 700,
				columns: [{ widthClassName: 'w-[100%]', element: SingleJobPaymentTermsAssignment }],
				buttons: [{ type: 'submit', title: 'SUBMIT DETAILS', className: 'btn-primary' }],
				useFinishFunction: useEditPaymentTerms,
				checkButtonDisable: ({ formWatch, data }) => {
					const { job } = data

					const { cost } = job || 0
					const cumulativeSum =
						formWatch?.paymentTerms?.reduce((acc, term) => {
							if (term) {
								return acc + (term?.amount === '' ? 0 : parseFloat(term?.amount))
							} else {
								return acc
							}
						}, 0) || 0
					return !(cumulativeSum == parseFloat(cost))
				},
			},
		],
	}),
	vendorDetails: ({ edit, type }) => ({
		resetFormValues: ({ form, props }) => {
			const {
				data: { vendor },
			} = props
			const newValues = {
				cnic: vendor?.User?.cnic,
				phoneNumber: vendor?.User?.phoneNumber?.replace('+92', ''),
				name: formatName(vendor?.User?.firstName, vendor?.User?.lastName),
				...(type === 'broker' && { vendorType: vendor?.type }),
			}
			form.setFieldsValue(newValues)
		},
		pages: [
			{
				title: `${edit ? 'Edit' : 'Add'} Vendor`,
				watchField: 'all',
				width: 475,
				columns: [{ widthClassName: 'w-[100%]', element: AddEditVendor }],
				buttons: [
					{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
					{ type: 'submit', title: `${edit ? 'SUBMIT CHANGES' : 'ADD VENDOR'}`, className: 'btn-primary' },
				],
				useFinishFunction: useAddEditVendor,
			},
		],
	}),
	deleteBroker: ({ edit }) => ({
		pages: [
			{
				title: 'Remove Vendor',
				watchField: 'all',
				width: 350,
				columns: [{ widthClassName: 'w-[100%]', element: DeleteVendor }],
				buttons: [
					{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
					{ type: 'submit', title: 'DELETE BROKER', className: 'btn-primary' },
				],
				useFinishFunction: useDeleteVendor,
			},
		],
	}),
	bulkAuthenticate: () => ({
		pages: [
			{
				title: 'Authenticate Charges',
				width: 650,
				columns: [{ widthClassName: 'w-[100%]', element: BulkAuthenticateCharges }],
				buttons: [
					{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
					{ type: 'submit', title: 'AUTHENTICATE CHARGES', className: 'btn-primary' },
				],
				warning: {
					alwaysReject: true,
					function: ({ values, props }) => {
						const { selectedChargeIds, ...rest } = values || {}
						delete rest['undefined']
						const updateChargeIds = Object.entries(rest || {})
							?.filter((entry) => entry?.[1]?.['selectAll'] === undefined && typeof entry?.[1]?.amount === 'string')
							?.map((entry) => entry[0])
						console.log({ selectedChargeIds, updateChargeIds, rest })
						return updateChargeIds?.length <= 0 && selectedChargeIds?.length <= 0
					},
					data: {
						title: 'No charges selected',
						message: 'This action cannot be performed as no charges have been selected or updated.',
					},
				},
				useFinishFunction: useBulkAuthenticate,
			},
		],
	}),
	bulkAddCharges: () => ({
		pages: [
			{
				title: 'Add New Charges',
				width: 650,
				watchField: 'all',

				columns: [{ widthClassName: 'w-[100%]', element: BulkAddCharges }],
				buttons: [
					{ type: 'cancel', title: 'CANCEL', className: 'btn-delete' },
					{ type: 'submit', title: 'SUBMIT', className: 'btn-primary' },
				],
				checkButtonDisable: ({ formWatch, data, modalState }) => {
					return modalState?.numCosts > modalState?.currPage
				},
				useFinishFunction: useBulkAdd,
			},
		],
	}),
}

export const ParentRegionEnums = {
	Islamabad: {
		short: 'ISB',
		long: 'Islamabad',
	},
	Lahore: {
		short: 'LHR',
		long: 'Lahore',
	},
	Karachi: {
		short: 'KHI',
		long: 'Karachi',
	},
	Peshawar: {
		short: 'PEW',
		long: 'Peshawar',
	},
}

export const GraphQLEnums = {
	Corporates: {
		fetch: { default: { name: GET_ALL_CORPORATES, key: ['corporates'] } },
		create: {},
		update: {},

		atoms: {
			corporate: corporatesAtom,
		},
	},
	Suppliers: {
		fetch: { default: { name: GET_ALL_SUPPLIERS, key: ['suppliers'] } },
		create: { name: CREATE_SUPPLIER, key: ['createSupplier'], createKey: 'createSupplierInput' },
		update: { name: UPDATE_SUPPLIER, key: ['updateSupplier'], createKey: 'updateSupplierInput' },
		subscription: { name: SUPPLIER_UPDATED_SUBSCRIPTION, key: 'supplierUpdated' },
		atoms: {
			supplier: suppliersAtom,
		},
	},
	BiltyInvestors: {
		fetch: { default: { name: GET_ALL_BILTY_INVESTORS, key: ['biltyInvestors'] } },
		create: { name: CREATE_BILTY_INVESTOR, key: ['createBiltyInvestor'], createKey: 'createBiltyInvestorInput' },
		update: { name: UPDATE_BILTY_INVESTOR, key: ['updateBiltyInvestor'], createKey: 'updateBiltyInvestorInput' },
		subscription: { default: { name: BILTY_INVESTOR_UPDATED, key: 'biltyInvestorUpdated' } },

		atoms: {
			biltyInvestor: biltyInvestorsAtom,
		},
	},
	Terminals: {
		fetch: { default: { name: GET_ALL_EMPTY_TERMINALS, key: ['locations'] } },
		create: {},
		update: {},

		atoms: {
			terminal: containerTerminalsAtom,
		},
	},
	Vehicles: {
		fetch: { default: { name: GET_VEHICLE, key: ['vehicles'] } },
		update: { name: UPDATE_VEHICLE, key: ['updateVehicles', 'vehicles'], createKey: 'update' },
		create: { name: CREATE_VEHICLE, key: ['createVehicle'], createKey: 'createVehicleInput' },
	},
	Brokers: {
		fetch: { default: { name: GET_ALL_BROKERS, key: ['brokers'] } },
		create: { name: CREATE_BROKER, key: ['createBroker'], createKey: 'createBrokerInput' },
		update: { name: UPDATE_BROKER, key: ['updateBroker'], createKey: 'updateBrokerInput' },
		subscription: { name: BROKER_UPDATED_SUBSCRIPTION, key: 'brokerUpdated' },
		atoms: {
			broker: brokersAtom,
		},
	},
	Status: {
		create: { name: CREATE_PARENT_STATUS, key: ['createParentStatus'], createKey: 'createParentStatusInput' },
	},
	Lanes: {
		fetch: { default: { name: GET_ALL_LANE_RATES, key: ['lanes'] } },
		create: {},
		update: {},

		atoms: {
			lane: lanesAtom,
		},
	},
	AssignJobsToSuppliers: {
		fetch: {},
		create: { name: ASSIGN_JOBS_TO_SUPPLIER, key: ['assignJobstoSupplier'], createKey: 'assignJobstoSupplierInput' },
		update: {},

		atoms: {
			// user: userAtom,
		},
	},
	MatchOrderToJob: {
		fetch: {},
		create: { name: MATCH_ORDER_TO_JOB, key: ['matchOrderToJob'], createKey: 'matchOrderToJobInput' },
		update: {},

		atoms: {
			// user: userAtom,
		},
	},
	SplitJob: {
		fetch: {},
		create: { name: SPLIT_JOB, key: ['splitJob'], createKey: 'splitJobInput' },
		update: {},
		atoms: {
			// user: userAtom,
		},
	},
	SlipJob: {
		fetch: {},
		create: { name: SLIP_DRIVER_IN_JOB, key: ['slipDriverInJob'], createKey: 'id' },
		update: {},

		atoms: {
			// user: userAtom,
		},
	},
	ManagementData: {
		fetch: {
			default: {
				name: GET_MANAGEMENT_VIEW_DATA,
				key: ['managementViewApi'],
				getVariables: ({ type, tab, weightSplit, intakeDate, rtmDate }) => {
					const types =
						type === 'import'
							? tab === 'shifting' || tab === 'destuffing'
								? { movementType: 'ImportShifting', subMovementType: 'LongHaul' }
								: tab === 'local'
								? { movementType: 'Import', subMovementType: 'ImportShortHaul' }
								: { movementType: 'Import', subMovementType: 'Import' }
							: { movementType: Capitalize(type), subMovementType: Capitalize(type) }

					return {
						managementViewInput: {
							...types,
							weightSplit: weightSplit || 5,
							...(intakeDate
								? intakeDate.low &&
								  intakeDate.high && {
										createdAt: {
											createdAtFrom: intakeDate.low.startOf('day').format(),
											createdAtTo: intakeDate.high.endOf('day').format(),
										},
								  }
								: {
										createdAt: {
											createdAtFrom: '22 March 2023 00:00:00 GMT+05:00',
											createdAtTo: moment().endOf('day').format(),
										},
								  }),
							...(rtmDate &&
								rtmDate.low &&
								rtmDate.high && {
									rtmDate: {
										rtmFrom: rtmDate.low.startOf('day').format(),
										rtmTo: rtmDate.high.endOf('day').format(),
									},
								}),
						},
					}
				},
			},
		},
		subscription: {},
		create: {},
		update: {},
		atoms: {
			import: importManagementAtom,
			export: exportManagementAtom,
			longHaul: longHaulManagementAtom,
		},
	},
	Quotations: {
		fetch: {},
		create: { name: CREATE_QUOTATION, key: ['createQuotation'], createKey: 'createQuotationInput' },
		update: { name: UPDATE_QUOTATION, key: ['updateQuotation'], createKey: 'updateQuotationInput' },
		delete: { name: DELETE_QUOTATION, key: ['deleteQuotation'], createKey: 'deleteQuotationInput' },
		atoms: {
			quotation: undefined,
		},
	},
	Jobs: {
		fetch: {
			default: {
				name: GET_ALL_JOBS,
				key: ['jobs'],
				getVariables: ({ type, tab, status }) => {
					const types =
						tab === 'shifting' || tab === 'destuffing' || tab === 'local'
							? type === 'import'
								? tab === 'local'
									? [
											{ movementType: 'ImportShifting', subMovementType: 'ImportShortHaul' },
											{ movementType: 'Import', subMovementType: 'ImportShortHaul' },
									  ]
									: [
											{ movementType: 'ImportShifting', subMovementType: 'LongHaul' },
											{ movementType: 'ImportDoubleTwentyShifting', subMovementType: 'LongHaul' },
									  ]
								: [
										{ movementType: 'ExportShifting', subMovementType: 'LongHaul' },
										{ movementType: 'ExportDoubleTwentyShifting', subMovementType: 'LongHaul' },
								  ]
							: [
									{ movementType: Capitalize(type), subMovementType: Capitalize(type) },
									{ movementType: 'DoubleTwenty', subMovementType: Capitalize(type) },
							  ]

					return {
						where: {
							OR: types.map((type) => ({
								OrderDetails_CONTAINS: `"movementType":"${type.movementType}","subMovementType":"${type.subMovementType}"`,
							})),
							AND: type === 'import' ? [{ OrderDetails_NOT_CONTAINS: '"containerNumber":null' }, { OrderDetails_NOT_CONTAINS: '"containerNumber":""' }] : [],
							status_IN: status,
							createdAt_GTE: '22 March 2023 00:00:00 GMT+05:00',
						},
						options: { sort: [{ createdAt: 'DESC' }] },
					}
					// subscription: UPDATE_ORDER_SUBSCRIPTION,
					// subscriptionKey: 'OrderUpdatedByStatuses',
				},
			},
			lotOrder: {
				name: GET_ALL_ORDERS,
				key: ['orders'],
				getVariables: ({ type, tab, status }) => ({
					where: {
						type: Capitalize(type),
						ParentOrder: {
							ParentLongHaul: {
								createdAt_GTE: '15 December 2022 00:00:00 GMT+05:00',
								// SubLongHauls_SOME: {
								// 	status_IN: status,
								// },
								status: 'Order Confirmed',
								preferences: {
									lotOrder: true,
								},
							},
						},
					},
					options: { sort: [{ createdAt: 'DESC' }] },
				}),
			},
			count: {
				name: GET_ALL_JOB_COUNTS,
				key: ['getJobConfigs'],
			},
		},
		subscription: {
			default: {
				name: JOBS_UPDATED_SUBSCRIPTION,
				key: 'jobUpdatedByMovementTypeAndStatus',
				getVariables: ({ type, tab, status }) => {
					const variables =
						tab === 'shifting' || tab === 'destuffing' || tab === 'local'
							? type === 'import'
								? {
										movementType: ['Import', 'ImportShifting', 'ImportDoubleTwentyShifting'],
										subMovementType: ['LongHaul', 'ImportShortHaul', 'ImportDoubleTwentyShortHaul'],
								  }
								: { movementType: ['ExportShifting', 'ExportDoubleTwentyShifting'], subMovementType: ['LongHaul'] }
							: { movementType: [Capitalize(type), 'DoubleTwenty'], subMovementType: [Capitalize(type)] }
					return {
						...variables,
						status: status,
					}
				},
				checkRemove: (job) => {
					const orderDetails = job?.OrderDetails && JSON.parse(job?.OrderDetails)
					const order = checkArray(orderDetails) ? orderDetails?.[0] : orderDetails
					const isImport = ['Import', 'ImportShifting', 'ImportDoubleTwentyShifting'].includes(order?.movementType)
					return (
						job?.status === 'Job Cancelled' || job?.status === 'Job Ongoing' || (isImport && (order?.containerNumber === '' || order?.containerNumber === null))
					)
				},
			},
			lotOrder: {
				name: PARENT_ORDERS_UPDATED_SUBSCRIPTION,
				key: 'OrderUpdatedByStatusAndTypeAndBusinessCode',
				getVariables: ({ type, status }) => ({
					type: Capitalize(type),
					status: status,
				}),
				checkRemove: (order) => {
					return order?.ParentOrder?.[`Parent${Capitalize(order?.type)}`]?.status === 'Order Ongoing'
				},
			},
		},
		create: {},
		update: {
			name: UPDATE_JOB,
			key: ['updateJob'],
			createKey: 'updateJobInput',
		},
		atoms: {
			import: importOrdersAtom,
			export: exportOrdersAtom,
			longHaul: longHaulOrdersAtom,
			login: importOrdersAtom,
			count: jobCountAtom,
		},
	},
	Orders: {
		fetch: {
			default: {
				name: GET_ALL_ORDERS,
				key: ['orders'],
				getVariables: ({ type, status, tab, jobStatus }) => {
					const types =
						tab === 'shifting' || tab === 'destuffing' || tab === 'local'
							? type === 'Import'
								? tab === 'local'
									? { movementType_IN: ['Import', 'ImportShifting'], subMovementType_IN: ['ImportShortHaul'] }
									: { movementType_IN: ['ImportShifting', 'ImportDoubleTwentyShifting'], subMovementType_IN: ['LongHaul'] }
								: { movementType_IN: ['ExportShifting', 'ExportDoubleTwentyShifting'], subMovementType_IN: ['LongHaul'] }
							: { movementType_IN: [Capitalize(type), 'DoubleTwenty'], subMovementType_IN: [Capitalize(type)] }

					return {
						where: {
							type: type,
							ParentOrder: {
								[`Parent${Capitalize(type)}`]: {
									[`Sub${Capitalize(type)}s_SOME`]: {
										status_IN: status,
									},
								},
								Movements_SOME: types,
							},
							createdAt_GTE: jobStatus === 'Job Completed' ? '15 March 2023 00:00:00 GMT+05:00' : '15 January 2023 00:00:00 GMT+05:00',
						},
						movementsWhere2: {
							Job: {
								status_IN: jobStatus,
								// createdAt_GTE: jobStatus === "Job Completed" ? '15 March 2023 00:00:00 GMT+05:00': '15 January 2023 00:00:00 GMT+05:00'
							},
						},
						options: { sort: [{ createdAt: 'DESC' }] },
					}
				},
			},
		},
		subscription: {
			default: {
				name: ORDERS_UPDATED_SUBSCRIPTION,
				key: 'OrderUpdatedByChildOrderStatusAndType',
				getVariables: ({ type, status }) => ({
					type: type,
					statuses: status,
				}),
				checkRemove: (order) => {
					return order?.ParentOrder?.[`Parent${Capitalize(order?.type)}`]?.[`Sub${Capitalize(order?.type)}s`]?.every((subOrder) =>
						['Order Cancelled'].includes(subOrder?.status)
					)
				},
			},
		},
		create: {},
		update: {},
		atoms: {
			import: importAtom,
			export: exportAtom,
			longHaul: longHaulAtom,
		},
		childOrders: {
			import: 'SubImports',
			export: 'SubExports',
			longHaul: 'SubLongHauls',
		},
	},
	CreateSubLongHaul: {
		fetch: {},
		create: { name: CREATE_LONGHAUL_SUBORDER, key: ['createSubLongHaulOrder'], createKey: 'createSubLongHaulOrderInput' },
		update: { name: UPDATE_LONGHAUL_ORDER, key: ['updateLongHaulOrder'], createKey: 'updateLongHaulOrderInput' },
		atoms: {},
	},
	User: {
		fetch: { default: { name: GET_USER, key: ['users'], getVariables: ({ _id }) => ({ where: { _id: _id } }) } },
		create: {},
		update: {},

		atoms: {
			user: userAtom,
		},
	},
	Bilties: {
		update: {
			name: UPDATE_BILTY,
			key: ['updateBilties'],
			createKey: 'updateBiltiesInput',
		},
	},
	Charge: {
		create: { name: CREATE_CHARGE, key: ['createCharge'], createKey: 'createChargeInput' },
		update: { name: UPDATE_CHARGE, key: ['updateCharge'], createKey: 'updateChargeInput' },
	},
	MultipleCharges: {
		create: { name: CREATE_MULTIPLE_CHARGES, key: ['createChargesForJob'], createKey: 'createChargesInput' },
	},
	ChargeType: {
		fetch: {
			default: {
				name: GET_JOB_CHARGE_TYPES,
				key: ['chargeTypes'],
			},
		},
		atoms: {
			chargeTypes: chargeTypesAtom,
		},
	},
	Revenue: {
		create: { name: CREATE_REVENUE, key: ['createRevenue'], createKey: 'createRevenueInput' },
		update: { name: UPDATE_REVENUE, key: ['updateRevenueForJob'], createKey: 'updateRevenueInput' },
	},
	RevenueType: {
		fetch: {
			default: {
				name: GET_JOB_REVENUE_TYPES,
				key: ['revenueTypes'],
			},
		},
		atoms: {
			revenueTypes: revenueTypesAtom,
		},
	},
	ThirdParty: {
		fetch: {
			default: {
				name: GET_THIRD_PARTIES,
				key: ['thirdParties'],
			},
		},
		atoms: {
			thirdParties: thirdPartiesAtom,
		},
	},
	AuthenticateCharges: {
		create: { name: AUTHENTICATE_CHARGES, key: ['authenticateCharges'], createKey: 'authenticateChargesInput' },
	},
	AuthenticateRevenues: {
		create: { name: AUTHENTICATE_REVENUES, key: ['authenticateRevenues'], createKey: 'authenticateRevenuesInput' },
	},
	AuthenticateAndUpdateCharges: {
		create: { name: AUTHENTICATE_AND_UPDATE_CHARGES, key: ['authenticateAndUpdateCharges'], createKey: 'authenticateAndUpdateCharges' },
	},
}

export const PageSizes = {
	10: { frontend: 10, backend: 50 },
	20: { frontend: 20, backend: 80 },
	50: { frontend: 50, backend: 100 },
	100: { frontend: 100, backend: 200 },
}
