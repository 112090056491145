import { gql } from '@apollo/client'
import { BILTY_INVESTOR_FIELDS, BROKER_FIELDS, JOB_FIELDS, ORDER_FIELDS, SUBSCRIPTION_ORDER_FIELDS, SUPPLIER_FIELDS } from './fragments'

export const ORDERS_UPDATED_SUBSCRIPTION = gql`
	${SUBSCRIPTION_ORDER_FIELDS}
	subscription OrderUpdatedByChildOrderStatusAndType($statuses: [String!]!, $type: String!) {
		OrderUpdatedByChildOrderStatusAndType(statuses: $statuses, type: $type) {
			...OrderFields
		}
	}
`
export const PARENT_ORDERS_UPDATED_SUBSCRIPTION = gql`
	${SUBSCRIPTION_ORDER_FIELDS}
	subscription OrderUpdatedByStatusAndTypeAndBusinessCode($status: [String!], $type: String) {
		OrderUpdatedByStatusAndTypeAndBusinessCode(status: $status, type: $type) {
			...OrderFields
		}
	}
`

export const JOBS_UPDATED_SUBSCRIPTION = gql`
	${JOB_FIELDS}
	subscription JobUpdatedByMovementTypeAndStatus($movementType: [String!]!, $subMovementType: [String!]!, $status: [String!]!) {
		jobUpdatedByMovementTypeAndStatus(movementType: $movementType, subMovementType: $subMovementType, status: $status) {
			...JobFields
		}
	}
`
export const BROKER_UPDATED_SUBSCRIPTION = gql`
	${BROKER_FIELDS}
	subscription BrokerUpdated {
		brokerUpdated {
			...BrokerFields
		}
	}
`

export const SUPPLIER_UPDATED_SUBSCRIPTION = gql`
	${SUPPLIER_FIELDS}
	subscription SupplierUpdated {
		supplierUpdated {
			...SupplierFields
		}
	}
`

export const BILTY_INVESTOR_UPDATED = gql`
	${BILTY_INVESTOR_FIELDS}
	subscription BiltyInvestorUpdated {
		biltyInvestorUpdated {
			...BiltyInvestorFields
		}
	}
`
