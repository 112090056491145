import { useState } from 'react'

import Icon from '@mdi/react'
import { mdiChevronUp } from '@mdi/js'
import { Dropdown, Menu } from 'antd'
import { LeftOutlined, RightOutlined, DoubleLeftOutlined, DoubleRightOutlined, EllipsisOutlined } from '@ant-design/icons'

import { PageSizes } from '../../helpers/Enums'
import { usePagination, LeftDOTS, RightDOTS } from '../../hooks/usePagination'

const Pagination = (props) => {
	const { setPageInfo, setBackendPageInfo, totalCount, pageInfo } = props
	const [mouseEnter, setMouseEnter] = useState(null)
	const DropdownMenu = (
		<Menu
			onClick={(event) => {
				setPageInfo({ pageNo: 1, pageSize: parseInt(event?.key) })
			}}
		>
			{Object.keys(PageSizes).map((size) => {
				return (
					<Menu.Item
						className={`hover:bg-primary-50 hover:text-primary-800 ${pageInfo.pageSize === parseInt(size) && 'font-[600] text-primary-800'}`}
						key={size}
					>
						{size} / Page
					</Menu.Item>
				)
			})}
		</Menu>
	)

	const paginationRange = usePagination({ currentPage: pageInfo?.pageNo, totalCount, siblingCount: 1, pageSize: pageInfo?.pageSize })

	let lastPage = paginationRange[paginationRange?.length - 1]
	const firstPageIndex = 1
	return (
		<div className='flex cursor-pointer flex-wrap items-center justify-center bg-white w-full'>
			<div
				className={`mx-[2px] my-auto rounded-sm border-[1px] px-[8px] py-2 ${pageInfo.pageNo === firstPageIndex && `cursor-not-allowed`}`}
				onClick={() => {
					pageInfo?.pageNo > firstPageIndex && setPageInfo((prev) => ({ ...prev, pageNo: prev?.pageNo - 1 }))
				}}
			>
				<LeftOutlined className='align-middle' />
			</div>
			{paginationRange.map((pageNumber) => {
				// prettier-ignore
				if ([LeftDOTS].includes(pageNumber)) {
					return (
						<div
							className=' rounded-sm mx-2'
							onMouseEnter={() => setMouseEnter(LeftDOTS)}
							onMouseLeave={() => setMouseEnter(null)}
							onClick={() => {
								setPageInfo((prev) => ({ ...prev, pageNo: pageInfo.pageNo - 5 < firstPageIndex ? firstPageIndex : pageInfo.pageNo - 5 }))
							}}
						>
							{mouseEnter === LeftDOTS ? <DoubleLeftOutlined className='text-primary-800' style={{ fontSize: 'small' }} /> : <EllipsisOutlined className='text-lg text-[#717171de]' />}
						</div>
					)
				}
				if ([RightDOTS].includes(pageNumber)) {
					return (
						<div
							className='mx-2 rounded-sm'
							onMouseEnter={() => setMouseEnter(RightDOTS)}
							onMouseLeave={() => setMouseEnter(null)}
							onClick={() => {
								setPageInfo((prev) => ({ ...prev, pageNo: pageInfo.pageNo + 5 > lastPage ? lastPage : pageInfo.pageNo + 5 }))
							}}
						>
							{mouseEnter === RightDOTS ? (
								<DoubleRightOutlined className='text-primary-800' style={{ fontSize: 'small' }} />
							) : (
								<EllipsisOutlined className='text-lg text-[#717171de]' />
							)}
						</div>
					)
				}
				return (
					<div
						className={`mx-1 my-auto rounded-sm border-[1px] px-3 py-2 text-center align-middle  ${
							pageInfo.pageNo === pageNumber && `border-[1.5px] border-primary-800 font-[600] text-primary-800`
						}`}
						onClick={() => setPageInfo((prev) => ({ ...prev, pageNo: pageNumber }))}
					>
						{pageNumber}
					</div>
				)
			})}
			<div
				className='mx-[2px] my-auto rounded-sm border-[1px] px-[8px] py-2'
				onClick={() => {
					// prettier-ignore
					(pageInfo?.pageNo === lastPage || !lastPage) && setBackendPageInfo((prev) => ({ ...prev, pageNo: prev.pageNo + 1 }));

					pageInfo?.pageNo === lastPage
						? setPageInfo((prev) => ({ ...prev, pageNo: lastPage }))
						: !lastPage
						? setPageInfo((prev) => ({ ...prev, pageNo: 1 }))
						: setPageInfo((prev) => ({ ...prev, pageNo: prev?.pageNo + 1 }))
				}}
			>
				<RightOutlined className='align-middle' />
			</div>
			<Dropdown
				setPageInfo={setPageInfo}
				overlay={DropdownMenu}
				trigger={['click']}
				className='mx-2 my-auto rounded-sm border-[1px] px-[8px] py-[6.5px] text-center align-middle'
			>
				<div className='flex flex-row p-4'>
					<p className='px-2 font-bold'>{pageInfo?.pageSize.toString() + ' / Page'}</p>
					<Icon path={mdiChevronUp} size={1} className='text-primary-800' />
				</div>
			</Dropdown>
		</div>
	)
}

export default Pagination
