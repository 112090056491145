import Icon from '@mdi/react'
import { mdiAlertCircleOutline, mdiInformationOutline } from '@mdi/js'
import { Switch, Tooltip } from 'antd'
export const PhoneNumberCode = ({ code }) => <div className='bg-secondaryBackground text-sm font-[400] text-black-400'>{code}</div>

export const NewPortalPill = () => (
	<div className='flex flex-row m-2 rounded-[20px] py-[6px] px-3 items-center' style={{ backgroundColor: '#F44336' }}>
		<div className='text-white' style={{ lineHeight: '16px' }}>
			New Portal
		</div>
		<Tooltip title='You are currently on the New Portal'>
			<Icon path={mdiInformationOutline} className='text-white ml-2 cursor-pointer' size={1} />
		</Tooltip>
	</div>
)

export const CustomSwitchWrapper = ({ value, onChange, checkedChildren, unCheckedChildren, size }) => (
	<Switch
		className='my-auto ml-2'
		checked={value}
		checkedChildren={checkedChildren}
		unCheckedChildren={unCheckedChildren}
		onChange={(val) => onChange(val)}
		size={size || 'default'}
	/>
)

export const BlackListedVehicleBanner = (props) => {
	const { registrationNumber } = props
	return (
		<div className='flex flex-row items-center justify-start px-4 py-3 mb-4 bg-red-200 rounded-md'>
			<Icon path={mdiAlertCircleOutline} size={0.8} className='mr-2 text-red-400' />
			<span className='font-bold text-sm'>{registrationNumber}</span>&nbsp;
			<span className='font-medium text-black-300'>has been black listed in our system</span>
		</div>
	)
}
