import {
	mdiPencil,
	mdiPhone,
	mdiPlus,
	mdiTruck,
	mdiTruckOutline,
	mdiPackageVariantClosed,
	mdiEqual,
	mdiDownload,
	mdiChevronRight,
	mdiPencilOutline,
	mdiLightningBolt,
	mdiAlertCircleOutline,
	mdiDeleteOutline,
} from '@mdi/js'

import Icon from '@mdi/react'
import { Button, Form, Progress, Tooltip } from 'antd'
import { Pill } from '../components/utility/Pills'
import { formatName, formatPhoneNumber } from '../recoil/selectors'

import { VehicleTypeEnums, PaymentStatusEnums, VendorTypeEnums, ContainerDimensionsEnum } from './Enums'
import { FormRender } from './FormRenderer'
import { Capitalize, createManagementFilterString } from './Functions'

/* =================================================================================================== */
/* ==================================== ASSIGNMENT COLUMNS =========================================== */
/* =================================================================================================== */
const JobDetailsColumn = (segment, tab) => ({
	title: <div className='pl-[16px]'>JOB SUMMARY</div>,
	key: 'JobDetailsColumn',
	width: segment === 'longHaul' && 150,
	render: (_, record) => {
		return (
			<div className='flex flex-col pl-[24px]' key={record?._id}>
				<div className='flex flex-row'>
					<div className='font-bold text-lg text-black-400 pb-1'>{`J-${record?.jobNumber}`}</div>
				</div>

				{segment === 'longHaul' && <div className='font-[500] text-xs text-primary-800'>{record?.subOrderNumber}</div>}
				{segment !== 'longHaul' && (
					<div className='flex flex-row items-center'>
						<div className='font-[500] text-sm text-black-400 pb-[2px]'>{`${record?.documentNumber}`}</div>
						{record?.document && (
							<div>
								<Tooltip title='Open in new tab' mouseEnterDelay={0.7}>
									<a href={record?.document} target='_blank' onClick={(event) => event.stopPropagation()} rel='noreferrer'>
										<Icon className='ml-2 mr-2' path={mdiDownload} size={0.8} color='#0492c2' />
									</a>
								</Tooltip>
							</div>
						)}
					</div>
				)}
				<div className='flex flex-row'>
					<div className='font-[500] text-xs text-black-300'>{record?.clientName}</div>
				</div>
			</div>
		)
	},
})

const LaneDetailsColumn = (segment, tab, type) => ({
	title: 'LANE & EMPTY RETURN',
	key: 'LaneDetailsColumn',
	width: type === 'management' && '20%',
	render: (_, record) => {
		const port = record?.orderLocations?.find((location) => location?.Location?.type === 'Port')?.Location?.additionalDetails
		const yard = record?.orderLocations?.find((location) => location?.locationType === 'Yard')?.Location?.additionalDetails
		return tab === 'local' ? (
			<div className='flex flex-col pl-[8px]' key={record?._id}>
				<div className='flex flex-row text-[14px] items-center pt-2 pb-1'>
					<div className='font-[600]'>{(port || 'None') + ' --> ' + (yard || 'NLC Yard')}</div>
				</div>
			</div>
		) : (
			<div className='flex flex-col pl-[8px]' key={record?._id}>
				<div className='flex flex-row text-[14px] items-center pt-2 pb-1'>
					<div className='font-[600]'>
						{((type === 'management' ? record?.lane?.from : record?.superLane?.from) || 'None') +
							' --> ' +
							((type === 'management' ? record?.lane?.to : record?.superLane?.to) || 'None')}
					</div>
				</div>
				{type !== 'management' && (
					<div className='flex flex-row text-[14px] items-center  pt-1 pb-2'>
						<div className='font-[400]'>{(record?.lane?.from || 'None') + ' --> ' + (record?.lane?.to || 'None')}</div>
					</div>
				)}
				{segment === 'import' && !['destuffing', 'shifting'].includes(tab) && type !== 'management' && (
					<div className='flex flex-row text-sm pb-1'>
						<div className='!w-[7rem]'>
							<p className='font-[400] text-black-300'>Empty Return:&nbsp;</p>
						</div>
						<div className='w-[6rem]'>
							<Tooltip title={`${record?.emptyReturn?.additionalDetails}`}>
								<div className='font-[600] text-primary-800 overflow-ellipsis'>
									{record?.emptyReturn?.additionalDetails ? `${record?.emptyReturn?.additionalDetails}` : '---'}
								</div>
							</Tooltip>
						</div>
					</div>
				)}
			</div>
		)
	},
})

const ContainerDetailsColumn = (segment, tab) => ({
	title: segment === 'longHaul' ? 'VEHICLE TYPE & WEIGHT' : 'CONTAINER & VEHICLE',
	key: 'ContainerDetailsColumn',
	render: (_, record) => (
		<div className='flex flex-col w-full pl-[8px]'>
			{record?.containerInfo?.map(
				(container, index) =>
					segment === 'import' && (
						<div className='flex flex-row text-sm py-1' key={`${record?._id}-${index}`}>
							<div className='font-[400] text-black-300'>Container #:&nbsp;</div>
							<div className='font-[600] text-black-400'>{container?.containerNumber}</div>
						</div>
					)
			)}
			<div className='flex flex-row text-sm py-1 items-start'>
				<div className='flex flex-row pr-4 items-center'>
					<Icon className='mr-2' path={mdiTruckOutline} size={1} color='#9F9F9F' />
					{segment === 'longHaul' || tab === 'destuffing' ? (
						<div className='font-[600]'>{`${
							((VehicleTypeColumn[record?.vehicleType] || VehicleTypeEnums[Capitalize(record?.vehicleType)])?.text || 'N/A') +
							(record?.subVehicleType ? '-' + record?.subVehicleType : '')
						}`}</div>
					) : (
						<div className='font-[600] overflow-ellipsis'>
							{record?.containerInfo?.length > 1 && record?.containerInfo?.[0]?.containerDimensions === 'xl20ft'
								? ContainerDimensionsEnum['xl2020ft']?.text
								: ContainerDimensionsEnum[record?.containerInfo?.[0]?.containerDimensions]?.text || 'N/A'}
						</div>
					)}
				</div>

				<div className='flex flex-row items-center '>
					<Icon className='mr-2' path={mdiPackageVariantClosed} size={1} color='#9F9F9F' />
					<div className='font-[600] overflow-ellipsis'>{`${
						segment === 'longHaul'
							? record?.shipmentInfo?.freightWeight
							: record?.containerInfo?.[0]?.containerWeight && record?.containerInfo?.[0]?.containerWeight !== 'NaN'
							? record?.containerInfo?.reduce((totalWeight, container) => (parseFloat(totalWeight) + parseFloat(container?.containerWeight)).toString(), '0')
							: '---'
					} Tons`}</div>
				</div>
			</div>
		</div>
	),
})

const GoodTypeColumn = (tab) => ({
	title: () => 'GOOD TYPE',
	width: tab !== 'lot' && 110,

	key: 'GoodTypeColumn',
	render: (_, record) => (
		<div className='flex flex-col pl-[8px]' key={record?._id}>
			<div className='flex flex-row text-[16px] items-center pt-2 pb-1 '>
				<div className='font-[600]'>{record?.shipmentInfo?.freightType || 'N/A'}</div>
			</div>
		</div>
	),
})

const SupplierDetailsColumn = (tab) => ({
	title: tab === 'local' ? 'BROKER & COST' : 'SUPPLIER & BROKER',
	key: 'SupplierDetailsColumn',
	render: (_, record) =>
		tab === 'local' ? (
			<div className='flex flex-col w-full pl-[8px]' key={record?._id}>
				<div className='flex flex-row text-sm py-1 '>
					<div className='font-[400] text-black-300 pl-2 overflow-ellipsis'>
						{record?.broker?.name?.length > 0 ? record?.broker?.name : 'No Broker Assigned'}
					</div>
				</div>
				<div className='flex flex-row text-[18px] py-1 '>
					<div className='font-[600] text-black-400 pl-2 overflow-ellipsis'>{`PKR ${record?.cost || '---'}`}</div>
				</div>
			</div>
		) : (
			<div className='flex flex-col w-full pl-[8px]' key={record?._id}>
				<div className='flex flex-row text-sm py-1 '>
					<p className='!w-fit whitespace-nowrap'>Supplier :</p>
					<p className='font-[600] text-black-400 pl-2 overflow-ellipsis'>{record?.supplier?.name?.length > 0 ? record?.supplier?.name : '---'}</p>
				</div>
				<div className='flex flex-row text-sm py-1'>
					<p className='!w-fit whitespace-nowrap'>Broker :</p>
					<p className='font-[600] text-black-400 pl-2 overflow-ellipsis'>{record?.broker?.CompanyName ? record?.broker?.CompanyName : '---'}</p>
				</div>
			</div>
		),
})

const DriverDetailsColumn = (tab) => ({
	title: tab === 'local' ? 'VEHICLE INFORMATION' : 'VEHICLE & DRIVER NO.',
	key: 'DriverDetailsColumn',
	render: (_, record) => (
		<div className='flex flex-col w-full pl-[8px]' key={record?._id}>
			<div className='flex flex-row text-sm py-2'>
				<Icon className='bg-black-100 p-1 rounded-xl mr-2' path={mdiTruck} size={0.9} color='#676767' />
				<div>{record?.vehicle?.registrationNumber || '---'}</div>
			</div>
			<div className='flex flex-row text-sm py-1'>
				<Icon className='bg-black-100 p-1 rounded-xl mr-2' path={mdiPhone} size={0.9} color='#676767' />
				<div className='font-[500] text-black-400'>{record?.driver?.phoneNumber || '---'}</div>
			</div>
			{tab !== 'local' && <Pill bgClassName='bg-black-50' textColor='text-black-300' label='Cost' value={record?.cost || 0} />}
		</div>
	),
})

const OrderDetailsColumn = {
	title: 'ORDER NO.',
	key: 'OrderDetailsColumn',
	width: 130,
	render: (_, record) => (
		<div className='flex flex-col pl-[24px]' key={record?._id}>
			<div className='flex flex-row'>
				<div className='font-bold text-lg text-black-400 pb-1'>{`L-${record?.orderNumber}`}</div>
			</div>
		</div>
	),
}

const ClientDetailsColumn = {
	title: 'CLIENT NAME',
	key: 'ClientDetailsColumn',
	width: 170,
	render: (_, record) => (
		<div className='flex flex-col pl-[8px]' key={record?._id}>
			<div className='flex flex-row'>
				<div className='font-[500] text-sm text-black-400 pb-1'>{record?.clientName}</div>
			</div>
		</div>
	),
}

const RouteDetailsColumn = {
	title: 'ROUTE',
	key: 'RouteDetailsColumn',
	width: 600,
	render: (_, record) => (
		<div className='flex flex-col pl-[8px] pr-8' key={record?._id}>
			{record?.loading?.map((location, index) => (
				<div className='flex flex-row' key={'loading_' + index}>
					<div className='font-[600] text-sm text-black-400 pb-1 pr-2'>Loading:</div>
					<div title={location?.name} className='font-[400] text-sm text-black-400 pb-1 overflow-ellipsis'>
						{location?.name}
					</div>
				</div>
			))}
			{record?.dropoff?.map((location, index) => (
				<div className='flex flex-row' key={'dropoff_' + index}>
					<div className='font-[600] text-sm text-black-400 pb-1 pr-2'>Dropoff:</div>
					<div title={location?.name} className='font-[400] text-sm text-black-400 pb-1 overflow-ellipsis'>
						{location?.name}
					</div>
				</div>
			))}
		</div>
	),
}

const WeightDetailsColumn = {
	title: 'WEIGHT TO BE MOVED',
	key: 'WeightDetailsColumn',
	width: 200,
	render: (_, record) => (
		<div className='flex flex-row rounded-[100px] p-0 bg-black-50 w-fit items-center justify-center pr-2 my-8' key={record?._id}>
			<div
				className={
					(record?.totalAssignedWeight === '0'
						? 'bg-red-400'
						: parseFloat(record?.totalAssignedWeight) < parseFloat(record?.totalWeight)
						? 'bg-yellow-400'
						: 'bg-green-400') + ' rounded-[100px] p-2'
				}
			>
				<Icon path={mdiPackageVariantClosed} size={1} className='text-white' />
			</div>
			<p className='font-[500] text-[16px] text-black-400 px-2'>{parseFloat(record?.totalAssignedWeight).toFixed(2)}</p>
			<p className='font-[400] text-xs text-black-200 w-8'>out of</p>
			<p className='font-[500] text-[16px] text-black-400 px-2'>{parseFloat(record?.totalWeight).toFixed(2)}</p>
		</div>
	),
}

// const LotActionColumn = (navigator) => {
// 	return {
// 		title: <div className='text-center'>ACTION</div>,
// 		key: 'LotActionColumn',
// 		width: 210,
// 		render: (_, record) => (
// 			<div className='flex flex-col w-full items-center text-center' key={record?._id}>
// 				<Button
// 					className='bg-[#EDF7FF] text-primary-800 rounded flex flex-row items-center w-[4/5] font-[600] h-[40px]'
// 					onClick={(e) => {
// 						e.stopPropagation()
// 						navigator(`${record?._id}/edit`)
// 					}}
// 				>
// 					<Icon className='mr-2' path={mdiPencil} size={1} />
// 					Assign Suppliers & Drivers
// 				</Button>
// 			</div>
// 		),
// 	}
// }

const ActionColumn = (navigator, segment) => {
	return {
		title: <div className='text-center'>ACTION</div>,
		key: 'ActionColumn',
		render: (_, record) => (
			<div className='flex flex-col w-full items-center text-center' key={record?._id}>
				<Button
					className='bg-[#EDF7FF] text-primary-800 rounded flex flex-row items-center w-[4/5] font-[600] h-[40px]'
					onClick={(e) => {
						e.stopPropagation()
						navigator(`/${segment}/assignment/job/${record?._id}/edit`)
					}}
				>
					<Icon className='mr-2' path={record?.driver?.name || record?.vehicle?.registrationNumber ? mdiPencil : mdiPlus} size={1} />
					{record?.driver?.name || record?.vehicle?.registrationNumber
						? 'Edit Assignment'
						: record?.supplier?.name || record?.broker?.name
						? 'Assign Driver'
						: 'Assign Supplier'}
				</Button>
			</div>
		),
	}
}

/* =================================================================================================== */
/* ================================== POST-ASSIGNMENT COLUMNS ======================================== */
/* =================================================================================================== */
const JobDocumentColumn = (segment) => {
	return {
		title: `${['import', 'export'].includes(segment) ? (segment === 'import' ? `BL &` : `CRO &`) : ''} JOB #`,
		key: 'JobDocumentColumn',
		render: (_, record) => (
			<div className='m-[10px] !mr-0'>
				<div className='flex flex-row items-center text-center mb-3'>
					<div className='my-auto'>
						<p className=' text-[18px] text-primary-800 font-semibold'>J-{record?.jobNumber}</p>
					</div>

					{segment === 'longHaul' && record?.isLot && (
						<div className={`flex flex-row rounded-[40px] px-2 py-1 my-auto ml-2 bg-primary-800`}>
							<Icon path={mdiPackageVariantClosed} size={0.6} color='#ffffff' />
							<div className='text-[12px] font-[600] text-white pl-2 overflow-ellipsis'>Lot Order</div>
						</div>
					)}
				</div>
				{segment === 'longHaul' && <div className='font-[500] text-xs text-primary-800'>{record?.subOrderNumber}</div>}
				<div className='flex flex-col'>
					{record?.documentNumberArray?.length > 1 && !!record?.documentNumberArray?.[0] ? (
						record?.documentNumberArray?.map((documentNumber, index) => (
							<p key={index} className='mb-[5px] text-[14px] font-[500] text-black-400'>
								{' '}
								{index < 1 && record?.documentNumberArray?.length > 1 ? ` ${documentNumber} ` : `  ${documentNumber}`}{' '}
							</p>
						))
					) : (
						<p className='mb-[5px] text-[14px] font-[500] text-black-400'> {record?.documentNumber} </p>
					)}
				</div>

				<p className='text-xs font-[500] text-black-300'>{record?.businessName}</p>
			</div>
		),
	}
}

const ClientContainerColumn = (segment) => ({
	title: segment === 'longHaul' ? 'VEHICLE TYPE & WEIGHT' : 'CONTAINER DETAILS',
	key: 'ContainerColumn',
	width: 230,
	render: (_, record) => {
		return (
			<div className='flex flex-col w-full pl-[8px]'>
				{record?.containerInfo?.containerNumber?.map(
					(containerNumber, index) =>
						segment === 'import' && (
							<div className='flex flex-row text-sm py-1' key={`container_${record?._id}-${index}`}>
								<div className='font-[400] text-black-300'>Container #:&nbsp;</div>
								<div className='font-[600] text-black-400'>{containerNumber}</div>
							</div>
						)
				)}
				<div className='flex flex-row text-sm py-1 items-start'>
					<div className='flex flex-row pr-4 items-center'>
						<Icon className='mr-2' path={mdiTruckOutline} size={1} color='#9F9F9F' />
						{segment === 'longHaul' ? (
							<div className='font-[600]'>{`${
								(VehicleTypeEnums[record?.vehicleInfo?.vehicleType] || VehicleTypeEnums[Capitalize(record?.vehicleInfo?.vehicleType)])?.text +
								(record?.vehicleInfo?.subVehicleType ? '-' + record?.vehicleInfo?.subVehicleType : '')
							}`}</div>
						) : (
							<div className='font-[600]'>
								{record?.containerInfo?.containerNumber?.length > 1 &&
								record?.containerInfo?.containerDimensions === 'xl20ft' &&
								!['ImportShifting', 'ExportShifting'].includes(record?.movementType)
									? ContainerDimensionsEnum['xl2020ft']?.text
									: ContainerDimensionsEnum[record?.containerInfo?.containerDimensions]?.text}
							</div>
						)}
					</div>

					<div className='flex flex-row items-center '>
						<Icon className='mr-2' path={mdiPackageVariantClosed} size={1} color='#9F9F9F' />
						{segment === 'longHaul' ? (
							<div className='font-[600]'>{`${
								(record?.shipmentInfo?.freightWeight && parseFloat(record?.shipmentInfo?.freightWeight)?.toFixed(0)) || '---'
							} Tons`}</div>
						) : (
							<div className='font-[600]'>{`${record?.containerInfo?.containerWeight?.toFixed(0) || '---'} Tons`}</div>
						)}
					</div>
				</div>
			</div>
		)
	},
})

const SupplierBrokerColumn = {
	title: 'SUPPLIER & BROKER',
	key: 'SupplierDetailsColumn',
	width: 250,
	render: (_, record) => (
		<div className='flex flex-col w-full pl-[8px]' key={record?._id}>
			<div className='flex flex-row text-sm py-1 '>
				<p className='!w-fit whitespace-nowrap'>Supplier :</p>
				<p className='font-[600] text-black-400 pl-2 overflow-ellipsis'>{record?.supplier?.name?.length > 0 ? record?.supplier?.name : '---'}</p>
			</div>
			<div className='flex flex-row text-sm py-1'>
				<p className='!w-fit whitespace-nowrap'>Broker :</p>
				<p className='font-[600] text-black-400 pl-2 overflow-ellipsis'>{record?.broker?.CompanyName ? record?.broker?.CompanyName : '---'}</p>
			</div>
		</div>
	),
}

const DriverVehicleColumn = {
	title: 'VEHICLE & DRIVER NO.',
	key: 'DriverDetailsColumn',
	// width: 240,
	render: (_, record) => (
		<div className='flex flex-col w-full pl-[8px]' key={record?._id}>
			<div className='flex flex-row text-sm py-2'>
				<Icon className='bg-black-100 p-1 rounded-xl mr-2' path={mdiTruck} size={0.9} color='#676767' />
				<div>{record?.driverVehicleRegistration || '---'}</div>
			</div>
			<div className='flex flex-row text-sm py-1'>
				<Icon className='bg-black-100 p-1 rounded-xl mr-2' path={mdiPhone} size={0.9} color='#676767' />
				<div className='font-[500] text-black-400'>{record?.driverPhone || '---'}</div>
			</div>
		</div>
	),
}

const PayableAmountColumn = {
	title: () => (
		<div className='flex flex-row'>
			<div className='pl-4 pr-[72px]'>FREIGHT COST</div>
			<div className='pr-16'>CHARGES</div>
			<div>TOTAL PAYABLE AMOUNT</div>
		</div>
	),
	key: 'PayableAmountColumn',
	className: 'fill-cell',
	width: '35%',
	render: (_, record) => {
		let TotalAmount = 0
		const freightCost = record?.charges?.find((charge) => charge.chargeType.name === 'vehicleCost')?.amount || 0
		const paymentStatus = record?.vendorPayments?.paymentStatus || 'pendingFreightCostApproval'

		TotalAmount = record?.charges?.reduce((total, charge) => total + charge.amount, 0)
		TotalAmount -= record?.revenues?.reduce((total, revenue) => total + revenue.amount, 0)

		const bpayCost = record?.factorStatus === 'approved' ? TotalAmount - parseFloat(record?.factoredAmount) : 0

		return (
			<div className={`pl-[22px]  h-32 flex flex-col justify-center`}>
				<div className='flex flex-row items-center justify-start'>
					{record?.factorStatus === 'approved' && (
						<div className={`flex flex-row items-center rounded-[40px] px-2 py-1 my-auto ml-2 bg-secondary-300 text-white mr-4`}>
							<div className='border border-white rounded-full p-[1px]'>
								<Icon path={mdiLightningBolt} size={0.6} />
							</div>
							<div className='text-[12px] font-[600] pl-2 overflow-ellipsis'>BPay</div>
						</div>
					)}
					<div
						className={`flex w-fit flex-row rounded-[40px] pl-2 pr-3 py-1 mr-4  my-auto ${PaymentStatusEnums?.[paymentStatus]?.secondaryBackground} ${PaymentStatusEnums?.[paymentStatus]?.borderColor}`}
					>
						{PaymentStatusEnums?.[paymentStatus]?.icon && (
							<Icon path={PaymentStatusEnums?.[paymentStatus]?.icon} size={0.8} color={`${PaymentStatusEnums?.[paymentStatus]?.iconColor}`} />
						)}
						<div className={`text-[12px] ${PaymentStatusEnums?.[paymentStatus]?.textColor} font-[500]  pl-2 my-auto`}>
							{PaymentStatusEnums?.[paymentStatus]?.text}
						</div>
					</div>
					{(!record?.paymentTerms || record?.paymentTerms?.length === 0) && record?.factorStatus !== 'approved' && (
						<div className={`flex w-fit flex-row rounded-[40px] pl-2 pr-3 py-1 my-auto bg-red-100`}>
							<Icon path={mdiAlertCircleOutline} size={0.8} className='text-red-400' />
							<div className={`text-[12px] text-black-400 font-[500]  pl-2 my-auto`}>Payment Terms Missing</div>
						</div>
					)}
				</div>

				<div className='mr-[12px] ml-2 flex flex-row text-black-400 text-[18px] align-middle pt-4'>
					<div className='font-[600] my-auto basis-[30%]'>{`Rs ${freightCost - (record?.factorStatus === 'approved' ? bpayCost : 0)}`}</div>
					<Icon className='mx-[16px]' path={mdiPlus} size={1} color='#9f9f9f' />
					<div className='my-auto basis-[1/6]'>{`${TotalAmount - freightCost > 0 ? TotalAmount - freightCost : 'N/A'}`}</div>
					<Icon className='mx-[16px]' path={mdiEqual} size={1} color='#9f9f9f' />
					<div className='font-[600] my-auto basis-1/3'>{`Rs ${TotalAmount - (record?.factorStatus === 'approved' ? bpayCost : 0)}`}</div>
				</div>
			</div>
		)
	},
}
/* =================================================================================================== */
/* ======================================= MANAGEMENT COLUMNS ======================================== */
/* =================================================================================================== */
const VehicleTypeColumn = {
	key: 'VehicleTypeColumn',
	title: <div className='pl-[16px]'>VEHICLE TYPE</div>,
	width: '12%',
	render: (_, record) => (
		<>
			<div className='font-bold text-lg text-black-400 pl-7'>{VehicleTypeEnums[record.vehicleType]?.long || record?.vehicleType || 'N/A'}</div>
		</>
	),
}
const WeightRangeColumn = {
	key: 'WeightRangeColumn',
	title: 'WEIGHT RANGE',
	width: '10%',
	render: (_, record) => (
		<>
			<div className='font-bold text-lg text-black-400 pb-1'>{record.weight}&nbsp;Tons</div>
		</>
	),
}
const CostDetailsColumn = {
	key: 'CostDetailsColumn',
	title: 'COST DETAILS',
	width: '23%',
	render: (_, record) => (
		<>
			<div className='flex flex-col'>
				<div className='flex flex-row items-center'>
					<div className='font-[500] text-lg text-black-400'>Rs&nbsp;{record.cost || 0}</div>
					<div className={`${record.supplyBurn > 0 ? 'bg-green-400' : 'bg-red-400'} rounded-[4px] px-[6px] py-[2px] h-fit ml-4`}>
						<div className='text-white font-[600] text-sm text-center align-middle'>{`${record.supplyBurn > 0 ? '+' : ''}${record.supplyBurn || 0}%`}</div>
					</div>
				</div>
				<div className='flex flex-row'>
					<div className='font-[400] text-sm text-black-200 pb-1'>N-3 Vehicles cost variance :&nbsp;</div>
					<div className='font-[400] text-sm text-black-400 pb-1'>
						Rs&nbsp;
						{record?.costVariance && Array.isArray(record?.costVariance)
							? (record?.costVariance?.reduce((sum, prev) => sum + prev, 0) / record?.costVariance.length).toFixed(2)
							: '0'}
					</div>
				</div>
			</div>
		</>
	),
}
const MarginColumn = {
	title: 'MARGIN',
	key: 'MarginColumn',
	width: '15%',
	render: (_, record) => (
		<div className='flex flex-col pr-8'>
			<div className='text-black-400 text-[16px] font-[400]'>
				{record.cost ? ((record.margin / (record.cost + record.margin)) * 100).toFixed(2) : '0.00'}%
			</div>
			<div className=' flex flex-row'>
				<Progress percent={record.cost ? (record.margin / (record.cost + record.margin)) * 100 : 0} showInfo={false} strokeColor='#1C75BC' />
				{/* <Progress percent={record.cost ? record.margin / (record.cost + record.margin) * 100 : 0} showInfo={false} strokeColor='#1C75BD' /> */}
			</div>
		</div>
	),
}
const AssignmentStatsColumn = (navigator, segment, data) => ({
	title: () => {
		console.log({ navigator, segment, data })
		const totalVehicles = data.reduce((count, row) => count + row.totalVehicles, 0)
		const assignedVehicles = data.reduce((count, row) => count + row.assignedVehicles, 0)
		return (
			<>
				<div>
					<div className='flex flex-row items-center'>
						<p className='font-[600] text-[12px]'> STATS</p>
						<div className='flex flex-row  border-primary-700 rounded-lg border-dashed border-2  mx-2 px-1 items-center'>
							<Icon path={mdiTruckOutline} size={1} className=' text-gray-500' />
							<p className='font-[700] text-[12px] text-black-400 px-1'>{assignedVehicles}</p>
							<p className='font-[400] text-xs text-black-200'>out of</p>
							<p className='font-[700] text-[12px] text-black-400 px-1'>{totalVehicles}</p>
						</div>
					</div>
				</div>
			</>
		)
	},
	key: 'AssignmentStatsColumn',
	width: '20%',
	render: (_, record) => {
		const filterString = createManagementFilterString(record)
		return (
			<div className='flex flex-row items-center'>
				<div className='flex flex-row rounded-[100px] p-0 bg-black-50 w-fit items-center justify-center pr-2 my-8' key={record?._id}>
					<div className={`${record?.assignedVehicles < record?.totalVehicles ? 'bg-red-400' : 'bg-green-400'} rounded-[100px] p-2`}>
						<Icon path={mdiTruckOutline} size={1} className='text-white' />
					</div>
					<p className='font-[500] text-[16px] text-black-400 px-2'>{record?.assignedVehicles}</p>
					<p className='font-[400] text-xs text-black-200 w-10'>out of</p>
					<p className='font-[500] text-[16px] text-black-400 px-2'>{record?.totalVehicles}</p>
				</div>
				<Tooltip
					title={record?.assignedVehicles === record?.totalVehicles ? 'No Unassigned Job' : 'View Unassigned Jobs'}
					className={`absolute right-6 ${record?.assignedVehicles === record?.totalVehicles ? 'cursor-not-allowed' : 'cursor-pointer'}`}
				>
					<div>
						<div
							className={`${record?.assignedVehicles === record?.totalVehicles ? 'no-hover' : ''}  `}
							onClick={() => navigator(`../assignment/filters/${filterString}`)}
						>
							<Icon path={mdiChevronRight} size={2} className={record?.assignedVehicles === record?.totalVehicles ? 'text-black-75' : 'text-black-300'} />
						</div>
					</div>
				</Tooltip>
			</div>
		)
	},
})
/* =================================================================================================== */
/* ================================ BROKER MANAGEMENT COLUMNS ====================================== */
/* =================================================================================================== */
const CarrierId = (navigator) => ({
	key: 'CarrierId',
	title: <div className='pl-[16px]'>CARRIER ID</div>,
	width: '150px',
	render: (_, record) => (
		<>
			<div onClick={() => navigator(`info/${record?._id}`)} class="text-sky-600 pl-7 text-[13px] font-bold font-['Inter'] underline hover:cursor-pointer">
				{' '}
				{record?.brokerCode || 'N/A'}
			</div>
		</>
	),
})

const VendorNameColumn = {
	key: 'VendorNameColumn',
	title: <div className='pl-[16px]'>NAME</div>,
	render: (_, record) => (
		<>
			<div className='font-[500] text-sm text-black-400 pl-7'>{formatName(record?.User?.firstName, record?.User?.lastName) || 'N/A'}</div>
		</>
	),
}
const VendorCNICColumn = {
	key: 'VendorCNICColumn',
	title: 'CNIC NUMBER',

	render: (_, record) => (
		<>
			<div className='font-[500] text-sm text-black-400 pl-2'>{record?.User?.cnic || 'N/A'}</div>
		</>
	),
}

const VendorCompanyName = {
	key: 'VendorCompanyName',
	title: 'COMPANY NAME',

	render: (_, record) => (
		<>
			<div className='font-[500] text-sm text-black-400 pl-2'>{record?.CompanyName || 'N/A'}</div>
		</>
	),
}

const VendorNumberColumn = {
	key: 'VendorNumberColumn',
	title: 'CONTACT',
	render: (_, record) => (
		<>
			<div className='font-[500] text-sm text-black-400 pl-2'>{formatPhoneNumber(record?.User?.phoneNumber) || 'N/A'}</div>
		</>
	),
}

const VehicleCountColumn = {
	key: 'VehicleCountColumn',
	title: <div className='pl-5'>NO OF VEHICLES</div>,
	render: (_, record) => (
		<div className='flex flex-row rounded-[100px] p-0 bg-black-50 w-fit items-center justify-center pr-2 ml-8 my-4' key={record?._id}>
			<div className={`bg-primary-800 rounded-[100px] p-2`}>
				<Icon path={mdiTruckOutline} size={1} className='text-white' />
			</div>
			<p className='font-[500] text-[13px] text-black-400 px-2'>{record?.totalVehiclesOwned || 0}</p>
		</div>
	),
}

const BrokerTypeColumn = {
	key: 'BrokerTypeColumn',
	title: <div className='pl-5'>VENDOR TYPE</div>,
	render: (_, record) => (
		<div className='flex flex-row rounded-[100px] p-0 bg-black-50 w-fit items-center justify-center pr-2 ml-8 my-4' key={record?._id}>
			<div className={`${VendorTypeEnums[record.type]?.background} rounded-[100px] p-2`}>
				<Icon path={VendorTypeEnums[record.type]?.icon} size={1} className='text-white' />
			</div>
			<p className='font-[500] text-[12px] text-black-400 px-2'>{VendorTypeEnums[record.type]?.text || record.type}</p>
		</div>
	),
}

const BrokerActionColumn = (navigator, userEmail) => ({
	key: 'BrokerActionColumn',
	title: <div className='w-full pr-16 text-right'>ACTION</div>,
	render: (_, record) => (
		<div className='flex flex-row justify-end pr-7'>
			<div
				className='p-2 mr-6 bg-background rounded flex flex-row w-fit items-center cursor-pointer'
				onClick={() => navigator(`broker/${record?._id}/action/edit`)}
			>
				<Icon path={mdiPencilOutline} size={0.8} className='text-primary-800 mr-2' />
				<div className='text-primary-800 font-[600] text-xs'>Edit</div>
			</div>
			<div
				className='p-2 mr-2 bg-redBackground rounded flex flex-row w-fit items-center cursor-pointer'
				onClick={() => navigator(`broker/${record?._id}/action/delete`)}
			>
				<Icon path={mdiDeleteOutline} size={0.8} className='text-red-400' />
			</div>
		</div>
	),
})

const SupplierActionColumn = (navigator) => ({
	key: 'SupplierActionColumn',
	title: <div className='w-full pr-16 text-right'>ACTION</div>,
	render: (_, record) => (
		<div className='flex flex-row justify-end pr-7'>
			<div
				className='p-2 mr-6 bg-background rounded flex flex-row w-fit items-center cursor-pointer'
				onClick={() => navigator(`supplier/${record?._id}/action/edit`)}
			>
				<Icon path={mdiPencilOutline} size={0.8} className='text-primary-800 mr-2' />
				<div className='text-primary-800 font-[600] text-xs'>Edit</div>
			</div>
			{/* <div
				className='p-2 mr-2 bg-redBackground rounded flex flex-row w-fit items-center cursor-pointer'
				onClick={() => navigator(`supplier/${record?._id}/action/delete`)}
			>
				<Icon path={mdiDeleteOutline} size={0.8} className='text-red-400' />
			</div> */}
		</div>
	),
})

const InvestorNameColumn = {
	key: 'InvestorNameColumn',
	title: <div className='pl-[16px]'>Bilty Investor Name</div>,
	render: (_, record) => (
		<>
			<div className='font-[500] text-sm text-black-400 pl-7'>{record?.name || 'N/A'}</div>
		</>
	),
}
const InvestorContactColumn = {
	key: 'InvestorContactColumn',
	title: <div className='pl-[16px]'>Contact Number</div>,
	render: (_, record) => (
		<>
			<div className='font-[500] text-sm text-black-400 pl-2'>{formatPhoneNumber(record?.phoneNumber) || 'N/A'}</div>
		</>
	),
}
const InvestorCNICColumn = {
	key: 'InvestorCNICColumn',
	title: 'CNIC NUMBER',

	render: (_, record) => (
		<>
			<div className='font-[500] text-sm text-black-400 pl-2'>{record?.cnic || 'N/A'}</div>
		</>
	),
}
const InvestorActionColumn = (navigator) => ({
	key: 'InvestorActionColumn',
	title: <div className='w-full pr-16 text-right'>ACTION</div>,
})

/* =================================================================================================== */
/* ================================ SUPPLIER ASSIGNMENT COLUMNS ====================================== */
/* =================================================================================================== */
const SupplierNameColumn = {
	key: 'SupplierNameColumn',
	width: 150,
	render: (_, record) => (
		<>
			<div className='font-[500] text-sm text-black-400 pl-2 mx-0 truncate'>{record?.name}</div>
			<Form.Item name={['suppliers', `${record?._id}`, 'selected']} noStyle={true} initialValue={false} />
			{FormRender({ type: 'input', hidden: true, name: ['suppliers', `${record?._id}`, '_id'], initialValue: record?._id })}
		</>
	),
}

const SupplierNumberColumn = {
	key: 'SupplierNumberColumn',
	width: 140,
	render: (_, record) => <div className='font-[400] text-sm text-black-400 px-0 mx-0 truncate'>{record?.phoneNumber}</div>,
}

const VehicleInputColumn = (props) => ({
	key: 'VehicleInputColumn',
	width: 170,
	render: (_, record) =>
		FormRender({
			type: 'number',
			placeholder: 'Enter No. Of Vehicles',
			itemClassName: 'w-[170px] px-2 my-auto',
			elementClassName: 'h-[40px] rounded-lg',
			maxValue: props?.totalVehicles - (props?.currVehicles - parseInt(props?.suppliers?.[record?._id]?.numVehicles || '0')),
			disabled: !props?.suppliers?.[record?._id]?.selected,
			name: ['suppliers', `${record._id}`, 'numVehicles'],
			message: `Cannot assign more than ${props?.totalVehicles} vehicles`,
		}),
})

/* ================================== ALL ASSIGNMENT COLUMNS =========================================== */
export const assignmentColumns = ({ segment, tab, navigator }) => [
	...(tab === 'lot'
		? [OrderDetailsColumn, ClientDetailsColumn, RouteDetailsColumn, GoodTypeColumn(tab), WeightDetailsColumn]
		: [
				JobDetailsColumn(segment, tab),
				LaneDetailsColumn(segment, tab),
				...(segment === 'longHaul' ? [ContainerDetailsColumn(segment, tab), GoodTypeColumn(tab)] : [ContainerDetailsColumn(segment, tab)]),
				...(tab === 'local' ? [DriverDetailsColumn(tab), SupplierDetailsColumn(tab)] : [SupplierDetailsColumn(tab), DriverDetailsColumn(tab)]),
				ActionColumn(navigator, segment),
		  ]),
]
/* ================================ ALL POST-ASSIGNMENT COLUMNS ========================================= */
export const postAssignmentColumns = (segment) => [
	JobDocumentColumn(segment),
	ClientContainerColumn(segment),
	SupplierBrokerColumn,
	DriverVehicleColumn,
	PayableAmountColumn,
]
/* ======================================= ALL MANAGEMENT COLUMNS ======================================= */
export const managementColumns = ({ segment, tab, navigator, data }) => [
	VehicleTypeColumn,
	LaneDetailsColumn(segment, tab, 'management'),
	WeightRangeColumn,
	CostDetailsColumn,
	MarginColumn,
	AssignmentStatsColumn(navigator, segment, data),
]
/* ============================== ALL SUPPLIER ASSIGNMENT COLUMNS ======================================= */
export const assignSupplierColumns = (suppliers) => [SupplierNameColumn, SupplierNumberColumn, VehicleInputColumn(suppliers)]
export const brokerManagementColumns = ({ navigator, userEmail }) => [
	CarrierId(navigator),
	VendorNameColumn,
	BrokerTypeColumn,
	VendorCompanyName,
	VehicleCountColumn,

	VendorCNICColumn,
	VendorNumberColumn,
	...([
		'asaleem@bridgelinxpk.com',
		'aaslam@bridgelinxpk.com',
		'szaidi@bridgelinxpk.com',
		'oqayyum@bridgelinxpk.com',
		'zshah@bridgelinxpk.com',
		'sabbas@bridgelinxpk.com',
		'hyasir@bridgelinxpk.com',
		'atanveer@bridgelinxpk.com',
	].includes(userEmail)
		? [BrokerActionColumn(navigator, userEmail)]
		: []),
]
export const supplierManagementColumns = ({ navigator }) => [VendorNameColumn, VendorCNICColumn, VendorNumberColumn, SupplierActionColumn(navigator)]
export const biltyInvestorManagementColumns = ({ navigator }) => [
	InvestorNameColumn,
	InvestorContactColumn,
	InvestorCNICColumn,
	InvestorActionColumn(navigator),
]
