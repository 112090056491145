import { useRecoilStateCallback } from '../../hooks/useRecoilStateCallback'
import { userAtom } from '../../recoil/atoms'
import { authSelector } from '../../recoil/selectors'

import { Navigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { auth } from '../../firebase/firebaseConfig'

export const PrivateRoute = (props) => {
	const [loading, setLoading] = useState(true)
	const [_, setUserAtom] = useRecoilStateCallback(userAtom)
	const isAuthenticated = useRecoilValue(authSelector())

	const { redirect } = props

	useEffect(() => {
		const userData = JSON.parse(sessionStorage.getItem('user'))
		if (userData) {
			setUserAtom(userData, () => setLoading(false))
		} else {
			setLoading(false)
		}
	}, []) // eslint-disable-line

	return isAuthenticated?.authorized ? <>{props.children}</> : loading ? <div>LOADING...</div> : <Navigate to={redirect} replace />
}
