//react
import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

// pages
import Login from './containers/Login'
import HomePage from './containers/HomePage'

// components
import AssignmentTable from './components/tables/AssignmentTable'
import PostAssignmentTable from './components/tables/PostAssignmentTable'
import DynamicModal from './components/utility/DynamicModal'
import ManagementPage from './containers/Management'
import JobSidebar from './components/drawers/JobSidebar'
import { PrivateRoute } from './components/utility/PrivateRoute'

// css
import './App.css'
import './tw.css'
import VendorManagementPage from './containers/VendorManagement'
import CarrierManagementPage from './containers/Carriers'
import CarrierInfoSideBar from './containers/CarrierInfo'
import AddEditBiltyInvestorPage from './containers/BiltyInvestor'

// TODO: separate filter components, pills e.t.c, enums for pill

const App = () => {
	return (
		<Router>
			<Routes>
				<Route
					path=':segment'
					element={
						<PrivateRoute redirect='../login'>
							<HomePage />
						</PrivateRoute>
					}
				>
					<Route path='assignment/filters?/:filterState?' element={<AssignmentTable />}>
						<Route path='job/:id' element={<DynamicModal />}>
							<Route path='edit' />
							<Route path=':jobId'>
								<Route path='edit' />
							</Route>
							<Route path='create-job' />
							<Route path='finish-assignment' />
						</Route>
						<Route path='bulk-assign' element={<DynamicModal />}>
							<Route path='edit' />
						</Route>
						<Route path='split-job/:jobId' element={<DynamicModal />} />
					</Route>

					<Route path='post-assignment/filters?/:filterState?' element={<PostAssignmentTable />}>
						<Route path='bulk-authenticate' element={<DynamicModal />} />
						<Route path='bulk-add-charges' element={<DynamicModal />} />
						<Route path='job/:id' element={<JobSidebar />}>
							<Route path='charge/:chargeId/edit?' />
							<Route path='charge/:chargeId/thread' />
							<Route path='new-charge' />

							<Route path='revenue/:revenueId/edit?' />
							<Route path='new-revenue' />
							<Route path='edit'>
								<Route path='assignment' element={<DynamicModal />} />
								<Route path='payments' element={<DynamicModal />} />
								<Route path='paymentTerms' element={<DynamicModal />} />
							</Route>
						</Route>
					</Route>
					<Route path='management/filters?/:filterState?' element={<ManagementPage />} />
					<Route path='brokers/filters?/:filterState?' element={<VendorManagementPage />}>
						<Route path='broker/:brokerId/action/delete' element={<DynamicModal />} />
						<Route path='info/:id' element={<CarrierInfoSideBar />} />
					</Route>
					{/* <Route path='brokers/broker/:brokerId?/action/:action' element={<CarrierManagementPage />} /> */}
					<Route path='suppliers/filters?/:filterState?' element={<VendorManagementPage />}>
						<Route path='supplier/:brokerId?/action/:action' element={<DynamicModal />} />
					</Route>
					<Route path='bilty-investors/filters?/:filterState?' element={<VendorManagementPage />}>
						<Route path='bilty-investor/:brokerId?/action/:action' element={<DynamicModal />} />
					</Route>
					<Route path='*' element={<Navigate to='assignment' replace />} />
				</Route>
				<Route
					path='vendorManagement/brokers/broker/:brokerId?/action/:action'
					element={
						<PrivateRoute redirect='../login'>
							<CarrierManagementPage />{' '}
						</PrivateRoute>
					}
				/>
				<Route
					path='vendorManagement/bilty-investors/bilty-investor/:brokerId?/action/:action'
					element={
						<PrivateRoute redirect='../login'>
							<AddEditBiltyInvestorPage />
						</PrivateRoute>
					}
				/>
				<Route path='login' element={<Login />} />
				<Route path='*' element={<Navigate to='import' replace />} />
			</Routes>
		</Router>
	)
}

export default App
