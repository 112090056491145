import { NavEnums, TabNavEnums } from '../../helpers/Enums'
import { mdiMenu } from '@mdi/js'
import { Layout } from 'antd'

import { NewPortalPill } from '../utility/Utility'
import Icon from '@mdi/react'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

const HeaderError = ({ error, componentStack, resetErrorBoundary, ref }) => {
	const { segment } = useParams()

	useEffect(() => {
		if (error.message === 'Unauthenticated') {
			setTimeout(() => {
				resetErrorBoundary()
			}, [500])
		}
	}, [])

	return (
		<>
			<Layout.Header className={'sticky flex !h-[72px] items-center justify-between  py-4 px-4 ' + (segment === 'import' ? 'border-b' : 'border-b-[2px]')}>
				<div className='flex h-full items-center'>
					<div className=' cursor-pointer flex flex-row items-center px-4 h-full mr-6 bg-[#F5F5F5] rounded-[100px]'>
						<Icon className='w-6 h-6 mr-2' path={mdiMenu} color='#676767' />
						<div className='items-center font-[500] text-black-300 text-base'>{NavEnums[segment]?.title}</div>
					</div>

					<NewPortalPill />
				</div>
			</Layout.Header>
		</>
	)
}

export default HeaderError
