// react
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// recoil
import { userAtom } from '../../recoil/atoms'
import { useRecoilValue } from 'recoil'

//auth
import { useAuth } from '../../hooks/auth'

// helpers
import { Capitalize } from '../../helpers/Functions'
import { PROJECT_ID } from '../../helpers/Enums'

// components
import { Layout, Dropdown, Menu } from 'antd'
import Icon from '@mdi/react'
import TabHeader from './TabHeader'
import { NewPortalPill } from '../utility/Utility'
import NavBreadCrumbs from '../utility/BreadCrumbs'

// icons
import { mdiAccountCircleOutline, mdiMenu, mdiChevronDown } from '@mdi/js'
import { numRowsSelector } from '../../recoil/selectors'
import BridgelinxIcon from '../../logo.png'

const Header = (props) => {
	const { collapseMenu, setCollapseMenu, setTab, tab } = props.props
	const user = useRecoilValue(userAtom)?.[0]
	const navigator = useNavigate()
	const { pathname } = useLocation()
	const { segment } = useParams()
	const [selectedTab, setSelectedTab] = useState('')

	// const numOrders = useRecoilValue(numRowsSelector({ segment, combined: true }))

	const { logout } = useAuth()

	useEffect(() => {
		setSelectedTab(pathname?.split('/')?.[2])
	}, [pathname])

	const DropdownMenu = (
		<Menu className='font-bold m-0 p-0'>
			<Menu.Item key='1'>Profile Info</Menu.Item>
			<Menu.Item key='2' danger onClick={logout}>
				Sign Out
			</Menu.Item>
		</Menu>
	)

	return (
		<>
			<Layout.Header className={'sticky flex !h-[72px] items-center justify-between px-4 ' + (segment === 'import' ? 'border-b' : 'border-b-[2px]')}>
				<div className='flex h-full items-center'>
					<div
						onClick={() => setCollapseMenu(!collapseMenu)}
						className='cursor-pointer flex flex-row items-center h-full w-[11.25rem] py-4 border-r border-black-75 mr-4'
					>
						<div className='mr-4'>
							<Icon className=' text-primary-800 ' path={mdiMenu} size={1} />
						</div>
						<div className='mr-4'>
							<img className=' h-auto w-full' src={BridgelinxIcon} alt='' />
							{PROJECT_ID == 'bridgelinx-dev' && (
								<div className='fixed left-0 top-0 w-[12rem] h-[72px] '>
									<div className='m-1 bg-[#379fb3] rounded-lg right-2 leading-4 top-0 absolute border-2 border-white'>
										<p className='font-semibold italic text-[8px] ml-1 mr-[5.5px] text-white align-middle'>dev</p>
									</div>
								</div>
							)}
							<div className='text-[#4B535B] text-[10px] font-[500]'>SUPPLY OPS</div>
						</div>
					</div>
					<NavBreadCrumbs pathName={pathname} className='mx-4' />
				</div>
				<div className='flex h-full items-center py-4'>
					<NewPortalPill />
					<Dropdown overlay={DropdownMenu} trigger={['click']} className='cursor-pointer'>
						<div title='Profile' className='flex h-full w-max items-center rounded-full bg-primary-50 px-3 py-0'>
							<Icon path={mdiAccountCircleOutline} size={1} className='text-primary-800' />
							<p className='px-3 font-[600] text-black-400 text-sm'>{`${user?.firstName} ${user?.lastName}`}</p>
							<Icon path={mdiChevronDown} size={1} className='text-primary-800' />
						</div>
					</Dropdown>
				</div>
			</Layout.Header>
			<TabHeader segment={Capitalize(segment)} setTab={setTab} tab={tab} type={selectedTab} />
		</>
	)
}

export default Header
