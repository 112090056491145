import { mdiAlertCircleOutline, mdiArrowLeft, mdiCheck } from '@mdi/js'
import { Layout, Modal, Button, Form, Steps, notification } from 'antd'
import { useState, useRef, useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { ModalEnums } from '../../helpers/Enums'

import Icon from '@mdi/react'

const { Header, Content } = Layout
const { Step } = Steps

const DynamicModal = () => {
	//TODO: Create a custom hook for all of this
	// hooks
	const navigator = useNavigate()
	const { segment } = useParams()
	const { modalType, props, loading } = useOutletContext()
	const modalData = useMemo(() => ModalEnums[modalType]?.(props), [props, modalType])
	const [form] = Form.useForm()
	const modalRefState = useRef({})
	const [modalState, modalSetState] = useState()
	// states
	const [promiseLoading, setPromiseLoading] = useState(false)
	const [promiseDisabled, setPromiseDisabled] = useState(false)
	const [showWarning, setShowWarning] = useState(false)
	const [allowExecution, setAllowExecution] = useState({})
	const [showResultPage, setShowResultPage] = useState({ show: false, result: {} })
	const [page, setPage] = useState(modalData?.startingPage?.({ ...props }) || 0)

	// modal data states
	const watchField = modalData?.pages?.[page]?.watchField || ''
	const formWatch = Form.useWatch(watchField === 'all' ? undefined : watchField, form)
	const pages = useMemo(
		() => modalData?.pages?.filter((page) => !page.hidePage?.({ values: form.getFieldsValue(true), props })),
		[modalData, formWatch]
	)
	const maxPage = pages?.length
	const disableFunction = pages?.[page]?.checkButtonDisable
	const { function: warningFunction, data: warningData, alwaysReject } = pages?.[maxPage - 1]?.warning || {}
	const [finishFunction] = pages?.[maxPage - 1]?.useFinishFunction()
	// ***************************************CALLBACKS****************************************************
	const resolveExecution = useCallback(() => allowExecution?.resolve(), [allowExecution])
	const rejectExecution = useCallback(() => allowExecution?.reject('hideWarning'), [allowExecution])

	const buttonfuncs = {
		cancel: () => navigator(-1),
		back: () => (page <= 0 ? navigator(-1) : setPage((prev) => prev - 1)),
		next: () => form.validateFields().then(() => (page >= maxPage ? () => {} : setPage((prev) => prev + 1))),
		submit: () => handleFinish(),
		custom: (button) => button.function(),
	}
	const modalButtons = useMemo(
		() =>
			pages?.[page]?.buttons
				?.filter((button) => !(button?.type === 'next' && !pages?.[page + 1]))

				?.map((button, index) => {
					const buttonDisabled =
						promiseDisabled ||
						loading ||
						(button?.type !== 'cancel' && disableFunction
							? disableFunction?.({ ...props, formWatch, values: form.getFieldsValue(true), buttonType: button?.type, modalRefState, modalState, modalSetState })
							: false)
					return {
						...button,
						buttonDisabled,
					}
				}),
		[pages, page, promiseDisabled, loading, disableFunction, formWatch, modalState]
	)
	const warningButtons = [
		{
			type: 'custom',
			title: 'CANCEL',
			className: 'btn-delete',
			function: rejectExecution,
		},
		...(!alwaysReject ? [{ type: 'custom', title: 'CONTINUE', className: 'btn-primary', function: resolveExecution }] : []),
	]
	const handleFinish = () => {
		const values = form.getFieldsValue(true)
		const isWarningTrue = warningFunction && warningFunction({ values, props })
		form
			.validateFields()
			.then(async () => {
				return new Promise((resolve, reject) => {
					if (isWarningTrue) {
						setShowWarning(true)
						setAllowExecution({ resolve, reject })
					} else {
						resolve()
					}
				})
			})
			.then(async () => {
				setShowWarning(false)
				setPromiseLoading(true)
				return await finishFunction({ values: form.getFieldsValue(true), props })
					.then((response) => response)
					.catch((e) => e)
			})
			.then((queryresult) => {
				if (queryresult?.type) {
					notification[queryresult?.type]({
						message: queryresult.message,
						description: queryresult.description,
						duration: '5',
					})
				}
				setPromiseLoading(false)
				if (modalData?.customResultPage) {
					setShowResultPage({ show: true, result: queryresult })
				} else {
					navigator(-1)
				}
			})
			.catch((e) => {
				if (e === 'hideWarning') {
					setShowWarning(false)
					console.log(e)
				}
			})
	}

	//*****************************************COMPONENTS**************************************************
	//TODO: Put components outside main component
	const CardHeader = ({ title, description, warning }) => (
		<div className='flex flex-row justify-start items-center px-6 ' key='header'>
			{page > 0 && !warning && <Icon className='cursor-pointer mr-4' path={mdiArrowLeft} size={1} onClick={buttonfuncs.back} />}
			<div className='flex flex-col bg-white h-[60px] justify-center rounded-t-lg'>
				<p className='font-[500] text-[16px] text-black-400'>{title}</p>
				{description && <p className='text-xs text-black-300'>{description}</p>}
			</div>
		</div>
	)
	const ModalFooter = () => (
		<div className='w-full items-center justify-end flex flex-row' key='footer'>
			{modalButtons?.map((button, index) => {
				return (
					<Button
						className={button?.className}
						style={{ height: 40 }}
						onClick={buttonfuncs[button?.type]}
						disabled={button?.buttonDisabled}
						key={'button-' + index}
						loading={promiseLoading}
					>
						{button?.altCondition?.({ values: form.getFieldsValue(true), props }) || button?.title}
					</Button>
				)
			})}
		</div>
	)

	const WarningFooter = () => (
		<div className='w-full items-center justify-end flex flex-row' key='footer'>
			{warningButtons.map((button, index) => {
				return (
					<Button className={button?.className} style={{ height: 40 }} onClick={() => button.function()} key={'warningButton' + index}>
						{button?.title}
					</Button>
				)
			})}
		</div>
	)

	const WarningModal = () => (
		<Modal
			title={<CardHeader title={warningData?.title || 'Warning'} warning={true} />}
			key={'warningModal'}
			visible={showWarning}
			onCancel={() => setShowWarning(false)}
			className='transition-[width] duration-[500] overflow-x-scroll '
			footer={<WarningFooter />}
			centered
			width='400px'
		>
			<Layout>
				<Content className={'bg-white flex flex-row h-fit overflow-y-clip'}>
					<div className={`${loading ? 'no-hover' : ''} w-full m-6 flex flex-col items-center justify-center`}>
						<Icon path={mdiAlertCircleOutline} size={3} className='text-red-400 ' />
						<p className='text-black-300 text-base font-semibold pt-4 text-center'>{warningData?.message} </p>
					</div>
				</Content>
			</Layout>
		</Modal>
	)
	const StepsHeader = () => (
		<div className='flex flex-row flex-grow-0 px-6 py-4 w-full items-center border-b-2 border-black-75'>
			<Steps direction='horizontal' size='small' current={page}>
				{pages
					?.filter((page) => !page.resultPage)
					?.map((pageData, index) => (
						<Step
							key={`step-${index}`}
							className='items-center'
							title={<div className='font-[500] text-[16px] text-black-400'>{pageData.title}</div>}
							status={index < page ? 'finish' : 'process'}
							icon={
								index < page ? (
									<div className='bg-primary-800 rounded-full flex h-6 w-6 items-center justify-center mr-3'>
										<Icon path={mdiCheck} className='text-white' size={0.7} />
									</div>
								) : (
									<div
										className={`rounded-full h-6 w-6 flex items-center justify-center mr-3 font-[500] text-xs ${
											index === page ? 'bg-primary-800 text-white' : 'bg-black-75 text-white'
										}`}
									>
										<p className=''>{index + 1}</p>
									</div>
								)
							}
						/>
					))}
			</Steps>
		</div>
	)
	useEffect(() => {
		modalData?.resetFormValues?.({ form, props })
	}, [props]) // eslint-disable-line
	return (
		<Modal
			title={
				showResultPage?.show ? false : <CardHeader title={modalData?.showTimeline === true ? modalData?.title : pages?.[page]?.title} warning={false} />
			}
			key={modalType}
			visible={true}
			className={`transition-[width] duration-[500] overflow-x-scroll  ${showResultPage?.show ? 'rounded-lg' : ''}`}
			onCancel={() => navigator(-1)}
			footer={showResultPage?.show ? false : <ModalFooter />}
			centered
			width={showResultPage?.show ? modalData?.customResultPage?.width : pages?.[page]?.width}
		>
			{modalData?.showTimeline === true && !showResultPage?.show && <StepsHeader />}
			<Form form={form} layout='vertical' colon={false}>
				<Layout>
					{!showResultPage?.show && pages?.[page]?.subHeader && (
						<Header className='px-0'>{pages?.[page]?.subHeader({ ...props, form, formWatch, navigator, segment })}</Header>
					)}
					<Content className={`bg-secondaryBackground flex flex-row h-fit overflow-y-clip ${showResultPage?.show ? 'max-h-[40rem]' : 'max-h-[36rem]'}`}>
						{(showResultPage?.show ? modalData?.customResultPage : pages?.[page])?.columns?.map((column, index) => (
							<div key={index} className={`${loading ? 'no-hover' : ''} ${column?.widthClassName} `}>
								<column.element
									{...{
										...props,
										setPromiseDisabled,
										showResultPage,
										form,
										formWatch,
										navigator,
										segment,
										modalRefState,
										modalState,
										modalSetState,
										goBack: buttonfuncs.back,
									}}
								/>
							</div>
						))}
					</Content>
				</Layout>
			</Form>
			<WarningModal />
		</Modal>
	)
}

export default DynamicModal
