// react
import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useParams, useOutletContext, useNavigate, useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import SearchHeader from '../layout/SearchHeader'
import DynamicTable from '../utility/DynamicTable'
import { useFetch } from '../../hooks/graphql'
import { Capitalize } from '../../helpers/Functions'
import { filteredPostAssignmentJobsSelector, jobByIDSelector, numFilteredJobsSelector, postAssignmentJobByIdsSelector } from '../../recoil/selectors'
import { postAssignmentColumns } from '../../helpers/TableColumns'
import { useRecoilValue } from 'recoil'
import useFiltrationData from '../../hooks/useFiltrationData'
import FilteredRowsHeader from '../utility/FilteredRowsHeader'
import moment from 'moment'
import Icon from '@mdi/react'
import Pagination from '../utility/Pagination'
import { PageSizes } from '../../helpers/Enums'
const { Content, Footer } = Layout

const ExpandableJobColumn = (record, selectedRows, segment) => {
	const { assignedBy, driverMatchedAt, loadingDT, freeDays, freeDaysDate, nocExpiry } = record
	const assignedAt = assignedBy?.assignedAt && moment(assignedBy?.assignedAt)?.format('DD MMM YYYY - LT')
	const jobInfos = []
	assignedBy?.name &&
		jobInfos.push({
			heading: 'Assigned By: ',
			value: () => (
				<div className='flex flex-row my-auto text-sm pl-2'>
					<div className='font-[700] text-black-300'>{assignedBy?.name}</div>
					<div className='pl-1 font-[400] text-black-300'>{`${' - ' + assignedAt}`}</div>
				</div>
			),
		})

	driverMatchedAt &&
		jobInfos.push({
			heading: 'Driver Matched At:',
			value: `${moment(driverMatchedAt).format('DD MMM YYYY - LT')}`,
			colorClass: 'text-black-300',
		})
	loadingDT &&
		jobInfos.push({
			heading: 'Loading Date :',
			value: `${moment(loadingDT).format('DD MMM YYYY - LT')}`,
			colorClass: 'text-black-300',
		})
	freeDays &&
		jobInfos.push({
			heading: 'Empty Free Date :',
			value: freeDaysDate
				? `${moment(freeDaysDate).utc().format('DD MMM YYYY')} ${freeDays < 0 ? `(${-freeDays} day(s) passed)` : `(${freeDays} day(s) left)`}`
				: 'N/A',
			colorClass: freeDays < 0 ? 'text-red-400' : 'text-black-300',
		})
	nocExpiry &&
		jobInfos.push({
			heading: 'NOC Expiry',
			value: nocExpiry ? moment.unix(parseInt(nocExpiry) / 1000).format('DD MMM YYYY') : 'N/A',
			colorClass: 'text-black-300',
		})
	return (
		jobInfos.length > 0 && (
			<div className={`${selectedRows?.includes(record?._id) ? 'bg-[#e6f7ff]' : 'bg-white'} w-full pl-[50px] pr-[21px] pb-[17px]`}>
				<div className={`${selectedRows?.includes(record?._id) ? 'bg-white' : 'bg-[#f5f5f5]'} rounded py-2 pr-3 flex flex-row  pl-3`} itemlayout='horizontal'>
					{jobInfos.map((info, index) => (
						<div className='flex flex-row items-center' key={'jobInfo_' + index}>
							{info.icon && <Icon className='mr-2' path={info.icon} size={0.8} color={info.iconColor} />}
							{info.heading && <div className='text-sm font-[400] text-black-300 my-auto'>{`${info.heading}`}</div>}
							{info.value && typeof info.value === 'function' ? (
								info.value()
							) : (
								<div className={`text-[14px] font-[600] my-auto ${info.colorClass ? info.colorClass : 'text-black-400'} pl-[3px]`}>{info.value}</div>
							)}
							{index < jobInfos?.length - 1 && <div className='px-2 my-auto'>|</div>}
						</div>
					))}
				</div>
			</div>
		)
	)
}

const PostAssignmentTable = (props) => {
	const { segment } = useParams()
	const { tab } = useOutletContext()
	const { pathname } = useLocation()
	const currLocation = pathname.split('/')?.[2]

	const [pageInfo, setPageInfo] = useState({ pageNo: 1, pageSize: 10 })
	const [selectedRows, setSelectedRows] = useState([])
	const [backendPageInfo, setBackendPageInfo] = useState({ pageNo: 1, pageSize: PageSizes[pageInfo.pageSize].backend })
	const navigator = useNavigate()
	const [filterVariables, setFilterVariables] = useState({ showCompletedJobs: false })
	const variables = useMemo(
		() => ({
			type: Capitalize(segment),
			tab,
			status: filterVariables.showCompletedJobs ? ['Order Completed'] : ['Order Ongoing', 'Partial Ongoing'],
			jobStatus: filterVariables.showCompletedJobs ? ['Job Completed', 'Job Ongoing'] : ['Job Ongoing', 'Job Completed'],
		}),
		[segment, tab, filterVariables]
	)
	const subscriptionVariables = useMemo(
		() => ({ type: Capitalize(segment), status: ['Order Confirmed', 'Order Ongoing', 'Order Completed', 'Order Cancelled'] }),
		[segment, tab]
	)
	useEffect(() => {
		setBackendPageInfo({ pageNo: 1, pageSize: PageSizes[pageInfo.pageSize].backend })
	}, [pageInfo.pageSize]) // eslint-disable-line
	useEffect(() => {
		setSelectedRows([])
	}, [segment, tab])
	const loading = useFetch({
		type: 'Orders',
		atomKey: segment,
		variables,
		subscriptionVariables: variables,
		pageInfo: { pageNo: backendPageInfo?.pageNo, pageSize: backendPageInfo?.pageSize },
	})
	const filterDataLoading = useFiltrationData()
	const [collectionData, totalCount] = useRecoilValue(
		filteredPostAssignmentJobsSelector({ segment, tab, pageNo: pageInfo.pageNo, pageSize: pageInfo.pageSize })
	)
	const { total, filtered } = useRecoilValue(numFilteredJobsSelector({ segment, tab, type: 'post-assignment' }))

	useEffect(() => {
		setPageInfo({ pageNo: 1, pageSize: 10 })
	}, [filterVariables]) // eslint-disable-line

	return (
		<>
			<SearchHeader setExternalState={setFilterVariables} tab={tab} pageNo={backendPageInfo?.pageNo} pageSize={backendPageInfo?.pageSize} />
			<Layout className='bg-white rounded-lg overflow-y-hidden shadow-[0_1px_8px_rgba(0,0,0,0.16)]'>
				<FilteredRowsHeader
					total={total}
					filtered={filtered}
					currLocation={'post-assignment'}
					tab={tab}
					navigator={navigator}
					enableBulkAuth={selectedRows?.length > 0}
				/>
				<Content className='mb-14'>
					<DynamicTable
						columns={postAssignmentColumns}
						autoResetSelection={true}
						columnProps={segment}
						data={collectionData}
						selectedRowKeys={selectedRows}
						expandedRowKeys={collectionData?.map((order) => order?._id)}
						expandable={{
							expandIconColumnIndex: 4,
							expandedRowRender: (row) => ExpandableJobColumn(row, selectedRows, segment),
							defaultExpandAllRows: true,
							expandIcon: () => <></>,
						}}
						loading={loading}
						rowClassName=' hover:cursor-pointer expanded-row '
						onRow={(record) => ({
							onClick: (e) => {
								navigator(`/${segment}/post-assignment/job/${record?._id}`)
							},
						})}
						onSelect={(selectedRowKeys) => {
							setSelectedRows(selectedRowKeys)
						}}
					/>
				</Content>
				<Footer className='flex justify-center items-center !bg-white !h-14 !p-0 !border-t-[0.5px] !border-[rgba(0, 0, 0, 0.12)]'>
					<Pagination setPageInfo={setPageInfo} setBackendPageInfo={setBackendPageInfo} totalCount={totalCount} pageInfo={pageInfo} />
				</Footer>
			</Layout>
			<Outlet
				context={{
					loading: loading,
					tab: tab,
					modalType: pathname.split('/')?.reverse()?.[0] === 'bulk-authenticate' ? 'bulkAuthenticate' : 'bulkAddCharges',
					props: {
						tab: tab,
						data: {
							jobs: useRecoilValue(
								postAssignmentJobByIdsSelector({
									segment,
									ids: selectedRows,
									tab,
								})
							),
						},
					},
				}}
			/>
		</>
	)
}

export default PostAssignmentTable
