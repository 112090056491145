import { TabNavEnums } from '../../helpers/Enums'
import { Layout, Tabs } from 'antd'
import { useRecoilValue } from 'recoil'
import { numRowsSelector } from '../../recoil/selectors'

import Icon from '@mdi/react'
import { useEffect } from 'react'
import { useFetch } from '../../hooks/graphql'

const { TabPane } = Tabs

const TabHeader = (props) => {
	const { segment, setTab, tab, type } = props
	// const numOrders = useRecoilValue(numRowsSelector({ segment, type }))

	useEffect(() => {
		setTab(segment === 'Import' ? 'direct' : segment === 'Export' ? 'normal' : 'nonLot')
	}, [segment])

	return (
		<Layout.Header className={'sticky items-center p-0 '}>
			<Tabs
				activeKey={tab}
				onChange={(value) => {
					setTab(value)
				}}
				className={'w-full ' + (segment === 'Import' || (segment === 'LongHaul' && type === 'assignment') ? 'slide-animation-open' : 'slide-animation-close')}
			>
				{TabNavEnums[segment]?.map((type, _) => (
					<TabPane
						tab={
							<div className='flex flex-row px-4 items-center'>
								<Icon className='mr-2' size={0.8} path={type.icon} />
								{type.text}
								{/* <div className='ml-2 flex flex-row items-center py-[0.5] px-1 h-full bg-[#E9ECF1] rounded-[100px]'>
									<div className='items-center text-xs'>{numOrders?.[type.key] || 0}</div>
								</div> */}
							</div>
						}
						key={type.key}
					></TabPane>
				))}
			</Tabs>
		</Layout.Header>
	)
}

export default TabHeader
