import { VehicleTypeEnums } from './Enums'
import { formatName } from '../recoil/selectors'
import { findSlab } from './Functions'
import dayjs from 'dayjs'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export const LoginFormSchema = (props) => {
	const { forgotPassword } = props
	return [
		{
			type: 'input',
			label: 'Email',
			name: 'email',
			inputType: 'text',
			required: true,
			message: 'Email is required.',
			addonAfter: '@bridgelinxpk.com',
		},
		!forgotPassword && {
			type: 'password',
			label: 'Password',
			name: 'password',
			inputType: 'password',
			required: true,
			message: 'Password is required.',
		},
	]
}

/* ============================================================================================== */
/* ====================================== FILTER SCHEMAS ======================================== */
/* ============================================================================================== */

export const RangeSchema = ({ filter }) => {
	const { propertyKey, filterProps } = filter
	const { inputType, lowTitle, highTitle } = filterProps
	return [
		[
			{
				type: inputType || 'input',
				label: lowTitle,
				name: [propertyKey, 'values', 'low'],
				// initialValue: '',
				inputType: 'text',
				className: 'min-w-[40%]',
				itemClassName: 'filter-label mb-5',
				elementClassName: 'filter-input',
			},
			{
				type: inputType || 'input',
				label: highTitle,
				name: [propertyKey, 'values', 'high'],
				// initialValue: '',
				inputType: 'text',
				className: 'min-w-[40%]',
				itemClassName: 'filter-label mb-5',
				elementClassName: 'filter-input',
			},
		],
	]
}
export const CheckboxSchema = ({ filter, options = {}, keyword }) => {
	const { propertyKey, filterProps, searchFunction, showSearch } = filter
	const { render, key, displayProperty } = filterProps
	const optionList = Object.values(options)
	return [
		...(showSearch && keyword ? optionList?.filter((option) => searchFunction({ keyword, option })) : optionList).map((option) => ({
			key: option?.[key],
			type: 'checkbox',
			name: [propertyKey, 'values', `${key ? option?.[key] : option}`],
			label: render ? render(option) : displayProperty ? option[displayProperty] : key ? option[key] : option,
		})),
	]
}

export const SelectSchema = ({ filter, options = {} }) => {
	const { propertyKey, filterProps } = filter
	const { displayProperty, placeholder, key } = filterProps

	return [
		{
			type: 'select',
			placeholder: placeholder,
			name: [propertyKey, 'values'],
			valueProperty: key || '_id',
			showSearch: true,
			displayProperty: displayProperty,
			options: Object.values(options),
		},
	]
}

/* ============================================================================================== */
/* ====================================== MODAL SCHEMAS ========================================= */
/* ============================================================================================== */

export const EditSupplierSchema = (props) => {
	const { job = {}, suppliers, tab } = props
	return [
		{
			type: 'select',
			// label: 'Supplier',
			name: ['supplier'],
			valueProperty: '_id',
			displayProperty: 'name',
			elementClassName: 'bg-secondary-300 text-white font-[600]',
			iconClassName: 'text-white',
			options: suppliers,
			required: tab === 'local' ? false : true,
			showSearch: true,
			placeholder: 'Select Supplier',
			initialValue: job?.supplier?._id,
		},
	]
}

export const EditJobSchema = (props) => {
	const { job = {}, brokers, form, tab, formWatch, onChange, loading } = props
	return [
		{
			type: 'select',
			label: 'Broker Name',
			name: ['broker'],
			valueProperty: '_id',
			form: form,
			displayProperty: 'namePhoneNumber',
			options: brokers,
			required: true,
			showSearch: true,
			initialValue: job?.broker?._id,
		},
		{
			type: 'phoneNumber',
			label: 'Driver Mobile Number',
			name: ['phoneNumber'], //disable if it is already assigned
			required: tab === 'local' || formWatch?.phoneNumber || formWatch?.vehicleRegistration,
			message: 'Phone Number is Required with Vehicle Number',
			initialValue: job?.driver?.phoneNumber,
		},
		[
			{
				type: 'vehicleRegistration',
				label: 'Vehicle Number',
				name: ['vehicleRegistration'],
				required: tab === 'local' || formWatch?.phoneNumber || formWatch?.vehicleRegistration,
				message: ' Vehicle Number is Required with Driver Phone Number',
				onChange: onChange,
				className: 'blacklist-loading',
				addonAfter: loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} />} /> : null,
				initialValue: job?.vehicle?.registrationNumber,
			},
			{
				type: 'select',
				label: 'Vehicle Type',
				name: ['vehicleType'],
				valueProperty: 'key',
				form: form,
				displayProperty: 'text',
				options: Object.values(VehicleTypeEnums),
				initialValue:
					job?.vehicleInfo?.vehicleType && job?.vehicleInfo?.vehicleType === 'Trolly-xl2020ft'
						? 'Trolly-xl40ft'
						: job?.vehicleInfo?.vehicleType || 'Trolly-xl20ft',
			},
		],
	]
}

export const CreateLotJobSchema = (props) => {
	const { job, brokers, suppliers, form, tab, remainingWeight, onChange, loading } = props
	const finalRemainingWeight = job ? remainingWeight + parseFloat(job?.shipmentInfo?.freightWeight) : remainingWeight
	return [
		{
			type: 'select',
			label: 'Supplier',
			name: ['supplier'],
			valueProperty: '_id',
			displayProperty: 'name',
			options: suppliers,
			disabled: job?.supplier?._id ? true : false,
			required: tab === 'local' ? false : true,
			form,
			initialValue: job?.supplier?.name,
		},
		[
			{
				type: 'phoneNumber',
				label: 'Driver Mobile Number',
				name: ['phoneNumber'], //disable if it is already assigned
				required: true,
				disabled: job?.driver?._id || job?.vehicle?.registrationNumber ? true : false,
				initialValue: job?.driver?.phoneNumber,
			},
			{
				type: 'vehicleRegistration',
				label: 'Vehicle Number',
				name: ['vehicleRegistration'],
				required: true,
				onChange: onChange,
				className: 'blacklist-loading',
				addonAfter: loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} />} /> : null,
				disabled: job?.driver?._id || job?.vehicle?.registrationNumber ? true : false,
				initialValue: job?.vehicle?.registrationNumber,
			},
		],
		[
			{
				type: 'select',
				label: 'Broker Name',
				name: ['broker'],
				valueProperty: '_id',
				displayProperty: 'namePhoneNumber',
				options: brokers,
				setEmptyValues: ['phoneNumber', 'vehicleRegistration', 'cost'],
				showSearch: true,
				form,
				disabled: job?.broker?._id ? true : false,
				required: true,
				initialValue: job?.broker?._id,
			},
			{
				type: 'dateTime',
				label: 'Committed Days',
				name: ['committedDays'],
				initialValue: job?.committedDays ? dayjs(parseInt(job?.committedDays)) : '',
			},
		],
		[
			{
				type: 'select',
				label: 'Vehicle Type',
				name: ['vehicleType'], //disable if it is already assigned
				valueProperty: 'key',
				displayProperty: 'long',
				required: true,
				options: Object.values(VehicleTypeEnums),
				initialValue: job?.vehicleType,
			},
			{
				type: 'input',
				inputType: 'number',
				label: 'Weight (tons)',
				required: true,
				maxValue: finalRemainingWeight,
				name: ['weight'],
				initialValue: job?.shipmentInfo?.freightWeight,
			},
		],
		[
			{
				type: 'input',
				inputType: 'number',
				label: 'Number of Units',
				placeholder: 'Enter moved units',
				name: ['freightCount'],
				initialValue: job?.shipmentInfo?.freightCount,
			},
			{},
		],
	]
}

export const PaymentTermsSchema = ({ job, formWatch, cost }) => {
	const paymentModeOptions = [
		{ value: 'IBFT', label: 'IBFT' },
		{ value: 'Cash', label: 'Cash' },
		{ value: 'Cheque', label: 'Cheque' },
		{ value: 'Spot', label: 'Spot' },
	]
	const pointOfPaymentOptions = [
		{ value: 'loadingCompleted', label: 'After Loading' },
		{ value: 'unloadingCompleted', label: 'After Unloading' },
		{ value: 'completed', label: 'After POD' },
	]
	const cumulativeSum =
		formWatch?.paymentTerms?.reduce((acc, term) => {
			if (term) {
				return acc + (term?.amount === '' ? 0 : parseFloat(term?.amount))
			} else {
				return acc
			}
		}, 0) || 0
	return [
		[
			{
				type: 'dynamicFields',
				name: 'paymentTerms',
				label: 'Payment Terms',
				message: 'You have settled the amount',
				minEntries: 1,
				maxEntries:
					cumulativeSum >= parseFloat(job?.factoredAmount || formWatch?.costType === 'factored' ? job?.factoredAmount || cost + findSlab(cost, true) : cost)
						? formWatch?.paymentTerms?.length
						: formWatch?.paymentTerms?.length + 1,
				initialValue:
					job?.paymentTerms && job.paymentTerms.length > 0
						? job?.paymentTerms
						: [{ paymentType: 'IBFT', index: '0', journeyPoint: pointOfPaymentOptions?.[0]?.value, days: '0', amount: '1' }],
				fields: [
					{
						type: 'select',
						label: 'Payment Mode',
						name: 'paymentType',
						valueProperty: 'value',
						displayProperty: 'label',
						options: paymentModeOptions,
						message: 'Payment Mode is Required',
						initialValue: 'IBFT',
						required: true,
					},
					{
						type: 'input',
						name: 'index',
						hidden: true,
					},
					{
						type: 'select',
						label: 'Point of Payment',
						name: 'journeyPoint',
						valueProperty: 'value',
						displayProperty: 'label',
						options: pointOfPaymentOptions,
						message: 'Point of Payment is Required',
						initialValue: pointOfPaymentOptions?.[0]?.value,
						required: true,
					},
					{
						type: 'input',
						inputType: 'number',
						label: 'Number of Days',
						name: 'days',
						initialValue: '0',
						minValue: 0,
						required: true,
					},
					{
						type: 'input',
						inputType: 'number',
						label: 'Amount',
						name: 'amount',
						minValue: 1,
						subtractCurrValue: true,
						initialValue: '1',
						maxValue:
							(cost || job?.cost) &&
							parseFloat(job?.factorStatus === 'approved' || formWatch?.costType === 'factored' ? job?.factoredAmount || cost + findSlab(cost, true) : cost) -
								cumulativeSum,
						required: true,
					},
				],
			},
		],
	]
}

export const AddEditVendorScehma = ({ data, type, edit }) => {
	const { vendor, formData } = data
	return [
		...(type === 'brokers'
			? [
					{
						type: 'select',
						label: 'Vendor Type',
						name: 'vendorType',
						valueProperty: '_id',
						displayProperty: 'name',
						initialValue: vendor?.type,
						options: formData?.vendorType && Object.values(formData?.vendorType),
						required: true,
					},
			  ]
			: []),
		{
			type: 'input',
			label: 'Name',
			name: 'name',
			initialValue: formatName(vendor?.User?.firstName, vendor?.User?.lastName),
			required: true,
		},
		{
			type: 'phoneNumber',
			label: 'Mobile Number',
			name: 'phoneNumber',
			disabled: edit,
			initialValue: vendor?.User?.phoneNumber,
			required: true,
		},
		{
			type: 'cnic',
			label: 'CNIC (Optional)',
			name: 'cnic',
			...(vendor?.User?.cnic && { initialValue: vendor?.User?.cnic }),
		},
	]
}
