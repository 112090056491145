import { Select, Input, Form, Divider } from 'antd'

import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons'
import FilterHeader from './FilterHeader'

import { useMemo, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { FilterTypeEnums, AssignmentSearchEnums, PostAssignmentSearchEnums, PageSearchEnums, PageFilterEnums } from '../../helpers/Enums'

import useFilterState from '../../hooks/useFilterState'
import { Capitalize } from '../../helpers/Functions'

const SearchHeader = ({ tab, setExternalState, pageNo, pageSize }) => {
	// const navigator = useNavigate()
	const { pathname } = useLocation()
	const currLocation = pathname.split('/')?.[2]
	const { segment, filterState: urlFilterString } = useParams()
	const filterController = useFilterState({
		urlFilterString,
		page: currLocation,
		segment: ['shifting', 'destuffing'].includes(tab) ? 'ImportShifting' : tab === 'lot' ? 'Lot' : segment,
		type: segment,
		tab,
		setExternalState,
		pageNo, pageSize
	})

	const { onFilterFormChange, filterFormController, filterState } = filterController
	const selectedFilters = useMemo(
		() => Object.values(filterState).filter((filter) => FilterTypeEnums[filter.type]?.checkSelected(filter?.values)),
		[filterState, tab, segment]
	)

	const searchEnum = useMemo(
		() => Object.assign([], PageSearchEnums[currLocation]?.[segment === 'vendorManagement' ? 'default' : Capitalize(tab === 'lot' ? tab : segment)]),
		[segment, currLocation]
	)
	useEffect(() => {
		filterController.filterDispatch({ type: 'search', payload: { property: searchEnum?.[0]?.propertyKey, value: '' } })
	}, [segment, currLocation])

	const SearchBarAddOn = () => (
		<Form.Item key='searchOption' name={['search', 'property']} noStyle={true} initialValue={searchEnum?.[0]?.propertyKey}>
			<Select className='flex flex-row h-full justify-center font-bold bg-white pr-2 text-black-300' suffixIcon={<CaretDownOutlined color='#333333' />}>
				{searchEnum?.map((option) => (
					<Select.Option key={option.propertyKey} value={option.propertyKey}>
						By&nbsp;{option.name}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	)
	return (
		<Form form={filterFormController} onValuesChange={onFilterFormChange} colon={false}>
			<div className='flex flex-row justify-between mb-2 h-max'>
				<div className='flex flex-wrap sticky items-start h-max justify-start'>
					{PageSearchEnums[currLocation] && (
						<>
							<div className=''>
								<Form.Item key={'searchInput'} name={['search', 'value']} initialValue='' noStyle={true}>
									<Input
										className='h-[40px] flex flex-row search-input align-top pr-2 mb-4'
										addonBefore={<SearchBarAddOn />}
										placeholder='Search'
										prefix={<SearchOutlined className='px-2' />}
									/>
								</Form.Item>
							</div>
							<Divider type='vertical' className='h-[40px]' />
						</>
					)}

					{PageFilterEnums[currLocation] && (
						<FilterHeader
							props={{
								...(currLocation === 'management' && { primaryFilterProperties: ['client'] }),
								filterFormController,
								filterController,
								selectedFilters,
								segment: ['shifting', 'destuffing'].includes(tab) ? 'ImportShifting' : tab === 'lot' ? 'Lot' : segment,
								tab,
								page: currLocation,
							}}
						/>
					)}
				</div>
			</div>
		</Form>
	)
}

export default SearchHeader
