// import Icon from '@mdi/react'
import { Button, Alert, Result } from 'antd'
import { LockTwoTone } from '@ant-design/icons'

export const AlertBanner = (alert, setAlert) => (
	<Alert
		type={alert.type}
		message={alert.message}
		showIcon
		closable
		className={`font-bold text-left mb-6 ${alert.type}`}
		afterClose={() => setAlert({ type: '', message: '' })}
	/>
)

export const UnAuthorizedResult = ({ onClickFunction }) => (
	<Result
		icon={<LockTwoTone />}
		title={<p className='font-bold m-0'>Not Authorized</p>}
		subTitle={
			<p className='m-0'>
				Sorry, your account role does not
				<br />
				allow you to access this portal.
			</p>
		}
		extra={
			<Button className='btn-primary' onClick={() => onClickFunction()}>
				Go Back
			</Button>
		}
	/>
)
