import { mdiChevronLeftCircle, mdiChevronRightCircle, mdiMinusCircle, mdiPlusCircle } from '@mdi/js'
import Icon from '@mdi/react'

const CustomStepper = ({ onChange, value, state }) => {
	return (
		<div className='flex flex-row justify-between items-center rounded py-2 px-4 border border-black-900 my-2'>
			<Icon
				path={mdiMinusCircle}
				className='text-red-400 cursor-pointer'
				size={1}
				onClick={() => onChange(value ? (parseInt(value) > 1 ? parseInt(value) - 1 : parseInt(value)).toString() : '4')}
			/>
			<p>{value || state}</p>
			<Icon
				path={mdiPlusCircle}
				className='text-primary-800 cursor-pointer'
				size={1}
				onClick={() => onChange(value ? (parseInt(value) + 1).toString() : '6')}
			/>
		</div>
	)
}

export default CustomStepper
