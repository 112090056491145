import React, { useEffect, useState } from 'react'
import BridgelinxLogo from '../logo.png'

import { Form, Button } from 'antd'
import { RenderSchema } from '../helpers/FormRenderer'
import { LoginFormSchema } from '../helpers/FormSchemas'
import { AlertBanner, UnAuthorizedResult } from '../components/auth/Auth'

import { useAuth } from '../hooks/auth'

const Login = (props) => {
	const [loading, setLoading] = useState(false)
	const [authorized, setAuthorized] = useState(true)
	const [forgotPassword, setForgotPassword] = useState(false)
	const [alert, setAlert] = useState({ type: '', message: '' })
	const [form] = Form.useForm()
	const { authorization } = useAuth(setLoading, setAuthorized, setAlert)

	useEffect(() => {
		if (alert.type.length > 0) {
			setAlert({ type: '', message: '' })
		}
	}, [authorized, forgotPassword])
	useEffect(() => {
		if (alert.type.length > 0) {
			setAlert({ type: '', message: '' })
		}
	}, [])

	return (
		<div className='h-screen flex'>
			<div className='w-1/2 h-full flex bg-[#0066CC]'></div>
			<div className='w-1/2 h-full flex flex-col justify-center items-center'>
				{authorized ? (
					<Form
						key='LoginForm'
						layout='vertical'
						className='w-1/2'
						form={form}
						onFinish={(values) => authorization(forgotPassword, values.email + '@bridgelinxpk.com', values.password)}
					>
						<div className='w-full flex flex-col items-center'>
							<img className='bridgelinx-logo ' src={BridgelinxLogo} alt='' />
							{alert.type !== '' && AlertBanner(alert, setAlert)}
						</div>
						{RenderSchema(LoginFormSchema({ forgotPassword: forgotPassword }))}
						<div className='w-full pt-4 text-center'>
							<Form.Item>
								<Button className='btn-primary' htmlType='submit' disabled={loading} loading={loading} block>
									{loading ? 'Please Wait' : !forgotPassword ? 'Login' : 'Confirm'}
								</Button>
							</Form.Item>
							{!forgotPassword ? (
								<Form.Item>
									<a className={`btn-dashed ${loading && 'no-hover'}`} href='/#' onClick={() => !loading && setForgotPassword(true)} disabled={loading}>
										Forgot Password?
									</a>
								</Form.Item>
							) : (
								<Form.Item>
									<a className={`btn-dashed ${loading && 'no-hover'}`} href='/#' onClick={() => !loading && setForgotPassword(false)} disabled={loading}>
										Try Logging in Again?
									</a>
								</Form.Item>
							)}
						</div>
					</Form>
				) : (
					<UnAuthorizedResult
						onClickFunction={() => {
							form.resetFields()
							setAuthorized(true)
						}}
					/>
				)}
			</div>
		</div>
	)
}

export default Login
